import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

export default function RejectReason({
  onReject,
  onCloseReject,
  value,
}: {
  onReject: (a: string) => void;
  onCloseReject: () => void;
  value: object;
}) {
  const [previewReason, setPreviewReason] = useState<string[]>([]);
  let rejectValue = Object.entries(value).filter((arr: any[]) => arr[1] === false);
  const { t } = useTranslation();

  return (
    <Box sx={{ px: 2, pb: 2 }}>
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={2}>
          {" "}
          <IconButton size="large" edge="start" aria-label="menu" sx={{ mr: 2 }} onClick={onCloseReject}>
            <ClearIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} sx={{ pr: 9 }}>
          <h3 style={{ textAlign: "center", borderBottom: "0.2px solid #F5F5F9", padding: "1rem" }}>عدم تایید بازی </h3>
        </Grid>
      </Grid>
      {rejectValue.map((i: any) => (
        <>
          <Typography sx={{ fontSize: "12px", pb: 1 }}>{` عدم تایید ${t(i[0])} `} </Typography>
          <textarea
            name="reason"
            //onChange={handleChange}
            onBlur={(e: any) => setPreviewReason((prev: any) => [...prev, e.target.value])}
            cols={30}
            rows={8}
            style={{
              width: "100%",
              borderRadius: "8px",
              paddingRight: "1rem",
              paddingLeft: "1rem",
              paddingTop: "1rem",
            }}
          ></textarea>
        </>
      ))}
      <ul>
        {" "}
        {previewReason.map((i: any) => (
          <li>{i}</li>
        ))}
      </ul>
      <Button variant="contained" fullWidth onClick={() => onReject(previewReason.join(","))}>
        عدم تایید و ارسال
      </Button>
    </Box>
  );
}
