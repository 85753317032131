import { useTranslation } from "react-i18next";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { BackButton, DatePicker, Img, TextField, TimePicker } from "components";
import { ItemContainer, LimitedTextField, SubItemContainer } from "./components/NewLeagueItems";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  Box,
  Button,
  Autocomplete,
  MenuItem,
  Typography,
  Grid,
  Select,
  InputAdornment,
  Divider,
  Chip,
  IconButton,
} from "@mui/material";
import { DateObject } from "react-multi-date-picker";
import PageContainer from "components/PageContainer";
import LeagueUploader from "components/uploader/leagueUploader";
import { useEffect, useState } from "react";
import ArrowLeftBlack from "assets/icons/left-arrow-icon-black.svg";
import PlusBlack from "assets/icons/Add_Plus-black.svg";
import { numberToWords } from "@persian-tools/persian-tools";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { ILeagueType } from "api/league";
import { formatDateObjToTime, formatJallali, formatTime } from "utils/date";
import { useNewLeague } from "./useNewLeague";
import { NumeralThousandGroupStyles, formatNumeral } from "cleave-zen";
import { ITagType } from "api/tags";
import Cancel from "@mui/icons-material/Cancel";

const filedStyle = {
  borderRadius: "5px",
  border: "1px solid grey",
  height: "45px",
  marginTop: "3px",
  paddingRight: "5px",
  paddingLeft: "5px",
};

const NewLeague = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [leagueId, setLeagueId] = useState(undefined);
  const { t } = useTranslation();

  const { gameList, tagList, submitLeague, onDateChange, initialValues, onAutocompleteChange, onNumericFieldChange } =
    useNewLeague(leagueId, navigate);

  const [value, setValue] = useState<{
    gameId: string;
    gameTitle: string;
    gameCoverImage: string;
  }>(gameList[0]);

  const { data: leagues } = useSWR<{ count: number; leagues: ILeagueType[] }>(leagueId ? "/leagues" : "");
  const leagueDetail = leagues?.leagues.filter((league: ILeagueType) => league?._id === leagueId) || [];
  const { data: tags, mutate } = useSWR<{ tags: ITagType[]; count: number }>(`game/tags?limit=0`);

  const [inputValue, setInputValue] = useState<string>(value?.gameTitle);
  const [openAddNewPrize, setOpenAddNewPrize] = useState(false);
  const [prizeType, setPrizeType] = useState<string[]>(["singlePosition"]);
  const [currentTag, setCurrentTags] = useState<any[]>([]);
  const [tagValue, setTagValue] = useState<any>(leagueDetail[0]?.tags);
  const [tagInputValue, setTagInputValue] = useState<string>();
  const [tagListElement, setTagListElement] = useState<any>([]);

  useEffect(() => {
    tagInputValue && setTagListElement((prev: any) => [...prev, tagInputValue]);
  }, [tagInputValue]);
  useEffect(() => {
    const filteredTags = tagList?.filter((tag: any) => tag?.gameId === value?.gameId) || [];
    setCurrentTags(filteredTags);
  }, [value]);

  const urlToFile = async (url: string) => {
    return await fetch(url).then((r) => r.blob());
  };
  const [bannerImage, setBannerImage] = useState<Blob>();
  const [gif, setGif] = useState<Blob>();

  const formik: any = useFormik({
    initialValues: initialValues,
    onSubmit: submitLeague,
  });
  //note:this useEffect handle filling formik input in updateleague state anf for the create new league is useless
  useEffect(() => {
    if (location.state) {
      setLeagueId((location.state as any)?.id);
    }
    if (leagueDetail[0]) {
      leagueDetail[0]?.prizes.forEach((i: any, index: number) => {
        index !== 0 && i.fromRank === i.toRank
          ? setPrizeType((prev: any) => [...prev, "singlePosition"])
          : index !== 0 && setPrizeType((prev) => [...prev, "range"]);
      });
      leagueDetail[0]?.bannerImage?.url &&
        urlToFile(leagueDetail[0]?.bannerImage?.url).then((file) => setBannerImage(file));
      leagueDetail[0]?.gif?.url && urlToFile(leagueDetail[0]?.gif?.url).then((file) => setGif(file));
      formik.setFieldValue("colorCode", leagueDetail[0]?.colorCode);
      formik.setFieldValue("totalPrize", leagueDetail[0]?.totalPrize);
      formik.setFieldValue("description", leagueDetail[0]?.description);
      formik.setFieldValue("title", leagueDetail[0]?.title);
      formik.setFieldValue("gameId", leagueDetail[0].game?._id);
      formik.setFieldValue("startDate", leagueDetail[0].startDate);
      formik.setFieldValue("endDate", leagueDetail[0].endDate);
      formik.setFieldValue("gifId", leagueDetail[0].gif?._id);
      formik.setFieldValue("bannerImageId", leagueDetail[0].bannerImage?._id);
      formik.setFieldValue("startTime", formatTime(new Date(leagueDetail[0]?.startDate)));
      formik.setFieldValue("prizes", leagueDetail[0]?.prizes);
      formik.setFieldValue("tags", leagueDetail[0]?.tags);
      setInputValue(leagueDetail[0]?.game.title);
      setValue({
        gameId: leagueDetail[0].game?._id,
        gameTitle: leagueDetail[0]?.game?.title,
        gameCoverImage: leagueDetail[0]?.game?.coverImage?.url,
      });
      leagueDetail[0]?.tags.length > 0 &&
        setCurrentTags([{ gameId: leagueDetail[0]?.game?._id, gameTags: leagueDetail[0]?.tags }]);
      setTagListElement(leagueDetail[0]?.tags);
    }
  }, [location.state, leagues]);

  return (
    <PageContainer>
      <Grid
        sx={{
          width: "100%",
          justifyContent: "center",
          textAlign: "center",
          backgroundColor: "white",
          borderRadius: "1.7rem",
          mb: "2rem",
          minheight: "90rem",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "1rem 4rem 1rem 10rem",
            }}
          >
            <BackButton />
          </Box>
          <Box sx={{ display: "flex", my: 2 }}>
            <Typography
              sx={{
                fontWeight: 600,
                borderRight: "2px solid black",
                height: "20px",
                width: "120px",
                ml: "10rem",
              }}
            >
              ایجاد لیگ جدید
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                height: "20px",
                justifyContent: "end",
                width: "80px",
              }}
            >
              45750
            </Typography>
          </Box>

          <Box
            sx={{
              padding: "3.5rem 4rem 5rem 11rem",
            }}
          >
            <ItemContainer title="chooseGame">
              <Autocomplete
                disabled={Boolean(leagueId)}
                id="games"
                options={gameList}
                value={value}
                inputValue={inputValue}
                onChange={(event: any, val: any) => {
                  setValue(val);
                  onAutocompleteChange(formik.setFieldValue, val.gameTitle, val.gameId);
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    // error={Boolean(formik.errors.gameId || formik.touched.gameId)}
                    {...params}
                    placeholder="بازی مورد نظر را جست‌وجو و انتخاب کنید"
                  />
                )}
                getOptionLabel={(option: any) => option.gameTitle}
                renderOption={(props, option: any) => (
                  <MenuItem
                    {...props}
                    key={option?.gameId}
                    value={option?.gameId}
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <Img
                      src={option?.gameCoverImage}
                      alt={option?.gameTitle}
                      sx={{
                        width: "3rem",
                        height: "3rem",
                        borderRadius: "0.5rem",
                      }}
                    />

                    <Typography>{option.gameTitle}</Typography>
                  </MenuItem>
                )}
              />
            </ItemContainer>
            <ItemContainer title="chooseTags" subtitle="حداکثر ۵ برچسب">
              <Autocomplete
                id="tags"
                options={tags?.tags.map((tag: any) => tag.title) || []}
                value={tagValue}
                inputValue={tagInputValue}
                onChange={(event: any, val: any) => {
                  setTagValue(val);
                }}
                onInputChange={(event: any, newInputValue) => {
                  if (!formik.values.tags.includes(newInputValue) && formik.values.tags.length < 5) {
                    setTagInputValue(newInputValue);
                    formik.setFieldValue("tags", [...formik.values.tags, newInputValue]);
                  }
                }}
                renderInput={(params) => <TextField {...params} placeholder="جست‌وجو و انتخاب برچسب" />}
                getOptionLabel={(option: any) => option}
                renderOption={(props, option: any) => (<Typography {...props}>{option}</Typography>) as JSX.Element}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                {tagListElement.map((i: any, index: number) => (
                  <Chip
                    key={index}
                    sx={{ bgcolor: "#676872", color: "white", borderRadius: "8px", px: 2, mx: 1, mt: 2 }}
                    label={i}
                    onDelete={() => setTagListElement((prev: any) => prev.filter((item: any) => item !== i))}
                    deleteIcon={<Cancel style={{ fill: "white" }} />}
                  />
                ))}
              </Box>
            </ItemContainer>

            <ItemContainer title="leagueTitle">
              <LimitedTextField
                maxLength={25}
                {...formik.getFieldProps("title")}
                // helperText={formik.errors.title || formik.touched.title}
                // error={Boolean(formik.errors.title)}
              />
            </ItemContainer>

            <ItemContainer title="leagueSDate">
              <Box
                sx={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  onChange={(date: DateObject) => onDateChange(formik.setFieldValue, "startDate", date)}
                  value={formatJallali(formik.values.startDate)}
                  // helperText={formik.errors.startDate}
                  // error={Boolean(formik.errors.startDate)}
                />

                <SubItemContainer title="startTime" titleWidth="6rem">
                  <TimePicker
                    onChange={(date: DateObject) =>
                      formik.setFieldValue("startTime", formatDateObjToTime(date.toDate()))
                    }
                    value={formik.values.startDate}
                    // helperText={formik.errors.startTime}
                    // error={Boolean(formik.errors.startTime)}
                  />
                </SubItemContainer>
              </Box>
            </ItemContainer>

            <ItemContainer title="leagueEndLeague">
              <Box
                sx={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  onChange={(date: DateObject) => onDateChange(formik.setFieldValue, "endDate", date)}
                  // helperText={formik.errors.endDate}
                  value={formatJallali(formik.values.endDate)}
                  // error={Boolean(formik.errors.endDate)}
                />

                <SubItemContainer title="endTime" titleWidth="6rem">
                  <TimePicker
                    onChange={(date: DateObject) => formik.setFieldValue("endTime", formatDateObjToTime(date.toDate()))}
                    // helperText={formik.errors.endTime}
                    value={formik.values.endDate}
                    // error={Boolean(formik.errors.endTime)}
                  />
                </SubItemContainer>
              </Box>
            </ItemContainer>

            <ItemContainer title="leagueDescription">
              <TextField
                multiline
                fullWidth
                // helperText={errors.description}
                rows={5}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "12rem",
                  },
                }}
                {...formik.getFieldProps("description")}
              />
            </ItemContainer>

            <ItemContainer title="leaguePrizes">
              <FormikProvider value={formik}>
                <FieldArray
                  name="prizes"
                  render={(arrayHelpers) => (
                    <Box>
                      {formik.values.prizes.map((prize: any, index: number) => (
                        <Box key={index} sx={{ position: "relative" }}>
                          {prizeType[index] === "singlePosition" && (
                            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 3fr ", gap: "1rem", mb: ".5rem" }}>
                              <Select
                                onChange={(e: any) => {
                                  formik.setFieldValue(`prizes[${index}].prizeType`, e.target.value);
                                }}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                name={`prizes[${index}].prizeType`}
                                value={prize.prizeType}
                              >
                                <MenuItem value={"cash"}>نقدی</MenuItem>
                                <MenuItem value={"merchandise"}>غیر نقدی</MenuItem>
                              </Select>

                              <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <TextField
                                  fullWidth
                                  name={`prizes[${index}].prize`}
                                  value={prize.prize}
                                  onChange={(e) => {
                                    formik.setFieldValue(`prizes[${index}].prize`, formatNumeral(e.target.value), {
                                      numeralThousandsGroupStyle: NumeralThousandGroupStyles.THOUSAND,
                                    });
                                    formik.setFieldValue(
                                      `prizes[${index}].fromRank`,
                                      formik.values.prizes[index - 1].toRank + 1
                                    );
                                    formik.setFieldValue(
                                      `prizes[${index}].toRank`,
                                      formik.values.prizes[index - 1].toRank + 1
                                    );
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Typography sx={{ borderRight: "1px solid #676872", pr: "1rem" }}>
                                          {index !== 0
                                            ? ` نفر
                                            ${numberToWords((formik.values.prizes[index - 1].toRank + 1).toString(), {
                                              ordinal: true,
                                            })}`
                                            : "نفر اول"}
                                        </Typography>
                                      </InputAdornment>
                                    ),
                                  }}
                                  endAdornment={
                                    prize.prizeType === "cash" ? (
                                      <InputAdornment position="start">
                                        <Typography sx={{ color: "#676872" }}> تومان</Typography>
                                      </InputAdornment>
                                    ) : (
                                      ""
                                    )
                                  }
                                  placeholder={prize.prizeType === "cash" ? "..." : "ارزش را به تومان وارد کنید."}
                                  sx={{ border: "none" }}
                                />
                              </Box>
                            </Box>
                          )}
                          {prizeType[index] === "range" && (
                            <>
                              <Box sx={{ display: "flex", my: 1, justifyContent: "space-between" }}>
                                <Box sx={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
                                  <Typography sx={{ pt: 2 }}>از نفر:</Typography>

                                  <Field
                                    style={{
                                      ...filedStyle,
                                    }}
                                    type={"number"}
                                    placeholder="انتخاب کنید"
                                    min={formik.values.prizes[index - 1].toRank + 1}
                                    {...formik.getFieldProps(`prizes[${index}].fromRank`)}
                                  />
                                </Box>
                                <Box sx={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
                                  <Typography sx={{ pt: 2 }}>تا نفر:</Typography>
                                  <Field
                                    style={{
                                      ...filedStyle,
                                    }}
                                    placeholder="انتخاب کنید"
                                    min={formik.values.prizes[index].fromRank + 1}
                                    type={"number"}
                                    {...formik.getFieldProps(`prizes[${index}].toRank`)}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{ display: "grid", gridTemplateColumns: "1fr 3fr", gap: "1rem", mb: ".5rem" }}>
                                <Select
                                  fullWidth
                                  onChange={(e: any) =>
                                    formik.setFieldValue(`prizes[${index}].prizeType`, e.target.value)
                                  }
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  name={`prizes[${index}].prizeType`}
                                  value={prize.prizeType}
                                >
                                  <MenuItem value={"cash"}>نقدی</MenuItem>
                                  <MenuItem value={"merchandise"}>غیر نقدی</MenuItem>
                                </Select>
                                <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                  <TextField
                                    fullWidth
                                    name={`prizes[${index}].prize`}
                                    onChange={(e: any) =>
                                      formik.setFieldValue(`prizes[${index}].prize`, formatNumeral(e.target.value), {
                                        numeralThousandsGroupStyle: NumeralThousandGroupStyles.THOUSAND,
                                      })
                                    }
                                    value={prize.prize}
                                    endAdornment={
                                      prize.prizeType === "cash" ? (
                                        <InputAdornment position="start">
                                          <Typography sx={{ color: "#676872" }}> تومان</Typography>
                                        </InputAdornment>
                                      ) : (
                                        ""
                                      )
                                    }
                                    placeholder="..."
                                    sx={{ border: "none" }}
                                  />
                                </Box>
                              </Box>
                            </>
                          )}
                          {formik.values.prizes.length - 1 === index && formik.values.prizes.length - 1 !== 0 && (
                            <IconButton sx={{ position: "absolute", top: 10, right: -30 }}>
                              <DeleteOutlinedIcon
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                  setPrizeType((prevArray) => {
                                    const newArray = [...prevArray];
                                    newArray.pop();
                                    return newArray;
                                  });
                                }}
                              />
                            </IconButton>
                          )}
                        </Box>
                      ))}

                      <Typography
                        onClick={() => setOpenAddNewPrize(true)}
                        sx={{
                          color: "#4A76FD",
                          display: "flex",
                          justifyContent: "flex-start",
                          mt: "0.5rem",
                          cursor: "pointer",
                        }}
                      >
                        + افزودن جایگاه جدید
                      </Typography>
                      {openAddNewPrize && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "8px",
                            boxShadow: "0px 0px 6px 0px #0000004D",
                            bgcolor: "#FFF",
                            p: "10px",
                            justifyContent: "flex-start",
                            width: "180px",
                          }}
                        >
                          <Box
                            onClick={() => {
                              setPrizeType([...prizeType, "singlePosition"]);
                              arrayHelpers.push({
                                fromRank: formik.values.prizes[formik.values.prizes.length - 1].fromRank + 1,
                                toRank: formik.values.prizes[formik.values.prizes.length - 1].fromRank + 1,
                                prize: null,
                                prizeType: "cash",
                              });
                              setOpenAddNewPrize(false);
                            }}
                            sx={{ display: "flex", gap: "0.5rem", alignItems: "center", cursor: "pointer" }}
                          >
                            <img src={ArrowLeftBlack} alt="" />
                            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>تعیین یک جایگاه</Typography>
                            <img src={PlusBlack} alt="" />
                          </Box>

                          <Divider sx={{ my: "0.5rem" }} />

                          <Box
                            onClick={() => {
                              arrayHelpers.push({
                                fromRank: "",
                                toRank: "",
                                prize: null,
                                prizeType: "cash",
                              });
                              setPrizeType([...prizeType, "range"]);
                              setOpenAddNewPrize(false);
                            }}
                            sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
                          >
                            <img src={ArrowLeftBlack} alt="" />
                            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>تعیین بازه جایگاه</Typography>
                            <img src={PlusBlack} alt="" />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                />
              </FormikProvider>
            </ItemContainer>

            <ItemContainer title="totalPrize">
              <TextField
                endAdornment={
                  <InputAdornment position="start">
                    <Typography sx={{ color: "#676872" }}> تومان</Typography>
                  </InputAdornment>
                }
                fullWidth
                name="totalPrize"
                onChange={onNumericFieldChange(formik.setFieldValue)}
                value={formatNumeral(`${formik.values.totalPrize}`, {
                  numeralThousandsGroupStyle: NumeralThousandGroupStyles.THOUSAND,
                })}
                FormHelperTextProps={{ style: { color: "black", fontSize: "10px", opacity: "70%" } }}
                helperText={
                  !formik.errors.totalPrize &&
                  `مثال:${formatNumeral("1000000", {
                    numeralThousandsGroupStyle: NumeralThousandGroupStyles.THOUSAND,
                  })} `
                }
                placeholder="ارزش مالی مجموع جوایز را وارد کنید"
              />
            </ItemContainer>

            <ItemContainer title="leagueCardColorCode">
              <TextField
                fullWidth
                name="colorCode"
                onChange={onNumericFieldChange(formik.setFieldValue)}
                value={formik.values.colorCode}
                FormHelperTextProps={{ style: { color: "black", fontSize: "10px", opacity: "70%" } }}
                placeholder="کد رنگی کارت لیگ را وارد کنید"
              />
            </ItemContainer>

            <ItemContainer title="leagueImage" type={"imageUploader"}>
              <LeagueUploader
                defaultPicture={leagueDetail[0]?.bannerImage?.url}
                defaultFileId={leagueDetail[0]?.bannerImage?._id}
                defaultValue={bannerImage}
                type="image"
                fieldTitle="bannerImageId"
                flow="authEdit"
                onChange={(event, a: string | boolean, b: any) => formik.setFieldValue(event, a)}
              />
            </ItemContainer>
            <ItemContainer title="showInSlider" type={"gifUploader"}>
              <LeagueUploader
                type="image"
                fieldTitle="gifId"
                defaultPicture={leagueDetail[0]?.gif?.url}
                defaultFileId={leagueDetail[0]?.gif?._id}
                defaultValue={gif}
                flow="authEdit"
                onChange={(event, a: string | boolean, b: any) => formik.setFieldValue(event, a)}
              />
            </ItemContainer>
            <Box
              sx={{
                bgColor: "red",
                display: "flex",
                textAlign: "center",
                width: "500px",
                mx: "20rem",
                justifyContent: "space-between",
              }}
            >
              <Button type="submit" variant="contained" sx={{ px: 10, py: 1.1 }}>
                {t("saveLeague")}
              </Button>
              <Button
                onClick={() =>
                  formik.resetForm({
                    values: {
                      ...formik.values,
                      title: "",
                      startDate: "",
                      description: "",
                      gameId: "",
                      endDate: "",
                      bannerImageId: "",
                      gifId: "",
                      totalPrize: "",
                    },
                  })
                }
                variant="contained"
                sx={{ px: 10, bgcolor: "#676872", py: 1.1 }}
              >
                {t("giveUp")}
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
    </PageContainer>
  );
};

export default NewLeague;
