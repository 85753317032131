import { Box, Button, Grid, Typography } from "@mui/material";
import { adsType } from "api/ads";
import PageContainer from "components/PageContainer";
import moment from "jalali-moment";
import { useOutletContext } from "react-router-dom";
import tapcell from "assets/tapcel-logo.svg";
import yaktanet from "assets/yektanet-logo.svg";
import googleAds from "assets/adivery-logo.svg";
import inner from "assets/innerBanner.svg";
import left from "assets/leftBanner.svg";
import bottom from "assets/bannerBottom.svg";

type AdListDetailsContext = [adsType[]];

export default function Detail() {
  const [filterAdsById] = useOutletContext<AdListDetailsContext>();
  const infoList = [
    {
      title: "شرکت تبلیغاتی",
      content: (
        <Box sx={{ display: "flex", gap: 1 }}>
          <img
            src={
              filterAdsById[0].provider == "yektanet"
                ? yaktanet
                : filterAdsById[0].provider === "tapcell"
                ? tapcell
                : googleAds
            }
            alt=""
          />
          <Typography sx={{ pt: 1 }}>
            {filterAdsById[0].provider == "yektanet"
              ? "یکتانت "
              : filterAdsById[0].provider === "tapcell"
              ? "تپسل"
              : "ادیوری"}
          </Typography>
        </Box>
      ),
    },
    {
      title: "الویت تبلیغ ",
      content: (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <input
            value={4}
            style={{
              borderRadius: "3px",
              border: "1px solid #676872",
              width: "36px",
              height: "36px",
              textAlign: "center",
            }}
          />
          <Button sx={{ bgcolor: "#333337", color: "white", heightL: "36px", width: "82px", fontSize: "10px", mx: 1 }}>
            به روز رسانی
          </Button>
        </Box>
      ),
    },
    {
      title: "نوع تبلیغ",
      content: (
        <Typography sx={{ fontSize: "14px", py: 0.5 }}>
          {filterAdsById[0].type === "banner" ? "بنر" : "ویدیو"}
        </Typography>
      ),
    },
    {
      title: "جایگاه",
      content: (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={
              filterAdsById[0].location === "site-left"
                ? left
                : filterAdsById[0].location === "site-bottom"
                ? bottom
                : filterAdsById[0].location === "site-inner"
                ? inner
                : inner
            }
            alt=""
          />
          <Typography sx={{ fontSize: "14px", width: "200px", px: 1 }}>
            {filterAdsById[0].location === "site-left"
              ? "بنر سمت چپ ۳۳۵*۳۶۸"
              : filterAdsById[0].location === "site-inner"
              ? "بنر داخل بازی ۵۴۰*۱۰۳۷"
              : "بنر پایین ۱۰۰*۱۰۳۷"}
          </Typography>
        </Box>
      ),
    },
    {
      title: "شناسه یکتا",
      content: (
        <Box sx={{ display: "flex", alignItems: "center"}}>
          <Typography sx={{width: "250px", fontSize: "14px", fontWeight: "bold" }}>
            {filterAdsById[0].uniqueIdentifier}
          </Typography>
        </Box>
      ),
    },
    {
      title: "کد تبلیغ",
      content: (
        <Box
          sx={{
            borderRadius: "4px",
            border: "1px solid #676872",
            fontSize: "14px",
            minHeight: "120px",
            minWidth: "250px",
            p: 1,
            justifyContent: "end",
            display: "flex",
          }}
        >
          {filterAdsById[0].type === 'video' ? filterAdsById[0]?.videoAds?.code : filterAdsById[0]?.bannerAds?.code}
        </Box>
      ),
    },
  ];

  return (
    <PageContainer noPadding={true}>
      <Grid
        container
        sx={{
          backgroundColor: "white",
          py: 2,
          mb: 2,
        }}
      >
        <Grid item xs={5}>
          {infoList.map((info: any, index: number) => {
            return (
              <Box key={index} sx={{ display: "flex", justifyContent: "space-between", py: 1 }}>
                <Typography sx={{ fontSize: "14px", py: 0.5 }}>{info.title}</Typography>
                <Box sx={{ width: "120px", display: "flex", justifyContent: "start" }}>{info.content}</Box>
              </Box>
            );
          })}
        </Grid>{" "}
      </Grid>
    </PageContainer>
  );
}
