import { Grid, Box, Typography, Avatar, Button } from "@mui/material";
import { IUserType, updateUser } from "api/user";
import activeUser from "assets/activeUser.svg";
import deactiveUser from "assets/deactiveUser.svg";
import { toast } from "react-toastify";
import { formatJallali } from "utils/date";

const UserSummery = ({ userInfo, onMutate }: { userInfo: IUserType | undefined; onMutate: () => void }) => {
  const handleChangeUserStatus = async () => {
    try {
      await updateUser({ active: !userInfo?.active }, userInfo?._id);
      toast.success(`وضعیت کاربر به ${userInfo?.active ? "غیرفعال" : "فعال"} تغییر یافت`);
      onMutate();
    } catch {
      toast.error("خطایی رخ داده است");
    }
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ pr: 3, pt: 3 }}>
        <Grid xs={2} item sx={{ height: 140, width: 100 }}>
          <Avatar
            sx={{ height: "90px", width: "90px" }}
            src={userInfo?.profilePicture?.url || userInfo?.coverImage?.url}
          />
        </Grid>
        <Grid xs={4} item sx={{ height: 140, width: 100 }}>
          <Box>
            <Typography sx={{ fontSize: "18px", fontWeight: "500", py: 0.5, color: "#333337" }}>
              {userInfo?.fullname}
            </Typography>
            <Typography sx={{ py: 0.5, fontSize: "16px" }}> {userInfo?.username}</Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ py: 0.5, color: "#676872" }}> تاریخ ثبت نام</Typography>
              <Typography sx={{ py: 0.5, color: "#333337", fontWeight: 600 }}>
                {formatJallali(userInfo?.createdAt)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ py: 0.5, color: "#676872" }}> وضعیت کاربر</Typography>
              <Typography sx={{ py: 0.5, color: "#333337", fontWeight: 600 }}>
                {userInfo?.active === true ? "فعال" : "غیرفعال"}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ mt: "92px", display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => handleChangeUserStatus()}>
              {userInfo?.active === false ? (
                <img alt="" src={activeUser} width={68} height={32} />
              ) : (
                <img alt="" src={deactiveUser} width={68} height={32} />
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default UserSummery;
