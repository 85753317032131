import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import IdentityDetails from "./identityDetails";
import { IGameType } from "api/game";
import { Box, Divider } from "@mui/material";
import GameDetails from "./gameDetails";
import { MyTypography } from "pages/games/components/GamesListItem";
import Preview from "./preview";
import RadioButtonsGroup from "./radioButton";
import ControlledCheckbox from "./qc&Security";
import History from "./history";
import Version from "./versions";
import AdvertisementForms from "./advertisementForms";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    marginTop: "1rem",
    marginBottom: "1rem",
    border: `1px solid ${theme.palette.divider}`,
    "&:before": {
      display: "none",
    },
  })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const advertisementTitle = [{ title: "سرویس تبلیغاتی گوگل:" }, { title: "سرویس تبلیغاتی یکتانت:" }];

export default function CustomizedAccordions({
  game,
  gameVersion,
  onSetValue,
}: {
  game: IGameType;
  gameVersion: any;
  onSetValue: (e: any, a: string) => void;
}) {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [chooseAccordion, setChooseAccordion] = React.useState<number>(-1);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const details = [
    {
      title: "مشخصات ",
      expanded: "panel1",
      accordionDetails: <IdentityDetails game={game} />,
      name: "info",
    },
    {
      title: "جزییات",
      expanded: "panel2",
      accordionDetails: <GameDetails game={game} />,
      lable: { accept: "تایید جزییات بازی  ", reject: "عدم تایید جزییات بازی" },
      name: "detail",
    },
    {
      title: "لینک های دیگر",
      expanded: "panel3",
      accordionDetails: (
        <ul>
          {gameVersion?.game?.otherMarketsLinks.map((i: any) => (
            <li>
              <MyTypography>{i || "فیلدهای لینک  را نمی دانم "}</MyTypography>
            </li>
          ))}{" "}
        </ul>
      ),
      lable: { accept: "تایید لینک های بازی  ", reject: "عدم تایید لینک های بازی" },
      name: "link",
    },
    {
      title: "تصاویر و آیکون ها",
      expanded: "panel4",
      accordionDetails: <Preview game={game} />,
      lable: { accept: "تایید تصاویر بازی", reject: "عدم تایید تصاویر بازی" },
      name: "image",
    },
    {
      title: "ورژن ها",
      expanded: "panel5",
      accordionDetails: <Version versions={game?.versions} />,
      name: "version",
    },
    {
      title: " تبلیغات",
      expanded: "panel6",
      accordionDetails: (
        <Box>
          {advertisementTitle.map((i: any, index: number) => {return(

            <div key={index}>
              <Typography sx={{fontSize:"14px",fontWeight:"bold",py:3}}>
                {i.title}
              </Typography>
              <AdvertisementForms setValue={(a,e)=>onSetValue(a,e)}/>
              {index===0 && <Divider/>}
            </div>
        )
          })}
        </Box>
      ),
      name: "version",
    },
    {
      title: "بررسی کیفیت و امنیت",
      expanded: "panel7",
      accordionDetails: <ControlledCheckbox />,
      lable: { accept: "تایید کیفیت و امنیت", reject: "عدم تایید کیفیت و امنیت" },
      name: "security",
    },
    {
      title: "تاریخچه بررسی بازی ها",
      expanded: "panel8",
      accordionDetails: <History game={game} />,
      name: "history",
    },
  ];
  return (
    <div>
      {details.map((item: any, index: number) => (
        <Box key={index}>
          <Accordion expanded={expanded === (item.expanded as string)} onChange={handleChange(item.expanded)}>
            <AccordionSummary onClick={() => setChooseAccordion(index)}>
              <Typography sx={{ fontWeight: 600, color: chooseAccordion === index ? "#4A76FD" : "unset" }}>
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item.accordionDetails}</Typography>

              {item.lable && (
                <>
                  <Divider />
                  <RadioButtonsGroup
                    onSetValue={(e: any, a: string) => {
                      onSetValue(e, a);
                    }}
                    lable={item.lable}
                    name={item.name}
                  />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </div>
  );
}
