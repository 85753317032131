import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  CircularProgress,
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Divider,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import { CloseRounded } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { createTag } from "api/tags";

const schema = Yup.object({
  title: Yup.string()
    .min(2, " باید از 2 کاراکتر بیشتر باشد ")
    .max(15, " بیشتر از 15 کاراکتر مجاز نیست")
    .required("نام برچسب اجباری است"),
  description: Yup.string().required("توضیحات الزامی است"),
});

export default function AddTicketCategory({
  open,
  onClose,
  onMutate,
}: {
  open: boolean;
  onClose?: () => void;
  onMutate: () => void;
}) {
  const {
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    isValid,
    touched,
    errors,
    getFieldProps,

    resetForm,
  } = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      title: "",
      description: "",
    },

    onSubmit: async (data) => {
      try {
        setSubmitting(true);
        await createTag(data);
        toast.success("با موفقیت بارگزاری شد");
        onMutate();
      } catch (error) {
        toast.error(String(error));
      } finally {
        setSubmitting(false);
        onClose && onClose();
        resetForm({ values: { title: "", description: "" } });
      }
    },
  });

  const [role, setRole] = useState("");
  const handleChange = (event: any) => {
    setFieldValue("userRoleId", event?.target.value);
    setRole(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
    >
      <CloseRounded sx={{ cursor: "pointer", mx: 2, my: 2 }} onClick={onClose} />
      <Divider />
      <Box sx={{ p: 2, width: "400px" }}>
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <>
            <Typography sx={{ fontSize: "12px", py: 1 }}>نام برچسب</Typography>
            <TextField
              {...getFieldProps("title")}
              type="text"
              error={Boolean(touched.title && errors.title)}
              fullWidth
              sx={{ my: 2, background: "#F5F5F9" }}
              size="small"
            />
            {errors.title && <FormHelperText>{errors.title}</FormHelperText>}
            <Typography sx={{ fontSize: "12px", py: 1 }}> توضیحات </Typography>
            <TextareaAutosize
              {...getFieldProps("description")}
              //error={Boolean(touched.description && errors.description)}

              minRows={3}
              style={{
                marginTop: 2,
                marginBottom: 2,
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                fontSize: "14px",
                fontFamily: "inherit",
                resize: "vertical",
                background: "#F5F5F9",
              }}
            />

            {errors.description && <FormHelperText>{errors.description}</FormHelperText>}
            {isSubmitting ? (
              <CircularProgress />
            ) : (
              <Button fullWidth variant="contained" type="submit" sx={{ mb: 1 }} disabled={isSubmitting || !isValid}>
                افزودن دسته بندی جدید
              </Button>
            )}
          </>
        </form>
      </Box>
    </Dialog>
  );
}
