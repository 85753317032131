import { Box, Grid, Typography } from "@mui/material";
import { ILeagueType } from "api/league";
import BackButton from "components/backButton/BackButton";

export default function LeagueDefaultDetails({ league }: { league?: ILeagueType }) {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mt: 2, ml: 2 }}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ borderRight: "2px solid grey", pr: 1, fontSize: "14px" }}>{league?.title}</Typography>
          <Typography sx={{ px: 1 }}>۴۵۷۱۰</Typography>
        </Box>
        <Grid container>
          <Grid item xs={1.5}>
            <img src={league?.bannerImage?.url} style={{ borderRadius: "8px" }} alt="" width={55} height={55} />
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ display: "felx", mt: 0.5 }}>
              <Typography sx={{ fontSize: "12px", opacity: "0.85" }}>تعداد بازی‌های انجام شده لیگ :</Typography>
              <Typography> {league?.totalPlayTimeAmount }</Typography>
            </Box>
            <Box sx={{ display: "felx", mt: 1.5 }}>
              <Typography sx={{ fontSize: "12px", opacity: "0.85",mt:0.25 }}> تعداد افراد شرکت کرده در لیگ :   </Typography>
              <Typography>  {league?.totalPlayersPlayedCount} </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
