import useNewGame from "./useNewGame";
import GamePreview from "./components/GamePreview";
import AddGameForm from "./components/AddGameForm";

const NewGame = () => {
  const { categories, isGamePreview, addGameProps, previewProps } = useNewGame();

  return isGamePreview ? (
    <GamePreview {...{ previewProps, categories }} />
  ) : (
    <AddGameForm {...{ categories, addGameProps }} />
  );
};

export default NewGame;
