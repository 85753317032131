import useGames from "./useGames";
import { ICONS } from "assets/icons";
import { useLocation } from "react-router-dom";
import { GAME_STATUS } from "types/game";
import { useTranslation } from "react-i18next";
import GamesList from "./components/GamesList";
import { Box, Button, Grid, Tab, Tabs } from "@mui/material";
import { Checkbox, Img, TabPanel } from "components";
import PageContainer from "components/PageContainer";
import { useEffect, useState } from "react";

const Games = () => {
  const [initialTabValue, setInitialTabValue] = useState<GAME_STATUS>(GAME_STATUS.PEND);
  const { t } = useTranslation();
  const { tabValue, onChangeTab, games, page, handleChange,count } = useGames(initialTabValue);
  const [fullCheckbox, setFullCheckbox] = useState(false);
  const location:any=useLocation()
  useEffect(()=>{
    if(location && location.state && location.state.tabValue){
      setInitialTabValue(location.state.tabValue)
    }
  },[location])
  return (
    <PageContainer>
      <Grid sx={{ backgroundColor: "white", borderRadius: "1.7rem", mb: 2 }}>
        <Box sx={{ pl: 1, pb: 1 }}>
          <Checkbox checked={fullCheckbox} onClick={() => setFullCheckbox(!fullCheckbox)} />
          <Button
          >
            <Img
              src={ICONS.ARROW_DOWN}
              alt="add"
              sx={{
                width: "2rem",
                height: "2rem",
              }}
            />
          </Button>
        </Box>
        <Tabs sx={{ pl: 2, pb: 1 }} value={tabValue} onChange={onChangeTab}>
          {Object.entries(GAME_STATUS).map(([key, value]) => (
            <Tab
              key={key}
              label={<Box sx={{ display: "flex" }}>{` ${t(`_gameStatus.${value}`)}`} </Box>}
              value={value}
            />
          ))}
        </Tabs>
        {Object.entries(GAME_STATUS).map(([key, value]) => (
          <TabPanel key={key} value={tabValue} tabValue={value}>
            {games && (
              <GamesList
                value={tabValue}
                fullCheckBox={fullCheckbox}
                games={games}
                status={tabValue}
                handleChange={handleChange}
                page={page}
                count={count}
              />
            )}
          </TabPanel>
        ))}
      </Grid>
    </PageContainer>
  );
};

export default Games;
