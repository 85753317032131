import { AUTH_STATUS } from "types/status";
import { loginThunk } from "./adminActions";
import { adminType, getMe } from "api/admin";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, removeToken, setRefreshToken, setToken } from "utils/token";

export const getMeThunk = createAsyncThunk("user/getMeThunk", async (_, { rejectWithValue }) => {
  try {
    if (!getToken()) {
      throw new Error("no token");
    }
    return await getMe();
  } catch (e: any) {
    return rejectWithValue(e?.message || "error");
  }
});

type initialStateType = {
  status: AUTH_STATUS;
  admin?: adminType | null;
  error: string | null;
};

const initialState: initialStateType = {
  status: AUTH_STATUS.IDLE,
  admin: null,
  error: null,
};

const adminSlice = createSlice({
  initialState,
  name: "admin",

  reducers: {
    logout(state) {
      console.log("exit");
      /**
       * To logout:
       * 1- Remove user info
       * 2- Remove tokens
       * 3- Route to login page (Done by router)
       */
      state.status = AUTH_STATUS.UNAUTHORIZED;
      state.admin = null;
      state.error = null;
      removeToken();
    },
  },

  extraReducers(builder) {
    builder.addCase(loginThunk.pending, (state, action) => {
      state.status = AUTH_STATUS.PENDING;
    });

    builder.addCase(loginThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.status = AUTH_STATUS.AUTHORIZED;
        setToken(action.payload.accessToken);
        setRefreshToken(action.payload.refreshToken);
        state.admin = action.payload.user;
      } else {
        state.status = AUTH_STATUS.ERROR;
      }
    });

    builder.addCase(loginThunk.rejected, (state, action) => {
      state.status = AUTH_STATUS.UNAUTHORIZED;
      state.error = (action.payload as any)?.message;
    });

    // ME REDUCERS
    builder.addCase(getMeThunk.pending, (state, action) => {
      state.status = AUTH_STATUS.PENDING;
    });

    builder.addCase(getMeThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.status = AUTH_STATUS.AUTHORIZED;
        state.admin = action.payload;
      } else {
        state.status = AUTH_STATUS.UNAUTHORIZED;
      }
    });

    builder.addCase(getMeThunk.rejected, (state, action) => {
      state.status = AUTH_STATUS.UNAUTHORIZED;
      state.error = (action.payload as any)?.error;
    });
  },
});

export default adminSlice.reducer;

export const { logout } = adminSlice.actions;
