import { delete_, get, put } from "api";
import { IImageType } from "./image";
import { AUTH_BASE_URL } from "./config";

export interface IRoleType {
  _id: string;
  name: string;
  permissions: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  id: string;
}
export type IUserType = {
  _id: string;
  bio: string;
  rejectedReasons: any[];
  accountType: string;
  accountVerified: string;
  nationalIdCardImage: any;
  coverImage: IImageType;
  fullname: string;
  address: string;
  profilePicture: IImageType;
  username: string;
  birthday: null | number | string;
  province: any;
  city: any;
  country: string;
  postalCode: string;
  createdAt: any;
  deleted: boolean;
  email: string;
  emailVerified: boolean;
  gender: string;
  lastLogin: null | string | number;
  msisdn: string;
  msisdnVerified: boolean;
  provider: string;
  role: string[];
  updatedAt: string;
  website: string;
  withoutWebsite: boolean;
  status: string;
  __v: number;
  rejectReasons: any[];
  balance: number;
  level: number;
  coins: number;
  userLanguage: string;
  soundOn: boolean;
  notificationOn: boolean;
  theme: "light" | "dark";
  inviteCode: string;
  inviteCount: number;
  invitedBy: string;
  invitedByUser: any;
  active: boolean;
  updatedGamesCount?: number;
  newGamesCount?: number;
  financialInfo: {
    bank: string;
    shebaNumber: string;
    cardNumber: string;
    accountNumber: string;
  };
  businessAccountInfo: {
    files: {
      ceoNationalIdCardImage: any;
      officialNewspaper: any;
      valueAddedTax: any;
      other: any;
    };
    companyName: string;
    economicCode: string;
    companyNationalId: string;
    companyEmail: string;
  };
  nationalId: string;
};

// export const replyTicket = (id: string, data: { content: string }) => {
//   return put(`/ticket/${id}`, data);
// };
export const deleteUser = (id: string) => {
  return delete_(`/user/${id}`);
};

export const getUser = ({ id }: { id?: string }) => {
  return get<any>(`${AUTH_BASE_URL}/user/profile/${id}`);
};
export const updateUser = (
  data: {
    active: boolean;
  },
  id?: string
) => {
  return put(`/users/${id}`, data);
};

export const rejectIdentity = (data: { rejectTitle: string; rejectDesc: string }, id?: string) => {
  return put(`/users/${id}/identity/reject`, data);
};
export const verifiedIdentity = (id?: string) => {
  return put(`/users/${id}/identity/verify`);
};
export const undoIdentity = (id?: string) => {
  return put(`/users/${id}/identity/undo`);
};
