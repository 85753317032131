import { useState } from "react";
import { Box, Grid, Button, Dialog, IconButton } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMomentJalaali } from "@mui/x-date-pickers/AdapterMomentJalaali";
import CustomDatePicker from "./customDatePicker";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import moment from "jalali-moment";

interface CustomRangeDateProps {
  changeDate: (data: any) => void;
}

const CustomRangeDate: React.FC<CustomRangeDateProps> = ({ changeDate }) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  return (
    <>
      <Box
        sx={{
          border: 1,
          borderRadius: "5px",
          bgcolor: "white",
          height: "50px",
          width: "50px",
          borderColor: "#C0C1CE",
          alignContent: "center",
          cursor: "pointer",
        }}
        onClick={() => setOpenDatePicker(true)}
      >
        <DateRangeIcon fontSize="large" color="action" />
      </Box>
      <Dialog open={openDatePicker} onClose={() => setOpenDatePicker(false)}>
        <Box sx={{ px: 2, py: 1, textAlign: "center", height: "400px", width: "400px" }}>
          <Grid container sx={{ mb: 1 }}>
            <Grid item xs={2}>
              {" "}
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => setOpenDatePicker(false)}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
            <Grid item xs={10} sx={{ pr: 9 }}>
              <h2>انتخاب بازه نمودار</h2>
            </Grid>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterMomentJalaali}>
             <DateRangeCalendar
              calendars={1}
              onChange={(newDate) => {
                const dates = newDate.map((date: any) => moment(date?._d).format("YYYY/MM/DD"));
                changeDate(dates);
              }}
            />
            <CustomDatePicker onChangeDate={(e) => changeDate({ e })} />
          </LocalizationProvider>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              setOpenDatePicker(false);
            }}
          >
            تایید
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default CustomRangeDate;