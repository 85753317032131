import { useEffect, useMemo, useState } from "react";
import { Img, Table } from "components";
import { Button, Box, Typography, Grid, Checkbox, IconButton, Divider, Pagination } from "@mui/material";
import useSWR from "swr";
import PageContainer from "components/PageContainer";
import AddCategory from "./components/addCategory";
import DeleteButton from "shared/Delete/Button";
import { ICONS } from "assets/icons";
import { useTranslation } from "react-i18next";
import EditCategory from "./components/editCategory";

export default function GameCategory() {

  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState(false);
  const [id, setId] = useState<any>("");
  const [deletItem, setDeletItem] = useState<any[]>();
  const [selectedItem, setSelectedItem] = useState<string[]>();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);

  const { data: gameCategory, mutate } = useSWR<{ categories: any; gameCounts: number[] }>(
    `game/categories?start=${(page-1)*10}&limit=${10}`
  );
  const { data: AllCategories } = useSWR<{ categories: any; gameCounts: number[] }>(
    `game/categories?start=0&limit=0`
  );
  const [add, setAdd] = useState(false);
 
  const categoryId = gameCategory?.categories?.map((i: any) => i._id) || [];
  const onSelectionModelChange = (ids: string[]) => {
    setSelectedItem(ids);
  };
  const handleEdit = (id: any) => {
    setOpenEdit(true);
    setId(id);
  };
  useEffect(() => {
    AllCategories && setCount(Math.ceil((AllCategories?.categories?.length) /10));
  }, [AllCategories]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const columns = useMemo(() => {
    const commonColumns = [
      {
        field: "image",
        headerName: "تصویر",
        flex: 2,
        renderCell({ row }: any) {
          return (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <img width="28px" height="28px" src={row?.image?.url} alt="" />
              <Typography sx={{ fontSize: "13px", px: 2, pt: 0.5, color: "#333337" }}>{row?.title}</Typography>
            </Box>
          );
        },
      },

      {
        field: "gameNumber",
        headerName: "تعداد بازی ها",
        flex: 5,
        renderCell({ row }: any) {
          return <Typography>{row.gameCount}</Typography>;
        },
      },
      {
        field: "edit",
        headerName: "ویرایش",
        flex: 1,
        renderCell({ row }: any) {
          return (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={() => handleEdit(row._id)}>
                <img width="25px" height="25px" alt="edit" src={ICONS.FILEEDIT} />
              </Button>
              <DeleteButton type="icon" url={[`/game/category/${row?._id}`]} onDone={() => mutate()} />
            </Box>
          );
        },
      },
    ];
    return commonColumns;
  }, [mutate]);

  return (
    <PageContainer>
      <AddCategory open={add} onClose={() => setAdd(false)} onMutate={() => mutate()} />
      <EditCategory
        data={gameCategory?.categories.filter((i: any) => i?._id === id)[0]}
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        onMutate={() => mutate()}
        id={id}
      />
      <Box dir="rtl" sx={{ mb: 10 }}>
        <Button
          sx={{ my: "30px", px: 2, color: "white" }}
          variant="contained"
          endIcon={<img src={ICONS.PLUS} alt="plus" />}
          onClick={() => setAdd(true)}
        >
          افزودن دسته جدید
        </Button>

        <Grid
          sx={{
            backgroundColor: "white",
            borderRadius: "1.7rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ padding: "1rem " }}>
              <Checkbox
                checked={selectedItem?.length === 0 || !selectedItem ? false : true}
                onClick={() => (selectedItem?.length === 0 ? setSelectedItem(categoryId) : setSelectedItem([]))}
              />

              <IconButton>
                <Img
                  src={ICONS.ARROW_DOWN}
                  alt="add"
                  sx={{
                    width: "2rem",
                    height: "2rem",
                  }}
                />
              </IconButton>

              <IconButton
                disabled={selectedItem?.length === 1 ? false : true}
                onClick={() => selectedItem && handleEdit(selectedItem[0])}
              >
                <Img
                  src={ICONS.FILEEDIT}
                  alt="active"
                  sx={{
                    width: "2rem",
                    height: "2rem",
                  }}
                />
              </IconButton>

              <IconButton
                disabled={selectedItem?.length === 0 || !selectedItem ? true : false}
                onClick={() => {
                  selectedItem && setDeletItem(selectedItem.map((item) => `/game/category/${item}`));
                }}
              >
                <DeleteButton type="icon" url={deletItem} onDone={() => mutate()} />
              </IconButton>
            </Box>
            {selectedItem && selectedItem?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mx: 2,
                }}
              >
                <Typography>{`${selectedItem?.length} ${t("itemSelected")}`}</Typography>
              </Box>
            )}
          </Box>

          <Divider />
          <Table
            columns={columns}
            rows={
              gameCategory?.categories?.map((i: any, index: number) => ({
                ...i,
                id: i?._id,
                gameCount: gameCategory.gameCounts[index],
              })) || []
            }
            checkboxSelection
            onSelectionModelChange={onSelectionModelChange}
            selectionModel={selectedItem}
          />

          <Pagination
            sx={{ mx: 5, pb: 10 }}
            count={count}
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Grid>
      </Box>
    </PageContainer>
  );
}
