import { useFormik } from "formik";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  IconButton,
  Dialog,
  TextField,
  Typography,
  FormControl,
  Stack,
  MenuItem,
  Select,
} from "@mui/material";
import { createAdCompany } from "api/adCompanies";
import useSWR from "swr";

export type valueType = {
  provider: string;
  rank: null | number | string;
  callNumber: string;
  emailAddress: string;
  provinceId: string;
  cityId: string;
  siteAddress: string;
};

export default function NewAddSlider({ onClose, open }: { onClose: () => void; open: boolean }) {
  const { data: provincesList } = useSWR("/provinces?limit=35&sortBy=name&sortOrder=1");
  const { data: citiesList } = useSWR(`/cities?limit=400&sortBy=name&sortOrder=1`);
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      provider: "",
      rank: "",
      callNumber: "",
      emailAddress: "",
      provinceId: "",
      cityId: "",
      siteAddress: "",
    },
    onSubmit: async (value) => {
      try {
        await createAdCompany(value);
        toast.success("done");
        onClose();
      } catch (error) {
        toast.error("something went wrong");
      }
    },
  });

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3, width: "100%" }}>
            <Box sx={{ mb: 3 }}>
              <IconButton>
                <DeleteIcon sx={{ fontSize: 20 }} onClick={onClose} style={{ cursor: "inital" }} />
              </IconButton>
              <Typography
                id="new-ad-title"
                sx={{ fontWeight: "bold", fontSize: 20, color: "disabled", display: "inline", pl: "30%" }}
              >
                {"افزودن شرکت جدید"}
              </Typography>
            </Box>
            <Box sx={{ m: 1.5, width: "350px" }}>
              <Typography sx={{ m: 0.5 }}>نام شرکت</Typography>
              <TextField
                sx={{ fontSize: 10 }}
                required
                id="add-company-provider"
                placeholder="وارد کنید"
                fullWidth
                onChange={handleChange}
                value={values.provider}
                name="provider"
              />
            </Box>
            <Box sx={{ m: 1.5, width: "350px" }}>
              <Typography sx={{ m: 0.5 }}>درجه تبلیغاتی</Typography>
              <FormControl fullWidth>
                <Select id="add-ad-prority" label="انتخاب کنید" onChange={handleChange} value={values.rank} name="rank">
                  {["A", "B", "C", "D"].map((i: string) => {
                    return <MenuItem value={i}>{i}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ m: 1.5, width: "350px" }}>
              <Typography sx={{ m: 0.5 }}>شماره تماس شرکت</Typography>
              <TextField
                sx={{ fontSize: 10 }}
                required
                id="add-company-number"
                placeholder="وارد کنید"
                fullWidth
                onChange={handleChange}
                value={values.callNumber}
                name="callNumber"
              />
            </Box>
            <Box sx={{ m: 1.5, width: "350px" }}>
              <Typography sx={{ m: 0.5 }}>آدرس ایمیل</Typography>
              <TextField
                sx={{ fontSize: 10 }}
                required
                id="add-company-address"
                placeholder="وارد کنید"
                fullWidth
                onChange={handleChange}
                value={values.emailAddress}
                name="emailAddress"
              />
            </Box>
            <Box sx={{ m: 1.5, width: "350px" }}>
              <Typography sx={{ m: 0.5 }}>استان</Typography>
              <FormControl fullWidth>
                <Select
                  id="add-tate"
                  label="انتخاب کنید"
                  onChange={handleChange}
                  value={values.provinceId}
                  name="provinceId"
                >
                  {provincesList?.provinces.map((province: any, i: number) => {
                    return (
                      <MenuItem key={i} value={province._id}>
                        {province.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ m: 1.5, width: "350px" }}>
              <Typography sx={{ m: 0.5 }}>شهر</Typography>
              <FormControl fullWidth>
                <Select
                  id="add-city"
                  label="انتخاب کنید"
                  onChange={handleChange}
                  value={values.cityId}
                  name="cityId"
                  disabled={!values.provinceId}
                >
                  {citiesList?.cities
                    .filter((city: any) => city.province._id === values.provinceId)
                    .map((city: any, i: number) => {
                      return (
                        <MenuItem key={i} value={city._id}>
                          {city.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ m: 1.5, width: "350px" }}>
              <Typography sx={{ m: 0.5 }}>آدرس سایت</Typography>
              <TextField
                sx={{ fontSize: 10 }}
                required
                id="add-site-address"
                placeholder="وارد کنید"
                fullWidth
                onChange={handleChange}
                value={values.siteAddress}
                name="siteAddress"
              />
            </Box>
            <Stack spacing={2} direction="row">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ align: "center", mt: 3, fontSize: 15, ml: "5%" }}
              >
                تایید و افزودن
              </Button>
            </Stack>
          </Box>
        </form>
      </Dialog>
    </>
  );
}
