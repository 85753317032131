import { ICONS } from "assets/icons";
import { Img, Table } from "components";
import { ROUTES } from "constants/routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, IconButton } from "@mui/material";

const TicketsList = ({ tickets }: { tickets: any }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      field: "title",
      headerName: t("title"),
      flex: 4,
    },
    {
      field: "status",
      headerName: t("status"),
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: t("date"),
      flex: 1,
    },
    {
      field: "action",
      headerName: t(""),
      flex: 1,
      renderCell({ row }: any) {
        return (
          <IconButton onClick={onTicketClick(row._id)}>
            <Img src={ICONS.ARROW_LEFT} alt="ticket" />
          </IconButton>
        );
      },
    },
  ];

  const onTicketClick = (id: string) => () => {
    navigate(ROUTES.TICKET(id));
  };

  return (
    <Grid>
      <Table columns={columns} rows={tickets} getRowId={(row: any) => row._id} />
    </Grid>
  );
};

export default TicketsList;
