import { useState, ReactNode } from "react";
import { ICONS } from "assets/icons";
import Img from "components/img/Img";
import { IconButton } from "@mui/material";
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField";

type TextFieldProps = MuiTextFieldProps & {
  endAdornment?: any;
};

const TextField = (props: TextFieldProps) => {
  const [hidePassword, setHidePassword] = useState(true);
  const { type, InputProps, endAdornment, ...rest } = props;

  const isPassword: boolean = type === "password";

  const togglePasswordState = () => {
    setHidePassword((hidePassword) => !hidePassword);
  };

  return (
    <MuiTextField
      type={isPassword ? (hidePassword ? "password" : "text") : type}
      InputProps={{
        endAdornment: isPassword ? (
          hidePassword ? (
            <IconButton onClick={togglePasswordState}>
              <Img
                src={ICONS.SHOW}
                alt="show"
                sx={{
                  width: "2rem",
                  height: "2rem",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          ) : (
            <IconButton onClick={togglePasswordState}>
              <Img
                src={ICONS.HIDE}
                alt="hide"
                sx={{
                  width: "2rem",
                  height: "2rem",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          )
        ) : (
          endAdornment
        ),
        ...InputProps,
      }}
      {...rest}
    />
  );
};

export default TextField;
