import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "store";
import { AUTH_STATUS } from "types/status";

export function useAuthStatus() {
  return useAppSelector((s) => s.admin?.status);
}
export function useAminInfo() {
  return useAppSelector((s) => s.admin.admin?.me);
}

export function useIsAuthorized() {
  const status = useAuthStatus();

  return status === AUTH_STATUS.AUTHORIZED;
}
export function useAdmin() {
  const AdminInfo = useAminInfo();

  return AdminInfo;
}

export function useProtectedRoute({
  authorizedRoute,
  UnauthorizedRoute,
}: {
  authorizedRoute?: string;
  UnauthorizedRoute?: string;
}) {
  const isAuthorized = useIsAuthorized();
  const navigate = useNavigate();
}
