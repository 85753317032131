import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Container({ children, title }: { children: React.ReactNode; title: string }) {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", width: "700px" }}>
      <Typography sx={{ fontSize: "1.3rem", fontWeight: 500, color: "#979797", width: "300px", pt: 2, px: 3 }}>
        {t(title)}
      </Typography>
      {children}
    </Box>
  );
}
