import { listenerType } from "logic/Socket";
import AdminSocketAgent from "logic/adminSocket";

export type messageType = {
  _id: string;
  message: string;
  updatedAt: string;
  createdAt: string;
  deleted: boolean;
  __v: number;
};

class ChatAdapter {
  private socket = AdminSocketAgent.getInstance();

  public sendMessage(body: { leagueId: string; message: string }, cb?: listenerType) {
    if (!body.leagueId || !body.message) {
      const error = {
        msg: "message and leagueId are required",
        input: body,
        scope: "ChatAdapter.sendMessage",
      };
      console.log(error);
    }
    this.socket.send("sendMessage", body, cb);
  }

  public joinLeagueChatRoom(leagueId: string, cb?: listenerType) {
    if (!leagueId) {
      const error = {
        msg: "leagueId is required",
        input: { leagueId },
        scope: "ChatAdapter.joinLeagueChatRoom",
      };
      console.log(error);
    }

    this.socket.send("join", { leagueId }, cb);
  }

  public openChatRoom(leagueId: string, cb?: listenerType) {
    if (!leagueId) {
      const error = {
        msg: "leagueId is required",
        input: { leagueId },
        scope: "ChatAdapter.openChatRoom",
      };
      console.log(error);
    }

    this.socket.send("openChat", { leagueId }, cb);
  }

  public blockUser(userId: string, cb?: listenerType) {
    if (!userId) {
      const error = {
        msg: "userId is required",
        input: { userId },
        scope: "ChatAdapter.blockUser",
      };

      console.log(error);
    }

    this.socket.send("blockUser", { targetUser: userId }, cb);
  }

  public getOnLineUsersCount(userId: string) {}

  public reportChat(body: { chatId: string; title: string; description: string }) {
    if (!body.chatId || !body.title || !body.description) {
      const error = {
        msg: "chatId, title and description are required",
        input: body,
        scope: "ChatAdapter.chatReport",
      };
      console.log(error);
    }

    this.socket.send("chatReport", body);
  }

  public onMessage(listener: listenerType) {
    this.socket.subscribe("message", listener);
  }

  public offMessage(listener: listenerType) {
    this.socket.unsubscribe("message", listener);
  }

  public getOnlineUserCount(listener: listenerType) {
    this.socket.subscribe("onlineUsersCount", listener);
  }

  public closeChat(leagueId: string, cb: listenerType) {
    console.log("Closing chat");
    this.socket.send("closeChat", { leagueId }, cb);
  }
}

export default ChatAdapter;
