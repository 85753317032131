import { AppBar, Avatar, Box, Button, Typography } from "@mui/material";
import { IGameType } from "api/game";
import RadioButtonsGroup from "./radioButton";
import { ICONS } from "assets/icons";
import { get } from "api";
import { toast } from "react-toastify";
import { webDomainUrl } from "api/config";

export default function GamePreview({
  game,
  name,
  onSetValue,
  onOpenPreview,
}: {
  game: IGameType;
  name: string;
  onSetValue: (e: any, a: string) => void;
  onOpenPreview: () => void;
}) {
  const navigateToWebSite = async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/game/${game?._id}/share`;
      const res: any = await get(url);
      if(res && res.shareGameToken){
        window.open(
          `${webDomainUrl}/fa/adminview/${game?._id}?shareGameToken=${res?.shareGameToken}`,
          "_blank",
          "noreferrer"
        );
      }
    } catch (e) {
      console.log(e);
      toast.error(" فعلا سرور به مشکل خورده به زودی رفع میشه :)");
    }
  };

  return (
    <Box sx={{ mt: 1, mb: 5, border: "2px solid #F5F5F9", borderRadius: "12px", height: "510px" }}>
      <Typography sx={{ borderBottom: "1px solid #F5F5F9", py: 2, px: 1, fontSize: "12px" }}>پیش نمایش</Typography>
      <Box
        sx={{ height: "460px", borderBottom: "1px solid #F5F5F9" }}
      > <img src={game?.coverImage?.url}  alt="" style={{objectFit:"cover",borderRadius:"2px",width:"100%",height:"100%"}}/></Box>
      <Box
        sx={{
          border: "1px solid #F5F5F9",
          borderBottomRightRadius: "12px",
          borderBottomLeftRadius: "12px",
          py: 0.5,
          px: 1,
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <Button
          onClick={navigateToWebSite}
          sx={{ fontSize: "12px" }}
          variant="text"
          startIcon={<img src={ICONS.LINK} alt="2" />}
        >
          پیش نمایش در سایت{" "}
        </Button>
        <RadioButtonsGroup
          onSetValue={(e: any, a: string) => {
            onSetValue(e, a);
          }}
          name={name}
          lable={{ accept: "تایید پیش نمایش بازی", reject: "عدم تایید پیش نمایش بازی " }}
        />
      </Box>
    </Box>
  );
}
