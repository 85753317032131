import { Avatar, Box, Chip, Grid, Typography, styled } from "@mui/material";
import { red } from "@mui/material/colors";
import { IGameType } from "api/game";
import { ICONS } from "assets/icons";
import moment from "jalali-moment";
import { MyTypography } from "pages/games/components/GamesListItem";

export default function GameDetails({ game }: { game: IGameType }) {
  console.log("game", game);
  const item = [
    {
      title: " عنوان بازی ",
      content: <MyTypography>{game?.title}</MyTypography>,
    },
    { title: "دسته بندی", content: <MyTypography>{game?.category?.title || "نامشخص"}</MyTypography> },
    {
      title: " برچسب ها",
      content: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.7rem",
          }}
        >
          {game?.tags &&
            game?.tags.map((tag: string) => (
              <Chip
                key={tag}
                label={tag}
                sx={{
                  height: "2.7rem",
                  minWidth: "unset",
                  padding: "0.3rem 0.7rem",
                  borderRadius: "8px",
                  backgroundColor: "text.secondary",
                  color: "white",
                  fontSize: "1.2rem",
                  width:'70%'
                }}
              />
            ))}
        </Box>
      ),
    },

    {
      title: " ورژن بازی",
      content: <MyTypography> {game?.version}</MyTypography>,
    },
    {
      title: " توضیحات بازی",
      content: (
        <div
          style={{
            paddingBottom: "5rem",
            border: "0.5px solid #C0C1CE",
            borderRadius: "12px",
            marginBottom: "1rem",
            paddingRight: "1rem",
            paddingTop: "-10rem !important",
          }}
          dangerouslySetInnerHTML={{ __html: game?.description || "توضیحاتی برای بازی درج نشده است" }}
        />
      ),
    },
    {
      title: "  کنترل ها",
      content: (
        <Box>
          {game?.mouseControl && game?.mouseControl?.use === true && (
            <Box sx={{ display: "flex" }}>
              <img
                style={{ width: "25px", height: "25px", paddingLeft: 2, marginBottom: 2 }}
                src={ICONS.MOUSECONTROL}
                alt="mouse"
              />
              <MyTypography>{game?.mouseControl?.description}</MyTypography>
            </Box>
          )}
          {game?.keysControl &&
            game?.keysControl.length > 0 &&
            game?.keysControl.map((i: any) => {
              <Grid>
                <Box sx={{ border: "1px solid grey", borderRadius: "5px", p: 1 }}>{i?.key}</Box>{" "}
                <MyTypography>{i?.description}</MyTypography>
              </Grid>;
            })}
        </Box>
      ),
    },
    {
      title: "رده ی سنی",
      content: `بالای ${game?.ageRangeFrom} سال`,
    },
    {
      title: " تکنولوژی بازی",
      content: <MyTypography>{game?.gameType}</MyTypography>,
    },
    {
      title: "جهت در موبایل",
      content: <MyTypography>{game?.gameDirection}</MyTypography>,
    },
  ];
  return (
    <>
      {item.map((i: any) => (
        <Grid container rowSpacing={5} key={i?.title}>
          <Grid item xs={4} sx={{ py: 1 }}>
            <Typography sx={{ color: "#676872" }}>{i?.title}</Typography>
          </Grid>
          <Grid item xs={8} sx={{}}>
            <Box>{i?.content}</Box>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
