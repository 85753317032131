import { useTranslation } from "react-i18next";
import { BackButton, Uploader, TextField, Select } from "components";
import { Box, Button, Chip, MenuItem, Typography } from "@mui/material";
import { AddTagButton, CheckBoxItem, DeleteButton, HintTypography, ItemContainer } from "./newGameItems";

const AddGameForm = (props: any) => {
  const { t } = useTranslation();
  const {
    categories,
    addGameProps: {
      errors,
      values,
      tagValue,
      addNewTag,
      onTagChang,
      onDeleteTag,
      handleSubmit,
      getFieldProps,
      setFieldValue,
    },
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "white",
          padding: "1rem 4rem 1rem 11rem",
        }}
      >
        <BackButton />
        <Button type="submit" variant="contained">
          {t("continueToGetPreview")}
        </Button>
      </Box>
      <Box
        sx={{
          padding: "3.5rem 4rem 5rem 11rem",
        }}
      >
        <ItemContainer title={"gameName"}>
          <TextField fullWidth {...getFieldProps("title")} helperText={errors.title} />
        </ItemContainer>

        <ItemContainer title="gameCategory">
          <Select fullWidth {...getFieldProps("category")} helperText={errors.category}>
            {categories &&
              categories.map((category: any) => (
                <MenuItem key={category._id} value={category._id}>
                  <Typography>{category.title}</Typography>
                </MenuItem>
              ))}
          </Select>
        </ItemContainer>

        <ItemContainer title="tags" {...getFieldProps("tags")}>
          <TextField
            fullWidth
            onChange={onTagChang}
            value={tagValue}
            endAdornment={<AddTagButton onClick={addNewTag} disabled={values.tags.length === 7 || !tagValue} />}
            placeholder={t("addRelatedTags")}
            disabled={values.tags.length === 7}
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: 0,
                overflow: "hidden",
              },
            }}
          />
          <HintTypography>
            {/* todo@sareh: add dynamic value */}
            {t("_AddGame.maxTagCountHint")}
          </HintTypography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.7rem",
              marginTop: "0.5rem",
              maxWidth: "100%",
              flexWrap: "wrap",
            }}
          >
            {values.tags.map((tag: string) => (
              <Chip
                key={tag}
                label={tag}
                onDelete={onDeleteTag(tag)}
                deleteIcon={<DeleteButton />}
                sx={{
                  height: "2.7rem",
                  minWidth: "unset",
                  padding: "0.3rem 0.7rem",
                  borderRadius: "0.3rem",
                  backgroundColor: "text.secondary",
                  color: "white",
                  fontSize: "1.2rem",
                }}
              />
            ))}
          </Box>
        </ItemContainer>

        <ItemContainer title={"gameDescription"}>
          <TextField
            multiline
            fullWidth
            rows={4}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "10rem",
              },
            }}
            {...getFieldProps("description")}
            helperText={errors.description}
          />
          <HintTypography>{t("_AddGame.describeYourGame")}</HintTypography>
        </ItemContainer>

        <ItemContainer title={"controls"}>
          {["keysControl", "mouseControl"].map((item: string) => (
            <CheckBoxItem
              key={item}
              title={item}
              {...getFieldProps(item)}
              // checked={Boolean(values[item]?.length > 0)}
            />
          ))}
        </ItemContainer>

        <ItemContainer title={"ageRange"}>
          <Select
            fullWidth
            {...getFieldProps("ageRangeFrom")}
            renderValue={(selected:any) => <Box>{selected ? `${selected} ${t("year")}` : ""}</Box>}
          >
            {[5, 13, 18].map((age) => (
              <MenuItem key={age} value={age}>
                <Typography sx={{ direction: "rtl", minWidth: "3rem" }}>{`+${age}`}</Typography>
                <Typography>{t("year")}</Typography>
              </MenuItem>
            ))}
          </Select>
        </ItemContainer>

        <ItemContainer title={"gameTechnology"}>
          <Select fullWidth {...getFieldProps("gapeType")}>
            {["HTML5"].map((technology) => (
              <MenuItem key={technology} value={technology}>
                <Typography>{technology}</Typography>
              </MenuItem>
            ))}
          </Select>
        </ItemContainer>

        <ItemContainer title={""}>
          {["fullscreenCompatible", "iosCompatible", "androidCompatible"].map((item) => (
            <CheckBoxItem key={item} title={`_AddGame.${item}`} {...getFieldProps(item)} />
          ))}
        </ItemContainer>

        <ItemContainer title={"uploadGameFiles"}>
          <HintTypography>{t("uploadGameFilesDescription")}</HintTypography>
          <Uploader
            onSaveFieldValue={setFieldValue}
            fieldTitle="gameFieldId"
            type="game"
            helperText={errors.gameFieldId}
          />
        </ItemContainer>

        <ItemContainer title={"previewGif"}>
          <HintTypography>{t("previewGifDescription")}</HintTypography>
          <Uploader type="gif" helperText={errors.coverGif} onSaveFieldValue={setFieldValue} fieldTitle="coverGif" />
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 500,
              color: "text.disabled",
            }}
          >
            {t("previewGifSizeHint")}
          </Typography>
        </ItemContainer>

        <ItemContainer title={"gameImage"}>
          <Uploader
            type="image"
            helperText={errors.coverImage}
            onSaveFieldValue={setFieldValue}
            fieldTitle="coverImage"
          />
        </ItemContainer>
      </Box>
    </form>
  );
};

export default AddGameForm;
