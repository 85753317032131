import useSWR from "swr";

import { Select } from "components";
import List from "./components/List";
import Chart from "./components/Chart";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputBase,
  MenuItem,
  NativeSelect,
  TextField,
  Typography,
} from "@mui/material";
import { format, subDays } from "date-fns";
import PageContainer from "components/PageContainer";
import { useAppDispatch } from "store";
import { getMeThunk } from "reducers/adminReducers";

const Dashboard = () => {
  const { t } = useTranslation();
  const [granularity, setGranularity] = useState<"daily" | "monthly" | "weekly">("monthly");
  const dispatch: any = useAppDispatch();
  const date = useMemo(() => new Date(), []);
  const now = format(date, "yyyy-MM-dd");
  const sevenDaysAgo = format(subDays(date, 7), "yyyy-MM-dd");
  const thirtyDaysAgo = format(subDays(date, 30), "yyyy-MM-dd");
  const ninetyDaysAgo = format(subDays(date, 90), "yyyy-MM-dd");
  const sixMonthAgo = format(subDays(date, 180), "yyyy-MM-dd");
  const twelveMonthsAgo = format(subDays(date, 360), "yyyy-MM-dd");
  const [dates, setDates] = useState(twelveMonthsAgo);
  const [displayDate, setDisplayDate] = useState("twelveMonthsAgo");
  const { data, isLoading } = useSWR(`/stats/?startDate=${dates}&endDate=${now}&granularity=${granularity}`);
  const { data: allData } = useSWR(`/stats?granularity=${granularity}`);

  const onDateChange = (event: any) => {
    setDisplayDate(event.target.value);
    if (event.target.value === "sevenDaysAgo") {
      setDates(sevenDaysAgo);
      setGranularity("daily");
      // setDisplayDate("sevenDaysAgo");
    } else if (event.target.value === "thirtyDaysago") {
      setDates(thirtyDaysAgo);
      setGranularity("daily");
    } else if (event.target.value == "ninetyDaysAgo") {
      setDates(ninetyDaysAgo);
      setGranularity("monthly");
    } else if (event.target.value == "sixMonthAgo") {
      setDates(sixMonthAgo);
      setGranularity("monthly");
    } else if (event.target.value == "twelveMonthsAgo") {
      setDates(twelveMonthsAgo);
      setGranularity("monthly");
    }
  };

  return (
    <PageContainer>
      <Grid
        container
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "1.7rem",
          justifyContent: "center",
          textAlign: "center",
          mb: 2,
        }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
              mx: 10,
            }}
          >
            <Typography sx={{ fontweight: 800, fontSize: "18px" }}>{t("overallReport")}</Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1.5rem",
              }}
            >
              <Typography>{t("timeFrame")}</Typography>
              <Select
                value={displayDate}
                placeholder=""
                onChange={onDateChange}
                sx={{ bgcolor: "white", width: "150px", height: "50px", borderColor: "white" }}
              >
                <>
                  {["sevenDaysAgo", "thirtyDaysago", "ninetyDaysAgo", "sixMonthAgo", "twelveMonthsAgo"].map(
                    (item: string) => (
                      <MenuItem key={item} value={item}>
                        {t(`${item}`)}
                      </MenuItem>
                    )
                  )}
                </>
              </Select>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1.78rem",
              mt: 4,
            }}
          >
            {[
              { name: "usersCount", background: "#F5F5FF", bottomColor: "#4F48E4" },
              { name: "usersCount", background: "#FFF", bottomColor: "#E7C720" },
              { name: "publishersCount", background: "#FAFFFE", bottomColor: "#0DD2AD" },
              { name: "publishersCount", background: "#FFF9F7", bottomColor: "#F66C40" },
            ].map((item, index) => (
              <Card
                key={index}
                sx={{
                  bgcolor: item.background,
                  width: "20%",
                  height: "13rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  pt: 10,
                  gap: "2rem",
                }}
              >
                <Typography sx={{ color: "#676872" }}>
                  {index === 0 || index === 2 ? t(item.name) : index === 1 ? t("newUsers") : t("newPublishers")}
                </Typography>
                {data && allData ? (
                  <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
                    {index === 0 || index === 2
                      ? allData?.allUsersCount && allData?.allUsersCount[item.name]
                      : data?.allUsersCount && (data?.allUsersCount as any)[item.name]}
                  </Typography>
                ) : (
                  <>loading</>
                )}
                <Box
                  sx={{
                    backgroundColor: item.bottomColor,
                    height: "40px",
                    borderBottomLeftRadius: "12px",
                    borderBottomRightRadius: "12px",
                    width: "100%",
                    pt: 10,
                  }}
                ></Box>
              </Card>
            ))}
          </Box>
        </Grid>

        <Grid item xs={10} sx={{}}>
          {isLoading ? (
            <Box sx={{ textAlign: "center", my: 10 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Chart data={data} />
          )}
        </Grid>
        <Grid item xs={12}>
          {data && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "start",
                gap: "3rem",
                margin: "4rem 0",
                justifyContent: "center",
              }}
            >
              <List
                type={"activeUsers"}
                data={data.activeUsers.map((item: any) => ({
                  id: item._id,
                  name: item.fullname,
                  image: item.profilePicture?.url,
                }))}
              />

              <List
                type={"activePublishers"}
                data={data.activePublishers.map((item: any) => ({
                  id: item._id,
                  name: item.fullname,
                  image: item.profilePicture?.url,
                }))}
              />

              <List
                type={"popularGames"}
                data={data?.popularGames.map((item: any) => ({
                  id: item._id,
                  name: item.title,
                  image: item.coverImage,
                }))}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Dashboard;
