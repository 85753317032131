import { useTranslation } from "react-i18next";
import { Typography, Box } from "@mui/material";

export const ItemContainer = (props: any) => {
  const { t } = useTranslation();
  const { title, children, value } = props;

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        borderRight: "1px solid",
        borderRightColor: "#CDCED6",
        ":last-of-type": {
          borderRightColor: "transparent",
        },
      }}
    >
      <Typography
        sx={{
          fontSize: "1.3rem",
          color: "#979797",
        }}
      >
        {t(title)}
      </Typography>
      <Box>{children}</Box>

      <Typography
        sx={{
          fontSize: "1.3rem",
          fontWeight: 500,
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};
