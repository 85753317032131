export const LIGHT_PALETTE = {
  primary: {
    main: "#4A76FD",
  },

  error: {
    main: "#EF3428",
  },

  text: {
    disabled: "#999AA6",
    secondary: "#676872",
    primary: "#333337",
  },

  background: {
    default: "#F5F5F9",
    header: "#15213D",
    menu: "#111B32",
    selectedTableRow: "#F0F0FC",
    lighter: "#ECEBFC",
  },

  gray: {
    light: "#C0C1CE",
  },
};

export const DARK_PALETTE = {
  primary: {
    main: "#4F48E4",
  },

  background: {
    default: "#F5F5F9",
  },
};
