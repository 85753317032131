import { Box, Pagination, Typography } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { adsType } from "api/ads";
import { Table } from "components";
import PageContainer from "components/PageContainer";
import moment from "jalali-moment";
import { useState } from "react";
import useSWR from "swr";
import tapcell from "assets/tapcel-logo.svg";
import yaktanet from "assets/yektanet-logo.svg";
import googleAds from "assets/adivery-logo.svg";
import { useNavigate } from "react-router-dom";

export default function AdsList() {
  const [selectedItem, setSelectedItem] = useState<string[]>();
  function calculateTotalIncome(adsStats: any): number {
    return adsStats.reduce((total: any, stat: any) => total + stat.income, 0);
  }
  const onSelectionModelChange = (ids: string[]) => {
    setSelectedItem(ids);
  };
  const { data: ads, isLoading, mutate } = useSWR<{ ads: adsType[]; count: number }>("/ads");
  const navigate = useNavigate();
  const fakeAd: any[] = [
    {
      id: "66a7a0e6656b5f50dc7fafce",

      provider: "yektanet",
      type: "banner",
      bannerAds: {
        code: '<div id="mediaad-nwJWr"></div>',
        width: 100,
        height: 100,
        createdAt: "2024-07-29T14:02:14.353Z",
        updatedAt: "2024-07-29T14:02:14.353Z",
      },
      location: "site_left",
      weight: 0,
      active: true,
      adsStats: [
        {
          view: 0,
          click: 0,
          startTime: "2024-07-29T14:02:14.349Z",
          endTime: null,
          income: 0,
          _id: "66a7a0e6656b5f50dc7fafcf",
        },
      ],
      totalAdsStats: {
        startTime: "2024-07-29T14:02:14.349Z",
        endTime: null,
        _id: "66a7a0e6656b5f50dc7fafcf",
      },
      createdAt: "2024-07-29T14:02:14.353Z",
      updatedAt: "2024-07-29T14:02:14.353Z",
      __v: 0,
    },
  ];

  const columns: GridColumns = [
    {
      field: "weight",
      headerName: "الویت",
      flex: 0.5,
      renderCell({ row }: any) {
        return (
          <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>
            {row?.weight ? `${row?.weight}#` : "14252#"}
          </Typography>
        );
      },
    },
    {
      field: "type",
      headerName: "نوع",
      flex: 0.5,
      renderCell({ row }: any) {
        return (
          <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>
            {row.type === "banner" ? "بنر " : "ویدیو"}
          </Typography>
        );
      },
    },
    {
      field: "company",
      headerName: "شرکت",
      flex: 1,
      renderCell({ row }: any) {
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <img
              src={row.provider == "yektanet" ? yaktanet : row.provider === "tapcell" ? tapcell : googleAds}
              alt=""
            />
            <Typography sx={{ pt: 1 }}>
              {row.provider == "yektanet" ? "یکتا نت " : row.provider === "tapcell" ? "تپسل" : "ادیوری"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "active",
      headerName: "نوع",
      flex: 1,
      renderCell({ row }: any) {
        return (
          <Typography sx={{ fontSize: "1.3rem", fontWeight: 400, color: !row.active ? "#F84E58" : "unset" }}>
            {row.active ? "فعال" : "غیر فعال"}{" "}
          </Typography>
        );
      },
    },
    {
      field: "income",
      headerName: "درآمد",
      flex: 0.7,
      renderCell({ row }: any) {
        return (
          <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>
            {` ${calculateTotalIncome(row.adsStats)} ریال `}{" "}
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "تاریخ ایجاد ",
      flex: 1,
      renderCell({ row }: any) {
        return (
          <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>
            {`${moment.utc(row.createdAt).locale("fa").format(" YYYY/MM/DD")}`}
          </Typography>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "تاریخ آپلود ",
      flex: 1,
      renderCell({ row }: any) {
        return (
          <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>
            {`${moment.utc(row.updatedAt).locale("fa").format(" YYYY/MM/DD")}`}
          </Typography>
        );
      },
    },
  ];

  return (
    <PageContainer noPadding={true}>
      <div style={{ height: "500px", width: "100%", marginTop: "1rem" }}>
        <Table
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={selectedItem}
          columns={columns}
          rows={ads && ads?.ads.length !== 0 ? ads.ads.map((i: adsType) => ({ ...i, id: i._id })) : fakeAd}
          onRowClick={() => selectedItem && navigate(`${selectedItem}`)}
        />
        <Pagination
          sx={{ mx: 5, pb: 10 }}
          count={10}
          variant="outlined"
          shape="rounded"
          // page={page}
          // onChange={handleChange}
          color="primary"
        />
      </div>
    </PageContainer>
  );
}
