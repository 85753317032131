import { ICONS } from "assets/icons";
import Img from "components/img/Img";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

const BackButton = ({back}:{back?:()=>void}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ display: "flex", gap: "0.7rem", alignItems: "center" }}>
      <Button variant="text" onClick={back? back: onBack} sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <Img src={ICONS.BACK} alt="back" sx={{ width: "2rem", height: "2rem" }} />
        <Typography sx={{ fontSize: "1.4rem" }}>{t("back")}</Typography>
      </Button>
    </Box>
  );
};

export default BackButton;
