import { Box, Divider, Grid, Typography } from "@mui/material";
import PageContainer from "components/PageContainer";
import ButtonGroup from "./buttonGroup";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

const LeagueManagement = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const item = [
    { id: 1, link: "slider", tittle: "اسلایدرها" },
    { id: 2, link: "banner", tittle: "بنرها " },
  ];

  function isActive(pathname: string, link: string) {
    return pathname.search(link) > -1;
  }

  return (
    <PageContainer>
      <Grid
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "1.7rem",
          mb: "2rem",
          minheight: "90rem",
          py: 3,
          px: 1,
        }}
      >
        <ButtonGroup />
        <Box sx={{ display: "flex" }}>
          {item?.map((i, idx) => {
            return (
              <Box sx={{ textDecoration: "none", cursor: "pointer", mt: 2 }} key={idx} onClick={() => navigate(i.link)}>
                <Typography mx={2} fontSize={"14px"} mb={1} fontWeight={isActive(pathname, i.link) ? "600" : "400"}>
                  {i.tittle}
                </Typography>
                <Divider sx={{ width: "100%" }} />
                {isActive(pathname, i?.link) && (
                  <Box
                    sx={{
                      width: "100%",
                      height: "6px",
                      background: "#4A76FD",
                      boxShadow: "0px -1px 2px rgba(189, 200, 239, 0.4)",
                      borderRadius: "20px",
                      transition: "background 0.5s",
                    }}
                  ></Box>
                )}
              </Box>
            );
          })}
        </Box>
        <Outlet />
      </Grid>
    </PageContainer>
  );
};
export default LeagueManagement;
