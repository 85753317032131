import { useEffect, useState } from "react";
import chatboxLight from "assets/chatBoxlight.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ILeagueType } from "types/league";
import lightEmoji from "assets/emoji.svg";
import users from "assets/user-group.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import close from "assets/closeChat.svg";
import greenOnLine from "assets/onlineGreen.svg";
import baziExCahtLogo from "assets/baziexChatLogo.svg";
import sendIcon from "assets/blueSend.svg";
import { useSocket } from "logic/SocketProvider";
import { useParams } from "react-router-dom";
import SocketAgent, { authType } from "logic/Socket";
import ChatDetails from "./chatDetail";
import useSWR from "swr";
import styled from "@emotion/styled";
import { getToken } from "utils/token";
import { useAdminSocket } from "logic/AdminSocketProvider";
import AdminSocketAgent from "logic/adminSocket";
import { useDashboardScrollBar } from "components/share/useStyle";

const ChatBox = styled(Box)({
  overflowY: "hidden",
  borderRadius: "12px",
  border: "1px solid #F5F6FA",
  marginBottom: "6rem",
  width: "80%",
  marginLeft: "10rem",
  boxShadow: "0px 0px 6px 0px #00000026",
});

const ChatHeaderBox = styled(Box)({
  borderTopRightRadius: "12px",
  borderTopLeftRadius: "12px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  height: "64px",
  padding: "0px 1rem",
});

export default function InnerLeagueComminucation() {
  const phone = useMediaQuery("(max-width:980px)");
  const [text, setText] = useState("");
  const providerId = "1100";
  const { id } = useParams();
  const [showPicker, setShowPicker] = useState(false);
  const [message, setMessage] = useState<any[]>([]);
  const [showChatTime, setShowChatTime] = useState(true);
  const [onLineUser, setOnLineUser] = useState(0);
  const [closeChat, setCloseChat] = useState(false);
  const [authenticatedFlag, setAuthenticatedFlag] = useState(false);
  const classes = useDashboardScrollBar();
  const [socketAgent, setSocketAgent] = useState<SocketAgent | undefined>();
  const { data: allLeagues } = useSWR<{ leagues: ILeagueType[] }>("/leagues");
  let filterdLeagueById = allLeagues?.leagues.filter((i: ILeagueType) => i._id === id) || [];

  const handleShowPicker = () => {
    setShowPicker(!showPicker);
  };

  const handleCloseChat = () => {
    const authenticated = (data: authType) => {
      if (data.status !== "ok") return;
      AdminSocketAgent?.getInstance().setAuthToken(data);
    };

    if (token && AdminSocketAgent) {
      AdminSocketAgent?.getInstance().sendAuthWithCallBack(token, authenticated);
      AdminSocketAgent?.getInstance().connect();
    }

    id &&
      adminChat?.closeChat(id, (data) => {
        if (data.status === "ok") {
          setCloseChat(true);
          setMessage([]);
        }
      });
  };
  const handleOpenChat = () => {
    const authenticated = (data: authType) => {
      if (data.status !== "ok") return;

      AdminSocketAgent?.getInstance().setAuthToken(data);

      id &&
        adminChat?.openChatRoom(id, (data: any) => {
          if (data.status === "ok") {
            setCloseChat(false);
            chat?.joinLeagueChatRoom(id, (data: any) => {
              setMessage(data?.data?.lastMessages);
            });
          }
        });
    };

    if (token && AdminSocketAgent) {
      AdminSocketAgent?.getInstance().sendAuthWithCallBack(token, authenticated);
      AdminSocketAgent?.getInstance().connect();
    }
  };

  const { chat } = useSocket();
  const { adminChat } = useAdminSocket();
  const token = getToken();

  useEffect(() => {
    setSocketAgent(SocketAgent.getInstance());
  }, []);

  useEffect(() => {
    const authenticated = (data: authType) => {
      if (data.status !== "ok") return;

      socketAgent?.setAuthToken(data);
      setAuthenticatedFlag((prev: any) => !prev);
    };

    if (token && socketAgent) {
      socketAgent.sendAuthWithCallBack(token, authenticated);
      socketAgent.connect();
    }
  }, [socketAgent, token]);

  useEffect(() => {
    const handleMessage = (data: any) => {
      setMessage((prev: any) => [...prev, data.chat]);
    };

    if (chat) {
      id &&
        chat.joinLeagueChatRoom(id, (data: any) => {
          data.status === "chatClosed" && setCloseChat(true);
          setMessage(data?.data.lastMessages);
        });

      const getCount = (data: any) => {
        setOnLineUser(data?.count);
      };

      chat.getOnlineUserCount(getCount);

      if (chat.getEventlisteners("message").length === 0) {
        chat.onMessage(handleMessage);
      }
    }

    return () => {
      chat?.offMessage(handleMessage);
    };
  }, [authenticatedFlag, chat, id]);

  return (
    <>
      {phone ? (
        <Accordion sx={{ mb: 3 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#FFF" }} />}>
            <Typography> گفت و گوی آنلاین لیگ</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box height="410px" textAlign="center" alignItems="center">
              {filterdLeagueById ? (
                <>jhbvjxfv</>
              ) : filterdLeagueById ? (
                <Box sx={{ position: "relative", top: "100px" }}>
                  <img width={100} height={100} alt="image" src={chatboxLight} />
                  <Typography fontSize={phone ? "12px" : "16px"} mx={phone ? 1 : 3}>
                    امکان گفتگوی آنلاین پیش از شروع مسابقه وجود ندارد
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                p: 1,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <IconButton
                type="button"
                disabled={filterdLeagueById[0]?.active === true ? false : true}
                onClick={() => {
                  console.log("sendchat");
                  if (chat && providerId) {
                    chat.sendMessage({ message: "salam", leagueId: providerId as string });
                  }
                }}
              >
                <img src={sendIcon} alt="sendIcon" />
              </IconButton>
              <InputBase placeholder="اینجا بنویسید ..." />
            </Box>
          </AccordionDetails>
        </Accordion>
      ) : (
        <ChatBox >
          <ChatHeaderBox>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography mx={1} sx={{ fontsize: "18px", color: "#676872" }}>
                گفت و گوی آنلاین لیگ{" "}
              </Typography>
            </Box>
            <Stack direction="row">
              <Stack direction="row">
                <img src={greenOnLine} alt="" />
                <Typography sx={{ fontSize: "12px", pt: closeChat ? 1.5 : 3, px: 1 }}>{onLineUser}</Typography>
              </Stack>
              <Stack direction="row" sx={{ px: 2 }}>
                <img src={users} alt="" />
                <Typography sx={{ fontSize: "12px", pt: closeChat ? 1.5 : 3, px: 1 }}>{onLineUser}</Typography>
              </Stack>
              {!closeChat ? (
                <IconButton onClick={handleCloseChat}>
                  <img src={close} alt="" />
                </IconButton>
              ) : (
                <Button onClick={handleOpenChat} variant={"text"} sx={{ color: "green", fontWeight: 600 }}>
                  باز کردن چت
                </Button>
              )}
            </Stack>
          </ChatHeaderBox>

          <Divider />
          <Box height="600px" className={classes.root} sx={{bgcolor: "#FFFFFF",overflowY:"scroll" }} textAlign="center" alignItems="center">
            {message?.length !== 0 ? (
              message?.map((i: any, index: number) => (
                <Box key={index}>
                  <ChatDetails chatMessages={i} index={index} onShowChatTime={showChatTime} />
                </Box>
              ))
            ) : (
              <>
                <img width={200} height={300} alt="image" src={chatboxLight} />
                <Typography fontSize={phone ? "12px" : "16px"} mx={phone ? 1 : 3}>
                  هیچ پیامی در این چت روم وجود ندارد
                </Typography>
              </>
            )}
          </Box>
          <Divider />
          <Box sx={{ display: "flex", px: 0.5 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={baziExCahtLogo} alt="" />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "95%",
                mx: 1,
                my: 2,
                bgcolor: "#F5F6FA",
                borderRadius: "50px",
                minHeight: "70px",
                px: 1,
              }}
            >
              <InputBase
                fullWidth
                rows={3}
                endAdornment={
                  <InputAdornment position="start">
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          pl: 2,
                        }}
                      >
                        {text.length}/200
                      </Typography>
                    </Box>
                    <Box onClick={handleShowPicker} sx={{ cursor: "pointer", px: 1 }}>
                      <img src={lightEmoji} alt="sendIcon" height={20} width={20} />
                    </Box>
                  </InputAdornment>
                }
                inputProps={{
                  maxLength: 200,
                  style: {
                    fontSize: "14px",
                    backgroundColor: "#F5F6FA",
                    borderRadius: "30px",
                    padding: "0.5rem 1rem 0.5rem 1rem",
                  },
                }}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                placeholder="به عنوان ادمین بازی‌اکسپرس می‌توانید با نوشتن در اینجا، پیام ارسال کنید"
              />
            </Box>
            <IconButton
              type="button"
              disabled={filterdLeagueById[0]?.active === true ? false : true}
              onClick={() => {
                console.log("el send", chat?.getEventlisteners("message"));
                id &&
                  chat?.sendMessage({ leagueId: id, message: text }, (data) =>
                    console.log("sendMessageresponse", data)
                  );

                setText("");
              }}
            >
              <img src={sendIcon} alt="sendIcon" width={50} height={50} />
            </IconButton>
            <Box></Box>
          </Box>
        </ChatBox>
      )}
    </>
  );
}
