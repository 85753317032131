import useUser from "../useUser";
import { BackButton } from "components";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import UserSummery from "../usersDetail/userSummery";
import UserInformation from "../usersDetail/userInformation";
import { IUserType } from "api/user";
import UserSupport from "../usersDetail/userSupport";
import PageContainer from "components/PageContainer";
import AuthenticationBusiness from "../usersDetail/authenticationBusiness";
import { formatJallali } from "utils/date";
import useSWR from "swr";
import DeveloperGames from "../usersDetail/developerGame";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  other?: any;
}

const UserDetails = ({ type }: { type: "user" | "publisher" }) => {
  const { id, user } = useUser();
  const { data, mutate } = useSWR<{ user: IUserType }>(`/user/${id}`);
  const state = data?.user;

  const businessTitle: any = [
    { field: "نوع حساب", content: state?.accountType === "personal" ? "شخصی" : "حقوقی" },
    { field: "نام کامل شرکت ", content: state?.businessAccountInfo?.companyName },
    { field: " کد اقتصادی", content: state?.businessAccountInfo?.economicCode },
    { field: "  شناسه ملی", content: state?.businessAccountInfo?.companyNationalId },
    { field: " ایمیل شرکتی", content: state?.businessAccountInfo?.companyEmail },
    { field: " شماره موبایل", content: state?.msisdn },
    { field: " کد پستی", content: state?.postalCode },
    { field: " استان", content: state?.country },
    { field: " شهر", content: state?.city?.name },
    {
      field: " آدرس",
      content: state?.address || "خیابان میرداماد، بلوار نلسون ماندلا، ساختمان شفق، پلاک ۱۲۳، واحد ۴",
    },
  ];
  const title: any = [
    { field: "نوع حساب", content: state?.accountType === "personal" ? "شخصی" : "حقوقی" },
    { field: "کدملی ", content: "0015537404" },
    { field: " شماره موبایل", content: state?.msisdn },
    { field: " تاریخ تولد", content: formatJallali(state?.birthday) },
    { field: " کد پستی", content: state?.postalCode || "14756454566" },
    { field: " استان", content: state?.province?.name },
    { field: " شهر", content: state?.city?.name },
    {
      field: " آدرس",
      content: state?.address || "خیابان میرداماد، بلوار نلسون ماندلا، ساختمان شفق، پلاک ۱۲۳، واحد ۴",
    },
  ];
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              px: Boolean(index === 1 && type === "user")
                ? 0.25
                : Boolean(index === 2 && type === "publisher")
                ? 1
                : Boolean(index === 1 && type === "publisher")
                ? 1
                : 2.5,
              py: 3,
            }}
          >
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <PageContainer>
      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: "white",
          borderRadius: "1.7rem",
          px: 2,
          py: 2,
          mb: 2,
        }}
      >
        <BackButton />
        <UserSummery userInfo={state} onMutate={() => mutate()} />
        <Box sx={{ borderColor: "divider", pr: 3, pt: 4 }}>
          <Tabs value={value} onChange={handleChange}>
            {type === "user" ? <Tab label="اطلاعات کاربر" /> : <Tab label="اطلاعات توسعه دهنده " />}
            {type === "publisher" ? <Tab label=" احراز هویت" /> : <Tab label="درخواست های پشتیبانی" />}
            {type === "publisher" ? <Tab label="  درخواست های پشتیانی" /> : <Tab label=" گزارشات کاربر " />}
            {type === "publisher" ? <Tab label="  بازی ها" /> : <Tab label="  عملکرد " />}
            {type === "publisher" ? <Tab label=" مشکلات گزارش شده" /> : <Tab label="  دستاوردها " />}
            {type === "user" && <Tab label=" جوایز" />}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <UserInformation userInfo={state} type={type} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {type === "user" && <UserSupport type={type} userInfo={state} />}
          {type === "publisher" ? (
            <AuthenticationBusiness
              onMutate={() => mutate()}
              type={type}
              userInfo={state}
              title={state?.accountType === "business" ? businessTitle : title}
            />
          ) : (
            <></>
          )}
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          {type === "publisher" && <UserSupport type={type} userInfo={state} />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
        {type === "publisher" && <DeveloperGames type={type} userInfo={state} />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          Item 5
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          Item 6
        </CustomTabPanel>
      </Box>
    </PageContainer>
  );
};

export default UserDetails;
