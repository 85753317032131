import { Button, IconButton } from "@mui/material";
import { ReactNode, useState } from "react";
import DeleteModal from "./Modal";
import { ICONS } from "assets/icons";

export default function DeleteButton({
  type,
  children,
  url,
  onDone,
}: {
  type?: "icon" | "button";
  children?: ReactNode;
  url: any[] | undefined;
  onDone?: () => void;
}) {
  const [open, setOpen] = useState(false);

  let button = (
    <Button variant="contained" color="error" onClick={() => setOpen(true)}>
      {children}
    </Button>
  );

  if (type === "icon") {
    button = (
      <IconButton onClick={() => setOpen(true)}>
        <img width="23px" height="23px" alt="edit" src={ICONS.TRASHEMPTY} />
      </IconButton>
    );
  }

  return (
    <>
      <DeleteModal open={open} onClose={() => setOpen(false)} url={url} onDone={onDone} />
      {button}
    </>
  );
}
