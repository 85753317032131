import { AdminWebsocketUrl } from "api/config";
import { IImageType } from "api/file";
import { io, Socket } from "socket.io-client";
export type listenerType = (args: any) => any;
export type authListenerType = (args: authType) => any;
export type authType = { status: string };
export type senderType = {
  bio: string;
  coverImage: IImageType;
  firstName: string;
  fullname: string;
  lastName: string;
  profilePicture: IImageType;
  roles: [string];
  username: string;
  _id: string;
};
export type lastMessageType = {
  conversationId: string;
  createdAt: string;
  message: string;
  sender: any;
  updatedAt: string;
  __v: number;
  _id: string;
};
export type responseMessages = {
  status: string;
  data: { allUsersCount: number; conversationId: string; lastMessages: lastMessageType[] };
};

class AdminSocketAgent {
  private static instance: AdminSocketAgent;
  private static socket: Socket;
  private auth: authType | null = null;

  private constructor() {
    AdminSocketAgent.socket = io(AdminWebsocketUrl, { autoConnect: false });

    this.subscribe("authenticate", (auth: authType) => {
      this.auth = auth;
    });

    this.subscribe("connect", () => {
      console.log("SocketAgent connected");
    });

    this.subscribe("disconnect", () => {
      console.log("SocketAgent disconnected!");
    });
  }

  //hold old connection if connected once
  public static getInstance(): AdminSocketAgent {
    if (!AdminSocketAgent.instance) {
      AdminSocketAgent.instance = new AdminSocketAgent();
    }

    return AdminSocketAgent.instance;
  }

  public send(event: string, data?: any, cb?: listenerType) {
    if (!this.auth) {
      const error = {
        msg: "Unauthorized",
        input: this.auth,
        scope: "SocketAgent.send",
      };
      console.log(error);
    }

    AdminSocketAgent.socket.emit(event, data, cb);
  }

  public getAllMessage(data: any, listener: listenerType) {
    AdminSocketAgent.socket.emit("sendMessage", data, listener);
  }

  public sendAuth(token: string) {
    AdminSocketAgent.socket.emit("authenticate", { accessToken: token }, (data: authType) => {
      console.log("authresponse", data);
      if (data.status !== "ok") {
        return;
      } else {
        this.auth = data;
      }
    });
  }

  public sendAuthWithCallBack(token: string, cb?: authListenerType) {
    AdminSocketAgent.socket.emit("authenticate", { accessToken: token }, cb);
  }

  public setAuthToken(token: authType) {
    this.auth = token;
  }

  public subscribe(event: string, listener: listenerType) {
    AdminSocketAgent.socket.on(event, listener);
  }

  public unsubscribe(event: string, listener?: listenerType) {
    AdminSocketAgent.socket.off(event, listener);
  }

  public subscribeOnce(event: string, listener: listenerType) {
    AdminSocketAgent.socket.once(event, listener);
  }

  public getEventListeners(event: string) {
    return AdminSocketAgent.socket.listeners(event);
  }

  public connect() {
    AdminSocketAgent.socket.connect();
  }

  public disconnect() {
    AdminSocketAgent.socket.disconnect();
  }
}

export default AdminSocketAgent;
