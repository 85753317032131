import { post, put } from "api";
import { companyPeopleType } from "./companyPeople";

export type companiesType = {
  _id: string;
  companyNumber: number;
  name: string;
  active: boolean;
  rank: string;
  callNumber: string;
  emailAddress: string;
  provinceId: string;
  cityId: string;
  siteAddress: string;
  people: companyPeopleType[];
  adsStats: any[];
  registrationDate: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
export const changeCompanyActive = (
  data: {
    active?: boolean;
  },

  companyId: string
) => {
  return put(`/ads/companies/${companyId}/active`, data);
};
export type updateCompaniesFieldType = {
  rank: string;
  callNumber: string;
  emailAddress: string;
  provinceId: string;
  cityId: string;
  siteAddress: string;
};

export function updateCompaniesInfo(companyId: string, data: updateCompaniesFieldType) {
  return put(`/ads/companies/${companyId}/update`, data);
}
export function createAdCompany(data: Partial<companiesType>) {
  return post("/ads/companies", data);
}
