import up from "./common/up.svg";
import show from "./common/show.svg";
import hide from "./common/hide.svg";
import mail from "./common/mail.svg";
import lock from "./common/lock.svg";
import down from "./common/down.svg";
import back from "./common/back.svg";
import logo from "./common/logo.svg";
import send from "./common/send.svg";
import sort from "./common/sort.svg";
import flag from "./common/flag.svg";
import plus from "./common/plus.svg";
import share from "./common/share.svg";
import heart from "./common/heart.svg";
import games from "./layout/games.svg";
import trash from "./common/trash.svg";
import mouse from "./common/mouse.svg";
import users from "./layout/users.svg";
import close from "./common/close.svg";
import check from "./common/check.svg";
import search from "./common/search.svg";
import avatar from "./common/avatar.svg";
import filter from "./common/filter.svg";
import camera from "./common/camera.svg";
import expand from "./common/expand.svg";
import goldStar from "assets/Star.svg";
import brands from "./layout/brands.svg";
import logout from "./common/logout.svg";
import upload from "./common/upload.svg";
import message from "./common/message.svg";
import leagues from "assets/outline.svg";
import finance from "./layout/finance.svg";
import reports from "./layout/reports.svg";
import avatars from "./layout/avatars.svg";
import support from "./layout/support.svg";
import newGame from "./common/new-game.svg";
import settings from "./layout/settings.svg";
import calendar from "./common/calendar.svg";
import keyboard from "./common/keyboard.svg";
import menuOpen from "./common/menu-open.svg";
import noTicket from "./common/no-ticket.svg";
import arrowLeft from "./common/arrowLeft.svg";
import dashboard from "./layout/dashboard.svg";
import arrowDown from "./common/arrow-down.svg";
import menuClose from "./common/menu-close.svg";
import newTicket from "./common/new-ticket.svg";
import developers from "./layout/developers.svg";
import editPencil from "./common/editPencil.svg";
// import openTicket from 'assets/openTicket.svg'
import categories from "./layout/categories.svg";
import selectIcon from "./common/selectIcon.svg";
import openTicket from "./common/open-ticket.svg";
import plusLeague from "./common/plus-league.svg";
import closeSelect from "./common/close-select.svg";
import achievements from "./layout/achievements.svg";
import arrowDownBlue from "./common/arrowDownBlue.svg";
import notifications from "./layout/notifications.svg";
import dashboardShow from "./common/show-dashboard.svg";
import activeUserAction from "./common/user-active.svg";
import gameImageBackup from "./common/gameImageBackup.svg";
import arrowDownLeague from "./common/arrowDownLeague.svg";
import checkedCheckbox from "./common/checkbox-checked.svg";
import inactiveUserAction from "./common/user-inactive.svg";
import uncheckedCheckbox from "./common/checkbox-unchecked.svg";
import logoPersian from "assets/logoPersin.svg";
import newticket from "../newMail.svg";
import openticket from "../openMail.svg";
import arrowRightSm from "../icons/Arrow_Up_Right_SM.svg";
import updatedGames from "assets/updatdGames.svg";
import noNewGame from "assets/noNewGame.svg";
import download from "assets/icons/Download.svg";
import refrenc from "assets/Arrow_Undo_Up_Right.svg";
import ticket from "assets/Ticket_Voucher.svg";
import attachment from "assets/icons/attach.svg";
import loginPic from "assets/login picture.svg";
import backgroundLogin from "assets/backgroundLogin.svg";
import category from "assets/category.svg";
import publisherRole from "assets/Code.svg";
import fullscreen from "assets/fullscreen.svg";
import warn from "assets/Close_Circle.svg";
import greenTick from "assets/Circle_Check.svg";
import downloadWhite from "assets/downloadWhite.svg";
import fileEdit from "assets/File_Edit.svg";
import trashEmpty from "assets/Trash_Empty.svg";
import ticketsCategory from "assets/Ticket_Voucher.svg";
import tags from "assets/Tag.svg";
import mouseControl from "assets/mouse.svg";
import link from "assets/External_Link.svg";
import chatCircle from "assets/Chat_Circle_Close.svg";
import calenderPicker from "assets/Calendar_Check.svg";
import chat from "assets/Chat.svg";
import dragAndDrag from "assets/drag&drop icon.svg";
import leaguesManagment from "assets/Folder_Open.svg";
import leagsList from "assets/List_Unordered.svg";
import chatComment from "assets/Chat_Circle_Dots.svg";
import ads from 'assets/ad-icon.svg'

export const ICONS: { [key: string]: string } = {
  CHATCOMMENT: chatComment,
  LEAGUESMANAGMENT: leaguesManagment,
  LEAGUESLIST: leagsList,
  CHAT: chat,
  CHATCIRCLE: chatCircle,
  CALENDERPICKER: calenderPicker,
  LINK: link,
  MOUSECONTROL: mouseControl,
  TICKETSCATEGORIES: ticketsCategory,
  TAGS: tags,
  ADVERTISEMENT:ads,
  FILEEDIT: fileEdit,
  TRASHEMPTY: trashEmpty,
  DRAGANDDRAG: dragAndDrag,
  DOWNLOADWITH: downloadWhite,
  WARN: warn,
  TICK: greenTick,
  PUBLISHERROLE: publisherRole,
  CATEGORY: category,
  BACKGROUNDLOGIN: backgroundLogin,
  LOGINPICTURE: loginPic,
  ATTACHMENT: attachment,
  TICKET: ticket,
  REFRENCE: refrenc,
  UPDATEDGAMES: updatedGames,
  NEWTICKET: newticket,
  NO_NEW_GAME: noNewGame,
  OPENTICKET: openticket,
  ARROWRIGHTSM: arrowRightSm,
  DOWNLOAD: download,
  LOGOPERSIAN: logoPersian,
  SHOW: show,
  HIDE: hide,
  MAIL: mail,
  LOCK: lock,
  LOGO: logo,
  AVATAR: avatar,
  DASHBOARD: dashboard,
  USERS: users,
  AVATARS: avatars,
  CATEGORIES: categories,
  GAMES: games,
  LEAGUES: leagues,
  BRANDS: brands,
  ACHIEVEMENTS: achievements,
  SUPPORT: support,
  NOTIFICATIONS: notifications,
  LOGOUT: logout,
  MENU: leagues,
  SEARCH: search,
  BACK: back,
  GAME_IMAGE_BACKUP: gameImageBackup,
  MESSAGE: message,
  TRASH: trash,
  ARROW_LEFT: arrowLeft,
  SEND: send,
  CHECKED_CHECKBOX: checkedCheckbox,
  UNCHECKED_CHECKBOX: uncheckedCheckbox,
  SORT: sort,
  UPLOAD: upload,
  SELECT_ICON: selectIcon,
  CAMERA: camera,
  EDIT_PENCIL: editPencil,
  TRASH_EMPTY: trashEmpty,
  FLAG: flag,
  GOLDSTAR: goldStar,
  HEART: heart,
  SHARE: share,
  ARROW_DOWN_BLUE: arrowDownBlue,
  EXPAND: expand,
  MOUSE: mouse,
  KEYBOARD: keyboard,
  PLUS: plus,
  CLOSE: close,
  CHECK: check,
  CALENDAR: calendar,
  UP: up,
  DOWN: down,
  ARROW_DOWN_LEAGUE: arrowDownLeague,
  PLUS_LEAGUE: plusLeague,
  FILTER: filter,
  MENU_OPEN: menuOpen,
  MENU_CLOSE: menuClose,
  DEVELOPERS: developers,
  REPORTS: reports,
  FINANCE: finance,
  SETTINGS: settings,
  ARROW_DOWN: arrowDown,
  USER_ACTIVE: activeUserAction,
  USER_INACTIVE: inactiveUserAction,
  NEW_TICKET: newTicket,
  OPEN_TICKET: openTicket,
  NO_TICKET: noTicket,
  CLOSE_SELECT: closeSelect,
  DASHBOARD_SHOW: dashboardShow,
  NEW_GAME: newGame,
  FULLSCREEN: fullscreen,
};
