import { Avatar, Box, MenuItem, Pagination, Popper, TextField, Typography } from "@mui/material";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { IGameType, gameVersionType } from "api/game";
import { ITicketType } from "api/ticket";
import { IUserType } from "api/user";
import DataGrid from "components/datagrid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { formatJallali } from "utils/date";
import block from "assets/block.png";
import mail from "assets/newMail.svg";
import eye from "assets/Show.svg";
import Search from "components/share/search";
import PageContainer from "components/PageContainer";

const publishStatus = [
  { status: "inProgress", title: "درحال بررسی" },
  { status: "done", title: " تایید شده" },
  { status: "rejected", title: " رد شده" },
  { status: "inProgress", title: "درحال بررسی" },
];
export default function DeveloperGames({
  userInfo,
  type,
}: {
  userInfo: IUserType | undefined;
  type: "user" | "publisher";
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [status, setStatus] = useState<"inProgress" | "done" | "rejected" | "draft" | "StopPublishing" | "">("done");
  const [searchValue, setSearchValue] = useState("");
  const [content, setContent] = useState("");
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(1);
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filteredGameByPublisher, setFilterdGameByPublisher] = useState<gameVersionType[]>([]);
  const { data: usertickets } = useSWR<any>(`/tickets?userId=${userInfo?._id}`);
  const { data: allGameVersion } = useSWR<{ gameVersions: gameVersionType[]; count: number }>(
    `/game/version?publishStatus=${status}&searchText=${searchValue}&start=${(page-1)*2}&limit=${2}`
  );
  const { data: allGameVersionWithNoFilter } = useSWR<{ gameVersions: gameVersionType[]; count: number }>(
    `/game/version?publishStatus=${status}`
  );
  const open = Boolean(anchorEl);
 
  useEffect(() => {
    if (allGameVersion && allGameVersion?.gameVersions) {
      setFilterdGameByPublisher(() =>
        allGameVersion?.gameVersions?.filter(
          (version: gameVersionType, index: number) => version?.publisher?._id === id
        ))
       
    }
  }, [allGameVersion]);
  useEffect(()=>{
    allGameVersionWithNoFilter && filteredGameByPublisher.length!==0 && setTotalCount(Math.ceil(allGameVersionWithNoFilter?.count/2))
  },[filteredGameByPublisher&& allGameVersionWithNoFilter])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  function handleDisplayContent(e: any, content: string) {
    setAnchorEl(e.target);
    setContent(content);
  }
  useEffect(() => {
    if (usertickets?.tickets) {
      let rows: GridRowsProp = usertickets?.tickets.map((ticket: ITicketType) => ({
        number: ticket?.ticketId,
        title: ticket?.title,
        status: ticket?.status,
        createdAt: ticket?.createdAt,
        updatedAt: ticket?.updatedAt,
        id: ticket?._id,
      }));
    }
  }, [usertickets]);

  const columns: GridColDef[] = [
    {
      field: "games",
      headerName: "بازی",

      flex: 1,
      renderCell({ row }: any) {
        return (
          <Box sx={{ display: "flex", width: "100%" }}>
            {" "}
            <Avatar
              sx={{ width: "65px", height: "45px", borderRadius: "5px" }}
              src={row?.coverImage?.url}
              alt=""
            />{" "}
            <Typography sx={{ fontWeight: 600, fontSize: "14px", mt: 1.3, mx: 1.5 }}>{row?.title}</Typography>
          </Box>
        );
      },
    },
    {
      field: "uploadedTime",
      headerName: " تاریخ آپلود",
      flex: 1,

      renderCell({ row }: any) {
        return <Typography sx={{ fontSize: "14px" }}>{formatJallali(row?.createdAt)}</Typography>;
      },
    },
    {
      field: "publishedTime",
      headerName: "تاریخ انتشار",
      flex: 0.5,

      renderCell({ row }: { row: IGameType }) {
        return <Typography sx={{ fontSize: "14px" }}>{formatJallali(row?.versions[0]?.publishDate)}</Typography>;
      },
    },
    {
      field: "numberOfPlayers",
      headerName: "مجموع بازیکنان",
      flex: 0.5,

      renderCell({ row }: { row: IGameType }) {
        return <Typography sx={{ fontSize: "1.3rem", fontWeight: 300 }}>{row?.playCount}</Typography>;
      },
    },
    {
      field: "numberOfGamePlayed",
      headerName: "تعداد بازی شده",
      flex: 0.5,

      renderCell({ row }: { row: IGameType }) {
        return <Typography sx={{ fontSize: "1.3rem", fontWeight: 300 }}>{row?.playCount}</Typography>;
      },
    },
    {
      field: "score",
      headerName: " امتیاز",
      flex: 0.5,

      renderCell({ row }: { row: IGameType }) {
        return <Typography sx={{ fontSize: "1.3rem", fontWeight: 300 }}>{row?.rate}</Typography>;
      },
    },
    {
      field: "",
      headerName: "",
      flex: 0.7,

      renderCell({ row }: { row: ITicketType }) {
        return (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {[
              { icon: block, content: "بلاک" },
              { icon: mail, content: "پیام به توسعه دهنده" },
              { icon: eye, content: "بررسی" },
            ]
              .reverse()
              .map((i: any, index: number) => {
                return (
                  <Box
                    key={index}
                    sx={{ cursor: "pointer" }}
                    onMouseEnter={(e: any) => handleDisplayContent(e, i.content)}
                    onMouseLeave={() => setAnchorEl(null)}
                  >
                    <img src={i.icon} alt="" style={{ padding: "0 0.5rem" }} />
                  </Box>
                );
              })}
          </Box>
        );
      },
    },
  ];
  const rejectedColumns: GridColDef[] = [
    {
      field: "games",
      headerName: "بازی",

      flex: 1,
      renderCell({ row }: any) {
        return (
          <Box sx={{ display: "flex", width: "100%" }}>
            {" "}
            <Avatar
              sx={{ width: "65px", height: "45px", borderRadius: "5px" }}
              src={row?.coverImage?.url}
              alt=""
            />{" "}
            <Typography sx={{ fontWeight: 600, fontSize: "14px", mt: 1.3, mx: 1.5 }}>{row?.title}</Typography>
          </Box>
        );
      },
    },
    {
      field: "uploadedTime",
      headerName: " تاریخ آپلود",
      flex: 1,

      renderCell({ row }: any) {
        return <Typography sx={{ fontSize: "14px" }}>{formatJallali(row?.createdAt)}</Typography>;
      },
    },
    {
      field: "rejectReason",
      headerName: "علت عدم تایید بازی",
      flex: 3,
      renderCell({ row }: any) {
        return (
          <Box>
            {row?.rejectReasons?.map((i: any, index: number) => {
              return <Typography sx={{ fontSize: "14px" }}>{i?.description}</Typography>;
            })}
          </Box>
        );
      },
    },
  ];
  return (
    <PageContainer noPadding={true}>
      <Box sx={{ display: "flex", py: 2, pr: 10 }}>
        <Search onSearchQuery={(a: string) => setSearchValue(a)} width="400px" bg="white" />
        <TextField
          sx={{ px: 3, width: "200px" }}
          id="outlined-select-currency"
          select
          placeholder="در حال بررسی "
          defaultValue="درحال بررسی"
          onChange={(e: any) => setStatus(e.target.value)}
        >
          {publishStatus?.map((option: any, index: number) => (
            <MenuItem key={index} value={option?.status}>
              {option?.title}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <DataGrid
        columns={status === "rejected" ? rejectedColumns : columns}
        rows={
          filteredGameByPublisher?.map((game: gameVersionType) => ({
            ...game.game,
            rejectReasons: game.rejectReasons,
            id: game?._id,
          })) || []
        }
    
        sx={{
          border: "1px solid gray.light",
         
          borderRadius: "12px",
          "& .MuiDataGrid-HeaderContent": {
            fontSize: "2rem",
            fontWeight: 400,
            textAlign: "center",
            pl: 4,
          },
          "& .MuiDataGrid-cell": {
            height: "500px",
          },
          "& .MuiDataGrid-virtualScroller": { marginTop: "14px" },
          "& .MuiDataGrid-cellContent": {
            borderBottomColor: "text.primary",
            fontSize: "1.3rem",
            fontWeight: 400,
          },
        }}
      />

      <Box sx={{ py: 2 }}>
        <Pagination
          count={totalCount }
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handleChange}
          color="primary"
        />
      </Box>
      <Popper open={open} anchorEl={anchorEl}>
        <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>{content}</Box>
      </Popper>
    </PageContainer>
  );
}
