import { Grid, Box, Typography, Divider } from "@mui/material";
import { ILeagueType } from "api/league";
import PageContainer from "components/PageContainer";
import BackButton from "components/backButton/BackButton";
import LeagueDefaultDetails from "components/league/leagueDefaultDetails";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

export default function InnerLeague() {
  const [disable,setDisable]=useState(false)
  const item = [
    { id: 1, link: "details", title: "جزییات" },
    { id: 2, link: "comminucations", title: "گفت و گوی لیگ " },
    { id: 3, link: "usersReports", title: "گزارشات کاربران" },
    { id: 4, link: "blockedUsers", title: "کاربران مسدود شده" },
    { id: 5, link: "scorBoard", title: "جدول امتیازات" },
  ];
  const { id } = useParams();
  const { pathname ,state} = useLocation();

  const navigate = useNavigate();
  useEffect(()=>{
    if( state==="lastleagues")setDisable(true)

  },[state])
  let contextValue={tabValue:disable}
  const { data: allLeagues } = useSWR<{ leagues: ILeagueType[] }>("/leagues");
  const filterdLeagueById = allLeagues?.leagues.filter((i: ILeagueType) => i._id === id) || [];
  function isActive(pathname: string, link: string) {
    return pathname.search(link) > -1;
  }
  return (
    <PageContainer>
      <Grid
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "1.7rem",
          mb: "2rem",
          minHeight:"100vh",
          py: 3,
          px: 1,
        }}
      >
        <BackButton back={()=>navigate("/leagues")}/>
        <LeagueDefaultDetails league={filterdLeagueById[0]} />
        <Box sx={{ display: "flex" }}>
          {item?.map((i, idx) => {
            return (
              <Box sx={{ textDecoration: "none", cursor: "pointer", mt: 2 }} key={idx} onClick={() => navigate(i.link)}>
                <Typography mx={2} fontSize={"14px"} mb={1} fontWeight={isActive(pathname, i.link) ? "600" : "400"}>
                  {i.title}
                </Typography>
                <Divider sx={{ width: "100%" }} />
                {isActive(pathname, i?.link) && (
                  <Box
                    sx={{
                      width: "100%",
                      height: "6px",
                      bgcolor: "#4A76FD",
                      boxShadow: "0px -1px 2px rgba(189, 200, 239, 0.4)",
                      borderRadius: "20px",
                      transition: "backgroundColor 0.5s ease",
                    }}
                  ></Box>
                )}
              </Box>
            );
          })}
        </Box>
        <Box sx={{ mt: 5 }}>
          <Outlet context={contextValue} />
        </Box>
      </Grid>
    </PageContainer>
  );
}
