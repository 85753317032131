import React, { useRef, useEffect } from "react";

interface CustomVideoPlayerProps {
  src: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  style?: React.CSSProperties;
  videoStyle?: React.CSSProperties;
  onload?: () => void;
}

const CustomVideoPlayer: React.FC<CustomVideoPlayerProps> = ({
  src,
  autoPlay = true,
  loop = true,
  muted = true,
  style = {},
  videoStyle={},
  onload,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.controls = false;
    }
  }, []);

  return (
    <div style={{height:"100%"}}>

    <video
      ref={videoRef}
      autoPlay={autoPlay}
      loop={loop}
      muted={muted}
      onLoadedData={onload}
      playsInline
      style={{
        ...videoStyle,
        
      }}
      >
      <source
        src={src}
        style={{
          ...style,
        }}
        type="video/mp4"
        
        
        />
    </video>
        </div>
  );
};

export default CustomVideoPlayer;
