import useTickets from "./useTickets";
import { ICONS } from "assets/icons";
import { Img, Checkbox } from "components";
import { useTranslation } from "react-i18next";
import TicketList from "./component/ticketList";
import {
  Grid,
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  Pagination,
  Tabs,
  Tab,
} from "@mui/material";
import { ITicketType } from "api/ticket";
import { useState } from "react";
import PageContainer from "components/PageContainer";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Support = () => {
  const { t } = useTranslation();
  const { tickets, total, anchorEl, openPopover, closePopover, onUserFilterClick, onPageChange } = useTickets({
    pageSize: 20,
  });
  const [page, setPage] = useState<number>(1);
  const [value, setValue] = useState<number>(0);
  const ticketsId = tickets.map((i: any) => i._id);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const onSelectionModelChange = (ids: string[]) => {
    setSelectedItems(ids);
  };

  let newTickets = tickets.filter((i: ITicketType) => i.status === "new");
  let inProgressTickets = tickets.filter((i: ITicketType) => i.status === "inProgress");
  let repliedTickets = tickets.filter((i: ITicketType) => i.status === "replied");
  let closedTickets = tickets.filter((i: ITicketType) => i.status === "closed");
  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    onPageChange(value);
  };

  return (
    <PageContainer>
      <Grid
        sx={{
          backgroundColor: "white",
          borderRadius: "1.7rem",
          width: "100%",
        }}
      >
        <Grid
          xs={5}
          container
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={4} sx={{ px: 3 }}>
            <Checkbox
              checked={selectedItems?.length === 0 ? false : true}
              onClick={() => (selectedItems?.length === 0 ? setSelectedItems(ticketsId) : setSelectedItems([]))}
            />

            <Button onClick={openPopover}>
              {" "}
              <Img
                src={ICONS.ARROW_DOWN}
                alt="add"
                sx={{
                  width: "2rem",
                  height: "2rem",
                }}
              />
            </Button>
            <Menu id="filterUsers" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closePopover}>
         
              {["user", "publisher"].map((i: any) => (
                <MenuItem key={i} onClick={() => onUserFilterClick(i)}>
                  {t(t(i))}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
        <Box sx={{ pl: 3 }}>
          <Tabs value={value} onChange={handleChangeTabs} aria-label="basic tabs example">
            <Tab label={`جدید (${newTickets.length})`} />
            <Tab label={`درحال بررسی (${inProgressTickets.length})`} />
            <Tab label={`پاسخ داده شده (${repliedTickets.length})`} />
            <Tab label={`بسته شده (${closedTickets.length})`} />
          </Tabs>
        </Box>

        {[
          { ticket: newTickets },
          { ticket: inProgressTickets },
          { ticket: repliedTickets },
          { ticket: closedTickets },
        ].map((i: any, index: number) => (
          <CustomTabPanel value={value} index={index}>
            <TicketList
              tickets={i.ticket}
              onSelectionModelChange={onSelectionModelChange}
              selectedItems={selectedItems}
              onPageChange={onPageChange}
              total={total}
            />
          </CustomTabPanel>
        ))}
      </Grid>
      <Box sx={{ py: 3 }}>
        <Pagination
          count={total}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handleChange}
          color="primary"
        />
      </Box>
    </PageContainer>
  );
};

export default Support;
