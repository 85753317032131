import { Grid } from "@mui/material";

interface ImgTypes {
  sx?: any;
  src: string;
  alt: string;
  title?: string;
  onClick?: () => void;
}

const Img = ({ src, alt, sx, title, onClick }: ImgTypes) => {
  return <Grid component="img" {...{ src, alt, sx, title }} onClick={onClick && onClick} />;
};

export default Img;
