import useSWR from "swr";
import useUser from "./useUser";
import { ICONS } from "assets/icons";
import { GAME_STATUS } from "types/game";
import { SWR_KEYS } from "constants/swrKeys";
import GamesList from "./components/gamesList";
import { useTranslation } from "react-i18next";
import { useState, SyntheticEvent } from "react";
import { BackButton, Img, TabPanel, TextField } from "components";
import { Avatar, Grid, Typography, Tabs, Tab } from "@mui/material";

const UserGames = () => {
  const { id, user } = useUser();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [tabValue, setTabValue] = useState(GAME_STATUS.DONE);
  const { data, isLoading, error } = useSWR(
    `${SWR_KEYS.USER_GAMES(id)}?verified=${tabValue}`
    // `${SWR_KEYS.USER_GAMES(id)}?verified=${tabValue}?searchText=${searchValue}` // todo@sareh: Ask about this api
  );

  const onChangeTab = (event: SyntheticEvent, value: GAME_STATUS) => {
    setSearchValue("");
    setTabValue(value);
  };

  const onSearch = (event: any) => {
    const { value } = event.target;

    setSearchValue(value);
  };

  return (
    <Grid>
      <BackButton />

      <Grid>
        <Avatar src={"/"} />
        <Typography>{"user.fullname"}</Typography>
      </Grid>

      <Tabs value={tabValue} onChange={onChangeTab}>
        {Object.entries(GAME_STATUS).map(([key, value]) => (
          <Tab key={key} label={t(`_gameStatus.${value}`)} value={value} />
        ))}
      </Tabs>

      {Object.entries(GAME_STATUS).map(([key, value]) => (
        <TabPanel key={key} value={tabValue} tabValue={value}>
          <TextField
            onChange={onSearch}
            value={searchValue}
            InputProps={{
              startAdornment: (
                <Img
                  src={ICONS.SEARCH}
                  alt="search"
                  sx={{
                    width: "2rem",
                    height: "2rem",
                  }}
                />
              ),
            }}
            placeholder={t("searchGame")}
          />
          <GamesList games={data?.games || []} />
        </TabPanel>
      ))}
    </Grid>
  );
};

export default UserGames;
