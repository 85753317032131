import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { IGameType, changeGamePublishStatus } from "api/game";
import PageContainer from "components/PageContainer";
import BackButton from "components/backButton/BackButton";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import useSWR from "swr";
import stop from "assets/Stop_Sign.svg";
import tick from "assets/Circle_Check.svg";
import GamePublicInfo from "./gamePublicInfo";
import { toast } from "react-toastify";

const TabPanel = [
  { title: "جزئیات بازی", link: "details" },
  { title: "اطلاعات آماری ", link: "statistic" },
  { title: "ورژن‌ها", link: "versions" },
  { title: "تبلیغات  ", link: "advertisement" },
  { title: "تاریخچه بررسی", link: "history" },
  { title: " پیش‌ نمایش ", link: "preview" },
];
export default function PublishedGame() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [from, setFrom] = useState("");
  useEffect(() => {
    console.log("location", location?.state);
    location && location?.state && setFrom(location?.state?.from as string);
  }, [location]);
  
  const { gameId, versionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [gameIsActive, setGameIsActive] = useState(true);
  const { data: gameDetials } = useSWR<{ game: IGameType }>(`/games/${gameId}`);
  useEffect(() => {
    gameDetials && gameDetials?.game && setGameIsActive(gameDetials.game.active);
  }, [gameDetials]);
  const changePublishSatate = async () => {
    try {
      setIsLoading(true);
      if (gameId && versionId && gameDetials && gameDetials?.game && gameDetials?.game?.active) {
        await changeGamePublishStatus(!gameDetials?.game?.active, gameId, versionId);
        toast.success("بازی متوقف شد");
        navigate("/games", { state: { tabValue: "deactivatedByAdmin" } });
      } else if (gameId && versionId && gameDetials && gameDetials?.game && !gameDetials?.game?.active) {
        await changeGamePublishStatus(!gameDetials?.game?.active, gameId, versionId);
        toast.success("بازی  مجددا منتشر شد");
        navigate("/games", { state: { tabValue: "done" } });
      }
    } catch (error: any) {
      toast.error(error?.message?.data?.status || "مشکلی رخ داده است ");
      console.log("erorr", error);
    } finally {
      setIsLoading(false);
    }
  };

  const tabs = ['details', 'statistic', 'versions', 'advertisement', 'history', 'preview']

  const [tabPanelNumber, setTabPanelNumber] = useState(tabs.indexOf(location.pathname.split('/').pop()));
  function clickOnMe(index: number) {
    return Boolean(tabPanelNumber === index);
  }
  return (
    <PageContainer>
      <Grid
        container
        sx={{ backgroundColor: "white", borderRadius: "12px", width: "100%", mt: 1, mb: 5, lineHeight: 4 }}
      >
        <Grid item xs={12} sx={{ pt: 2 }}>
          <BackButton back={() => navigate("/games", { state: { tabValue: from } })} />
        </Grid>
        <Grid item md={7} sx={{ pl: 2, pb: 2 }}>
          <GamePublicInfo gameInfo={gameDetials?.game} />
        </Grid>
        <Grid item md={4} sx={{ display: "flex", justifyContent: "end", px: 2, mb: 16 }}>
          {isLoading ? (
            <Box sx={{ textAlign: "center" }}>
              {" "}
              <CircularProgress size={"medium"} />
            </Box>
          ) : (
            <Button
              onClick={changePublishSatate}
              sx={{
                bgcolor: gameIsActive ? "#F84E58" : "#16c109",
                color: "white",
                px: gameIsActive ? 2.5 : 4,

                borderRadius: "6px",
                fontSize: "14px",
                "&:hover": { backgroundColor: gameIsActive ? "#bd020d" : "#0cac00" },
                transition: "background 1s ",
              }}
              endIcon={gameIsActive ? <img src={stop} alt="" /> : <img src={tick} alt="" />}
            >
              {gameIsActive ? " توقف انتشار بازی" : "انتشار بازی"}{" "}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} xl={8} sx={{ display: "flex", pl: 2 }}>
          {TabPanel.map((tab: any, index: number) => {
            return (
              <Box
                key={index}
                sx={{
                  borderBottom: clickOnMe(index) ? "4px solid #4A76FD" : "1px solid grey",
                  cursor: "pointer",
                  transition: "borderBottom 1s ease-in",
                  justifyContent: "center",
                  display: "flex",
                  mb: 2,
                  px: 1.5,
                  width: "100%",
                }}
                onClick={() => {
                  setTabPanelNumber(index);
                  navigate(`${tab.link}`);
                }}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: clickOnMe(index) ? "bold" : "unset", py: 1 }}>
                  {tab.title}
                </Typography>
              </Box>
            );
          })}
        </Grid>

        <Grid xs={12} sx={{ pl: 2, minHeight: "100vh" }}>
          <Outlet />
        </Grid>
      </Grid>
    </PageContainer>
  );
}
