import { get } from "api";
import Router from "Router";
import { SWRConfig } from "swr";
import { prefixer } from "stylis";
import { setTheme } from "./theme";
import ErrorBoundary from "./pages/500";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import GetUser from "components/Get-User";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CacheProvider } from "@emotion/react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { getDirection } from "utils/muliLingualUtils";
import { CssBaseline, Direction } from "@mui/material";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from "chart.js";
import "chart.js/auto";
import "styles/globals.css";

const rtlCache = createCache({
  key: "rtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const ltrCache = createCache({
  key: "ltr",
});

function App() {
  const { i18n } = useTranslation();
  const theme = useSelector((state: any) => state.theme.mode);

  // Get language and direction based on language
  const language: string = i18n.language;
  const [direction, setDirection] = useState<Direction>(getDirection(language));

  useEffect((): void => {
    const dir = getDirection(language);
    setDirection(dir);
    document.dir = dir;
  }, [language]);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <CacheProvider value={direction === "rtl" ? rtlCache : ltrCache}>
          <SWRConfig value={{ fetcher: get }}>
            <ToastContainer />
            <ThemeProvider theme={setTheme(theme, direction)}>
              <GetUser>
                <CssBaseline />
                <Router />
              </GetUser>
            </ThemeProvider>
          </SWRConfig>
        </CacheProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
