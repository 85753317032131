import { SxProps, Theme, useMediaQuery } from "@mui/material";
import { DataGrid as MuiDataGrid, GridColumns, GridRowIdGetter, GridRowParams } from "@mui/x-data-grid";

export default function DataGrid({
  pageSize,
  columns,
  rows,
  height,
  onRowClick,
  sx,
  getRowId,
  width,
}: {
  columns: GridColumns;
  rows: any;
  height?: number;
  onRowClick?: (params: GridRowParams) => void;
  sx?: SxProps<Theme>;
  pageSize?: number;
  getRowId?: GridRowIdGetter<any> | undefined;
  width?: any;
}) {
  const mobile = useMediaQuery("(max-width: 750px)");

  return (
    <div style={{ height: height || 500 }}>
      <MuiDataGrid
        getRowId={getRowId}
        columns={columns}
        rows={rows}
        onRowClick={onRowClick}
        pageSize={pageSize}
        hideFooter
        sx={{
          background: "white",
          border: "1px solid #C0C1CE",
          borderRadius: 2,
          overflowX: mobile ? "scroll" : "auto",
          ...sx,
          "& .MuiDataGrid-cell": {
            fontSize: mobile ? "0.6rem" : "0.8rem",
          },
          "& .MuiDataGrid-columnHeaders ": {
            borderBottom: "1px solid #C0C1CE",
          },
          "& .MuiDataGrid-columnHeader--sortable": {
            borderLeft: "1px solid #C0C1CE",
          },
          "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
            borderBottom: "2px solid",
            borderColor: "secondary.100",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            fontSize: mobile ? "0.7rem" : "0.9rem",
            color: "#474859",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#676872",
          },
        }}
      />
    </div>
  );
}
