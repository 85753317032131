import { THEME } from "constants/theme";
import { createTheme, Direction } from "@mui/material";
import { DARK_PALETTE, LIGHT_PALETTE } from "./palette";

export const setTheme = (mode: string, direction: Direction) =>
  createTheme({
    direction,
    breakpoints: {
      values: {
        xs: 480,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    },
    palette: mode === THEME.LIGHT ? { ...LIGHT_PALETTE, mode: THEME.LIGHT } : { ...DARK_PALETTE, mode: THEME.DARK },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: ({ theme }) => ({
            fontSize: "1.4rem",
            fontWeight: 400,
            fontStyle: "normal",
            color: theme.palette.text.primary,

            [theme.breakpoints.down("sm")]: {
              fontSize: "1.3rem",
            },
          }),
        },
      },

      MuiButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            fontSize: "1.4rem",
            fontWeight: 400,
            fontStyle: "normal",
          }),
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: "1.4rem",
            fontWeight: 400,
            fontStyle: "normal",
            borderRadius: "0.7rem",
            height: "4.7rem",
          },

          input: {
            padding: "0 1rem",

            ":-webkit-autofill": {
              "-webkit-box-shadow": "0 0 0 1000px transparent inset",
              "-webkit-background-clip": "text",
              "box-shadow": "inset 0 0 20px 20px transparent",
            },
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "1rem",
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.error.main,
            margin: "0.5rem 0",
            fontSize: "0.9rem",
          }),
        },
      },

      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "unset",
            width: "fit-content",
            fontSize: "1.4rem",
            fontWeight: 700,
            paddingBottom: "1px",
          },

          flexContainer: ({ theme }) => ({
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.text.disabled,
            height: "4rem",
          }),

          indicator: ({ theme }) => ({
            height: "4px",
            backgroundColor: theme.palette.primary.main,
            borderRadius: "0.7rem",
          }),

          scroller: {
            height: "calc(4rem + 2px)",
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.text.disabled,
            padding: 0,
            minHeight: "unset",
            minWidth: "unset",
            fontSize: "1.3rem",
            fontWeight: 500,
            margin: "1rem 2rem",

            ":first-of-type": {
              marginLeft: 0,
            },

            ":last-of-type": {
              marginRight: 0,
            },

            "&.Mui-selected": {
              color: theme.palette.text.primary,
              fontWeight: 700,
            },
          }),
        },
      },
    },
  });

declare module "@mui/material/styles" {
  interface Palette {
    neutral?: Palette["primary"];
  }

  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
  }
}
