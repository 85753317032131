import { ICONS } from "assets/icons";
import useLeagues from "./useLeagues";
import { Link } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { LEAGUE_STATUS } from "types/league";
import { useTranslation } from "react-i18next";
import { Checkbox, Img, TabPanel } from "components";
import { Box, Button, Grid, Tab, Tabs } from "@mui/material";
import PageContainer from "components/PageContainer";
import LeaguesList from "./components/leaguesList";
import { useState } from "react";

const Leagues = () => {
  const { t } = useTranslation();
  const { tabValue, onChangeTab, leagues } = useLeagues();
  const [fullCheckbox, setFullCheckbox] = useState(false);

  return (
    <PageContainer>
      <Grid
        sx={{
          padding: "4rem 2.5rem 8rem",
          width: "100%",
          bgcolor: "background.paper",
          borderRadius: "1.7rem",
        }}
      >
        <Button
          component={Link}
          to={ROUTES.NEW_LEAGUE}
          variant="contained"
          startIcon={<Img src={ICONS.PLUS} alt="add" sx={{ width: "2rem", height: "2rem" }} />}
          sx={{
            padding: "0.4rem 3.6rem ",
            mb: 2,
          }}
        >
          {t("addNewLeague")}
        </Button>
        <Box sx={{ pl: 1, pb: 1 }}>
          <Checkbox checked={fullCheckbox} onClick={() => setFullCheckbox(!fullCheckbox)} />
          <Button
          //onClick={openPopover}
          >
            <Img
              src={ICONS.ARROW_DOWN}
              alt="add"
              sx={{
                width: "2rem",
                height: "2rem",
              }}
            />
          </Button>
        </Box>
        <Tabs value={tabValue} onChange={onChangeTab}>
          {Object.entries(LEAGUE_STATUS).map(([key, value]) => (
            <Tab key={key} label={t(`_leagueStatus.${value}`)} value={value} />
          ))}
        </Tabs>

        {Object.entries(LEAGUE_STATUS).map(([key, value]) => (
          <TabPanel key={key} value={tabValue} tabValue={value}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "2.5rem 0 2rem",
              }}
            >
              <LeaguesList fullCheckBox={fullCheckbox} leagues={leagues} tabValue={tabValue} />
            </Box>
          </TabPanel>
        ))}
      </Grid>
    </PageContainer>
  );
};

export default Leagues;
