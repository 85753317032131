import { IGameType } from "api/game";

import { Box, Grid, Typography } from "@mui/material";
import { ImageContainer, ItemContainer } from "./GameItems";
import { ICONS } from "assets/icons";
import { Img } from "components";
import { useTranslation } from "react-i18next";
import { GifContainer } from "./gifContainer";


export default function Preview({ game }: { game: IGameType }) {
  const { t } = useTranslation();

  return (
    <>
      <Typography sx={{ color: "#676872", my: 1 }}>{t("gameImage")}</Typography>
      <Grid sx={{ display: "flex", justifyContent: "space-around", my: 3}}>
        <ImageContainer src={game?.coverImage?.url} caption={"coverImage"} width={"100px"} height="100px"/>
        <ImageContainer src={game?.mobileImage?.url} caption={"mobileImage"} width="100px" height="150px" />
        <ImageContainer src={game?.image?.url} caption={"image"} width="150px" height="100px" />
      </Grid>
      <Typography sx={{ color: "#676872", my: 1 }}>{t("previewGif")} </Typography>
      <GifContainer src={game?.coverGif?.url} caption={"coverGif"} width="190px" height="130px" />
    </>
  );
}
