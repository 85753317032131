import { Box, Button, Grid, IconButton, Typography, Toolbar, AppBar, Dialog, Avatar, styled } from "@mui/material";
import { IUserType, undoIdentity, verifiedIdentity } from "api/user";
import card from "assets/nationalCard.svg";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import React, { useState } from "react";
import AuthenticationForm from "./authenticationForm";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ICONS } from "assets/icons";
import Preview from "./preview";
import NotSignYet from "./notSign";
import PDFPreview from "shared/Pdf/Viewer";
import { Title } from 'chart.js';
import PageContainer from "components/PageContainer";

const MyAvatar = styled(Avatar)({
  border: "1px solid rgba(192,193,206,1)",
  borderRadius: "12px",
  objectFit: "cover",
  marginTop: "2rem",
  marginRight: "1rem",
  marginLeft: "30rem",
  height: 85,
  width: 150,
});
export default function AuthenticationBusiness({
  onMutate,
  userInfo,
  title,
  type,
}: {
  onMutate: () => void;

  userInfo: IUserType | undefined;
  title: any[];
  type: "publisher";
}) {
  const [value, setValue] = React.useState("confirm");
  const [open, setOpen] = React.useState(false);
  const [src, setSrc] = useState("");
  const handleClickOpen = (title:string,a: string) => {
    setIdentifier(title)
    setSrc(a);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { id } = useParams();
  const [accountVerified, setAccountVerifed] = useState<"inProgress" | "notVerified" | "verified" | "rejected" | null>(
    null
  );
  const [acceptAuth, setAcceptAuth] = useState(false);
  const [rejectAuth, setRejectAuth] = React.useState(false);
  const [openVerification, setOpenVerification] = useState(false);
  const [fileType, setFileType] = useState<"pdf" | "image">("image");
  const [identifier, setIdentifier] = useState("");

  const handleReject = (b: boolean) => {
    setRejectAuth(b);
  };

  const handleSubmit = async () => {
    try {
      await verifiedIdentity(id);
      toast.success("احراز هویت با موفقیت انجام شد");
      setAcceptAuth(true);
      onMutate();
    } catch (error) {
      toast.error("مشکلی رخ داده");
      console.log(error);
    }
  };
  const changeAccountVerified = (e: "inProgress" | "notVerified" | "verified" | "rejected" | null) => {
    e && setAccountVerifed(e);
  };
  const undoTypeIdentity = async () => {
    try {
      await undoIdentity(id);

      setRejectAuth(false);
      setAcceptAuth(false);
      toast.success("وضعیت کاربر هم اکنون به در حال بررسی تغییر کرد");
    } catch (error: any) {
      toast.error("مشکلی رخ داده است");
    } finally {
      onMutate();
    }
  };
  function handleopenPreviewModal(title: string, src: string, file: "pdf" | "image") {
    setIdentifier(title);
    setOpen(true);
    setSrc(src);
    setFileType(file);
  }
  return (
    <PageContainer noPadding={true}>
      {userInfo?.accountVerified === "notVerified" ? (
        <NotSignYet />
      ) : (
        <>
          <Dialog open={open} onClose={handleClose}>
            <Preview onClose={handleClose} open={open} src={src} type={fileType} title={identifier}/>
          </Dialog>
          <Dialog open={openVerification} onClose={() => setOpenVerification(false)}>
            <Box sx={{ px: 3, py: 2, borderRadius: "8px" }}>
              <Typography sx={{ mt: 2 }}>آیا از تایید اطلاعات هویتی کاربر اطمینان دارید؟</Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
                <Button variant="contained" sx={{ px: 3, bgcolor: "green" }} onClick={handleSubmit}>
                  تایید
                </Button>
                <Button variant="contained" sx={{ px: 3 }} onClick={() => setOpenVerification(false)}>
                  انصراف{" "}
                </Button>
              </Box>
            </Box>
          </Dialog>
          {/* //from this line must refactor in Other component in order to be cleaner */}
          <Box
            sx={{
              display: "flex",
              p: 3,
              minHeight: "33rem",
              border: " 1px solid rgba(192, 193, 206, 1)",
              borderRadius: "12px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              {title.map((i: any, index: number) => (
                <>
                  <Grid container sx={{ display: "flex" }}>
                    <Grid item xs={3}>
                      <Typography sx={{ color: " rgba(103, 104, 114, 1)", lineHeight: "35px" }}>{i.field}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography sx={{ fontWeight: 600, color: "rgba(51, 51, 55, 1)", lineHeight: "35px" }}>
                        {i.content ? i.content : "__"}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ))}
            </Box>

            {userInfo?.accountType === "business" ? (
              <Box sx={{ width: "85%" }}>
                <>
                  {[
                    {
                      name: " تصویر کارت ملی مدیر ",
                      type: userInfo?.businessAccountInfo?.files?.ceoNationalIdCardImage?.contentType,
                      src: userInfo?.businessAccountInfo?.files?.ceoNationalIdCardImage?.url,
                    },
                    {
                      name: " تصویر  گواهی روزنامه رسمی  ",
                      type: userInfo?.businessAccountInfo?.files?.officialNewspaper?.contentType,
                      src: userInfo?.businessAccountInfo?.files?.officialNewspaper?.url,
                    },
                    {
                      name: " تصویر مدرک مالیات بر ارزش افزوده ",
                      type: userInfo?.businessAccountInfo?.files?.valueAddedTax?.contentType,
                      src: userInfo?.businessAccountInfo?.files?.valueAddedTax?.url,
                    },
                    {
                      name: " تصویر سایر مدارک ",
                      type: userInfo?.businessAccountInfo?.files?.other?.contentType,
                      src: userInfo?.businessAccountInfo?.files?.other?.url,
                    },
                  ].map((i: any) => (
                    <>
                      {" "}
                      <Typography sx={{ fontWeight: 600, fontsize: "16px", textAlign: "start", pt: 1 }}>
                        {i.name}:
                      </Typography>
                      {i.src ? (
                        i.type === "pdf" ? (
                          <Box sx={{ textAlign: "center", position: "relative" }}>
                            <img
                              onClick={() => handleopenPreviewModal(i.name, i.src, "pdf")}
                              src={ICONS.FULLSCREEN}
                              alt="fullscreen"
                              height={20}
                              width={20}
                              style={{ position: "absolute", top: "65%", left: "60%", cursor: "pointer", zIndex: 1000 }}
                            />
                            <PDFPreview
                              pdf={i.src}
                              height={100}
                              width={150}
                              style={{
                                border: "1px solid rgba(192,193,206,1)",
                                borderRadius: "12px",
                                objectFit: "cover",
                                marginTop: "2rem",
                                marginRight: "16rem",
                                marginLeft: "17rem",
                              }}
                            />
                            <Box sx={{ position: "absolute", top: "65%", right: "10%", cursor: "pointer" }}>
                              <IconButton>
                                <VerticalAlignBottomIcon fontSize="medium" />
                                <a
                                  href={i.src}
                                  download
                                  style={{ textDecoration: "none", color: "black", fontSize: "14px" }}
                                >
                                  دانلود تصویر
                                </a>
                              </IconButton>
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ textAlign: "center", position: "relative" }}>
                            <img
                              src={i.src}
                              alt="card"
                              height={85}
                              width={150}
                              style={{
                                border: "1px solid rgba(192,193,206,1)",
                                borderRadius: "12px",
                                objectFit: "cover",
                                marginTop: "2rem",
                                marginRight: "1rem",
                                marginLeft: "2rem",
                              }}
                            />
                            <img
                              onClick={() =>
                                handleopenPreviewModal(i.name,i.src,"image")
                              }
                              src={ICONS.FULLSCREEN}
                              alt="fullscreen"
                              height={20}
                              width={20}
                              style={{ position: "absolute", top: "65%", left: "60%", cursor: "pointer" }}
                            />

                            <Box sx={{ position: "absolute", top: "65%", right: "10%", cursor: "pointer" }}>
                              <IconButton>
                                <VerticalAlignBottomIcon fontSize="medium" />
                                <a
                                  href={i.src}
                                  download
                                  style={{ textDecoration: "none", color: "black", fontSize: "14px" }}
                                >
                                  دانلود تصویر
                                </a>
                              </IconButton>
                            </Box>
                          </Box>
                        )
                      ) : (
                        <Box sx={{ textAlign: "center", position: "relative" }}>
                          <MyAvatar />

                          <Box sx={{ position: "absolute", top: "65%", right: "10%", cursor: "pointer" }}>
                            <IconButton>
                              {" "}
                              <VerticalAlignBottomIcon fontSize="medium" />
                              <a
                                href={i.src}
                                download
                                style={{ textDecoration: "none", color: "black", fontSize: "14px" }}
                              >
                                {" "}
                                دانلود تصویر
                              </a>
                            </IconButton>
                          </Box>
                        </Box>
                      )}
                    </>
                  ))}
                </>
              </Box>
            ) : (
              <Box>
                <>
                  <Typography>تصویر کارت ملی آپلود شده</Typography>
                  {userInfo?.nationalIdCardImage && userInfo?.nationalIdCardImage?.contentType === "pdf" ? (
                    <Box sx={{ textAlign: "center", position: "relative" }}>
                      <img
                        onClick={() =>
                          handleopenPreviewModal("تصویر کارت ملی اپلود شده", userInfo?.nationalIdCardImage?.url, "pdf")
                        }
                        src={ICONS.FULLSCREEN}
                        alt="fullscreen"
                        height={20}
                        width={20}
                        style={{ position: "absolute", top: "65%", left: "60%", cursor: "pointer", zIndex: 1000 }}
                      />
                      <PDFPreview
                        pdf={userInfo?.nationalIdCardImage?.url}
                        height={100}
                        width={150}
                        style={{
                          border: "1px solid rgba(192,193,206,1)",
                          borderRadius: "12px",
                          objectFit: "cover",
                          marginTop: "2rem",
                          marginRight: "16rem",
                          marginLeft: "17rem",
                        }}
                      />
                      <Box sx={{ position: "absolute", top: "65%", right: "10%", cursor: "pointer" }}>
                        <IconButton>
                          <VerticalAlignBottomIcon fontSize="medium" />
                          <a
                            href={userInfo?.nationalIdCardImage?.url}
                            download
                            style={{ textDecoration: "none", color: "black", fontSize: "14px" }}
                          >
                            دانلود تصویر
                          </a>
                        </IconButton>
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={{ textAlign: "end", position: "relative" }}>
                      <img
                        src={userInfo?.nationalIdCardImage?.url}
                        alt="card"
                        style={{
                          height: "130px",
                          width: "210px",
                          border: "1px solid rgba(192,193,206,1)",
                          borderRadius: "12px",
                          objectFit: "cover",
                          marginTop: "2rem",
                          marginRight: "1rem",
                          marginLeft: "2rem",
                        }}
                      />
                      <img
                        onClick={() => handleClickOpen("تصویر کارت ملی ",userInfo?.nationalIdCardImage?.url)}
                        src={ICONS.FULLSCREEN}
                        alt="fullscreen"
                        style={{ position: "absolute", top: "75%", left: "10%", cursor: "pointer" }}
                      />
                      <Box sx={{ display: "flex", position: "absolute", top: "100%", left: "55%" }}>
                        <IconButton>
                          {" "}
                          <VerticalAlignBottomIcon fontSize="medium" />
                          <a href={card} download style={{ textDecoration: "none", color: "black", fontSize: "14px" }}>
                            {" "}
                            دانلود تصویر
                          </a>
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              my: 2,
              py: 3,
              width: "100%",
              border: " 1px solid rgba(192, 193, 206, 1)",
              borderRadius: "12px",
              bgcolor: rejectAuth || acceptAuth ? "#C0C1CE" : "unset",
            }}
          >
            <Grid container>
              <Grid item xs={6} sx={{ px: 1 }}>
                {userInfo && userInfo?.accountVerified === "verified" ? (
                  <Box sx={{ display: "flex" }}>
                    <img src={ICONS.TICK} alt="tick" />
                    <Typography sx={{ color: "#04BD0C", fontWeight: 600, mt: 1, px: 1 }}>
                      اطلاعات ثبت شده ی کاربر تایید شده است
                    </Typography>
                  </Box>
                ) : userInfo?.accountVerified === "inProgress" ? (
                  <AuthenticationForm
                    onRejectAuth={handleReject}
                    userInfo={userInfo}
                    onMutate={onMutate}
                    onChangeAccountVerified={changeAccountVerified}
                  />
                ) : userInfo?.accountVerified === "rejected" ? (
                  <>
                    <Box sx={{ display: "flex" }}>
                      <img src={ICONS.WARN} alt="warn" />
                      <Typography sx={{ color: "#F84E58", fontWeight: 600, mt: 1, px: 1 }}>
                        اطلاعات ثبت شده ی کاربر تایید نشده است
                      </Typography>
                    </Box>
                    <ul>
                      {userInfo?.rejectReasons?.map((i: any) => (
                        <li>{` علت ثبت شده :مربوط به فیلد ${i.title} با توضیحات :${i.description}`}</li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "center", pt: !acceptAuth ? 2 : "unset" }}>
                {userInfo && userInfo?.accountVerified === "verified" ? (
                  <Button variant="contained" onClick={undoTypeIdentity} sx={{ bgcolor: "#F84E58" }}>
                    لغو حالت تایید اطلاعات
                  </Button>
                ) : userInfo?.accountVerified === "inProgress" ? (
                  <Button variant="contained" onClick={() => setOpenVerification(true)}>
                    ادامه وبروز رسانی
                  </Button>
                ) : userInfo?.accountVerified === "rejected" ? (
                  <Button variant="contained" onClick={undoTypeIdentity}>
                    لغو حالت عدم تایید
                  </Button>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </PageContainer>
  );
}
