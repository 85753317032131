import { ReactNode } from "react";
import { Button, SxProps, Theme, Typography } from "@mui/material";
import { To, useNavigate } from "react-router-dom";

import right from "assets/Arrow_Left_SM.svg";

export default function BackButton({
  sx,
  to = -1,
  children,
}: {
  sx?: SxProps<Theme>;
  to?: string | -1;
  children?: ReactNode;
}) {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(to as To)} startIcon={<img src={right} alt="back" />} sx={sx}>
      <Typography color="#333337" sx={{ opacity: "70%", mb: 0.3 }}>
        {children || "بازگشت"}
      </Typography>
    </Button>
  );
}
