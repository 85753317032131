import { useState } from "react";
import { ICONS } from "assets/icons";
import Img from "components/img/Img";
import { ROUTES } from "constants/routes";
import { useTranslation } from "react-i18next";
import { NAVIGATIONS } from "constants/navigation";
import { Link, useLocation } from "react-router-dom";
import { List, ListItemButton, IconButton, Box, Typography, Button, Collapse, CircularProgress } from "@mui/material";
import useSWR from "swr";
import { IRoleType, IUserType } from "api/user";
import { useStyles } from "components/share/useStyle";

const Menu = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { data: roleData } = useSWR<{ roles: IRoleType[] }>(`/roles`);
  const userRoleId = roleData?.roles.find((i) => i.name === "user")?._id;
  const publisherRoleId = roleData?.roles.find((i) => i.name === "publisher")?._id;
  const { data } = useSWR<{ users: IUserType[]; count: number }>(userRoleId ? `/users?roles=${userRoleId}` : "");
  const { data: publishers } = useSWR<{ users: IUserType[]; count: number }>(`/users?roles=${publisherRoleId}`);
  const { data: games } = useSWR("/games?sortby=created_at");
  const { data: tickets } = useSWR("/tickets");
  const { data: leagues } = useSWR("/leagues");
  let userscount = data && (data?.count / 1000).toFixed(1);
  let publisherscount = publishers && (publishers?.count / 1000).toFixed(1);
  let gamescount = (games?.count / 1000).toFixed(1);
  let ticketscount = (tickets?.count / 1000).toFixed(1);
  let leaguescount = (leagues?.leagues.length / 1000).toFixed(1);
  const [select, setSelect] = useState<string>("");
  const [selectSubMenu, setSelectSubMenu] = useState<any>({ SYSTEMSETTIN: false });
  const isCurrentRoute = (route: string) => pathname.includes(route);

  const fakeData: { [key: string]: string } = {
    users:
      data?.count && userscount ? `${Number(data?.count) < 1000 ? data?.count : `${userscount?.toString()}K`}` : "0",
    developers: publishers?.count && publisherscount ?`${Number(publishers?.count) < 1000 ? publishers?.count : `${publisherscount?.toString()}K`}` :"0",
    games:games?.count && gamescount ? `${Number(games?.count) < 1000 ? games?.count : `${gamescount.toString()}K`}`: "0",
    tickets:tickets?.count && ticketscount ? `${Number(tickets?.count) < 1000 ? tickets?.count : `${ticketscount.toString()}K`}` : "0",
    leagues:leagues?.leagues && leaguescount ?
      `${Number(leagues?.leagues.length) < 1000 ? leagues?.leagues.length : `${leaguescount.toString()}K`}` : "0",
  };

  const openSubMenu = (item: string) => () => {
    setSelect(item === select ? "" : item);
  };
  const classes = useStyles();
  return (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        padding: 0,
        overflowY: "scroll",
      }}
      className={classes.root}
    >
      {NAVIGATIONS.map(({ key, subMenu, menu }) => (
        <ListItemButton
          key={key.toLowerCase()}
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0",
            width: "100%",
            paddingRight: "1rem",
            ":hover": {
              backgroundColor: "transparent",
            },
          }}
          component={subMenu ? Box : Link}
          to={!subMenu && ROUTES[key]}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "4.2rem",
              padding: 0,
              borderRadius: "0rem 2rem 2rem 0rem",
              bgcolor:
                isCurrentRoute(ROUTES[key]) || key === select ? `${theme.palette.primary.main}40` : "transparent",
              ":hover": {
                bgcolor: `${theme.palette.primary.main}1A`,
              },
            })}
            onClick={(subMenu || menu) && openSubMenu(key)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {(subMenu || menu) && (
                <IconButton
                  sx={{
                    width: "2rem",
                    height: "2rem",
                    padding: 0,
                    marginLeft: "1rem",
                  }}
                  onClick={(subMenu || menu) && openSubMenu(key)}
                >
                  <Img
                    alt={""}
                    src={key === select ? ICONS.MENU_OPEN : ICONS.MENU_CLOSE}
                    sx={{ width: "2rem", height: "2rem" }}
                  />
                </IconButton>
              )}

              <Img
                alt={key.toLowerCase()}
                src={ICONS[key]}
                sx={{ width: "2rem", height: "2rem", marginLeft: subMenu || menu ? "0.5rem" : "3.5rem" }}
              />
              <Typography sx={{ fontSize: "1.3rem", marginLeft: "1rem" }}>
                {t(`_navigation.${key.toLowerCase()}`)}
              </Typography>
            </Box>

            {fakeData[key.toLowerCase()] ? (
              <>
                <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, marginRight: "1rem" }}>
                  {fakeData[key.toLowerCase()]}
                </Typography>
              </>
            ) : (
              <CircularProgress size={"small"} />
            )}
          </Box>
          <Collapse in={Boolean(key === select && menu)} timeout="auto" unmountOnExit>
            <List
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                padding: 0,
                width: "100%",
              })}
            >
              {menu &&
                menu.map(({ key }) => (
                  <ListItemButton
                    sx={(theme) => ({
                      display: "flex",
                    })}
                    key={key.toLowerCase()}
                    component={subMenu ? Box : Link}
                    to={!subMenu && ROUTES[key]}
                  >
                    <Img alt={key.toLowerCase()} src={ICONS[key]} sx={{ width: "2rem", height: "2rem" }} />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        px: 1,
                        color: isCurrentRoute(ROUTES[key]) || key === select ? "#4A76FD" : "unset",
                      }}
                    >
                      {t(`_navigation.${key.toLowerCase()}`)}
                    </Typography>
                  </ListItemButton>
                ))}
            </List>
          </Collapse>
          {subMenu && key === select && (
            <List
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                padding: 0,
                width: "100%",

                //backgroundColor: `${theme.palette.primary.main}1A`,
              })}
            >
              {subMenu.map(({ key, subMenu }) => (
                <>
                  <Box
                    sx={{ display: "flex" }}
                    onClick={() => setSelectSubMenu({ ...selectSubMenu, [key]: !selectSubMenu[key] })}
                  >
                    <Button
                      startIcon={
                        <Img
                          alt={""}
                          src={selectSubMenu[key] ? ICONS.MENU_OPEN : ICONS.MENU_CLOSE}
                          sx={(theme: any) => ({
                            width: "2rem",
                            height: "2rem",
                            borderRadius: "0rem 2rem 2rem 0rem",
                            backgroundColor:
                              isCurrentRoute(ROUTES[key]) || key === select
                                ? `${theme.palette.primary.main}40`
                                : "transparent",

                            ":hover": {
                              backgroundColor: `${theme.palette.primary.main}1A`,
                            },
                          })}
                        />
                      }
                      variant="text"
                      sx={{ ml: 4, fontSize: "12px", boxShadow: 0, my: 1 }}
                    >
                      {t(`${key.toLowerCase()}`)}
                    </Button>
                  </Box>
                  {selectSubMenu[key] &&
                    subMenu.map(({ key }) => (
                      <>
                        <ListItemButton
                          key={key.toLowerCase()}
                          sx={{
                            alignItems: "center",
                            width: "100%",
                            padding: 0,
                            ":hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          component={Link}
                          to={ROUTES[key]}
                        >
                          <Box
                            sx={(theme) => ({
                              width: "100%",
                              display: "flex",
                              height: "3.5rem",
                              alignItems: "center",
                              gap: "1rem",
                              backgroundColor:
                                isCurrentRoute(ROUTES[key]) || key === select
                                  ? `${theme.palette.primary.main}40`
                                  : "transparent",

                              ":hover": {
                                backgroundColor: `${theme.palette.primary.main}1A`,
                              },
                              borderRadius: "0rem 2rem 2rem 0rem",
                            })}
                          >
                            <Img
                              alt={key.toLowerCase()}
                              src={ICONS[key]}
                              sx={{ width: "2rem", height: "2rem", marginLeft: "3.5rem" }}
                            />
                            <Typography sx={{ fontSize: "12px" }}>{t(`_navigation.${key.toLowerCase()}`)}</Typography>
                          </Box>
                        </ListItemButton>
                      </>
                    ))}
                </>
              ))}
            </List>
          )}
        </ListItemButton>
      ))}
    </List>
  );
};

export default Menu;
