import { post } from "api";

export type companyPeopleType = {
  personName: string;
  personRole: string;
  personEmail: string;
  personCallNumber: string;
  _id: string;
};

export function addMemberOfCompany(
  companyId: string,
  data: { personName: string; personRole: string; personEmail: string; personCallNumber: string }
) {
  return post(`/ads/companies/${companyId}/person`, data);
}
