import { Avatar, Box, Grid, Typography } from "@mui/material";
import { IGameType } from "api/game";
import moment from "jalali-moment";
import { useNavigate } from "react-router-dom";
import { formatJallali } from "utils/date";

export default function IdentityDetails({ game }: { game: IGameType }) {
  const navigate = useNavigate();
  console.log("game", game?.versions[0]?.publisher?._id);
  const item = [
    {
      title: "توسعه دهنده ",
      content: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/users/publishers/user/${game?.versions[0]?.publisher?._id}`)}
        >
          <Avatar
            sx={{ border: "1px solid grey" }}
            src={game?.publisher?.profilePicture?.url || game?.publisher?.coverImage?.url}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            {game?.publisher?.username && <Typography>{game?.publisher?.username}</Typography>}
            {game?.publisher?.fullname && <Typography>{game?.publisher?.fullname}</Typography>}
          </Box>
        </Box>
      ),
    },
    { title: "وضعیت", content: "در حال بررسی" },
    { title: "تاریخ آپلود بازی", content: formatJallali(game?.createdAt) },

    {
      title: "آخرین به روز رسانی",
      content: formatJallali(game?.updatedAt),
    },
  ];
  return (
    <>
      {item.map((i: any) => (
        <Grid container rowSpacing={3} key={i.title}>
          <Grid item xs={6} sx={{ py: 1 }}>
            <Typography sx={{ color: "#676872" }}>{i.title}</Typography>
          </Grid>
          <Grid item xs={6} sx={{ py: 1 }}>
            <Box>{i.content}</Box>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
