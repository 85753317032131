import useSWR from "swr";
import { GAME_STATUS } from "types/game";
import { SWR_KEYS } from "constants/swrKeys";
import { SyntheticEvent, useEffect, useState } from "react";

const useGames = (initialValue: GAME_STATUS) => {
  const [tabValue, setTabValue] = useState<GAME_STATUS>(GAME_STATUS.PEND);
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const { data } = useSWR(SWR_KEYS.GAMES(tabValue, searchValue, page));
 
  useEffect(() => {
    setTabValue(initialValue);
  }, [initialValue]);
  const handleChange = (e: any, value: number) => {
    setPage(value);
  };
  const onChangeTab = (event: SyntheticEvent, value: GAME_STATUS) => {
    setSearchValue("");

    setTabValue(value);
  };
  useEffect(() => {
    data &&  data?.gameVersions && setCount(data?.count);
   
  }, [data]);
  const onSearch = (event: any) => {
    const { value } = event.target;

    setSearchValue(value);
  };
useEffect(()=>{
setPage(1)
},[tabValue])
  return {
    page,
    handleChange,
    tabValue,
    onChangeTab,
    searchValue,
    onSearch,
    games: data?.gameVersions || [],
    count: count,
  };
};

export default useGames;
