import { ReactNode } from "react";
import { Box } from "@mui/material";

import PageAnimation from "./PageAnimation";

export default function PageContainer({ children,noPadding }: { children: ReactNode,noPadding ?:boolean}) {
  return (
    <Box
      component="main"
      sx={{
      pl:noPadding?"unset":5,
      pr:noPadding?"unset":13,
      width:"100%"
      }}
    >
        <PageAnimation>{children}</PageAnimation>
    </Box>
  );
}
