export const tokenKey = "token_key";
export const refreshTokenKey = "refreshToken";

// todo@sareh: edit these utils

export function getToken() {
  const token = localStorage.getItem(tokenKey);
  return token;
}

export function getRefreshToken() {
  const token = localStorage.getItem(refreshTokenKey);
  return token;
}

export function setToken(token: string) {
  localStorage.setItem(tokenKey, token);
}

export function setRefreshToken(token: string) {
  localStorage.setItem(refreshTokenKey, token);
}

export function removeToken() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(refreshTokenKey);
}

