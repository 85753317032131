import { Box, Typography, LinearProgress, IconButton, useMediaQuery } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import fileBlank from "assets/File_Blank.svg";

export default function UploadListItem({
  error,
  fileName,
  loading,
  onRemove,
}: {
  fileName?: string;
  loading?: boolean;
  error?: boolean;
  onRemove?: () => void;
}) {
  
  const phone = useMediaQuery("(max-width:590px)");
  return (
    <Box
     my={1}
      p={phone ? "unset" : 1}
      borderRadius={1}
      alignItems="center"
      sx={{
        bgcolor:"white",
       borderRadius:"8px",
        justifyContent: "space-between",
        display: "flex",
       width:"400px",direction:"rtl"
      }}
    >
      {loading ? (
        <Box display="flex">
          <IconButton color="error" onClick={() => onRemove && onRemove()}>
            <CloseRounded />
          </IconButton>
          <LinearProgress
            color="primary"
            sx={{ height: "10px", width: "150px", borderRadius: "3px", mt: 1.75 }}
          />
        </Box>
      ) : (
        <>
          <Box display="flex">
            <IconButton color="error" onClick={() => onRemove && onRemove()}>
              <CloseRounded />
            </IconButton>
            <Typography
              sx={{
                color: error ? "error.main" : "#69BB2B",
                fontSize: "12px",
                pt: 0.75,
              }}
            >
              {error ? "خطا" : "تکمیل شده"}
            </Typography>
          </Box>
        </>
      )}
      <Box display="flex">
        <Typography sx={{ pt: 1, fontSize: phone ? "12px" : "unset" }}>{fileName}</Typography>
        <img src={fileBlank} alt="fileblank" />
      </Box>
    </Box>
  );
}
