import { Box, Divider, Grid, Typography } from "@mui/material";
import { useState } from "react";
import inner from "assets/innerBanner.svg";
import left from "assets/leftBanner.svg";
import bottom from "assets/bannerBottom.svg";
import Form from "pages/game/components/advertisementForms/form";
import PageContainer from "components/PageContainer";

export default function Advertisement() {
  const [bannerTypeNumber, setBannerTypeNumber] = useState(0);
  const bannersType = [
    { title: "بنر سمت چپ ۳۳۵*۳۶۸", id: "left" },
    { title: "بنر پایین ۱۰۰*۱۰۳۷", id: "bottom" },
    { title: "بنر داخل بازی ۵۴۰*۱۰۳۷", id: "inner" },
  ];
  function clickOnMe(index: number) {
    return Boolean(bannerTypeNumber === index);
  }
  return (
    <PageContainer noPadding={true}>
      {["سرویس تبلیغاتی گوگل:", "سرویس تبلیغاتی یکتانت:"].map((i: any, idx) => {
        return (
          <Grid container spacing={2} key={idx}>
            <Grid item xs={12}>
              <h4>{i}</h4>
            </Grid>
            {bannersType.map((bannerType: any, index: number) => {
              return (
                <Grid item xs={3.9} key={index}>
                  <Box sx={{ display: "flex" }}>
                    {index === 0 ? (
                      <img src={left} alt="" />
                    ) : index === 1 ? (
                      <img src={bottom} alt="" />
                    ) : (
                        index === 2 && <img src={inner} alt="" />
                    )}
                    <p style={{ fontSize: "12px", fontWeight: "bolder", padding: "0 10px" }}>{bannerType.title}</p>
                  </Box>

                  <Form />
                </Grid>
              );
            })}
            {idx===0 && <Divider/>}
          </Grid>
        );
      })}
    </PageContainer>
  );
}
