import Menu from "./Menu";
import { useAppDispatch } from "store";
import { LAYOUT } from "constants/styles";
import { useTranslation } from "react-i18next";
import { logout } from "reducers/adminReducers";
import {  Drawer, Theme, useMediaQuery } from "@mui/material";
import { useStyles } from "components/share/useStyle";

interface SidebarProps {
  openDrawer: boolean;
  toggleOpenDrawer: () => void;
}

const Sidebar = ({ openDrawer, toggleOpenDrawer }: SidebarProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));



  return (
    <Drawer
      variant={downMd ? "temporary" : "permanent"}
      open={downMd ? openDrawer : true}
      onClose={downMd ? toggleOpenDrawer : undefined}
     
      sx={{
        zIndex: 2,
        position: "relative",
        display: downMd ? "none" : "block",
        overflowY:"scroll",
        "& .MuiDrawer-paper": {
          width: LAYOUT.SIDEBAR_WIDTH,
          paddingTop: LAYOUT.HEADER_HEIGHT,
          borderRadius: 0,
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          paddingBottom: "4rem",
          border: "none",
        },
      }}
      ModalProps={{
        keepMounted: true,
        
      }}
    >
      <Menu />
    </Drawer>
  );
};

export default Sidebar;
