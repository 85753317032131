import { ICONS } from "assets/icons";
import Img from "components/img/Img";
import { TextField } from "components";
import { Link } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { LAYOUT } from "constants/styles";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { logout } from "reducers/adminReducers";

interface HeaderProps {
  toggleOpenDrawer: () => void;
}

const Header = ({ toggleOpenDrawer }: HeaderProps) => {
  const me = useAppSelector((s) => s.admin.admin?.me);

  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const userName = me && (me?.username || me?.fullname || me?.email); // todo@niloo" review
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  console.log("me", me);
  return (
    <>
      <Grid
        container
        xs={15}
        sx={{
          width: "100%",
          height: LAYOUT.HEADER_HEIGHT,
          backgroundColor: "#F5F5F9",
          display: "flex",
          alignItems: "center",
          px: "4rem",
          zIndex: 3,
          position: "relative",
        }}
      >
        {downMd && (
          <IconButton onClick={toggleOpenDrawer}>
            <Img
              src={ICONS.MENU}
              alt="menu"
              sx={{
                width: "2rem",
                height: "2rem",
              }}
            />
          </IconButton>
        )}
        <Grid item xs={2.5}>
          <Link
            to={ROUTES.HOME}
            style={{
              height: "3.4rem",
              width: `calc(${LAYOUT.SIDEBAR_WIDTH} - 4rem)`, // header padding
              backgroundColor: "#F5F5F9",
            }}
          >
            <Img
              src={ICONS.LOGO}
              alt="logo"
              sx={{
                width: "9.4rem",
                height: "3.4rem",
              }}
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={9}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: downMd ? "end" : "space-between",
          }}
        >
          {!downMd && (
            <TextField
              InputProps={{
                startAdornment: (
                  <Img
                    src={ICONS.SEARCH}
                    alt="search"
                    sx={{
                      width: "2rem",
                      height: "2rem",
                    }}
                  />
                ),
              }}
              endAdornment={
                <Img
                  src={ICONS.FILTER}
                  alt="filter"
                  sx={{
                    width: "2rem",
                    height: "2rem",
                  }}
                />
              }
              sx={{
                width: "50rem",
                "& .MuiOutlinedInput-root": {
                  height: "3.7rem",
                  minWidth: "40rem",
                  paddingLeft: "1rem",
                  backgroundColor: "background.lighter",
                  borderRadius: "4.7rem",

                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",

                    ":hover": {
                      borderColor: "transparent",
                    },

                    ":focus": {
                      borderColor: "transparent",
                    },
                  },
                },
              }}
            />
          )}

          <Grid
            item
            xs={3}
            onClick={handleOpen}
            sx={{
              ml: 10,
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              cursor: "pointer",

              justifyContent: "end",
            }}
          >
            <Typography>{userName}</Typography>
            <Avatar
              alt={userName}
              src={me?.coverImage?.url || ICONS.AVATAR}
              sx={{
                width: "3.4rem",
                height: "3.4rem",
                cursor: "pointer",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Popover open={open} onClose={() => setOpen(false)}>
        <Box sx={{ p: 3, cursor: "pointer" }} onClick={() => dispatch(logout())}>
          <Button variant="contained" sx={{ bgcolor: "red", width: "15rem" }}>
            خروج
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Header;
