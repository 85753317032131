import { Box, Typography } from "@mui/material";
import { totalType } from "api/asStats";

export default function GeneralStat({ totlaData }: { totlaData?: totalType }) {
  const info = [
    { title: "تعداد کل نمایش تبلیغ", value: totlaData?.view },
    { title: "تعداد کل کلیک بر روی تبلیغ", value: totlaData?.click },
    { title: "درآمد کل تبلیغ", value: totlaData?.income },
  ];

  return (
    <Box
      sx={{
        justifyContent: "center",
        mt: 4,
        mr: 8,
        ml: 8,
        mb: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "#F5F5F9",
        borderRadius: "16px",
        width: "90%",
      }}
    >
      <Box sx={{ height: "136px", width: "100%", display: "flex", alignContent: "center" }}>
        {info.map((i: any, index: number) => {
          return (
            <Box
              key={index}
              sx={{
                p: 2,
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                borderRight: index !== info.length - 1 ? "1px solid #4A76FD" : "unset",
                my: 5,
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>{i.title}</Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600, pt: 1 }}>{i.value}</Typography>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{ height: "18px", bgcolor: "#676872", width: "100%", alignContent: "center" }}
        style={{ borderBottomRightRadius: "16px", borderBottomLeftRadius: "16px" }}
      ></Box>
    </Box>
  );
}
