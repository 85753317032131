import { MouseEvent, useEffect, useState } from "react";
import useSWR from "swr";
import { updateUser } from "api/user";
import generateQuery from "utils/generateQuery";
import { toast } from "react-toastify";
import { ITicketType } from "api/ticket";

const useUsers = ({ pageSize }: { pageSize: number }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [total, setTotal] = useState<number>(4);
  const [role, setRole] = useState<"publisher" | "user" | null>();
  const [filter, setFilter] = useState<{
    active?: "true" | "false";
    ticketStatus?: "new" | "open";
  }>();

  const { query } = generateQuery({
    role: role,
    start: (pageNumber - 1) * pageSize,
    limit: pageSize,

  });

  const { data, mutate } = useSWR<{ tickets: ITicketType[] }>(`/tickets?${query}`);

  const openPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };


  const onPageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const onSelectionModelChange = (ids: string[]) => {
    setSelectedItems(ids);
    //console.log("SelectedItems", ids);
  };

  const onUserActionClick = async (action: boolean) => {
    if (selectedItems.length === 1) {
      try {
        await updateUser({ active: action }, selectedItems[selectedItems.length - 1]);
        action === true
          ? toast.success("کاربر به وضعیت فعال تغییر یافت")
          : toast.success("کاربر به وضعیت غیر فعال تغییر یافت");
      } catch (error) {
        toast.error("error");
      } finally {
        mutate();
        setSelectedItems([]);
      }
    } else if (selectedItems.length > 1) {
      selectedItems.map(async (i: string) => {
        try {
          await updateUser({ active: action }, i);
        } catch (error) {
          toast.error("با خطایی مواجه شدید");
        } finally {
          mutate();
          setSelectedItems([]);
        }
      });
    }
  };

  const onUserFilterClick = (value: "user" | "publisher") => {
    setRole(value);

    closePopover();
  };

  const onRemoveSelectedItemsClick = () => {
    setSelectedItems([]);
  };

  return {
    anchorEl,
    openPopover,
    closePopover,
    selectedItems,
    setSelectedItems,
    onUserActionClick,
    onUserFilterClick,
    onSelectionModelChange,
    tickets: data?.tickets || [],
    total,
    onRemoveSelectedItemsClick,
    onPageChange,
  };
};

export default useUsers;
