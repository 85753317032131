import { Box, Typography, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { ReportType, blockeUser } from "api/chat";
import { toast } from "react-toastify";
import Report from "./report";

export default function InnerLeagueUsersReport() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { id } = useParams();
  const { data: reportedChat } = useSWR<{ reports: ReportType[] }>(`/chat/league/${id}/reports`);
  console.log(reportedChat, "reportedchat");
  const handleBlockUser = async (e: any, index: number) => {
    let hour: number = 0;
    switch (index + 1) {
      case 1:
        hour = 1;
        break;
      case 2:
        hour = 2;
        break;
      case 3:
        hour = 4;
        break;
      case 4:
        hour = 8;
        break;
      case 5:
        hour = 24;
        break;
      case 6:
        hour = 72;
        break;
      case 7:
        hour = 1000;
    }
    const currentTime = new Date().getTime();
    const targetTime = currentTime + hour * 3600000;
    const fomatedTargettime = new Date(targetTime);
    try {
      if (reportedChat) {
        await blockeUser(reportedChat?.reports[index]?.chatMessage?.sender?._id, {
          chatId: reportedChat?.reports[index].chatMessage._id,
          blockUntil: fomatedTargettime.toString(),
        });
      }
      toast.success("کاربر مسدود شد");
    } catch (err) {
      toast.error("خطا");
    }
    setAnchorEl(null);
  };
  return (
    <Box sx={{ px: 2 }}>
      {reportedChat?.reports?.map((i: ReportType, index: number) => {
        return (
          <Box key={index}>
            <Report report={i} onSetAnchorE={(e: HTMLElement) => setAnchorEl(e)} />
          </Box>
        );
      })}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {[
          "مسدود برای ۱ ساعت",
          "مسدود برای ۲ ساعت",
          "مسدود برای ۴ ساعت",
          "مسدود برای ۸ ساعت",
          "مسدود برای ۱ روز",
          "مسدود برای ۳ روز",
          "همیشه (تا اقدام مجدد)",
        ].map((option: any, index: number) => (
          <MenuItem onClick={(e) => handleBlockUser(e, index)} sx={{ display: "flex", justifyContent: "start" }}>
            <Typography sx={{ fontSize: "12px " }} key={index}>
              {" "}
              {option}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
