import * as React from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Clear";
import { addBannerAd, addVideoAd } from "api/ads";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  Stack,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { mutate } from "swr";

export type valueType = {
  name: string;
  weight: number | null;
  provider: "yektanet" | "googleAds" | "tapcell";
  type: "banner" | "video" | "native";
  location: "site_left" | " site_bottom" | "game_inner";
  id: string;
  adLink: string;
  code: string;
  active: boolean;
  isResponsive: boolean;
};
export default function NewAddSlider({ onClose, open }: { onClose: () => void; open: boolean }) {
  const [tikedValue, setTikedValue] = React.useState<"banner" | "video" | "native">("video");

  const { values, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      weight: null,
      provider: "yektanet",
      type: "banner",
      location: "game_inner",
      id: "",
      adLink: "",
      code: "",
      active: true,
      isResponsive: true,
    } as valueType,

    onSubmit: async (values) => {
      try {
        values.type === "banner" || values.type === "native"
          ? await addBannerAd({
              uniqueIdentifier: values.id,
              provider: values.provider,
              code: values.code,
              location: values.location,
              type: values.type,
              active: values.active,
              isResponsive: values.isResponsive,
              adsLink: values.adLink,
              width: 100,
              height: 100,
            })
          : await addVideoAd({
              uniqueIdentifier: values.id,
              provider: values.provider,
              code: values.code,
              active: values.active,
              adsLink: values.adLink,
              location: values.location,
            });
        toast.success("done");
        onClose();
        mutate("/ads");
      } catch (error) {
        toast.error("somthing went wrong");
      }
    },
  });

  const handleChangeRadioValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTikedValue((event.target as HTMLInputElement).value as any);

    setFieldValue("type", event.target.value);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3, width: "100%" }}>
            <Box sx={{ mb: 3 }}>
              <Tooltip title="Delete">
                <IconButton>
                  <DeleteIcon sx={{ fontSize: 20 }} onClick={onClose} style={{ cursor: "inital" }} />
                </IconButton>
              </Tooltip>
              <Typography
                id="new-ad-title"
                sx={{ fontWeight: "bold", fontSize: 20, color: "disabled", display: "inline", pl: "30%" }}
              >
                {"افزودن تبلیغ جدید"}
              </Typography>
            </Box>

            <Box sx={{ m: 1.5, width: "100%" }}>
              <Typography sx={{ m: 0.5 }}>اولویت تبلیغ</Typography>
              <TextField
                required
                id="add-priority-required"
                placeholder="عدد را وارد کنید"
                size="small"
                onChange={handleChange}
                value={values.weight}
                name="priority"
              />
            </Box>
            <Box sx={{ m: 1.5, width: "350px" }}>
              <Typography sx={{ m: 0.5 }}>شرکت تبلیغاتی</Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="انتخاب کنید"
                  onChange={handleChange}
                  value={values.provider}
                  name="provider"
                >
                  <MenuItem value="yektanet">یکتانت</MenuItem>
                  <MenuItem value="googleAds">تبلیغات گوگل</MenuItem>
                  <MenuItem value="tapcell">تپسل</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ m: 1.5, width: "350px" }}>
              <Typography sx={{ m: 0.5 }}>نوع تبلیغ</Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="type"
                  value={tikedValue}
                  onChange={handleChangeRadioValue}
                >
                  <FormControlLabel value="banner" control={<Radio />} label="بنر" />
                  <FormControlLabel value="video" control={<Radio />} label="ویدئو" />
                  <FormControlLabel value="native" control={<Radio />} label="همسان" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ m: 1.5, width: "350px" }}>
              <Typography sx={{ m: 0.5 }}>جایگاه تبلیغ</Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleChange}
                  value={values.location}
                  name="location"
                  disabled={tikedValue === "video"}
                >
                  <MenuItem value="site_left">سمت چپ صفحه</MenuItem>
                  <MenuItem value="site_bottom">سمت پایین صفحه</MenuItem>
                  <MenuItem value="game_inner">داخل بازی</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ m: 1.5, width: "350px" }}>
              <Typography sx={{ m: 0.5 }}>شناسه یکتا</Typography>
              <TextField
                sx={{ fontSize: 10 }}
                required
                id="add-priority-required"
                placeholder="وارد کنید"
                fullWidth
                onChange={handleChange}
                value={values.id}
                name="id"
              />
            </Box>
            <Box sx={{ m: 1.5, width: "350px" }}>
              <Typography sx={{ m: 0.5 }}>لینک تبلیغ</Typography>
              <TextField
                sx={{ fontSize: 10 }}
                required
                id="add-priority-required"
                placeholder="وارد کنید"
                fullWidth
                onChange={handleChange}
                value={values.adLink}
                name="adLink"
              />
            </Box>
            <Box sx={{ m: 1.5, width: "100%" }}>
              <Typography sx={{ m: 0.5 }}>کد تبلیغ</Typography>
              <textarea
                style={{ borderRadius: "8px", width: "100%", borderColor: "#808080" }}
                rows={5}
                required
                id="add-priority-required"
                placeholder="وارد کنید"
                onChange={handleChange}
                value={values.code}
                name="code"
              />
            </Box>
            <Stack spacing={2} direction="row">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ align: "center", mt: 3, fontSize: 15, ml: "5%" }}
              >
                تایید و افزودن
              </Button>
            </Stack>
          </Box>
        </form>
      </Dialog>
    </>
  );
}
