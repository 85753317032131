import { ICONS } from "assets/icons";
import Img from "components/img/Img";
import { Box, IconButton, Typography } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const Preview = ({ flow, file: { name, size, url }, previewUrl, type, loading, onRemove, direction, image }: any) => {
  const vertical = direction === "vertical";
  const horizontal = direction === "horizontal";
  const Square = direction === "Square";
  const isGif = type === "gif";
  const flew: boolean = Boolean(flow === "authEdit");
  switch (type) {
    case "image":
      return (
        <Box sx={{ position: "relative" }}>
          <IconButton sx={{ position: "absolute", bottom: "5%", left: "5%", color: "white", bgcolor: "#3f51b5" }}>
            <DeleteForeverOutlinedIcon onClick={onRemove} fontSize="large" />
          </IconButton>
          <Img
            src={previewUrl}
            alt="knk"
            sx={{
              width: vertical ? "150px" : horizontal ? "270px" : "170px",
              height: vertical ? "250px" : horizontal ? "170px" : "170px",
              objectfit: "cover",
              borderRadius: "8px",
            }}
          />
          {/* <Button sx={{ bgcolor: "E1E2E6", position: "absolute", left: 50 }} endIcon={<ModeOutlinedIcon />}>
            آپلود
          </Button> */}
        </Box>
      );
    case "gif":
      return (
        <Box sx={{ position: "relative" }}>
          <IconButton sx={{ position: "absolute", color: "white", bgcolor: "#3f51b5", bottom: "5%", left: "5%" }}>
            <DeleteForeverOutlinedIcon onClick={onRemove} fontSize="large" />
          </IconButton>
          <Img
            src={previewUrl || image}
            alt=""
            sx={{
              width: flew ? "" : "280px",
              height: flew ? "" : "270px",
              objectfit: "cover",
              borderRadius: "8px",
            }}
          />
          {/* <Button sx={{ bgcolor: "E1E2E6", position: "absolute", left: 100 }} endIcon={<ModeOutlinedIcon />}>
            آپلود
          </Button> */}
        </Box>
      );

    default:
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", margin: "1rem 0 0" }}>
          <Typography
            sx={{ backgroundColor: "gray.light", padding: "0.5rem 1.5rem", borderRadius: "0.5rem" }}
          >{`${name} - ${size}`}</Typography>
          <Img
            src={ICONS.TRASH}
            alt="delete"
            sx={{
              width: "2rem",
              height: "2rem",
              cursor: "pointer",
            }}
          />
        </Box>
      );
  }
};

export default Preview;
