import { useState } from "react";
import { Box, Button, Dialog, CircularProgress, Typography, TextField, FormHelperText } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { upload } from "api/file";
import { createCategory } from "api/gameCategory";
import Uploader from "shared/Uploader";

const schema = Yup.object({
  title: Yup.string().required(),
  image: Yup.string().required(),
});

export default function AddCategory({
  open,
  onClose,
  onMutate,
}: {
  open: boolean;
  onClose?: () => void;
  onMutate: () => void;
}) {
  const { handleSubmit, setFieldValue, isSubmitting, setSubmitting, isValid, touched, errors, getFieldProps, values } =
    useFormik({
      validationSchema: schema,
      enableReinitialize: true,
      initialValues: {
        title: "",
        image: "",
        description: "",
        gif: "",
        darkImage: "",
        darkGif: "",
        parents: "646a11c4033e9a5caff40b50",
      },

      onSubmit: async (data) => {
        try {
          setSubmitting(true);
          await createCategory(data);
          toast.success("با موفقیت بارگزاری شد");
          onMutate();
        } catch (error) {
          toast.error(String(error));
        } finally {
          setSubmitting(false);
          onClose && onClose();
        }
      },
    });

  const [loading, setLoading] = useState<any>({
    imageLoading: false,
    gifLoading: false,
    darkImageLoading: false,
    darkGifLoading: false,
  });
  const [previewUrl, setPreviewUrl] = useState<any>();

  const handleUpload = async (f: File[], type: "image" | "gif" | "game" | "darkImage" | "darkGif") => {
    try {
      setLoading({ ...loading, [`${type}Loading`]: true });
      if (f[0]) {
        const resp = await upload({ contentType: "image", file: f[0] });
        setFieldValue(type, resp.fileId);
        const imageUrl = URL.createObjectURL(f[0]);
        setPreviewUrl({ ...previewUrl, [type]: imageUrl });
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading({ ...loading, [`${type}Loading`]: false });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose && onClose();
        setPreviewUrl(null);
      }}
    >
      <Box dir="rtl" py={1} px={2} sx={{ width: "400px" }}>
        <CloseRounded sx={{ cursor: "pointer" }} onClick={onClose} />
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <Typography my={1}>نام دسته بندی</Typography>
          <TextField
            type="text"
            error={Boolean(touched.title && errors.title)}
            {...getFieldProps("title")}
            fullWidth
            sx={{ my: 1, background: "#F5F5F9" }}
            size="small"
          />
          {errors.title && <FormHelperText>نام دسته بندی اجباری است</FormHelperText>}
          {/* <Typography my={1}>توضیحات </Typography>
          <TextField
            {...getFieldProps("description")}
            type="text"
            error={Boolean(touched.title && errors.title)}
            fullWidth
            sx={{ my: 1, background: "#F5F5F9" }}
            size="small"
            multiline
          /> */}

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {["image", "gif"].map((i: any) => {
              return (
                <Uploader
                  type={i}
                  previewUrl={previewUrl?.[i]}
                  loading={loading?.[`${i}Loading`]}
                  onDrop={handleUpload}
                />
              );
            })}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {["darkImage", "darkGif"].map((i: any) => {
              return (
                <Uploader
                  type={i}
                  previewUrl={previewUrl?.[i]}
                  loading={loading?.[`${i}Loading`]}
                  onDrop={handleUpload}
                />
              );
            })}
          </Box>
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <Button fullWidth variant="contained" type="submit" sx={{ mb: 1 }} disabled={isSubmitting || !isValid}>
              تایید
            </Button>
          )}
        </form>
      </Box>
    </Dialog>
  );
}
