import { useEffect, useMemo, useState } from "react";
import { Img, Table } from "components";

import { Button, Box, Typography, Grid, Checkbox, IconButton, Divider, Pagination } from "@mui/material";
import useSWR from "swr";
import PageContainer from "components/PageContainer";
import DeleteButton from "shared/Delete/Button";
import { ICONS } from "assets/icons";
import { useTranslation } from "react-i18next";
import { ITicketCategoryType } from "api/ticket";
import AddTags from "./AddTicketCategories";

export default function TicketsCategories() {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { data: ticketCategory, mutate } = useSWR<{ categories: ITicketCategoryType[]; count: number }>(
    `tickets/categories?start=${(page - 1) * 10}&limit=10`
  );
  const { data: allTicketCategory } = useSWR<{ categories: ITicketCategoryType[]; count: number }>(
    `tickets/categories`
  );
  const [add, setAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [id, setId] = useState<any>("");
  const [deletItem, setDeletItem] = useState<any[]>();
  const [selectedItem, setSelectedItem] = useState<string[]>();
  const [counter, setCounter] = useState(1);
  useEffect(() => {
    allTicketCategory && setCounter(Math.ceil(allTicketCategory?.count / 10));
  }, [allTicketCategory]);

  const categoryId = ticketCategory?.categories?.map((i: ITicketCategoryType) => i._id) || [];

  const onSelectionModelChange = (ids: string[]) => {
    setSelectedItem(ids);
  };
  const handleEdit = (id: any) => {
    setOpenEdit(true);
    setId(id);
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  console.log(
    "rows",
    ticketCategory?.categories?.map((i: any) => ({ ...i, id: i?._id }))
  );
  const columns = useMemo(() => {
    const commonColumns = [
      {
        field: "relatedPanel",
        headerName: "مربوط به پنل",
        flex: 2,
        renderCell({ row }: any) {
          return (
            <Box sx={{ display: "flex" }}>
              {row?.userRole?.name === "publisher" && (
                <img width="20px" height="20px" src={ICONS.PUBLISHERROLE} alt="role" />
              )}
              <Typography
                sx={{ fontSize: "13px", px: row?.userRole?.name === "publisher" ? 2 : "unset", color: "#333337" }}
              >
                {row?.userRole?.name === "publisher" ? "توسعه دهنده" : "تیکت کاربر"}
              </Typography>
            </Box>
          );
        },
      },

      {
        field: "gameNumber",
        headerName: "تعداد بازی ها",
        flex: 5,
        renderCell({ row }: any) {
          return <Typography sx={{ fontSize: "13px", color: "#333337" }}>{row.title}</Typography>;
        },
      },
      {
        field: "edit",
        headerName: "ویرایش",
        flex: 1,
        renderCell({ row }: any) {
          return (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={() => handleEdit(row._id)}>
                <img width="25px" height="25px" alt="edit" src={ICONS.FILEEDIT} />
              </Button>
              <DeleteButton type="icon" url={[`/tickets/category/${row?._id}`]} onDone={() => mutate()} />
            </Box>
          );
        },
      },
    ];
    return commonColumns;
  }, [mutate]);

  return (
    <PageContainer>
      <AddTags open={add} onClose={() => setAdd(false)} onMutate={() => mutate()} />
      {/* <EditCategory
        data={gameCategory}
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        onMutate={() => mutate()}
        id={id}
      /> */}
      <Box dir="rtl" sx={{ mb: 10 }}>
        <Button
          sx={{ my: "30px", px: 2, color: "white" }}
          variant="contained"
          endIcon={<img src={ICONS.PLUS} alt="plus" />}
          onClick={() => setAdd(true)}
        >
          افزودن دسته بندی تیکت جدید
        </Button>

        <Grid
          sx={{
            backgroundColor: "white",
            borderRadius: "1.7rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ padding: "1rem " }}>
              <Checkbox
                checked={selectedItem?.length === 0 || !selectedItem ? false : true}
                onClick={() => (selectedItem?.length === 0 ? setSelectedItem(categoryId) : setSelectedItem([]))}
              />

              <IconButton>
                <Img
                  src={ICONS.ARROW_DOWN}
                  alt="add"
                  sx={{
                    width: "2rem",
                    height: "2rem",
                  }}
                />
              </IconButton>

              <IconButton
                disabled={selectedItem?.length === 1 ? false : true}
                onClick={() => selectedItem && handleEdit(selectedItem[0])}
              >
                <Img
                  src={ICONS.FILEEDIT}
                  alt="active"
                  sx={{
                    width: "2rem",
                    height: "2rem",
                  }}
                />
              </IconButton>

              <IconButton
                disabled={selectedItem?.length === 0 || !selectedItem ? true : false}
                onClick={() => {
                  selectedItem && setDeletItem(selectedItem.map((item) => `/tickets/category/${item}`));
                }}
              >
                <DeleteButton type="icon" url={deletItem} onDone={() => mutate()} />

                {/* <Img
                  src={ICONS.TRASHEMPTY}
                  alt="inactive"
                  sx={{
                    width: "2rem",
                    height: "2rem",
                  }}
                /> */}
              </IconButton>
            </Box>
            {selectedItem && selectedItem?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mx: 2,
                }}
              >
                <Typography>{`${selectedItem?.length} ${t("itemSelected")}`}</Typography>
              </Box>
            )}
          </Box>

          <Divider />
          <Table
            columns={columns}
            rows={ticketCategory?.categories?.map((i: any) => ({ ...i, id: i?._id })) || []}
            checkboxSelection
            onSelectionModelChange={onSelectionModelChange}
            selectionModel={selectedItem}
          />

          <Pagination
            sx={{ mx: 5, pb: 10 }}
            count={counter}
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Grid>
      </Box>
    </PageContainer>
  );
}
