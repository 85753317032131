import useSWR from "swr";
import useUser from "../useUser";
import { ICONS } from "assets/icons";
import { DATE_RANGE } from "types/date";
import { SWR_KEYS } from "constants/swrKeys";
import { useTranslation } from "react-i18next";
import { useState, SyntheticEvent } from "react";
import TicketsList from "../components/TicketsList";
import { BackButton, Img, TextField } from "components";
import { Grid, Avatar, Typography, Box, Select, MenuItem } from "@mui/material";

const UserTickets = () => {
  const { id, user } = useUser();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState(DATE_RANGE.ALL); 

  const { data, isLoading, error } = useSWR(`${SWR_KEYS.USER_TICKETS(id)}&searchText=${searchValue}`);

  const onSearch = (event: any) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onDateRangeChange = (event: any) => {
    const { value } = event.target;
    setStartDate(value);
  };

  return (
    <Grid>
      <BackButton />

      <Grid>
        <Avatar src={"/"} />
        <Typography>{"user.fullname"}</Typography>
      </Grid>

      <Box>
        <TextField
          onChange={onSearch}
          value={searchValue}
          InputProps={{
            startAdornment: (
              <Img
                src={ICONS.SEARCH}
                alt="search"
                sx={{
                  width: "2rem",
                  height: "2rem",
                }}
              />
            ),
          }}
          placeholder={t("search")}
        />

        <Select onChange={onDateRangeChange} value={startDate}>
          {Object.entries(DATE_RANGE).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {t(`_dateRange.${value}`)}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <TicketsList tickets={data?.tickets || []} />
    </Grid>
  );
};

export default UserTickets;
