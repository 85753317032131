import { getUploadLink, uploadDone, uploadFile } from "api/upload";

// Upload file requires 3 step:
// 1) Upload file and get file "url" and "id".
// 2) Upload file ?
// 3) Check uploaded file state
export const upload = async ({ contentType, file }: { contentType: "image" | "game"; file: string | File }) => {
  const response = await getUploadLink(contentType);
  if (!response?.fileId || !response?.uploadLink) {
    throw new Error("Can't get upload link");
  }

  await uploadFile(response.uploadLink, file, contentType);
  await uploadDone(response.fileId);

  return { msg: "Done", ...response };
};
