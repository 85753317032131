import { Button, Typography, Tooltip, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { addMemberOfCompany } from "api/companyPeople";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Clear";


export default function AddPeople({ onClose }: { onClose: () => void }) {
  const { id } = useParams();
  const { handleSubmit, getFieldProps, errors, touched, setFieldValue, isSubmitting, setSubmitting, values } =
    useFormik({
      initialValues: {
        personName: "",
        personRole: "",
        personEmail: "",
        personCallNumber: "",
      },
      onSubmit: async (data) => {
        try {
          if (id) await addMemberOfCompany(id, data);
          toast.success("done");
          onClose();
        } catch (error: any) {
          console.log("error", error);
          toast.error("reject");
        }
      },
    });
  const content = [
    {
      name: "نام",
      content: (
        <input
          style={{ width: "348px", height: "36px", borderRadius: "4px", padding: "1rem", borderColor:"#D3D3D3" }}
          placeholder="وارد کنید"
          {...getFieldProps("personName")}
        />
      ),
    },
    {
      name: "سمت",
      content: (
        <input
          style={{ width: "348px", height: "36px", borderRadius: "4px", padding: "1rem", borderColor:"#D3D3D3" }}
          placeholder="وارد کنید"
          {...getFieldProps("personRole")}
        />
      ),
    },
    {
      name: "ایمیل",
      content: (
        <input
          style={{ width: "348px", height: "36px", borderRadius: "4px", padding: "1rem", borderColor:"#D3D3D3" }}
          placeholder="وارد کنید"
          {...getFieldProps("personEmail")}
        />
      ),
    },
    {
      name: "شماره تماس ",
      content: (
        <input
          style={{ width: "348px", height: "36px", borderRadius: "4px", padding: "1rem", borderColor:"#D3D3D3" }}
          placeholder="وارد کنید"
          {...getFieldProps("personCallNumber")}
        />
      ),
    },
  ];

  return (
    <Box sx={{ py: 2, px: 2 }}>
      <Box width={"100%"} sx={{ display: "flex", m:"10px"}}>
       <Tooltip title="Delete">
          <IconButton>
              <DeleteIcon sx={{ fontSize: 20 }} onClick={onClose} style={{cursor:'inital'}}/>
          </IconButton>
        </Tooltip>
        <Typography sx={{ fontWeight: "bold", fontSize: 20, display: "inline", pl: "80px" }}>افزودن سمت جدید </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box sx={{ px: 2, py: 1, maxWidth: "428px" }}>
          {content.map((i: any, index: number) => {
            return (
              <Box sx={{ py: 1 }}>
                <Typography sx={{ pb: 1 }}>{i.name}</Typography>
                <Box>{i.content}</Box>
              </Box>
            );
          })}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              my: 2,
              borderRadius: "8px",
              bgcolor: "#4A76FD",
              height: "50px",
              "&:hover": {
                bgcolor: "#4A76FD",
              },
            }}
          >
            تایید و افزودن
          </Button>
        </Box>
      </form>
    </Box>
  );
}
