import React, { useState, useEffect } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { LinearProgress, Paper, Typography } from "@mui/material";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

export default function PDFPreview({
  pdf,
  height,
  width,
  style,
}: {
  pdf: string;
  width?: string | number;
  height?: string | number;
  style?: React.CSSProperties;
}) {
  const [pdfData, setPdfData] = useState<Blob | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(pdf)
      .then((res) => res.blob())
      .then((pdf) => {
        setPdfData(pdf);
      })
      .finally(() => setLoading(false));
  }, [pdf]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <>
      <Paper
        style={{
          width: width || "100%",
          height: height || 400,
          overflow: "auto",
          ...style,
        }}
      >
        {loading && (
          <>
            <Typography>Downloading PDF...</Typography>
            <LinearProgress />
          </>
        )}
        {pdfData && (
          <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess} options={options}>
            {Array.from(new Array(numPages), (_el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        )}
      </Paper>
    </>
  );
}
