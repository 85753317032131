import CustomBarChart, { DataItem } from "components/share/barChart";

export default function TimeFilterCharts({ data, xAxisTitle }: { data: DataItem[]; xAxisTitle: string }) {
  return (
    <>
      <CustomBarChart
        xAxisTitle={xAxisTitle}
        yAxisTitle={"نمایش تبلیغ"}
        barColor={"#2A41E7"}
        tooltipColor={["#2A41E7", "#FFC706", "#FE7968"]}
        data={data}
        title="نمایش تبلیغ"
        yAxisBarKey={"displayCount"}
      />
      <CustomBarChart
        xAxisTitle={xAxisTitle}
        yAxisTitle={"کلیک تبلیغ"}
        barColor={"#FFC706"}
        tooltipColor={["#2A41E7", "#FFC706", "#FE7968"]}
        data={data}
        title={"کلیک تبلیغ"}
        yAxisBarKey={"clickCount"}
      />
      <CustomBarChart
        xAxisTitle={xAxisTitle}
        yAxisTitle={"درآمد تبلیغ"}
        barColor={"#FE7968"}
        tooltipColor={["#2A41E7", "#FFC706", "#FE7968"]}
        data={data}
        title={"درآمد تبلیغ"}
        yAxisBarKey={"income"}
      />
    </>
  );
}
