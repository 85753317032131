import { THEME } from "constants/theme";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: THEME.LIGHT,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.mode = state.mode === THEME.LIGHT ? THEME.DARK : THEME.LIGHT;
    },
  },
});

export const { toggleTheme } = themeSlice.actions;

export default themeSlice.reducer;
