import { Avatar, Box, Pagination, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ILeagueType } from "api/league";
import { useTranslation } from "react-i18next";
import { ICONS } from "assets/icons";
import Table from "components/table/Table";
import { formatJallali } from "utils/date";
import { useNavigate } from "react-router-dom";
import PageContainer from "components/PageContainer";

export default function League({
  leagues = [],
  fullCheckBox,
  tabValue,
}: {
  leagues: ILeagueType[];
  fullCheckBox: boolean;
  tabValue: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const pageSize = 10;

  const columns = useMemo(() => {
    let initColumns = [
      {
        field: "leagueNumber",
        headerName: "شماره لیگ",
        flex: 0.5,
        renderCell({ row }: any) {
          return (
            <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>
              {row?.leagueNumber ? `${row?.leagueNumber}#` : "14252#"}
            </Typography>
          );
        },
      },
      {
        field: "title",
        headerName: "عنوان",
        flex: 1.5,
        renderCell({ row }: any) {
          return (
            <Box sx={{ display: "flex" }}>
              <Avatar
                src={row?.bannerImage?.url}
                alt="game"
                sx={{ width: "5rem", height: "3rem", borderRadius: "4px" }}
              />
              <Typography sx={{ fontSize: "1.3rem", fontWeight: 400, px: 1 }}>{row?.title}</Typography>
            </Box>
          );
        },
      },
      {
        field: "gameTitle",
        headerName: "عنوان بازی",
        flex: 1.5,
        renderCell({ row }: any) {
          return <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{row?.game?.title}</Typography>;
        },
      },
      {
        field: "publisher",
        headerName: "نام پابلیشر بازی ",
        flex: 1.2,
        renderCell({ row }: any) {
          return (
            <Box sx={{ display: "flex" }}>
              <Avatar src={row?.game?.publisher?.coverImage?.url} alt="game" sx={{ width: "3rem", height: "3rem" }} />
              <Typography sx={{ fontSize: "1.3rem", fontWeight: 400, px: 0.5 }}>
                {row?.game?.publisher?.fullname}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "startDate",
        headerName: t("startDate"),
        flex: 1,
        renderCell({ row }: any) {
          return (
            <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{`${formatJallali(row?.startDate)}`}</Typography>
          );
        },
      },
      {
        field: "endDate",
        headerName: t("endDate"),
        flex: 1,
        renderCell({ row }: any) {
          return (
            <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{`${formatJallali(row?.endDate)}`}</Typography>
          );
        },
      },
      {
        field: "actions",
        headerName: t("actions"),
        flex: 1,
        renderCell({ row }: any) {
          return <img src={ICONS.CHATCOMMENT} alt="1" onClick={() => navigate(`${row.id}`, { state: tabValue })} />;
        },
      },
    ];
    return initColumns;
  }, [leagues]);

  const [selectedItem, setSelectedItem] = useState<string[]>();
  const onSelectionModelChange = (ids: string[]) => {
    setSelectedItem(ids);
  };
  const leaguesId = leagues?.map((i: any) => i._id) || [];

  useEffect(() => {
    fullCheckBox ? setSelectedItem(leaguesId) : setSelectedItem([]);
  }, [fullCheckBox]);

  const displayedLeagues = leagues.slice((page - 1) * pageSize, page * pageSize);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <PageContainer noPadding={true}>
      <Box>
        <Table
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={selectedItem}
          columns={columns}
          rows={displayedLeagues.map((league: ILeagueType) => ({ ...league, id: league._id })) || []}
        />
        {leagues.length > pageSize && (
          <Pagination
            sx={{ mx: 5, pb: 10 }}
            count={Math.ceil(leagues.length / pageSize)}
            page={page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            color="primary"
          />
        )}
      </Box>
    </PageContainer>
  );
}
