import { useEffect, useState } from "react";
import { InputBase, Stack, useMediaQuery, useTheme } from "@mui/material";
// import SearchPhone from "assets/icon/SearchPhone.svg";
import searchIcon from "assets/Search_Magnifying_Glass.svg";
import SearchResult from "./searchResult";
export default function Search({
  width,
  border,
  backdrop,
  height,
  placeholder,
  bg,
  onSearchQuery,
}: {
  height?: string;
  backdrop?: string;
  border?: string;
  width?: string;
  placeholder?: string;
  bg?: string;
  onSearchQuery?: (a: string) => void;
}) {
  const [search, setSearch] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [open, setOpen] = useState(false);
  const phone = useMediaQuery("(max-width:550px)");
  const theme = useTheme();
  
  useEffect(() => {
    search !== undefined && onSearchQuery && onSearchQuery(search);
  }, [search]);
  return (
    <>
      <SearchResult
        width={phone ? 270 : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setSearch("");
          setOpen(false);
          setAnchorEl(undefined);
        }}
      />
      <Stack
        direction="row"
        alignItems="center"
        bgcolor={bg || "#EBEAF2"}
        sx={{
          pl:2,
          backdropFilter: backdrop || "",
          border:"1px solid #999AA6",
          borderRadius:"10px"
          //boxShadow: "0px 2px 4px rgba(41, 41, 48, 0.05)",
        }}
        borderRadius={1}
        width={width||"100%"}
        height={height}
      >
        <InputBase
          placeholder={placeholder || "جست وجو در بازی ها"}
          fullWidth
          sx={{ margin: "8px 5px", fontSize: phone ? "12px" : "14px" }}
          onChange={(e) => {
            setAnchorEl(e.currentTarget);
            setSearch(e.target.value);
          }}
        />

      <img
        width={phone ? "20px" : "22px"}
        height={phone ? "15px" : "22px"}
        alt="emptypage"
        src={searchIcon}
        style={{ marginLeft: "2rem"}}
      />
      </Stack>
    </>
  );
}
