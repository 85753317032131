import { delete_, post, put } from "api";
import { IImageType } from "./image";
import { AUTH_BASE_URL } from "./config";

export type IGameCategoryType = {
  _id: string;
  title: string;
  image: IImageType;
  gif:IImageType,
  darkGif:IImageType,
  darkImage:IImageType,
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
};

export const createCategory = (data: { title: string; image: string; description: string; gif: string }) => {
  return post(`/game/category`, data);
};

export const updateCategory = (data: { title: string; image: string; gif: string }, id: string) => {
  return put(`/game/category/${id}`, data);
};
export const deleteCategory = (id: string) => {
  return delete_(`/game/category/${id}`);
};
