import { useState } from "react";
import Header from "./components/Header";
import { LAYOUT } from "constants/styles";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import { Grid, useMediaQuery } from "@mui/material";

const Layout = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const bigLaptop = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));
  const toggleOpenDrawer = () => {
    setOpenDrawer((openDrawer) => !openDrawer);
  };

  return (
    <Grid
      sx={{
        backgroundColor: "background.default",
        overflowX: "unset",
      }}
    >
      <Header toggleOpenDrawer={toggleOpenDrawer} />
      <Grid>
        <Sidebar {...{ openDrawer, toggleOpenDrawer }} />
        <Grid
          sx={{
             bgcolor: "#F5F5F9",
            width: downMd ? "100%" : `calc(100% - ${LAYOUT.SIDEBAR_WIDTH})`,
            ml: downMd ? 0 : LAYOUT.SIDEBAR_WIDTH,
          }}
        >
          <Outlet />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Layout;
