import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Dialog,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Divider,
  TextField,
  styled,
  Button,
  TextareaAutosize,
} from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import RejectForm from "./rejectForm";
import { IUserType } from "api/user";

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
    width: 100%;
    height:30rem;
    borderRadius:12px;
    border:0.01px solid grey ;
    background: transparent;
    padding:1rem
  `
);
export default function AuthenticationForm({
  onChangeAccountVerified,
  onRejectAuth,
  userInfo,
  onMutate,
}: {
  onChangeAccountVerified?: (b: "inProgress" | "notVerified" | "verified" | "rejected") => void;
  onRejectAuth: (b: boolean) => void;
  userInfo: IUserType;
  onMutate: () => void;
}) {
  const [value, setValue] = React.useState<"verified" | "rejected" | null>(null);
  const [rejectAuth, setrejectedAuth] = React.useState(false);
  const handleChange = (event: any) => {
    onChangeAccountVerified && onChangeAccountVerified((event.target as any).value);
    setValue(event.target.value);
  };

  return (
    <>
      <Dialog open={value === "rejected"} onClose={() => setValue("verified")}>
        <Box sx={{ width: "40rem", my: 1, minHeight: "40rem" }}>
          <IconButton onClick={() => setValue("verified")}>
            <CloseIcon />
          </IconButton>
          <Divider sx={{ mb: 1 }} />
          <RejectForm
            accountType={userInfo?.accountType}
            onMutate={onMutate}
            onchageValue={() => setValue("verified")}
            value={value}
            handleReject={() => setrejectedAuth(true)}
            onRejectAuth={onRejectAuth}
          />
        </Box>
      </Dialog>

      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value="verified"
          control={<Radio />}
          label={
            <Box sx={{ display: "flex" }}>
              اطلاعات احراز شده توسعه دهنده{" "}
              <Typography sx={{ fontWeight: 600, textDecoration: "underline", px: 0.5 }}>مورد تایید</Typography>
              است.{" "}
            </Box>
          }
        />
        <FormControlLabel
          value="rejected"
          control={<Radio />}
          label={
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontWeight: 600, textDecoration: "underline", px: 0.5 }}>عدم تایید</Typography> اطلاعات
              احراز شده توسعه دهنده
            </Box>
          }
        />
      </RadioGroup>
    </>
  );
}
