export const NAVIGATION = [
  { key: "DASHBOARD" },
  { key: "USERS" },
  { key: "DEVELOPERS" },
  { key: "GAMES" },
  { key: "LEAGUES" },
  { key: "BRANDS" },
  { key: "SUPPORT" },
  { key: "REPORTS" },
  { key: "NOTIFICATIONS" },
  { key: "FINANCE" },
  {key:'ADVERTISEMENT'},
  { key: "SETTINGS", subMenu: [{ key: "AVATARS" }, { key: "CATEGORIES" }, { key: "ACHIEVEMENTS" }] },
];

export const NAVIGATIONS = [
  { key: "DASHBOARD" },
  { key: "USERS" },
  { key: "DEVELOPERS" },
  { key: "GAMES" },
  {
    key: "LEAGUES",
    menu: [{ key: "LEAGUESLIST" }, { key: "LEAGUESMANAGMENT" }],
  },
  { key: "BRANDS" },
  { key: "SUPPORT" },
  { key: "REPORTS" },
  { key: "NOTIFICATIONS" },
  { key: "FINANCE" },
  {key:"ADVERTISEMENT"},
  {
    key: "SETTINGS",
    subMenu: [
      {
        key: "SYSTEMSETTING",
        subMenu: [
          { key: "AVATARS" },
          { key: "CATEGORIES" },
          { key: "TAGS" },
          { key: "ACHIEVEMENTS" },
          { key: "TICKETSCATEGORIES" },
        ],
      },
    ],
  },
];
