import { IconButton } from "@mui/material";
import { ICONS } from "assets/icons";
import Img from "components/img/Img";
import TextField from "components/textField/TextField";

export const DatePickerInput = ({ onFocus, value, onChange, helperText, error }: any) => {
  return (
    <TextField
      onFocus={onFocus}
      value={value}
      onChange={onChange}
      {...{ helperText, error }}
      sx={{
        "& .MuiOutlinedInput-root": {
          paddingRight: 0,
          overflow: "hidden",
        },
      }}
      endAdornment={
        <IconButton
          onClick={onFocus}
          sx={(theme) => ({
            backgroundColor: "text.secondary",
            borderRadius: 0,
            height: "100%",
            aspectRatio: "1",

            ":hover": {
              backgroundColor: `${theme.palette.text.secondary}a6`,
            },

            "&.Mui-disabled": {
              backgroundColor: `${theme.palette.text.secondary}7d`,
            },
          })}
        >
          <Img
            src={ICONS.CALENDAR}
            alt="show"
            sx={{
              width: "3rem",
              height: "3rem",
            }}
          />
        </IconButton>
      }
    />
  );
};
