import { Box, Chip, TextField, Typography } from "@mui/material";
import { IGameType, keysControlType } from "api/game";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";

export default function EditForm({
  data,
  index,
  keyName,
  type,
  field,
  title,
  disabled,
}: {
  data: any;
  index: number;
  keyName: string;
  title: string;
  field: keyof IGameType;
  disabled?: boolean;
  type: "text" | "number" | "tag";
}) {
 
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState<string | number | any[]>();
  const params = useParams();
  const [tagsList, setTagsList] = useState<any[]>([]);
  const { versionId } = useParams();
  const { data: gameVersion, mutate } = useSWR<{ data: { gameVersion: any }; status: string }>(
    versionId ? `/game/me/version/${versionId}` : ""
  );
  useEffect(() => {
    if (data?.game && field === "keysControl") {
      setValue(data?.game.keysControl as any[]);
    } else if (data?.game && field === "category") {
      setValue(data?.game?.category.title);
    } else if (data.game && field === "versionChanges") {
      setValue(data.game?.activeVersion?.versionChanges);
    } else if (data?.game) {
      setValue(data?.game[field]);
    }
  }, [data, field]);
  function initialMaker(text: any, option?: string) {
    let inputTilte: any = null;
    if (option && option === "mouseControl") {
      inputTilte = gameVersion?.data?.gameVersion?.game?.mouseControl[text];
    } else if (text === "category") {
      inputTilte = gameVersion?.data?.gameVersion?.game.category?._id;
    } else {
      inputTilte = gameVersion?.data?.gameVersion?.game[text as keyof Partial<IGameType>];
    }

    return inputTilte;
  }

  let inputField: any = <></>;
  keyName === "ageRangeFrom"
    ? (inputField = (
        <TextField
          // {...getFieldProps(field)}
          name={field}
          type={type}
          sx={{
            width: "100%",
            color: "#474859",
            opacity: editing ? "50%" : "100%",
          }}
          inputProps={{ style: { fontSize: "14px" } }}
          disabled={!editing}
          variant={editing ? "outlined" : "standard"}
          InputProps={!editing ? { readOnly: true } : {}}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      ))
    : keyName === "tags" && !editing
    ? (inputField =
        typeof value === "object" ? (
          <Box
            sx={{
              display: "flex",
              borderBottom: "0.2px solid grey",
              borderBottomStyle: "dotted",
              width: "100%",
              pb: 2,
            }}
          >
            {value.map((i: any) => (
              <Chip label={i} sx={{ bgcolor: "#474859", mx: 1, fontSize: "12px", color: "white",borderRadius:"5px" }} />
            ))}
          </Box>
        ) : (
          <></>
        ))
    : keyName === "description" && typeof value === "string" && !editing
    ? (inputField = (
        <>
          <div
            style={{ borderBottom: "0.2px solid grey", borderBottomStyle: "dotted", fontSize: "14px", width: "100%" }}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        </>
      ))
    : keyName === "keysControl" && typeof value === "object" && !editing
    ? (inputField = value?.map((i: keysControlType, index: number) => (
        <Box key={index} sx={{ display: "flex",py:1 }}>
          <Typography sx={{pt:0.5}}>{`${i.description}`}</Typography>
          <Typography sx={{ px: 1.3,py:0.7, mx: 1, border: "1px solid grey", borderStyle: "dotted",borderRadius:"8px",fontSize:"12px" }}>{`${i.key}`}</Typography>
        </Box>
        // <TextField
        //   key={index}
        //   // {...getFieldProps(field)}
        //   name={field}
        //   variant="filled"
        //   type={type}
        //   inputProps={{ style: { fontSize: "14px" } }}
        //   sx={{
        //     width: "100%",
        //     color: "#474859",
        //     opacity: editing ? "50%" : "100%",
        //     "& .rtl-33s8i1-MuiInputBase-root-MuiInput-root ": {
        //       fontSize: "1.2rem",
        //     },
        //   }}
        //   disabled={!editing}

        //   InputProps={!editing ? { readOnly: true } : {}}
        //   value={`${i.key} - ${i.description}`}
        //   onChange={(e) => {
        //     setValue(e.target.value);

        //   }}
        // />
      )))
    : (inputField = (
        <TextField
          // {...getFieldProps(field)}
          name={field}
          type={type}
          inputProps={{ style: { fontSize: "14px" } }}
          sx={{
            width: "100%",
            color: "#474859",
            opacity: editing ? "50%" : "100%",
            "& .rtl-33s8i1-MuiInputBase-root-MuiInput-root ": {
              fontSize: "1.2rem",
            },
          }}
          disabled={!editing}
          variant={editing ? "outlined" : "standard"}
          InputProps={!editing ? { readOnly: true } : {}}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      ));
  return (
    <form>
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          my: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                mb: 1.5,
                fontSize: "14px",
                mt: 2.5,
                fontWeight: 600,
                opacity: editing ? "40%" : "100%",
              }}
            >
              {title}
            </Typography>
          </Box>
          {/* {!editing && (
            <Button
              onClick={() => setEditing(true)}
              variant="text"
              sx={{
                display: "flex",
                flexDirection: "column-reverse",
                fontWeight: 500,
                mt: 2,
                opacity: editing ? "40%" : "100%",
                color: "black",
                boxShadow: 0,
              }}
              disabled={editing || field === "category" || field === "title"}
            >
              <img src={underLine} alt="line" width="45px" />
              ویرایش
            </Button>
          )} */}
        </Box>
        {inputField}
      </Box>
    </form>
  );
}
