import { useEffect, useState } from "react";
import "react-multi-date-picker/styles/colors/teal.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import { DateObject } from "react-multi-date-picker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterMomentJalaali } from '@mui/x-date-pickers/AdapterMomentJalaali';

interface CustomDatePickerProps {
  onChangeDate: (date: any) => void;
  defaultValue?: any;
  isDisablePast?: boolean;
}

export default function CustomDatePicker({ onChangeDate, defaultValue, isDisablePast }: CustomDatePickerProps) {
  const [value, setValue] = useState<DateObject | any | null>(defaultValue ? new DateObject(defaultValue) : null);

  useEffect(() => {
    if (value) {
      onChangeDate(new Date(value));
      console.log(value);
    } else {
      onChangeDate(null);
    }
  }, [value, onChangeDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterMomentJalaali}>
      <DateCalendar 
        sx={{ height: '285px' }}
        value={value}
        onChange={setValue}
        className="teal"
        defaultValue={defaultValue ? new DateObject(defaultValue) : null}
        disablePast = {isDisablePast ? true : false}
      />
    </LocalizationProvider>
  );
}
 