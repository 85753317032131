import { createContext, ReactNode, useContext } from "react";
import ChatAdapter from "./Chat/Adapter";

const socketContext = createContext<{
  chat: ChatAdapter | null;
}>({
  chat:  new ChatAdapter(),
});

export default function SocketProvider({ children }: { children: ReactNode }) {
    const chat = ChatAdapter && new ChatAdapter();

  return <socketContext.Provider value={{ chat }}>{children}</socketContext.Provider>;
}

export const useSocket = () => {
  const chat = useContext(socketContext);
  return chat;
};
