import { Table } from "components";
import { useParams } from "react-router-dom";
import { styled, Typography, Button, ButtonProps } from "@mui/material";
import { Box, Pagination } from "@mui/material";
import { formatJallali } from "utils/date";
import { IGameType } from "api/game";
import useSWR from "swr";
import PageContainer from "components/PageContainer";
import { useEffect, useState } from "react";

interface StyledButtonProps extends ButtonProps {
  component?: any;
  to?: string;
}
export const StyledButton = styled(Button)<StyledButtonProps>({
  fontSize: "1.3rem",
  fontWeight: 300,
  height: "3rem",
  padding: "0 1.5rem",
  minWidth: "unset",
});

export const StyledTypography = styled(Typography)({
  fontSize: "12px",
  fontWeight: 300,
});
export const MyTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: "1rem",
});

export default function Versions() {
  const { gameId } = useParams();
  const [active, setActive] = useState(false);
  const { data } = useSWR<{ game: IGameType }>(`/games/${gameId}`);
  useEffect(() => {
    data && data.game && setActive(data?.game.active);
  }, [data]);
  const columns = [
    {
      field: "version",
      headerName: "ورژن",
      flex: 1,
      renderCell({ row }: any) {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <StyledTypography>{row?.version}</StyledTypography>
          </Box>
        );
      },
    },

    {
      field: "versionSdk",
      headerName: "ورژنsdk",
      flex: 1,
      renderCell({ row }: any) {
        return <StyledTypography>{`نسخه ${row?.version}`}</StyledTypography>;
      },
    },
    {
      field: "upload",
      headerName: "تاریخ اپلود",
      flex: 2,
      renderCell({ row }: any) {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <StyledTypography>{formatJallali(row.createdAt)}</StyledTypography>
          </Box>
        );
      },
    },
    {
      field: "publishedDate",
      headerName: "تایید و انتشار",
      flex: 1,
      renderCell({ row }: any) {
        return <StyledTypography>{formatJallali(row.createdAt)}</StyledTypography>;
      },
    },
    {
      field: "size",
      headerName: "حجم نسخه ",
      flex: 1,
      renderCell({ row }: any) {
        return <StyledTypography>{"10Mb"}</StyledTypography>;
      },
    },
    {
      field: "status",
      headerName: "وضعیت ",
      flex: 1,
      renderCell({ row }: any) {
        return (
          <Box>
            {" "}
            <span style={{color:active?"#04BD0C":"#F84E58"}}>{active ? "فعال" : "غیر فعال"}</span> , 
            <span style={{color:row?.publishStatus === "done"?"#04BD0C":"#F84E58"}}>
              {row?.publishStatus === "done"
                ? "منتشر شده"
                : row?.publishStatus === "rejectadeactivatedByAdmin"
                ? "توقف انتشار عمومی"
                : row?.publishStatus === "rejected"
                ? "رد شده"
                : row?.publishStatus === "inProgress" ? "در انتظار بررسی"
                : row?.publishStatus === "draft" && "در صف انتشار"}
            </span>
          </Box>
          // <StyledTypography sx={{color:row?.publishStatus === "done"?"#04BD0C":"#F84E58"}}>
          //   {active && row?.publishStatus === "done"
          //     ? "منتشر شده - فعال"
          //     : !active && row?.publishStatus === "rejectadeactivatedByAdmin"
          //     && " توقف انتشار عمومی - غیر فعال"
          //     }
          // </StyledTypography>
        );
      },
    },
  ];
  return (
    <PageContainer noPadding={true}>
      {" "}
      <Table
        columns={columns}
        rows={data?.game?.versions || []}
        getRowId={(row: any) => row._id}
        onHeaderHide={true}
        checkboxSelection
        // onSelectionModelChange={onSelectionModelChange}
        // selectionModel={selectedItem}
      />
      <Pagination
        sx={{ mx: 5, pb: 10 }}
        count={10}
        variant="outlined"
        shape="rounded"
        // page={page}
        // onChange={handleChange}
        color="primary"
      />{" "}
    </PageContainer>
  );
}
