import { Box, Button, Dialog, Typography, useMediaQuery } from "@mui/material";
import { delete_ } from "api";
import { toast } from "react-toastify";

export default function DeleteModal({
  open,
  url,
  onClose,
  onDone,
}: {
  open: boolean;
  url: any[] | undefined;
  onClose: () => void;
  onDone?: () => void;
}) {
  const phone = useMediaQuery("(max-width:550px)");
  const handleDelete = async (element: any) => {
    try {
      await delete_(element);
      toast.success("Done");
      onDone && onDone();
    } catch (error) {
      toast.error("error");
    } finally {
      onClose();
    }
  };

  return (
    <Dialog dir="rtl" open={open} onClose={onClose}>
      <Box sx={{ width: phone ? "312px" : "400px", height: phone ? "160px" : "180px" }}>
        <Typography sx={{ fontSize: phone ? "16px" : "20px", textAlign: "center", mt: "30px", mb: "20px" }}>
          از انجام عملیات اطمینان دارید؟
        </Typography>
        <Box
          sx={{
            width: "93%",
            m: "30px auto",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              width: phone ? "195px" : "148px",
              height: "48px",
              background: "#E33C25",
              borderRadius: "8px",
              color: "#FFFFFF",
              fontSize: phone ? "12px" : "14px",
              "&:hover": {
                background: "#E33C25",
              },
              justifyContent: "center",
              ml: 1,
            }}
            onClick={() => url && url.map(() => url.forEach((element) => handleDelete(element)))}
          >
            حذف از لیست
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: phone ? "195px" : "148px",
              height: "48px",
              border: "0.5px solid #474859",
              borderRadius: "8px",
              color: "#474859",
              fontSize: phone ? "12px" : "14px",
              justifyContent: "center",
            }}
            onClick={onClose}
          >
            انصراف
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
