import { Button, MenuItem, Select, Typography, Box, styled, TextareaAutosize } from "@mui/material";
import React from "react";
import star from "assets/Game Details.svg";
import { useFormik } from "formik";
import { rejectIdentity } from "api/user";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  height:30rem;
  borderRadius:12px;
  border:0.01px solid grey ;
  background: transparent;
  padding:1rem
  `
);
export default function RejectForm({
  accountType,
  onchageValue,
  value,
  handleReject,
  onRejectAuth,
  onMutate,
}: {
  accountType?: string;
  onchageValue: () => void;
  value: "verified" | "rejected" | null;
  handleReject: () => void;
  onRejectAuth: (b: boolean) => void;
  onMutate: () => void;
}) {
  const [reason, setReson] = React.useState("");
  const content =
    accountType && accountType === "business"
      ? [
          { value: "registerCode", title: "مشکل در کد ثبت" },
          { value: "companyName", title: "مشکل در  نام شرکت" },
          { value: "firstName", title: "مشکل در  نام نماینده" },
          { value: "lastName", title: "مشکل در  نام خانوادگی نماینده" },
          { value: "economicCode", title: "مشکل در  کد اقتصادی" },
          { value: "companyNationalId", title: "مشکل درشناسه ملی" },
          { value: "companyEmail", title: "مشکل در  ایمیل شرکتی" },
          { value: "msisdn", title: "مشکل در  شماره همراه" },
          { value: "postalCode", title: "مشکل در کد پستی" },
          { value: "country", title: "مشکل در  نام استان" },
          { value: "city", title: "مشکل در نام شهر" },
          { value: "address", title: "مشکل در آدرس " },
          { value: "ceoNationalIdCardImage", title: "مشکل در تصویر کارت ملی " },
          { value: "officialNewspaper", title: "مشکل در تصویر روزنامه رسمی" },
          { value: "valueAddedTax", title: "مشکل در تصویر مالیات بر ارزش افزوده " },
          { value: "other", title: "مشکل در دیگر تصاویر     " },
          { value: "email", title: "مشکل در ایمیل کاربر " },
        ]
      : [
          { value: "firstName", title: "مشکل در  نام نماینده" },
          { value: "lastName", title: "مشکل در  نام خانوادگی نماینده" },
          { value: "msisdn", title: "مشکل در  شماره همراه" },
          { value: "postalCode", title: "مشکل در کد پستی" },
          // { value: "country", title: "مشکل در  نام استان" },
          // { value: "city", title: "مشکل در نام شهر" },
          { value: "email", title: "مشکل در ایمیل کاربر " },

          { value: "address", title: "مشکل در آدرس " },
          { value: "nationalId", title: "مشکل در کد ملی کاربر  " },
          { value: "gender", title: "ایراد در ثبت جنسیت کاربر  " },
          { value: "nationalIdCardImage", title: "مشکل در تصویر کارت ملی کاربر  " },
          { value: "birthday", title: "مشکل درتاریخ تولد کاربر " },
        ];

  const { id } = useParams();

  const handleChangeReason = (event: any) => {
    setReson((event.target as any).value);
  };
  const { handleSubmit, getFieldProps, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      rejectTitle: reason,
      rejectDesc: "",
    },

    onSubmit: async (values) => {
      try {
        await rejectIdentity({ rejectTitle: values.rejectTitle, rejectDesc: values.rejectDesc }, id);
        toast.success("باموفقیت انجام شد");
        handleReject();
        onRejectAuth(true);
        onMutate();
      } catch (error) {
        console.log(error);
        toast.error("مشکلی رخ داده است");
      } finally {
        onchageValue();
      }
    },
  });

  return (
    <form style={{ paddingLeft: "20px", paddingRight: "20px" }} onSubmit={handleSubmit}>
      <Box sx={{ display: "flex", py: 1 }}>
        <img src={star} alt="star" width={10} height={10} />
        <Typography>علت عدم تایید اطلاعات توسعه دهنده را مشخص کنید</Typography>
      </Box>
      <Select value={reason} onChange={handleChangeReason} fullWidth>
        {content.map((i: any) => (
          <MenuItem key={i.value} value={i.value}>
            {i.title}
          </MenuItem>
        ))}
      </Select>
      <Typography sx={{ my: 2 }}>توضیحات</Typography>
      <Box sx={{ width: "100%", minHeight: "180px", borderRadius: "12px" }}>
        <StyledTextarea
          id="rejectDesc"
          placeholder="در اینجا بنویسید..."
          minRows={6}
          {...getFieldProps("rejectDesc")}
        />
      </Box>
      <Box textAlign={"end"}>
        <Button type="submit" variant="contained" sx={{ px: 3 }}>
          تایید
        </Button>
      </Box>
    </form>
  );
}
