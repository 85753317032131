import Img from "components/img/Img";
import { ICONS } from "assets/icons";
import { Checkbox, CheckboxProps, IconButton } from "@mui/material";

const checkedIcon = (
  <IconButton>
    <Img
      src={ICONS.CHECKED_CHECKBOX}
      alt="search"
      sx={{
        width: "2rem",
        height: "2rem",
      }}
    />
  </IconButton>
);

const unCheckedIcon = (
  <IconButton>
    <Img
      src={ICONS.UNCHECKED_CHECKBOX}
      alt="search"
      sx={{
        width: "2rem",
        height: "2rem",
      }}
    />
  </IconButton>
);

const ListCheckbox = (props: CheckboxProps) => {
  return <Checkbox sx={{ ...props.sx, padding: 0 }} {...props} checkedIcon={checkedIcon} icon={unCheckedIcon} />;
};

export { ListCheckbox };
