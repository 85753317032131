import { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

const Chart = ({ data }: { data: any }) => {
  const { t } = useTranslation();
  const labels = useMemo(() => {
    if (data) {
      return data.countsByGranularity.map((item: any) => item.date);
    }
  }, [data]);

  const chartDate = useMemo(() => {
    if (!data) {
      return {
        labels,
        datasets: [],
      };
    }
    return {
      labels,
      datasets: [
        {
          label: t("allPublishers"),
          data: data.countsByGranularity.map((item: any) => item.publisherCount),
          borderColor: "#4F48E4",
          backgroundColor: "#4F48E4",
          pointStyle: "circle",
          pointRadius: 3,
          pointHoverRadius: 6,
        },
        {
          label: t("allUsers"),
          data: data.countsByGranularity.map((item: any) => item.userCount),
          borderColor: "#0DD2AD",
          backgroundColor: "#0DD2AD",
          pointStyle: "circle",
          pointRadius: 3,
          pointHoverRadius: 6,
        },
      ],
    };
  }, [data, labels, t]);

  const options = {
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
      },
    },
  };

  return <Box>{data && <Line options={options} data={chartDate} />}</Box>;
};

export default Chart;
