import { DataItem } from "components/share/barChart";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { adCompanyFilterDetailType, diagramType, totalType } from "api/asStats";
import GeneralStat from "../adsList/[id]/statistics/general";
import Chart from "../adsList/[id]/statistics/chart";
import TimeFilterCharts from "./timefilterCharts";
import { useTranslation } from "react-i18next";
import CustomRangeDate from "pages/game/components/customRangeDate";

const data: DataItem[] = [
  { value: "01/06/24", displayCount: 75000, clickCount: 13000, income: 26000000 },
  { value: "01/06/25", displayCount: 65000, clickCount: 11000, income: 22000000 },
  { value: "01/06/26", displayCount: 40000, clickCount: 7000, income: 14000000 },
  { value: "01/06/27", displayCount: 50000, clickCount: 9000, income: 18000000 },
  { value: "01/06/28", displayCount: 80000, clickCount: 14000, income: 28000000 },
  { value: "01/06/29", displayCount: 80000, clickCount: 14000, income: 28000000 },
  { value: "01/06/30", displayCount: 85000, clickCount: 15000, income: 30000000 },
  { value: "01/06/31", displayCount: 80000, clickCount: 14000, income: 28000000 },
];

const pieChartData = [
  { name: "یکتانت", value: 800 },
  { name: "  تپسل  ", value: 300 },
  { name: "  گوگل  ", value: 300 },
];

export default function AdsStatistics() {
  const { t } = useTranslation();
  const [startAndEndDate, setStartAndEndDate] = useState(["1403-06-01", "1403-06-05"]);
  const [filterBy, setFilterBy] = useState<"gameFilter" | "timeFilter" | "advertisingCompanyFilter">("timeFilter");
  const [companyName, setCompanyName] = useState<"googleAds" | "yektanet" | "tapcell" | "all">("tapcell");

  const { data: statistics } = useSWR<{ statistics: { total: totalType; diagram: diagramType } }>(
    `/ads/statistics/all?startDate=${startAndEndDate[0]}&endDate=${startAndEndDate[1]}&companyName=${companyName}`
  );

  const [totlalClickCompanies, setTotlalClickCompanies] = useState(0);
  const [totlalViewCompanies, setTotlalViewCompanies] = useState(0);

  function calculateTotalValue(data: any[]) {
    return data.reduce((total: any, item: any) => total + item.value, 0);
  }

  function callSetStartAndEndDate(data: any) {
    setStartAndEndDate(data);
  }

  useEffect(() => {
    statistics?.statistics.diagram?.advertisingCompanyFilter.clicks &&
      setTotlalClickCompanies(calculateTotalValue(statistics?.statistics.diagram?.advertisingCompanyFilter.clicks));
    statistics?.statistics.diagram?.advertisingCompanyFilter.views &&
      setTotlalViewCompanies(calculateTotalValue(statistics?.statistics.diagram?.advertisingCompanyFilter.views));
  }, [
    statistics?.statistics.diagram?.advertisingCompanyFilter.clicks,
    statistics?.statistics.diagram?.advertisingCompanyFilter.views,
  ]);

  const convertedData: DataItem[] =
    statistics?.statistics?.diagram[filterBy]?.clicks?.map((click: any, index: number) => ({
      value:
        filterBy === "advertisingCompanyFilter"
          ? t(`${click.name}`)
          : filterBy === "gameFilter"
          ? click.name
          : click.date,
      displayCount: statistics?.statistics?.diagram?.[filterBy].views[index].value,
      clickCount: statistics?.statistics?.diagram?.[filterBy].clicks[index].value,
      income: statistics?.statistics?.diagram?.[filterBy].incomes[index].value,
    })) || data;
  const pieChartdisplayCountData =
    statistics?.statistics.diagram?.advertisingCompanyFilter.views.map(
      (item: adCompanyFilterDetailType, index: number) => {
        return { name: `${t(`${item.name}`)} ${(item.value / totlalViewCompanies) * 100}% `, value: item.value };
      }
    ) || pieChartData;
  const pieChartClickcountData =
    statistics?.statistics.diagram?.advertisingCompanyFilter?.clicks?.map(
      (item: adCompanyFilterDetailType, index: number) => {
        return { name: `${t(`${item.name}`)} ${(item.value / totlalClickCompanies) * 100}%`, value: item.value };
      }
    ) || pieChartData;
  return (
    <>
      <GeneralStat totlaData={statistics?.statistics?.total} />

      <Box sx={{ display: "flex", gap: 2, borderTop: "1px solid #C0C1CE", width: "100%", py: 4 }}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ mr: 0.5, ml: 5, mt: 2 }} align="left">
            انتخاب شرکت ها :
          </Typography>
          <Select
            value={companyName}
            onChange={(event: any) => setCompanyName(event.target.value)}
            placeholder=""
            sx={{ bgcolor: "white", width: "150px", height: "50px", borderColor: "white" }}
          >
            {["googleAds", "yektanet", "tapcell", "all"].map((item: string) => (
              <MenuItem key={item} value={item}>
                {t(`${item}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ ml: 5, mr: 0.5, mt: 2 }} align="left">
            نمودار بر اساس :
          </Typography>
          <Select
            value={filterBy}
            onChange={(event: any) => setFilterBy(event.target.value)}
            placeholder=""
            sx={{ bgcolor: "white", width: "150px", height: "50px", borderColor: "white" }}
          >
            {["gameFilter", "timeFilter", "advertisingCompanyFilter"].map((item: string) => (
              <MenuItem key={item} value={item}>
                {t(`${item}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <CustomRangeDate changeDate={callSetStartAndEndDate} />
      </Box>
      <TimeFilterCharts xAxisTitle={t(`${filterBy}`)} data={convertedData} />
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ fontWeight: "bold", ml: 5, mt: 2 }} align="left">
          گزارش عملکرد شرکت ها
        </Typography>
        <Chart chartTitle="تعداد نمایش تبلیغ" data={pieChartdisplayCountData} />
        <Chart chartTitle="تعداد کلیک تبلیغ" data={pieChartClickcountData} />
      </Box>
    </>
  );
}
