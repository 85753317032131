import { useEffect, useState } from "react";
import { Box, Typography, TextField, CircularProgress, Button, Dialog, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import { IGameCategoryType, updateCategory } from "api/gameCategory";
import Uploader from "shared/Uploader";
import { upload } from "api/file";

export type IloadingFile = {
  image: boolean;
  gif: boolean;
  darkImage: boolean;
  darkGif: boolean;
};
const schema = Yup.object({
  title: Yup.string().required(),
});

export default function EditCategory({
  open,
  onClose,
  data,
  onMutate,
  id,
}: {
  id: any;
  data: IGameCategoryType;
  open: boolean;
  onClose: () => void;
  onMutate: () => void;
}) {
  const [previewUrl, setPreviewUrl] = useState<any>();
  const [image, setImage] = useState<Blob>();
  const [darkImage, setDarkImage] = useState<Blob>();
  const [gif, setGif] = useState<Blob>();
  const [darkGif, setDarkGif] = useState<Blob>();

  const urlToFile = async (url: string) => {
    return await fetch(url).then((r) => r.blob());
  };
  console.log(data, "data");
  useEffect(() => {
    setPreviewUrl({
      image: data?.image?.url,
      gif: data?.gif?.url,
      darkImage: data?.darkImage?.url,
      darkGif: data?.darkGif?.url,
    });
    if (data?.image?.url && data?.darkImage?.url && data?.gif?.url && data?.darkGif?.url) {
      urlToFile(data?.image?.url).then((file) => setImage(file));
      urlToFile(data?.darkImage?.url).then((file) => setDarkImage(file));
      urlToFile(data?.gif?.url).then((file) => setGif(file));
      urlToFile(data?.darkGif?.url).then((file) => setDarkGif(file));
    }
  }, [data]);
  const {
    handleSubmit,
    getFieldProps,
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    setSubmitting,

  } = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      title: data?.title,
      image: data?.image?._id,
      gif: data?.gif?._id,
      darkImage: data?.darkImage?._id,
      darkGif: data?.darkGif?._id,
    },
    onSubmit: async (values, { resetForm }) => {
     
      try {
        setSubmitting(true);
        await updateCategory(values, id);
        toast.success("با موفقیت آپدیت شد");
        onMutate();
      } catch (error) {
        toast.error(String(error));
      } finally {
        setSubmitting(false);
        onClose();
        resetForm({ values: { ...values, title: "" } });
      }
    },
  });

  const [fileLoading, setFileLoading] = useState<IloadingFile>({
    image: false,
    gif: false,
    darkImage: false,
    darkGif: false,
  });
  const handleUpload = async (f: File[], type: string) => {
    try {
      setFileLoading((prev) => ({ ...prev, [type as keyof IloadingFile]: true }));
      if (f[0]) {
        const resp = await upload({ contentType: "image", file: f[0] });
        setFieldValue(type, resp.fileId);
        const imageUrl = URL.createObjectURL(f[0]);
        setPreviewUrl({ ...previewUrl, [type]: imageUrl });
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setFileLoading((prev) => ({ ...prev, [type as keyof IloadingFile]: false }));
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <CloseIcon sx={{ cursor: "pointer", mx: 2, mt: 1 }} onClick={() => onClose()} />

      <Box sx={{ mx: 4, mb: 2 }}>
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <Box gap={3} my={3}>
            <Typography my={1}>نام دسته بندی</Typography>
            <TextField
              id="title"
              value={values.title}
              onChange={handleChange}
              // {...getFieldProps("title")}
              error={Boolean(touched.title && errors.title)}
              sx={{ my: 1, background: "#F5F5F9" }}
              size="small"
              fullWidth
            />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {[{type:"image",blobFile:image},{ type:"gif",blobFile:gif}].map((i: any) => {
                return <Uploader loading={fileLoading} type={i.type} previewUrl={previewUrl?.[i.type]} onDrop={handleUpload} blobFile={i.blobFile}/>;
              })}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {[{type:"darkImage",blobFile:darkImage},{ type:"darkGif",blobFile:darkGif}].map((i: any) => {
                return <Uploader loading={fileLoading} type={i.type} previewUrl={previewUrl?.[i.type]} onDrop={handleUpload} blobFile={i.blobFile} />;
              })}
            </Box>
          </Box>
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" type="submit" fullWidth>
              به روزرسانی
            </Button>
          )}
        </form>
      </Box>
    </Dialog>
  );
}
