import { styled, Typography, Button, ButtonProps } from "@mui/material";

interface StyledButtonProps extends ButtonProps {
  component?: any;
  to?: string;
}
export const StyledButton = styled(Button)<StyledButtonProps>({
  fontSize: "1.3rem",
  fontWeight: 300,
  height: "3rem",
  
});

export const StyledTypography = styled(Typography)({
  fontSize: "1.3rem",
  fontWeight: 300,
});
export const MyTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: "1.2rem",
});
