import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ICONS } from "assets/icons";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Button,
  Avatar,
  Typography,
  TextareaAutosize,
  styled,
  IconButton,
  useMediaQuery,
  Chip,
  responsiveFontSizes,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { ITicketType, replyTicket } from "api/ticket";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import UploaderImage from "components/uploader/UploaderImage";
import { upload, uploadEx } from "api/file";
import useSWR from "swr";
import moment from "jalali-moment";
import BackButton from "components/back-button";
import PageContainer from "components/PageContainer";
import { useStyles } from "components/share/useStyle";
import FileDownloader from "shared/filedownloader";

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
`
);

const Ticket = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState<string[] | undefined>();
  const me = useSelector((state: any) => state.admin);
  const { id, userId } = useParams();
  const [currentTiket, setCurrentTiket] = useState<ITicketType[]>();
  const contentRef = useRef<HTMLDivElement>(null);
  const [uploadImageLoading, setUploadImageLoading] = useState<boolean>(false);
  const { data: userTickets, mutate } = useSWR<{ tickets?: any[] }>(`/tickets?userId=${userId}`);
  const phone = useMediaQuery("(max-width:590px)");
  const tablet = useMediaQuery("(max-width:768px)");
  const slaptop = useMediaQuery("(max-width:1024px)");
  const classes=useStyles()

  //thi is good the same as chatroom but ican not cope whit!
  const scrollToLastComment = () => {
    const lastChildElement = contentRef.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setCurrentTiket(userTickets?.tickets?.filter((item: ITicketType) => item._id === id));
  }, [id, userTickets?.tickets]);
  console.log("currentticket", currentTiket);

  useEffect(() => {
    scrollToLastComment();
  }, [currentTiket]);

  const uploadFile = async (f: File[]) => {
  
    try {
      setUploadImageLoading(true);
      if (f.length > 0) {
        const resp = await uploadEx("image", f[0]);
        setFile([resp.fileId]);
        console.log("rt: ", resp.fileId);
      }
      setUploadImageLoading(false);
    } catch (error: any) {
      setUploadImageLoading(false);
      toast.error(error?.data?.message);
    }
  };

  const schema = Yup.object({
    content: Yup.string().required(),
  });

  const { handleSubmit, values, errors, touched, handleChange } = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      content: "",
    },
    onSubmit: async (data) => {
      if (currentTiket) {
        try {
          console.log("rt: ", file);
          await replyTicket(currentTiket[0]?._id, data.content, file);
          toast.success("پاسخ شما با موفقیت ارسال شد");
          mutate();
        } catch (error) {
          toast.error(String(error));
        } finally {
          values.content = "";
          setFile([]);
        }
      }
    },
  });

  return (
    <PageContainer>
      <Box sx={{ backgroundColor: "white", borderRadius: "1.7rem", px: 2, py: 2, mb: 2 }}>
        {currentTiket && (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between", mx: 7, my: 2 }}>
              <BackButton />
            </Box>

            <Grid container xs={12}>
              <Grid container xs={12} sx={{ mx: 5 }}>
                <Grid item xs={6} sm={2} md={3} sx={{ textAlign: "center", mt: 1,pl:3 }}>
                  <Box display={"flex"}>
                    <Typography sx={{ pr: 1, opacity: "70%" }}>موضوع:</Typography>
                    <Typography sx={{ fontSize: "16px", color: "#333337", fontWeight: 600 }}>
                      {currentTiket[0]?.title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3} md={1.5} sx={{ textAlign: phone ? "end" : "center" }}>
                  <Chip
                    label={
                      currentTiket[0]?.status === "new"
                        ? "جدید"
                        : currentTiket[0]?.status === "replied"
                        ? "پاسخ داده شده"
                        : currentTiket[0]?.status === "closed"
                        ? "بسته  شده"
                        : "درحال بررسی"
                    }
                    sx={
                      currentTiket[0]?.status === "new"
                        ? {
                            bgcolor: "#FFC1C1",
                            color: "#F84E58",
                            p:1,
                            borderRadius: "31px",
                            width: "130px",
                            fontSize: "14px",
                          }
                        : currentTiket[0]?.status === "replied"
                        ? {
                            bgcolor: "#E9FFEA",
                            color: "#04BD0C",
                            px: 0.1,
                            py: 1,
                            borderRadius: "31px",
                            fontSize: "14px",
                          }
                        : currentTiket[0]?.status === "closed"
                        ? {
                            bgcolor: "#292A5433",
                            color: "#292A54",
                            px: 3,
                            py: 0.5,
                            borderRadius: "31px",
                            fontSize: "14px",
                          }
                        : {
                            bgcolor: "#FFF0CF",
                            color: "#E89F00",
                            px: 3,
                            py: 0.5,
                            borderRadius: "31px",
                            fontSize: "14px",
                          }
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={7.5}
                  sx={{ textAlign: phone ? "center" : "end", py: phone ? 1 : "unset" }}
                >
                  <Button
                    startIcon={<img src={ICONS.REFRENCE} alt="" width={20} height={20} />}
                    sx={{ bgcolor: "#676872", mb: 2 }}
                    variant="contained"
                  >
                    {t("refrencTicket")}
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1} >
                <Grid item sm={2.2} xs={6}>
                  <Box sx={{ display: "flex", mr: 2, ml: 8, mt: 1 }}>
                    <Box sx={{ px: 0.5 }}>
                      <img src={ICONS.TICKET} alt="ticket" width={20} height={20} />
                    </Box>
                    <Typography sx={{ color: "#7B7D79", px: 0.5, fontSize: "14px" }}> تیکت </Typography>
                    <Typography sx={{ color: "#474859", fontSize: "14px"  }}>{currentTiket[0]?.ticketId}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={0.7}>
                  <Chip
                    label={
                      <Box sx={{ pt: 0.5,display: "flex", justifyContent: "center" }}>
                        <Box>
                          <img src={ICONS.CATEGORY} alt="category" />
                        </Box>
                        <Typography sx={{  pt: 0.3,fontSize: "12px" }}>
                          {currentTiket[0]?.category ? currentTiket[0]?.category : "بازی"}
                        </Typography>
                      </Box>
                    }
                    sx={{
                      border: "1px solid #C5C8D2",
                      bgcolor: "#C5C8D2",
                      borderRadius: "27px",
                      color: "black",
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Chip
                    label={
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box>
                          <img src={ICONS.NO_NEW_GAME} alt="game" width={15} />
                        </Box>
                        <Typography sx={{ pt: 0.2, fontSize: "12px" }}> دیتای غیر واقعی</Typography>
                      </Box>
                    }
                    sx={{
                      border: "1px solid #C5C8D2",
                      bgcolor: "#C5C8D2",
                      borderRadius: "27px",
                      color: "black",
                      // mb: 2,
                      // display: "flex",
                      // justifyContent: "center",
                      // py: 0.8,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid  item xs={12}  sx={{ bgcolor: "#F5F5F9", borderRadius: "20px", mx: 6,mt:2 }}>
                <Box
                className={classes.root}
                  sx={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "500px",
                    borderTopRightRadius: "16px",
                    borderTopLeftRadius: "16px",
                    p: 3,
                    border: "1px solid inherit",
                  }}
                >
                  {currentTiket[0]?.contents.map((i: any) => {
                    return (
                      <Box ref={contentRef}>
                        {i?.sender?._id === me?.admin?.me?._id ? (
                          <>
                            {" "}
                            <Box sx={{ display: "flex", justifyContent: "end" }}>
                              <Box
                                sx={{
                                  padding: "8px",
                                  mt: 2,
                                  color: "#7B7D79",
                                  minHeight: "86px",
                                  width: "470px",
                                  fontSize: "16px",
                                  borderTopLeftRadius: "12px",
                                  borderBottomLeftRadius: "12px",
                                  borderBottomRightRadius: "0",
                                  borderTopRightRadius: "12px",
                                  bgcolor: "white",
                                  border: "1px solid inherit",
                                }}
                              >
                                <Box>
                                  <Typography sx={{ flexWrap: "wrap", width: "100%" }}>{i.content}</Typography>
                                </Box>
                                {i.images.length ? (
                                  <Box
                                    sx={{
                                      display:"flex",
                                      justifyContent:"flex-end"
                                    }}
                                  >
                                    {" "}

                                    {i.images.map((image:any,index:number)=>{
                                      return(
                                        <div key={index} >
                                          <FileDownloader type={"admin"} url={image.url}/>
                                        </div>
                                      )
                                    })}
                                  </Box>
                                ) : (
                                  <></>
                                )}
                              </Box>
                              <Avatar
                                sx={{ mt: "65px", ml: 2, bgcolor: "#292A54" }}
                                src={i.sender.profilePicture?.url}
                              />
                            </Box>
                            <Typography sx={{ display: "flex", justifyContent: "end", mr: 8, mt: 1 }}>ادمین</Typography>
                            <Typography
                              sx={{ display: "flex", justifyContent: "end", mr: "37%", mt: -2, opacity: "35%" }}
                            >
                              {`${moment.utc(i.createdAt).locale("fa").local().format("HH:mm | YYYY/MM/DD")}`}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Box sx={{ display: "flex", justifyContent: "start" }}>
                              <Avatar sx={{ mr: 2 }} src={i.sender.profilePicture?.url} />
                              <Box
                                sx={{
                                  backgroundColor: " #676872",
                                  padding: "8px",
                                  minHeight: "86px",
                                  width: "470px",
                                  borderTopLeftRadius: "0px",
                                  borderBottomLeftRadius: "12px",
                                  borderBottomRightRadius: "12px",
                                  borderTopRightRadius: "12px",
                                  fontSize: "16px",
                                  bordercolor: "#676872",
                                }}
                              >
                                <Box>
                                  <Typography sx={{ flexWrap: "wrap", width: "100%", color: "#FFFFFF" }}>
                                    {i.content}
                                  </Typography>
                                </Box>

                                {i.images.length ? (
                                  <Box
                                    sx={{
                                    
                                      display:"flex",
                                    }}
                                  >
                                   
                                    {i.images.map((image:any,index:number)=>{
                                      return(
                                        <div key={index}>
                                          <FileDownloader url={image.url}/>
                                        </div>
                                      )
                                    })}
                                   
                                  </Box>
                                ) : (
                                  <></>
                                )}
                              </Box>
                            </Box>

                            <Button
                              sx={{ display: "flex", ml: 6, fontSize: "14px" }}
                              endIcon={<img src={ICONS.ARROWRIGHTSM} alt="fdg" />}
                            >
                              {i.sender.username}
                            </Button>
                            <Typography
                              sx={{ display: "flex", justifyContent: "end", mr: "52%", mt: -3, opacity: "35%" }}
                            >{`${moment
                              .utc(i.createdAt)
                              .locale("fa")
                              .local()
                              .format("HH:mm | YYYY/MM/DD")}`}</Typography>
                          </>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                minHeight: "100px",
               bgcolor: "#F5F5F9",
                border: "0.1px solid inherit",
                borderTop: "none",
                p: 3,
                marginBottom: " 2rem",
                borderRadius: "20px",
                mx: 6,
                mt: 2,
              }}
            >
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ width: "100%", minHeight: "200px" }}>
                    <StyledTextarea
                      name="content"
                      id="content"
                      onChange={handleChange}
                      value={values.content}
                      placeholder={currentTiket[0]?.status !== "closed" ? "در اینجا بنویسید..." : ""}
                      minRows={4}
                      disabled={currentTiket[0]?.status === "closed"}
                    />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-around", px: 2 }}>
                    <Box>
                      <UploaderImage loading={uploadImageLoading} onDrop={uploadFile} fileId={file}/>
                    </Box>
                    <Box sx={{ px: 2, pt: 1.2 }}>
                      <Button
                        disabled={currentTiket[0]?.status === "closed"}
                        variant="contained"
                        startIcon={<img alt="refrenc" width={18} height={18} src={ICONS.SEND} />}
                        type="submit"
                        size="medium"
                      >
                        ارسال
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            </Box>
            {currentTiket && currentTiket[0]?.status === "closed" && (
              <Typography
                sx={{
                  bgcolor: "#C0C1CE",
                  justifySelf: "center",
                  height: "70px",
                  borderRadius: "8px",
                  textAlign: "center",
                  fontsize: "24px",
                  fontWeight: 600,
                  mt: 3,
                  pt: 3,
                }}
              >
                تیکت بسته شده
              </Typography>
            )}
          </>
        )}
      </Box>
    </PageContainer>
  );
};

export default Ticket;
