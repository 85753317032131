import { Box, Button, Grid, Typography } from "@mui/material";
import { adsType, changeActive } from "api/ads";
import { BackButton } from "components";
import PageContainer from "components/PageContainer";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import stop from "assets/Stop_Sign.svg";
import tick from "assets/Circle_Check.svg";
import tapcell from "assets/tapcel-logo.svg";
import yaktanet from "assets/yektanet-logo.svg";
import googleAds from "assets/adivery-logo.svg";
import useSWR, { mutate } from "swr";
import moment from "jalali-moment";
import adLink from "assets/ads-link.svg";
import { toast } from "react-toastify";
import { changeCompanyActive, companiesType } from "api/adCompanies";
type AdListDetailsContext = [companiesType[]];
export default function AdCompaniesDetails() {
  const fakeAd: any[] = [
    {
      _id: "66a7a0e6656b5f50dc7fafce",

      provider: "yektanet",
      type: "banner",
      bannerAds: {
        code: '<div id="mediaad-nwJWr"></div>',
        width: 100,
        height: 100,
        createdAt: "2024-07-29T14:02:14.353Z",
        updatedAt: "2024-07-29T14:02:14.353Z",
      },
      location: "site_left",
      weight: 0,
      active: true,
      adsStats: [
        {
          view: 0,
          click: 0,
          startTime: "2024-07-29T14:02:14.349Z",
          endTime: null,
          income: 0,
          _id: "66a7a0e6656b5f50dc7fafcf",
        },
      ],
      totalAdsStats: {
        startTime: "2024-07-29T14:02:14.349Z",
        endTime: null,
        _id: "66a7a0e6656b5f50dc7fafcf",
      },
      createdAt: "2024-07-29T14:02:14.353Z",
      updatedAt: "2024-07-29T14:02:14.353Z",
      __v: 0,
    },
  ];
  const {
    data: companies,
    isLoading,
    mutate,
  } = useSWR<{ companies: companiesType[]; count: number }>("/ads/companies");
  const [filterCompById, setFilterCompById] = useState<companiesType[]>(fakeAd);
  const [isClicked, setIsClicked] = useState(0);
  const navigate = useNavigate();
  function handleClick(link: string, idx: number) {
    setIsClicked(idx);
    navigate(link);
  }
  const { id } = useParams();
  useEffect(() => {
    companies &&
      companies.companies &&
      companies.companies.length !== 0 &&
      setFilterCompById(companies?.companies.filter((company: companiesType) => company?._id === id));
  }, [companies, id]);
  async function handleChangeActive() {
    try {
      if (id) await changeCompanyActive({ active: !filterCompById[0].active }, id);
      toast.success("done");
      mutate();
    } catch (error: any) {
      toast.error("reject");
    }
  }
  const infoList = [
    {
      title: "تاریخ ثبت تبلیغ",
      content: (
        <Typography sx={{ fontSize: "14px", py: 0.5 }}>
          {`${moment.utc(filterCompById[0].createdAt).locale("fa").format(" YYYY/MM/DD")}`}
        </Typography>
      ),
    },
    {
      title: "اخرین به روز رسانی",
      content: (
        <Typography sx={{ fontSize: "14px", py: 0.5 }}>
          {`${moment.utc(filterCompById[0].updatedAt).locale("fa").format(" YYYY/MM/DD")}`}
        </Typography>
      ),
    },
    {
      title: "وضعیت",
      content: (
        <Typography sx={{ fontSize: "14px", py: 0.5 }}>{filterCompById[0].active ? "فعال" : "غیر فعال"}</Typography>
      ),
    },
    {
      title: "لینک تبلیغ",
      content: (
        <Box sx={{ display: "flex", width: "80px", alignItems: "center" }}>
          <img src={adLink} alt="" />{" "}
          <a href="/" style={{ paddingRight: "0.5rem", textDecoration: "none", color: "#4A76FD" }}>
            کلیک کنید
          </a>{" "}
        </Box>
      ),
    },
  ];
  const list = [
    { title: " اطلاعات شرکت ", link: "detail" },
    { title: "افراد مرتبط ", link: "relatedPeople" },
    { title: "آمار و اطلاعات ", link: "statistics" },
  ];

  return (
    <PageContainer>
      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: "white",
          borderRadius: "1.7rem",
          px: 2,
          py: 2,
          mb: 2,
        }}
      >
        <BackButton back={()=>navigate("/manageAdvertisement/adsCompanies")}/>
        <Grid container sx={{ py: 3 }}>
          <Grid item xs={1}>
            <img
              style={{ height: "80px", width: "80px" }}
              src={
                filterCompById[0].name === "yektanet"
                  ? yaktanet
                  : filterCompById[0].name === "tapcell"
                  ? tapcell
                  : googleAds
              }
              alt=""
            />
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                width: "400px",
                height: "150px",
                display: "flex",
                flexDirection: "column",
                mt: 2,
                gap: 1.5,
              }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>{filterCompById[0].name}</Typography>
              <Typography>{`#${filterCompById[0]._id.slice(0, 5)}`}</Typography>
              <Box sx={{ display: "flex", gap: 20 }}>
                <Typography sx={{ fontWeight: 400, fontSize: "16px" }}>تاریخ ثبت</Typography>
                <Typography sx={{ fontWeight: 500 }}>{`${moment
                  .utc(filterCompById[0].registrationDate)
                  .locale("fa")
                  .format(" YYYY/MM/DD")}`}</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 20 }}>
                <Typography sx={{ fontWeight: 400, fontSize: "16px" }}> وضعیت</Typography>
                <Typography sx={{ fontWeight: 500 }}>{filterCompById[0].active ? "فعال" : "غیرفعال"}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5} sx={{ display: "flex", alignItems: "end", justifyContent: "right", height: "150px" }}>
            <Button
              onClick={handleChangeActive}
              startIcon={filterCompById[0].active ? <img src={stop} alt="" /> : <img src={tick} alt="" />}
              sx={{
                bgcolor: !filterCompById[0].active ? "green" : "#F84E58",
                color: "white",
                px: filterCompById[0].active ? 2.5 : 4,
                height: "39px",

                borderRadius: "6px",
                fontSize: "14px",
                "&:hover": { backgroundColor: !filterCompById[0].active ? "#0cac00" : "#bd020d" },
                transition: "background 1s ",
              }}
            >
              {filterCompById[0].active ? " غیر فعال" : "فعال"}
            </Button>
          </Grid>

          <Grid item xs={12} sx={{ borderBottom: "1px solid #C0C1CE", display: "flex", mt: 5, gap: 5 }}>
            {list.map((i: any, idx: number) => {
              return (
                <Box
                  onClick={() => handleClick(i.link, idx)}
                  key={idx}
                  sx={{
                    borderRadius: "2px",
                    borderBottom: isClicked === idx ? "3px solid blue" : "1px solid transparent",
                    cursor: "pointer",
                    fontSize: "14px",
                    py: 2,
                    transition: "border 0.2s  allow-discrete",

                    fontWeight: isClicked === idx ? 600 : "unset",
                    color: isClicked === idx ? "#4A76FD" : "unset",
                  }}
                >
                  {i.title}
                </Box>
              );
            })}
          </Grid>
        </Grid>
        <Outlet context={[filterCompById] as AdListDetailsContext} />
      </Box>
    </PageContainer>
  );
}
