import { format } from "date-fns";

import { DateObject } from "react-multi-date-picker";

export function formatTimestampToDate(timestamp: Date | number | string) {
  if (!timestamp) {
    return "";
  }

  if (typeof timestamp === "string") {
    return format(new Date(timestamp), "yyyy-MM-dd hh:mm a");
  }

  return format(timestamp, "yyyy-MM-dd hh:mm a");
}

export function formatDateToDay(timestamp: number) {
  return format(timestamp, "MMMM dd");
}

export function formatDateToHour(timestamp: number) {
  return format(timestamp, "hh:mm bbb");
}
export function formatDate(date: Date | number) {
  return format(date, "yyyy/MM/dd");
}
export function formatD(date: Date | number) {
  return format(date, "yyyy-MM-dd");
}

export function formatDate2(date: Date | number) {
  return format(date, "yyyyMMdd");
}

export function formatTime(date: Date | number) {
  return format(date, "HH:mm");
}

export const formatJallali = (t?: number | string | null, options?: { time?: boolean }) => {
  if (!t) {
    return "";
  }
  let date = new Date(t);

  if (options?.time) {
    return new Intl.DateTimeFormat("fa-IR", { dateStyle: "short", timeStyle: "short" }).format(date);
  }

  return new Intl.DateTimeFormat("fa-IR").format(date);
  
};
export function formatDateObjToTime(dateObj: Date) {
  return format(dateObj, 'HH:mm')
 
}