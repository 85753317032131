import { format } from "date-fns";
import { GAME_STATUS } from "types/game";
import { formatD } from "utils/date";

export const SWR_KEYS = {
  USERS: (role: string) => `/users?role=${role}`,
  USER_GAMES: (id?: string) => id && `/games/${id}`,
  USER_TICKETS: (id?: string) => id && `tickets?userId=${id}`,
  TICKET: (id?: string) => id && `tickets?ticketId=${id}`,
  GAMES: (status: GAME_STATUS, searchText: string, page: number) =>
    status === "draft"
      ? `/game/version?publishAfter=${formatD(new Date())}`
      : `/game/version?publishStatus=${status}&start=${(page - 1) * 10}&searchText=${searchText}`,
  LEAGUES: (tabValue: "active" | "lastleagues" | "upcoming") =>
    tabValue === "active"
      ? `/leagues?start=${format(new Date(), "yyyy-MM-dd")}&active=true&filterBy=percent`
      : tabValue === "lastleagues"
      ? `/leagues?start=${format(new Date(), "yyyy-MM-dd")}&limit=10&filterBy=past`
      : `/leagues?active=false&start=${format(new Date(), "yyyy-MM-dd")}&filterBy=now`,
};

//SWR_KEYS.GAMES(GAME_STATUS.DONE, searchValue)
