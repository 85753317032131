import { useEffect } from "react";

import { useAppDispatch } from "store";
import { getMeThunk } from "reducers/adminReducers";

export default function GetUser({ children }: { children: any }) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMeThunk());
  }, [dispatch]);

  return children;
}
