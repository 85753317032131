import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { MyTypography } from "pages/games/components/GamesListItem";

export default function RadioButtonsGroup({
  lable,
  onSetValue,
  name,
}: {
  onSetValue: (e: any, a: string) => void;
  lable: { accept: string; reject: string };
  name: string;
}) {
  const [value, setValue] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    onSetValue((event.target as HTMLInputElement).value, name);
  };

  return (
    <FormControl>
      <RadioGroup row name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
        <FormControlLabel
          value="accept"
          control={<Radio color="success" />}
          label={<MyTypography>{lable.accept}</MyTypography>}
          color="success"
          sx={{
            "& .MuiButtonBase-root": { color: "#04BD0C" },
            "& .MuiSvgIcon-root": {
              fontSize: 22,
            },
          }}
        />
        <FormControlLabel
          value="reject"
          control={<Radio color="error" />}
          label={<MyTypography>{lable.reject}</MyTypography>}
          sx={{
            "& .MuiButtonBase-root": { color: "#F84E58" },
            "& .MuiSvgIcon-root": {
              fontSize: 22,
            },
          }}
        />
      </RadioGroup>
    </FormControl>
  );
}
