import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, FormHelperText, Grid } from "@mui/material";
import { MyTypography } from "pages/games/components/GamesListItem";
import { createTheme } from "@mui/material/styles";
import { purple } from "@mui/material/colors";

export type checkedType = {
  accept: boolean;
  reject: boolean;
};
export default function ControlledCheckbox() {
  const [checked, setChecked] = React.useState<checkedType>({ accept: false, reject: false });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked((prev) => ({ ...prev, [event?.target.name as keyof checkedType]: event?.target?.checked }));
  };

  return (
    <Grid>
      <FormHelperText sx={{ fontSize: "12px", color: "#676872" }}>
        هر مورد را پس از چک کردن و بررسی تیک بزنید
      </FormHelperText>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked.accept}
            name="accept"
            onChange={handleChange}
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 22 },
              color: "#04BD0C",
              "&.Mui-checked": {
                color: "#04BD0C",
              },
            }}
          />
        }
        label={<MyTypography>اسکن فایل های ارسال شده بازی انجام شد.</MyTypography>}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checked.reject}
            name="reject"
            onChange={handleChange}
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 22 },
              color: "#04BD0C",
              "&.Mui-checked": {
                color: "#04BD0C",
              },
            }}
          />
        }
        label={<MyTypography>پیش‌نمایش و بررسی بازی روی حالت ریسپانسیو (موبایل) چک شد.</MyTypography>}
      />
    </Grid>
  );
}
