import { DataItem } from "components/share/barChart";
import StateTable from "./table";
import GeneralStat from "./general";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import CustomRangeDate from "pages/game/components/customRangeDate";
import useSWR from "swr";
import TimeFilterCharts from "pages/manageAds/AdsStatistics/timefilterCharts";

const data: DataItem[] = [
  { value: "01/06/24", displayCount: 75000, clickCount: 13000, income: 26000000 },
  { value: "01/06/25", displayCount: 65000, clickCount: 11000, income: 22000000 },
  { value: "01/06/26", displayCount: 40000, clickCount: 7000, income: 14000000 },
  { value: "01/06/27", displayCount: 50000, clickCount: 9000, income: 18000000 },
  { value: "01/06/28", displayCount: 80000, clickCount: 14000, income: 28000000 },
  { value: "01/06/29", displayCount: 80000, clickCount: 14000, income: 28000000 },
  { value: "01/06/30", displayCount: 85000, clickCount: 15000, income: 30000000 },
  { value: "01/06/31", displayCount: 80000, clickCount: 14000, income: 28000000 },
];

export default function Statistics() {
  const { t } = useTranslation();
  const [startAndEndDate, setStartAndEndDate] = useState(["1403-06-01", "1403-06-05"]);
  const [filterBy, setFilterBy] = useState<"gameFilter" | "timeFilter" | "advertisingCompanyFilter">("timeFilter");
  const { id } = useParams();
  const { data: statistics } = useSWR(
    `/ads/statistics/company/${id}?startDate=${startAndEndDate[0]}&endDate=${startAndEndDate[1]}`
  );

  function callSetStartAndEndDate(data: any) {
    setStartAndEndDate(data);
  }

  const convertedData: DataItem[] =
    statistics?.statistics?.diagram[filterBy]?.clicks?.map((click: any, index: number) => ({
      value:
        filterBy === "advertisingCompanyFilter"
          ? t(`${click.name}`)
          : filterBy === "gameFilter"
          ? click.name
          : click.date,
      displayCount: statistics?.statistics?.diagram?.[filterBy].views[index].value,
      clickCount: statistics?.statistics?.diagram?.[filterBy].clicks[index].value,
      income: statistics?.statistics?.diagram?.[filterBy].incomes[index].value,
    })) || data;

  const getIntroOfPage = (label: string) => {
    console.log(label);
    return label;
    // ... your getIntroOfPage logic here
  };

  return (
    <>
      <GeneralStat totlaData={statistics?.statistics?.total} />
      <StateTable />
      <Box sx={{ display: "flex", gap: 2, borderTop: "1px solid #C0C1CE", width: "100%", py: 4 }}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ ml: 5, mr: 0.5, mt: 2 }} align="left">
            نمودار بر اساس :
          </Typography>
          <Select
            value={filterBy}
            onChange={(event: any) => setFilterBy(event.target.value)}
            placeholder=""
            sx={{ bgcolor: "white", width: "150px", height: "50px", borderColor: "white" }}
          >
            {["gameFilter", "timeFilter", "advertisingCompanyFilter"].map((item: string) => (
              <MenuItem key={item} value={item}>
                {t(`${item}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <CustomRangeDate changeDate={callSetStartAndEndDate} />
      </Box>

      <Box sx={{ maxWidth: "1000px", margin: "0px auto", padding: "5px 20px" }}>
        <TimeFilterCharts xAxisTitle={t(`${filterBy}`)} data={convertedData} />
      </Box>
    </>
  );
}
