import { Avatar, Box, Grid, Pagination, Typography } from "@mui/material";
import Checkbox from "components/checkbox/Chechbox";
import PageContainer from "components/PageContainer";
import Table from "components/table/Table";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DeleteButton from "shared/Delete/Button";
import useSWR from "swr";
import { formatJallali } from "utils/date";

const BannerList = ({ type }: { type: "slider" | "banner" }) => {
  const { data: sliders, mutate } = useSWR<{ leagueSliders: any[]; count: number }>("/leagueSliders");

  const leagues =
    sliders?.leagueSliders.map((i: any) => ({ ...i, id: i._id })).filter((i: any) => i.type === type) || [];
  const [page, setPage] = React.useState(1);
  const [fullCheckbox, setFullCheckbox] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<string[]>();
  const { t } = useTranslation();
  const counter = sliders ? Math.floor(sliders?.count / 10) + 1 : 1;
  const slidersId = leagues.map((i: any) => i.id) || [];
  const onSelectionModelChange = (ids: string[]) => {
    setSelectedItem(ids);
  };
  function handleChangeInput(e: any) {
    console.log(e);
  }
  const columns = useMemo(() => {
    return [
      {
        filed: "leaguesNumber",
        headerName: "LeagueNumber",
        flex: 5,
        renderCell: ({ row }: any) => {
          return (
            <div style={{ display: "flex" }}>
              <Typography>{row?.league?.leaguesNumber}</Typography>
              <Avatar
                sx={{ width: "100px", height: "30px", borderRadius: "12px", px: 2 }}
                src={row?.league.bannerImage?.url || row?.image?.url}
              />
              <Typography sx={{ fontSize: "12px", pt: 1 }}>{row?.league.title}</Typography>
            </div>
          );
        },
      },
      {
        field: "startDate",
        headerName: t("StartDate"),
        flex: 1,
        renderCell({ row }: any) {
          return (
            <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{`${formatJallali(row?.endDate)}`}</Typography>
          );
        },
      },
      {
        field: "endDate",
        headerName: t("endDate"),
        flex: 1,
        renderCell({ row }: any) {
          return (
            <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{`${formatJallali(row?.endDate)}`}</Typography>
          );
        },
      },
      {
        field: "edit",
        headerName: "ویرایش ",
        flex: 0.5,
        renderCell({ row }: any) {
          return (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <DeleteButton type="icon" url={[`/leagueSlider/${row?._id}`]} onDone={() => mutate()} />
            </Box>
          );
        },
      },
      {
        field: "sortOrder",
        headerName: "ترتیب ",
        flex: 0.5,
        renderCell({ row }: any) {
          return (
            <Box>
              {type==="slider" && (
                <input
                  name="order"
                  type="number"
                  onChange={handleChangeInput}
                  style={{
                    width: "40px",
                    height: "30px",
                    backgroundColor: "#F5F5F9",
                    borderRadius: "3px",
                    cursor: "pointer",
                    border: "none",
                  }}
                />
              )}
            </Box>
          );
        },
      },
    ];
  }, [sliders]);

  useEffect(() => {
    fullCheckbox ? setSelectedItem(slidersId) : setSelectedItem([]);
  }, [fullCheckbox]);

  return (
    <PageContainer noPadding={true}>
      <div>
        <Checkbox sx={{ px: 1 }} checked={fullCheckbox} onClick={() => setFullCheckbox(!fullCheckbox)} />
      
        <Table
          rows={leagues}
          columns={columns}
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={selectedItem}
          // checkboxSelection
        />
        <Pagination onChange={(event: any, numberValue: any) => setPage(numberValue)} page={page} count={counter} />
       
      </div>
      </PageContainer >
   
  );
};

export default BannerList;
