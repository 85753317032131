import Preview from "./Preview";
import { ICONS } from "assets/icons";
import { upload } from "utils/upload";
import { useDropzone } from "react-dropzone";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Theme, Typography, useTheme, FormHelperText, Button, CircularProgress, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { deletFile } from "api/upload";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const defaultStyles = (theme: Theme) => ({
  width: "45px",
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  justifyContent: "center",
  bgcolor: "#474859",
  border: "1px solid transparent",
  borderRadius: "8px",
  cursor: "pointer",
  px: 10,
  py: 0.65,
});

interface UPLOADED_FILE {
  //image?: string;
  name?: string;
  path?: string;
  size?: string;
  type?: string;
}
//not :defaultPicture is imageurlType and for the firstTime preview image ,defualtfileId used for deleting file and defaultValue is blob and in this component change to url for preview each time uploaded file

const Uploader = ({
  defaultPicture,
  flow,
  type,
  onSaveFieldValue,
  fieldTitle,
  helperText,
  setFieldTouched,
  direction,
  defaultValue,
  defaultFileId,
  maxFileSize,
  onChange,
  acceptWidth,
  acceptHeight,
  imagewidth,
  imageHeight,
}: {
  imagewidth?: number;
  imageHeight?: number;
  defaultPicture?: any;
  flow?: "auth" | "authEdit";
  type: "gif" | "image" | "game";
  fieldTitle: string;
  helperText?: string;
  direction?: "vertical" | "horizontal" | "Square";
  defaultValue?: any;
  defaultFileId?: any;
  maxFileSize?: number;
  acceptWidth?: number;
  acceptHeight?: number;
  setFieldTouched?: () => void;
  onSaveFieldValue?: (value: any) => void;
  onChange: (event: string, a: string | boolean, b: any) => void;
}) => {
  const [file, setFile] = useState<UPLOADED_FILE | null>();
  const [previewUrl, setPreviewUrl] = useState<any>();
  const [curretFileId, setCurretFileId] = useState<any>();
  const [isLoading, setIsloading] = useState(false);
  const { t } = useTranslation();
  const isImage = type === "image";

  const imageStyles = (theme: Theme) => ({
    width: "45px",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    justifyContent: "center",
    bgcolor: "#474859",
    border: "1px solid transparent",
    borderRadius: "6px",
    cursor: "pointer",
    px: 6,
    py: 0.65,
    my: vertical ? 10 : "unset",
  });

  const onDrop = useCallback(
    async (acceptedFiles: any[]) => {
      if (isImage && acceptedFiles?.length) {
        const imageFile = acceptedFiles[0];
        const image = new Image();

        image.src = URL.createObjectURL(imageFile);
        image.onload = (e) => {
          const { width, height } = image;
          console.log({ width, height });
        };
      }
      setIsloading(true);
      try {
        const uploadedFile = acceptedFiles[0];

        const response = await upload({ contentType: type === "gif" ? "image" : type, file: uploadedFile });

        if (isImage || type === "gif") {
          const imageUrl = URL.createObjectURL(uploadedFile);
          setPreviewUrl(imageUrl);
        }
       

        setFile(acceptedFiles[0]);
        toast.success("با موفقیت آپلود شد");
        setCurretFileId(response?.fileId);

        onChange && onChange(fieldTitle, response?.fileId, uploadedFile);
        setIsloading(false);
      } catch (error: any) {
        console.log("uploadError", error);
        toast.error(error?.message || "مشکلی رخ داده است");
        setIsloading(false);
      }
    },
    [fieldTitle, isImage, onChange, type]
  );

  useEffect(() => {
    defaultValue && setFile(defaultValue);
    defaultFileId && setCurretFileId(defaultFileId);

    if (defaultValue && (isImage || type === "gif")) {
      const imageUrl = URL.createObjectURL(defaultValue);
      setPreviewUrl(imageUrl);
    }
  }, [defaultFileId, defaultValue, isImage, type]);

  const theme = useTheme();
  const mode = theme.palette.mode;
  const isGame = type === "game";
  const isGif = type === "gif";
  const vertical = direction === "vertical";
  const horizontal = direction === "horizontal";
  const Square = direction === "Square";
  const styles = isImage ? imageStyles(theme) : defaultStyles(theme);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, maxSize: maxFileSize });

  const removefile = async () => {
    setIsloading(true);
    try {
      await deletFile(curretFileId);
      toast.success("فایل با موفقیت حذف شد ");

      onChange && onChange(fieldTitle, false, null);
      setFile(null);
      setIsloading(false);
    } catch (error) {
      toast.error("مشکلی در حذف فایل رخ داده است");
      setIsloading(false);
    }
  };

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            px: flow !== "auth" ? 3 : "unset",
            pt: isImage || isGif || flow !== "auth" ? 6 : "unset",
            pb: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {!isLoading && file && file?.name?.includes("pdf") && (
        <Box sx={{ borderRadius: "8px", backgroundColor: "#F5F5F9", px: 5, py: 1, my: 2 }}>
          {file?.name}
          <IconButton sx={{ mb: 0.5 }} onClick={removefile}>
            <DeleteForeverOutlinedIcon fontSize="large" />
          </IconButton>
        </Box>
      )}
      {flow && flow === "auth" && !isLoading && !file?.name?.includes("pdf") ? (
        <>
          {" "}
          {file ? (
            <Box>
              <Preview
                key={file?.name}
                {...{ file, previewUrl, type, direction }}
                loading={isLoading}
                onRemove={removefile}
              />
            </Box>
          ) : (
            <Box {...getRootProps()}>
              <input {...getInputProps()} />

              <Button
                variant="contained"
                sx={{ bgcolor: "#474859", color: "white", px: 4, my: 2 }}
                endIcon={<img src={ICONS.UPLOAD} />}
              >
                آپلود
              </Button>
            </Box>
          )}
        </>
      ) : !isLoading && !file?.name?.includes("pdf") ? (
        <Box
          sx={{
            border: "2px solid #E1E2E6",
            borderRadius: "10px",
            py: file && isGame ? 4 : !file && (isGame || isImage || isGif) ? 4 : "unset",
            my: 4,
            px: file && isGame ? 5 : isGame ? 5 : "unset",
            textAlign: "center",
            mx: isImage ? 1 : 4,

            width: isGame ? "unset" : isGif ? "280px" : vertical ? "150px" : horizontal ? "270px" : "100%",
            height: vertical ? "250px" : horizontal ? "170px" : Square ? "170px" : isGif ? "270px" : "unset",
          }}
        >
          {!file && (
            <img
              src={ICONS.DRAGANDDRAG}
              alt="drag"
              width={isGame ? "100px" : "60px"}
              height={isGame ? "100px" : "60px"}
            />
          )}
          {isGame && !file ? (
            <Typography sx={{ my: 2 }}>
              همه‌ی فایل‌های بازی را آپلود کنید. مطمئن شوید که "index.html" را به عنوان نقطه ورود برای بازی خود دارید.
              می‌توانید پوشه‌ها را بکشید و اینجا رها کنید یا از دکمه‌ی آپلود استفاده کنید.
            </Typography>
          ) : isGif && !file ? (
            <Typography sx={{ my: 2 }}>یک پیش‌نمایش چند ثانیه‌ای از بازی خود را با فرمت gif آپلود کنید.</Typography>
          ) : (
            <></>
          )}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {file ? (
              <Box>
                <Preview
                  key={file?.name}
                  {...{ file, previewUrl, type, direction }}
                  loading={isLoading}
                  onRemove={removefile}
                />
              </Box>
            ) : (
              <Box {...getRootProps()}>
                <input {...getInputProps()} />

                <Box
                  sx={{
                    ...styles,
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      mt: 0.25,
                    }}
                  >
                    آپلود
                  </Typography>
                  <img src={ICONS.UPLOAD} alt="upload" />
                </Box>
              </Box>
            )}
          </Box>
          {helperText && <FormHelperText sx={{ marginTop: isImage ? "0.6rem" : 0 }}>{helperText}</FormHelperText>}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default Uploader;
