import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from "recharts";
import { Paper, Typography, Box } from "@mui/material";

export type DataItem = {
  value: string;
  displayCount: number;
  clickCount: number;
  income: number;
};

interface CustomBarChartProps {
  data: DataItem[];
  title: string;
  barColor: string;
  tooltipColor: string[];
  yAxisTitle: string;
  xAxisTitle: string;
  yAxisBarKey: string;
}

const CustomBarChart: React.FC<CustomBarChartProps> = ({
  data,
  title,
  barColor,
  tooltipColor,
  yAxisTitle,
  xAxisTitle,
  yAxisBarKey,
}) => {
  const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Paper
          elevation={3}
          sx={{
            bgcolor: "white",
            color: "white",
            border: "none",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            // height: "150px",
            width: "234px",
          }}
        >
          <Box
            sx={{
              py: 1,
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              bgcolor: "#676872",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "12px", color: "white" }}>{label}</Typography>
          </Box>

          {payload.map((pld: any, index: number) => (
            <Box sx={{ p: 1 }}>
              <Box sx={{ p: 1, display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ borderRadius: "50%", width: "12px", height: "12px", bgcolor: tooltipColor[0] }}></Box>
                  <Typography sx={{ px: 1, fontSize: "12px" }}>تعداد تبلیغ نمایش </Typography>
                </Box>
                <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>{pld.payload.displayCount}</Typography>
              </Box>
              <Box sx={{ p: 1, display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ borderRadius: "50%", width: "12px", height: "12px", bgcolor: tooltipColor[1] }}></Box>
                  <Typography sx={{ px: 1, fontSize: "12px" }}> تعداد کلیک تبلیغ: </Typography>
                </Box>
                <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>{pld.payload.clickCount}</Typography>
              </Box>
              <Box sx={{ p: 1, display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ borderRadius: "50%", width: "12px", height: "12px", bgcolor: tooltipColor[2] }}></Box>
                  <Typography sx={{ px: 1, fontSize: "12px" }}> مبلغ درامد: </Typography>
                </Box>
                <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>{`  ${pld.payload.income} ریال   `}</Typography>
              </Box>
            </Box>
          ))}
        </Paper>
      );
    }
    return null;
  };
  return (
    <Box style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <BarChart data={data} margin={{ right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" vertical={true} />
          <XAxis dataKey="value" tickLine={false} axisLine={true}>
            <Label value={xAxisTitle} position="insideRight" angle={0} style={{ textAnchor: "middle" }} />
          </XAxis>
          <YAxis tickLine={false} axisLine={false}>
            <Label value={yAxisTitle} angle={-90} position="insideLeft" style={{ textAnchor: "middle" }} />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" height={36} />
          <Bar barSize={20} dataKey={yAxisBarKey} name={title} fill={barColor} radius={[4, 4, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
export default CustomBarChart;
