import { FormatAlignJustify } from "@mui/icons-material";
import { Avatar, Typography, Box, Grid } from "@mui/material";
import { IGameType } from "api/game";
import acceptDoc from "assets/acceptdocument.svg";
import rejectdoc from "assets/rejectdocument.svg";

//this component should be correct with data but now i don't kno any thing about datatype
export default function History({ game }: { game: IGameType }) {
  // const info = [
  //   {
  //     src: "https://mui.com/material-ui/react-avatar/static/images/avatar/1.jpg",
  //     name: "سهیل مشکاتی",
  //     action: "reject",
  //     file: acceptDoc,
  //   },
  //   { src: "/static/images/avatar/3.jpg", name: "سارا اشکانی", action: "accept", file: rejectdoc },
  //   { src: "/static/images/avatar/2.jpg", name: "سهیل مشکاتی", action: "accept", file: rejectdoc },
  // ];
  return (
    <Box>
      {game?.versions.map((i: any) => (
        <Box>
          {i.publishStatus === "done" || i.publishStatus === "rejected" ? (
            <Box sx={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #F5F5F9" }}>
              <Grid container>
                <Grid item>
                  {" "}
                  <Avatar alt="Remy Sharp" src={"/static/images/avatar/3.jpg"} sx={{ height: "35px" }} />
                </Grid>
                <Grid item sx={{ pt: 1, px: 0.5 }}>
                  <Typography sx={{ fontSize: "12px", borderRight: "1px solid #F5F5F9" }}>نام ادمین</Typography>
                </Grid>
                <Grid item sx={{ pt: 1, px: 0.5 }}>
                  <Typography sx={{ opacity: "50%", fontSize: "12px" }}>
                    {i.publishStatus === "done"
                      ? `ورژن ${i.version} بازی را تایید کرد`
                      : ` ورژن ${i.version}  بازی را رد کرد`}
                  </Typography>
                </Grid>
              </Grid>

              <Box>
                <img src={i.publishStatus === "done" ? rejectdoc : acceptDoc} alt="2" />
              </Box>
            </Box>
          ) : (
            <Typography sx={{ textAlign: "center" }}> این ورژن هنوز تایید یا رد نشده است </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
}
