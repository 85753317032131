
import { Avatar, Box, Typography, Card, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDashboardScrollBar } from "components/share/useStyle";

const List = ({ data, type }: { data: any; type: "popularGames" | "activePublishers" | "activeUsers" }) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState<number>(1);
  const [filterData, setFilterData] = useState(data.slice(0, 6));
  const [hasMore, setHasMore] = useState(true);
  const classes = useDashboardScrollBar();
  const fetchMoreData = () => {
    setTimeout(() => setCounter(counter + 1), 1500);
    setCounter(counter + 1);
  };
  useEffect(() => {
    setFilterData(data.slice(0, 6 * counter));
    if (data.length < 6 * counter) {
      setHasMore(false);
    }
  }, [counter, data]);

  useEffect(() => {
    if (data.length > 5) {
      setHasMore(true);
    }
  }, [data.length]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "26%" }}>
      <Card
        sx={{
          borderTop: "1px solid grey",
          width: "100%",
          gap: "2rem",
          minHeight: "600px",
        }}
      >
        <Box
          sx={{
            bgcolor: "#676872",
            height: "40px",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            width: "100%",
            pb: 5,
            color: "white",
            textAlign: "center",
            pt: 2,
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          {type === "activeUsers"
            ? t("activeUsers")
            : type === "activePublishers"
            ? t("activePublishers")
            : type === "popularGames"
            ? t("popularGames")
            : ""}
          <InfiniteScroll
            className={classes.root}
            style={{
              overflowY: "scroll",
              marginTop: "1.6rem",
              height: "500px",
              overflowX: "hidden",
            }}
            dataLength={filterData.length}
            next={fetchMoreData}
            hasMore={hasMore}
            // endMessage={
            //   <Typography sx={{ my: 2, display: "flex", justifyContent: "center", fontSize: "12px" }}>
            //     فعلا مورد دیگری برای نمایش وجود ندارد
            //   </Typography>
            // }
            loader={<CircularProgress />}
          >
            {filterData.map((item: any, index: number) => (
              <Box
                sx={{
                  pt: 2,
                  px: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <Typography>{`${index + 1}.`}</Typography>
                  <Avatar src={item.image?.url || item.image} />
                  <Typography>{item.name}</Typography>
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Card>

    </Box>
  );
};

export default List;
