import { ICONS } from "assets/icons";
import { Img, Table } from "components";
import { ROUTES } from "constants/routes";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";

const GamesList = ({ games }: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      field: "game",
      headerName: t("game"),
      flex: 1,
      renderCell({ row }: any) {
        return (
          <Box>
            <Img src={row.coverImage || ICONS.GAME_IMAGE_BACKUP} alt={row.title} />
            <Typography>{row.title}</Typography>
          </Box>
        );
      },
    },
    {
      field: "createdAt",
      headerName: t("uploadDate"),
      flex: 1,
    },
    {
      field: "publishDate",
      headerName: t("publishDate"),
      flex: 1,
    },
    {
      field: "playerCount",
      headerName: t("playerCount"),
      flex: 1,
    },
    {
      field: "playCount",
      headerName: t("playCount"),
      flex: 1,
    },
    {
      field: "rate",
      headerName: t("rate"),
      flex: 1,
    },

    {
      field: "actions",
      headerName: "",
      flex: 1,
      renderCell({ row }: any) {
        return (
          <Box>
            {[
              { actionName: "show", actionFunc: onShowActionClick },
              { actionName: "message", actionFunc: onMessageActionClick },
              { actionName: "trash", actionFunc: onTrashActionClick },
            ].map(({ actionName, actionFunc }) => (
              <Tooltip key={actionName} title={t(actionName)}>
                <IconButton onClick={actionFunc(row._id)}>
                  <Img src={ICONS[actionName.toUpperCase()]} alt={actionName} />
                </IconButton>
              </Tooltip>
            ))}
          </Box>
        );
      },
    },
  ];

  const onShowActionClick = (gameId: string) => () => {
    navigate(ROUTES.GAME(gameId));
  };

  const onMessageActionClick = () => () => {
    navigate(ROUTES.USER_TICKETS(id));
  };

  const onTrashActionClick = (gameId: string) => () => {
    // todo@sareh: ask and add api
  };

  return (
    <Grid>
      <Table columns={columns} rows={games} getRowId={(row: any) => row._id} />
    </Grid>
  );
};

export default GamesList;
