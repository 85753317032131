import { delete_, get, post, put } from "api";

export type ITagType = {
  _id: string;
  title: string;
  description: string;
  usageCount: number;
  __v: number;
};


export const createTag = (data: { title: string; description: string }) => {
  return post("/game/tag",data);
};

