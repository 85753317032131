import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  root: {
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#F5F5F9",
    },
    "&::-webkit-scrollbar-track:hover": {
      background: "white",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#bcd3ee",
      borderRadius: "12px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#4A76FD",
      cursor: "pointer",
    },
  },
});

export const useDashboardScrollBar = makeStyles({
  root: {
    "&::-webkit-scrollbar": {
      width: "9px",
    },
    "&::-webkit-scrollbar-track": {
      background: "white",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#e0e0e0 ",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#676872",
      cursor: "pointer",
    },
  },
});
