import { Box, Typography } from "@mui/material";
import notVerified from "assets/Empty sign.svg";
export default function NotSignYet() {
  return (
    <Box
      sx={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", mb: 20, mt: 5 }}
    >
      <img alt="empty" src={notVerified} />
      <Typography sx={{ color: "#333337", py: 5 }}>احراز هویت کاربر هنوز انجام نشده است</Typography>
    </Box>
  );
}
