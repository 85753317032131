import { delete_, get, post, put } from "api";
import { IUserType } from "./user";
import { getUploadLinks, uploadDone, uploadFile } from "./fileUpload";

const baseUrl = "/tickets";
export type ITicketType = {
  category: any;
  _id: string;
  id: string;
  status: "new" | "replied" | "closed" | "inProgress";
  user: IUserType;
  ticketId: number;
  title: string;
  contents: any[];
  state: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
export type ITicketCategoryType = {
  _id: string;
  title: string;
  userRole: {
    _id: string;
    name: string;
  };
  createdAt: string;
  updatedAt: string;
  __v: number;
};

export const replyTicket = async (id: string, content: string, images?: string[]) => {
  // let link = undefined;

  // if (image) {
  //   link = await getUploadLinks('image');
  //   console.log('link', link)
  //   await uploadFile(link?.uploadLink, image, 'image')
  //   await uploadDone(link?.fileId)
  // }

  return put(`/tickets/${id}`, { content: content, images: images });
};
export const createCategory = (data: { title: string; userRoleId: string }) => {
  return post(`/tickets/category`, data);
};
export const createCategoryUser = (data: { title: string; userRoleId: string }) => {
  return post(`/tickets/category`, { ...data, userRoleId: "6540377e8ff25a22eeffb425" });
};
export const createCategoryGuest = (data: { title: string; userRoleId: string }) => {
  return post(`/tickets/category`, { ...data, userRoleId: "6540377e8ff25a22eeffb423" });
};
export const deleteTicket = (id: string) => {
  return delete_(`/ticket/${id}`);
};
export const getUserTickets = ({ id }: { id?: string }) => {
  return get<any>(`/tickets?userId=${id}`);
};
