import { ICONS } from "assets/icons";
import { Box, FormHelperText } from "@mui/material";
import MuiSelect, {SelectProps as MuiSelectProps} from '@mui/material/Select';

interface SelectProps /*extends MuiSelectProps*/ {
  helperText?: string;
}

const Select = ({ helperText, ...rest }: any) => {
  return (
    <Box>
      <MuiSelect
        sx={{bgcolor:"red"}}
        IconComponent={(props) => (
          <img
            alt="open"
            style={{ width: "2.1rem", marginLeft: "0.7rem", cursor: "pointer" }}
            src={ICONS.SELECT_ICON}
            {...props}
          />
        )}
        {...rest}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </Box>
  );
};

export default Select;
