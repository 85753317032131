import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "components";
import { useTranslation } from "react-i18next";
import { Grid, Box, Chip, styled, Button, Dialog, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import PageContainer from "components/PageContainer";
import ClearIcon from "@mui/icons-material/Clear";
import { rejectGame, verifyGame } from "api/game";
import CustomizedAccordions from "./components/accordions";
import checked from "assets/Check.svg";
import close from "assets/Close_SM.svg";
import GamePreview from "./components/GamePreview";
import { ICONS } from "assets/icons";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RejectReason from "./components/rejectReason";
import PlayGame from "./components/playGame";
import CustomDatePicker from "./components/customDatePicker";
import useSWR from "swr";

const StyledTypography = styled(Typography)({
  color: "white",
  fontSize: "11px",
});
const InProgressTYpography = styled(Typography)({ fontSize: "12px", fontWeight: 600 });
const chipInfo = [
  {
    name: "detail",
    label: {
      accept: <StyledTypography> تایید جزئیات</StyledTypography>,
      inProgress: <InProgressTYpography> تایید جزئیات</InProgressTYpography>,
      reject: <StyledTypography> تایید جزئیات</StyledTypography>,
    },
    icon: { accept: <img src={checked} alt="1" />, reject: <img src={close} alt="1" /> },
    color: { accept: "#04BD0C", inProgress: "#F5F5F9", reject: "#F84E58" },
  },
  {
    name: "link",
    label: {
      accept: <StyledTypography> تایید لینک ها</StyledTypography>,
      inProgress: <InProgressTYpography> تایید لینک ها</InProgressTYpography>,
      reject: <StyledTypography> تایید لینک ها</StyledTypography>,
    },
    icon: { accept: <img src={checked} alt="1" />, reject: <img src={close} alt="1" /> },
    color: { accept: "#04BD0C", inProgress: "#F5F5F9", reject: "#F84E58" },
  },
  {
    name: "image",
    label: {
      accept: <StyledTypography> تایید تصاویر</StyledTypography>,
      inProgress: <InProgressTYpography> تایید تصاویر</InProgressTYpography>,
      reject: <StyledTypography> تایید تصاویر</StyledTypography>,
    },
    icon: { accept: <img src={checked} alt="1" />, reject: <img src={close} alt="1" /> },
    color: { accept: "#04BD0C", inProgress: "#F5F5F9", reject: "#F84E58" },
  },
  {
    name: "advertisement",
    label: {
      accept: <StyledTypography> تبلیغات </StyledTypography>,
      inProgress: <InProgressTYpography> تبلیغات </InProgressTYpography>,
      reject: <StyledTypography> تبلیغات </StyledTypography>,
    },
    icon: { accept: <img src={checked} alt="1" />, reject: <img src={close} alt="1" /> },
    color: { accept: "#04BD0C", inProgress: "#F5F5F9", reject: "#F84E58" },
  },
  {
    name: "security",
    label: {
      accept: <StyledTypography> کیفیت و امنیت</StyledTypography>,
      inProgress: <InProgressTYpography> کیفیت و امنیت </InProgressTYpography>,
      reject: <StyledTypography> کیفیت و امنیت</StyledTypography>,
    },
    icon: { accept: <img src={checked} alt="1" />, reject: <img src={close} alt="1" /> },
    color: { accept: "#04BD0C", inProgress: "#F5F5F9", reject: "#F84E58" },
  },
  {
    name: "preview",
    label: {
      accept: <StyledTypography> پیش نمایش</StyledTypography>,
      inProgress: <InProgressTYpography> پیش نمایش</InProgressTYpography>,
      reject: <StyledTypography> پیش نمایش</StyledTypography>,
    },
    icon: { accept: <img src={checked} alt="1" />, reject: <img src={close} alt="1" /> },
    color: { accept: "#04BD0C", inProgress: "#F5F5F9", reject: "#F84E58" },
  },
  {
    name: "finall",
    label: {
      accept: <StyledTypography> تایید نهایی</StyledTypography>,
      inProgress: <InProgressTYpography> تایید نهایی</InProgressTYpography>,
      reject: <StyledTypography> تایید نهایی</StyledTypography>,
    },
    icon: { accept: <img src={checked} alt="1" />, reject: <img src={close} alt="1" /> },
    color: { accept: "#04BD0C", inProgress: "#F5F5F9", reject: "#F84E58" },
  },
];
export type ICheckList = {
  detail: boolean | null;
  link: boolean | null;
  image: boolean | null;
  preview: boolean | null;
  advertisement: boolean | null;
  finall: boolean | null;
  security: boolean | null;
};

const Game = () => {
  const { t } = useTranslation();
  const { gameId, versionId } = useParams();

  const [value, setValue] = useState<ICheckList>({
    detail: null,
    link: null,
    image: null,
    advertisement: null,
    preview: null,
    finall: null,
    security: null,
  });

  const { data } = useSWR(`games/${gameId}`);
  const selectedGame = data?.game;
  const { data: gameVersion } = useSWR(`game/version/${versionId}`);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [checked, setChecked] = useState(false);
  const [reject, setReject] = useState(false);
  const [gamePreview, setGamePreview] = useState(false);
  const [date, setDate] = useState<any>("");
  const navigate = useNavigate();

  const handlerejectGame = async (reason: string, title?: string) => {
    try {
      if (gameId && versionId)
        await rejectGame(gameId, versionId, {
          rejectReasons: [
            {
              title: "we don't have any title field ",
              description: reason,
            },
          ],
        });
      toast.success("بازی با موفقیت رد شد ");
    } catch (err: any) {
      toast.error(err.message.data.error || "مشکلی رخ داده است");
      console.log("errror", err);
    } finally {
      setOpenReject(false);
    }
  };
  const handleVerifyGame = async () => {
    try {
      if (gameId && versionId) await verifyGame(gameId, versionId, { publishDate: date });
      toast.success("بازی با موفقیت تایید شد ");
      navigate("/games");
    } catch (err: any) {
      toast.error(err.message || "مشکلی رخ  داده است");
      console.log(err);
    } finally {
      setOpenDatePicker(false);
    }
  };

  useEffect(() => {
    let allTrue = true;
    Object.values({ ...value, finall: true }).forEach((value: any) => {
      if (!value) {
        allTrue = false;
      }
    });

    if (allTrue) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [value]);

  useEffect(() => {
    let notNullatAll: boolean = true;
    Object.values({ ...value, finall: true }).forEach((value: any) => {
      if (value === null) {
        notNullatAll = false;
      }
    });

    if (notNullatAll && !checked) {
      setReject(true);
    } else {
      setReject(false);
    }
  }, [checked, value]);

  return (
    <PageContainer>
      <Grid container sx={{ backgroundColor: "white", borderRadius: "12px", width: "100%", mt: 1, mb: 5 }}>
        <Grid item xs={12} sx={{ pt: 2 }}>
          <BackButton />
        </Grid>

        <Grid item xs={5} sx={{ p: 2 }}>
          <Box sx={{ border: "1px solid #F5F5F9", borderRadius: "12px", mb: 1 }}>
            <Typography
              sx={{ p: 2, fontWeight: 600 }}
            >{`${selectedGame?.title} _ نسخه ${gameVersion?.gameVersion?.version}`}</Typography>
          </Box>
          <CustomizedAccordions
            onSetValue={(e: any, a: string) =>
              setValue((prev) => ({
                ...prev,
                [a as keyof ICheckList]: e == "accept" ? true : e == "reject" ? false : null,
              }))
            }
            game={selectedGame}
            gameVersion={gameVersion?.gameVersion}
          />
        </Grid>
        <Grid item xs={7} sx={{ p: 2 }}>
          <Box sx={{ border: "2px solid #F5F5F9", borderRadius: "12px", py: 2 }}>
            {chipInfo.map((item: any, index: number) => (
              <Chip
                key={index}
                label={
                  value[item.name as keyof ICheckList] === true || value[item.name as keyof ICheckList] === false
                    ? item.label.reject
                    : item.label.inProgress
                }
                icon={
                  value[item.name as keyof ICheckList] === true
                    ? item.icon.accept
                    : value[item.name as keyof ICheckList] === false
                    ? item.icon.reject
                    : ""
                }
                sx={{
                  mr: -3,
                  pl: 3,
                  py: 2.2,
                  outline: "white 4px solid",
                  backgroundColor:
                    value[item.name as keyof ICheckList] === true
                      ? item.color.accept
                      : value[item.name as keyof ICheckList] === false
                      ? item.color.reject
                      : item.color.inProgress,
                }}
              />
            ))}{" "}
          </Box>
          <Box
            sx={{
              border: "2px solid #F5F5F9",
              borderRadius: "12px",
              py: 2,
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Typography sx={{ px: 2 }}>{t("currentVersion")}</Typography>
            <Typography sx={{ fontWeight: 600, px: 3 }}> {selectedGame?.version}</Typography>
          </Box>
          <GamePreview
            onOpenPreview={() => setGamePreview(true)}
            onSetValue={(e: any, a: string) =>
              setValue((prev) => ({ ...prev, [a as keyof ICheckList]: e == "accept" ? true : false }))
            }
            game={selectedGame}
            name={"preview"}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "12px",
              border: "1px solid  #F5F5F9 ",
              py: 2,
              px: 2,
              mt: 8,
            }}
          >
            <Typography>بررسی نهایی</Typography>
            <Box>
              <Button
                disabled={!checked}
                variant="contained"
                sx={{
                  px: 2,
                  mr: 0.5,
                  bgcolor: "#04BD0C",
                  color: "white",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderTopLeftRadius: "8px",
                  borderBottomLeftRadius: "8px",
                  "& .hover": {
                    bgcolor: "#04BD0C",
                  },
                }}
                startIcon={<img src={ICONS.CALENDERPICKER} />}
                onClick={() => setOpenDatePicker(true)}
              >
                تایید و انتشار
              </Button>
              <Button
                onClick={() => setOpenReject(true)}
                variant="contained"
                disabled={!reject}
                sx={{
                  px: 3,
                  bgcolor: "#F84E58",
                  color: "white",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "8px",
                }}
                startIcon={<img src={ICONS.CHATCIRCLE} />}
              >
                عدم تایید{" "}
              </Button>{" "}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Dialog open={openDatePicker} onClose={() => setOpenDatePicker(false)}>
        <Box sx={{ px: 2, py: 1, textAlign: "center", height: "430px", width: "350px" }}>
          <Grid container sx={{ mb: 1 }}>
            <Grid item xs={2}>
              {" "}
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => setOpenDatePicker(false)}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
            <Grid item xs={10} sx={{ pr: 6 }}>
              <h2>انتخاب زمان انتشار</h2>
            </Grid>
          </Grid>
          <CustomDatePicker onChangeDate={(e: any) => setDate(e)} />
          <Button variant="contained" fullWidth onClick={handleVerifyGame} sx={{ mt: 2 }}>
            تایید و انتشار
          </Button>
        </Box>
      </Dialog>
      <Dialog open={openReject} onClose={() => setOpenReject(false)}>
        <RejectReason onCloseReject={() => setOpenReject(false)} onReject={handlerejectGame} value={value} />
      </Dialog>
      <Dialog open={gamePreview} onClose={() => setGamePreview(false)} fullScreen>
        <PlayGame game={selectedGame} gameVersion={gameVersion?.gameVersion} onClose={() => setGamePreview(false)} />
      </Dialog>
    </PageContainer>
  );
};

export default Game;
