import { TimePickerInput } from "./TimePickerInput";
import BaseDatePicker from "react-multi-date-picker";
import BaseTimePicker from "react-multi-date-picker/plugins/time_picker";

const TimePicker = ({ helperText, error, disable,value, ...rest }: any) => {
  return (
    <BaseDatePicker
      disabled={disable}
      disableDayPicker
      format="HH:mm"
      value={value}
      plugins={[<BaseTimePicker hideSeconds />]}
      render={<TimePickerInput {...{ helperText, error,value }} />}
      {...rest}
    />
  );
};

export default TimePicker;
