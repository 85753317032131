import { USER } from "types/user";
import { getUser } from "api/user";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useUser = () => {
  const { id } = useParams();
  const [user, setUser] = useState<USER>();
  const { t } = useTranslation();

  useEffect(() => {
    getUser({ id })
      .then((response) => {
        setUser(response.user);
      })
      .catch((error) => {
        toast.error(
          error.message?.data?.status ? t(`_messages.${error.message?.data?.status}`) : t("_messages.generalError")
        );
      });
  }, []);

  return { id, user };
};

export default useUser;
