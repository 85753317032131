import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import PageContainer from "components/PageContainer";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import AddPeople from "./addPeopleModal";
import AddIcon from '@mui/icons-material/Add';

export default function CompanyPeople() {
  const { id } = useParams();
  const { data: companyPeople, mutate } = useSWR(`/ads/companies/${id}/people`);
  const [open, setOpen] = useState(false);

  function handleCloseModal() {
    setOpen(false);
  }
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <AddPeople onClose={handleCloseModal} />
      </Dialog>
      <PageContainer noPadding={true}>
        <Grid
          container
          sx={{
            backgroundColor: "white",
            py: 2,
            mb: 2,
          }}
        >
          <Grid item xs={12}>
            <Button 
              onClick={() => setOpen(true)}             
              sx={{
                ":hover": {bgcolor:"grey", },
                bgcolor:"#313131", 
                color:"white", 
                py:"10px", 
                width:"200px", 
               }}
            >
              <AddIcon sx={{color:"white", mr:"5px", fontSize: 20}}/>افزودن سمت جدید
            </Button>
          </Grid>
          {companyPeople?.map((i: any, index: number) => {
            return (
              <Grid
                item
                xs={6}
                gap={2}
                key={index}
                sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
              >
                <Box>
                  <Typography sx={{ px: 2, py: 1, m: 1 }}>{`#${index + 1}`}</Typography>
                  <Box
                    sx={{
                      border: "1px solid #C0C1CE",
                      borderRadius: "16px",
                      px: 2,
                      py: 1,
                      m: 1,
                      minHeight: "188px",
                    }}
                  >
                    {[
                      { name: "نام", content: i.personName },
                      { name: "سمت", content: i.personRole },
                      { name: "ایمیل", content: i.personEmail },
                      { name: "شماره تماس ", content: i.personCallNumber },
                    ].map((j: any, idx: number) => {
                      return (
                        <Box key={idx} sx={{ display: "flex", py: 2}}>
                          <Typography sx={{ color: "#676872", fontWeight: 400, fontsize: "16px", width: "150px"}}>
                            {j.name}
                          </Typography>
                          <Typography sx={{ color: "#333337", width: "100%", fontWeight: 'bold',textAlign:"left"}}>
                            {j.content}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </PageContainer>
    </>
  );
}
