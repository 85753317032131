import { Img } from "components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "components/checkbox/Chechbox";
import { styled, Typography, Button, FormControlLabel, Box, Avatar, Dialog } from "@mui/material";
import preview from "assets/preview.svg";
import download from "assets/DownLoad (2).svg";
import React from "react";
import { Height } from "@mui/icons-material";
export const StyledActionButton = styled(Button)({});

interface ItemContainerProps {
  title: string;
  children?: ReactNode;
}

export const ItemContainer = ({ title, children }: ItemContainerProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        gap: "2.87rem",
        alignItems: "start",
        marginTop: "1.71rem",

        ":first-of-type": {
          marginTop: "0",
        },
      }}
    >
      <Typography
        sx={{
          minWidth: "16rem",
          width: "16rem",
          fontSize: "1.5rem",
          fontWeight: 500,
        }}
      >
        {t(title)}
      </Typography>
      {children}
    </Box>
  );
};

export const StyledTypography = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: 500,
});

interface CheckBoxItemProps {
  title: string;
}

export const CheckBoxItem = ({ title }: CheckBoxItemProps) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      }}
      control={<Checkbox checked={true} disabled />}
      label={
        <Typography
          sx={{
            fontSize: "1.3rem",
            fontWeight: 500,
          }}
        >
          {t(title)}
        </Typography>
      }
    />
  );
};

interface ImageContainerProps {
  src: string;
  caption: string;
  width: string;
  height: string;
}

export const ImageContainer = (props: ImageContainerProps) => {
  const coverGif = props.caption === "coverGif";
  const mobileImage = props.caption === "mobileImage";
  const image = props.caption === "image";
  const coverImage = props.caption === "coverImage";
  const [open, setOpen] = React.useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",

        mb: 2,
      }}
    >
      {props.src ? (
        <img
          src={props.src}
          alt={props.caption}
          style={{
            objectFit: "cover",
            width: props.width,
            height: props.height,
            borderRadius: "0.6rem",
            border: "1px solid #F5F5F9",
          }}
        />
      ) : (
        <Avatar
          sx={{
            objectFit: "cover",
            width: props.width,
            height: props.height,
            borderRadius: "0.6rem",
            border: "1px solid #F5F5F9",
          }}
        />
      )}

      <img
        src={preview}
        width={25}
        height={25}
        alt={props.caption}
        onClick={() => setOpen(true)}
        style={{
          cursor: "pointer",
          position: "relative",
          right: coverImage || mobileImage ? 75 : coverGif ? 150 : 130,
          bottom: "28px",
        }}
      />
      {/* <a download href={props?.src || "https://bazi.express/storage/images/65535bac7461bd26ae9e9d74"}> */}
      <img
        onClick={() => window.open(props?.src || "https://bazi.express/storage/images/65535bac7461bd26ae9e9d74")}
        src={download}
        width={25}
        height={25}
        alt={props.caption}
        style={{
          cursor: "pointer",
          position: "relative",
          right: coverImage || mobileImage ? 45 : coverGif ? 120 : 105,
          bottom: "53px",
        }}
      />
      {/* </a> */}
      <Dialog open={open} fullScreen>
        <Box sx={{ textAlign: "center", mt: 10 }}>
          <Img src={props.src} alt={"برای این عکس سورسی یافت نشد "} />
        </Box>
        <Button onClick={() => setOpen(false)}>بستن</Button>
      </Dialog>
    </Box>
  );
};
