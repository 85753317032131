import { delete_, post } from "api";
import axios from "axios";
import { AUTH_BASE_URL } from "./config";

export const getUploadLink = (contentType: "image" | "game") => {
  return post<{
    uploadLink: string;
    fileId: "string";
  }>(`${AUTH_BASE_URL}/file/upload/link/${contentType}`);
};
export const Url = "https://bazi.express/api/v1/";
export const uploadFile = (url: string, file: string | File, contentType: "image" | "game") => {
  if (contentType === "game") {
    return axios.put(url, file, {
      headers: { "Content-Type": "application/zip", Accept: "*/*" },
    });
  } else {
    return axios.put(url, file, {
      headers: { "Content-Type": "application/image", Accept: "*/*" },
    });
  }
};

export const uploadDone = (fileId: string) => {
  return post<{
    status: string;
  }>(`${AUTH_BASE_URL}/file/upload/done/${fileId}`);
};

export const deletFile = (fileId: string) => {
  return delete_(`${AUTH_BASE_URL}/file/${fileId}`);
};
