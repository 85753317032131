import { Box, Divider, Grid, Avatar } from "@mui/material";
import { blockedUserType, unblockUser } from "api/chat";
import { toast } from "react-toastify";
import removeBlock from "assets/removeBlock.svg";
import useSWR from "swr";
import { formatJallali } from "utils/date";

export default function InnerLeagueBlockedUsers() {
  const { data: blockedUserbyAdmin, mutate } = useSWR<{ blockedUsers: blockedUserType[]; count: number }>(
    "/chat/user/blocks"
  );
  console.log("blockedUser", blockedUserbyAdmin);

  const blockUser = blockedUserbyAdmin?.blockedUsers?.map((user: blockedUserType, index: number) => {
    return {
      blockedTime: formatJallali(user.blockUntil, { time: true }),
      profilePicture: user.chatId.sender.profilePicture?.url || user.chatId.sender.coverImage?.url,
      name: user.chatId.sender.username || user.chatId.sender.fullname || "ananymous",
      id: user.chatId.sender._id,
    };
  });
  async function unBlockUser(id: string) {
    try {
      await unblockUser(id);
      toast.success("کاربر از حالت مسدود خارج شد ");
      mutate();
    } catch (error) {
      toast.error("خطایی رخ داده است ");
    }
  }

  return (
    <Box>
      {blockUser?.length === 0 ? (
        <div style={{ display: "flex", alignItems: "center",justifyContent:"center" }}> کاربر مسدود شده ای وجود ندارد</div>
      ) : (
        <Box>
          {blockUser?.map((i: any) => {
            return (
              <Grid container key={i.name} sx={{ alignItems: "center", padding: "0.5rem" }}>
                <Grid item xs={1} sx={{ alignItems: "center" }}>
                  <p>{i.blockedTime}</p>
                </Grid>
                <Grid item xs={1} sx={{ alignItems: "center" }}>
                  <Avatar src={i.profilePicture} alt="" sx={{ width: "3rem", height: "3rem" }} />
                </Grid>
                <Grid item xs={8} sx={{ alignItems: "center" }}>
                  <p style={{ marginLeft: "0.5rem" }}>{i.name}</p>
                </Grid>
                <Grid item xs={2} sx={{ alignItems: "center" }}>
                  <img src={removeBlock} alt="" onClick={() => unBlockUser(i.id)} />
                </Grid>
                <Grid item xs={12} sx={{ alignItems: "center" }}>
                  <Divider />
                </Grid>
              </Grid>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
