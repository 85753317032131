import { LEAGUE_STATUS } from "types/league";
import { SyntheticEvent, useState } from "react";
import useSWR from "swr";
import { SWR_KEYS } from "constants/swrKeys";
import { log } from "console";
import Leagues from ".";

const useLeagues = () => {
  const [tabValue, setTabValue] = useState<any>("active");
  const [searchValue, setSearchValue] = useState<string>("");
  //should filter according to startdate and endDate to api below
  const { data } = useSWR(SWR_KEYS.LEAGUES(tabValue));
  console.log(data);
  const onChangeTab = (event: SyntheticEvent, value: LEAGUE_STATUS) => {
    console.log("event", event.target, value);

    setTabValue(value);
  };

  const onSearch = (event: any) => {
    const { value } = event.target;

    setSearchValue(value);
  };

  return { tabValue, onChangeTab, searchValue, onSearch, leagues: data?.leagues };
};

export default useLeagues;
