import { Grid, Box, Typography, styled } from "@mui/material";
import { IUserType } from "api/user";
import PageContainer from "components/PageContainer";
import { formatJallali } from "utils/date";

const baseInfo1 = ["نام کاربری", "نام کامل", "تاریخ تولد"];
const baseInfo2 = ["وضعیت کاربر", "آخرین ورود به سایت", "تاریخ ثبت نام"];
const contactInfo = ["شماره موبایل", "آدرس ایمیل"];
const publisherContactInfo = ["شماره موبایل", "آدرس ایمیل", "آدرس وبسایت"];
const contactIfo1 = ["شهر", "استان"];
const financeInfo = ["بانک", "شماره کارت", "شماره حساب", "شماره شبا"];

const CostumeTypography = styled("div")({
  paddingTop: "7.5px",
  paddingBottom: "7.5px",
  color: "#676872",
});
const CostumeTypography1 = styled("div")({
  paddingTop: "7.5px",
  paddingBottom: "7.5px",
  color: "#333337",
  fontWeight: 600,
});

const UserInformation = ({ userInfo, type }: { userInfo: IUserType | undefined; type?: "user" | "publisher" }) => {
  return (
    <>
      <Typography sx={{ fontWeight: 600, pr: 3 }}> اطلاعات پایه </Typography>
      <Grid container spacing={2} sx={{ pr: 3, my: 1, border: "1px solid #C0C1CE", borderRadius: "16px" }}>
        <Grid xs={2} item sx={{ height: 145 }}>
          {baseInfo1.map((i) => (
            <CostumeTypography>{i}</CostumeTypography>
          ))}
        </Grid>
        <Grid item xs={3}>
          <CostumeTypography1>{userInfo?.username || "__"}</CostumeTypography1>
          <CostumeTypography1>{userInfo?.fullname || "__"}</CostumeTypography1>
          <CostumeTypography1> {userInfo?.birthday ? formatJallali(userInfo?.birthday) : "__"}</CostumeTypography1>
        </Grid>
        <Grid xs={2} item sx={{ height: 140, width: 100 }}>
          {baseInfo2.map((i) => (
            <CostumeTypography>{i}</CostumeTypography>
          ))}
        </Grid>
        <Grid xs={4} item sx={{ minHeight: 140, width: 100 }}>
          <CostumeTypography1>{userInfo?.active === true ? "فعال" : "غیرفعال"}</CostumeTypography1>
          <CostumeTypography1>{userInfo?.lastLogin ? formatJallali(userInfo?.lastLogin) : "__"} </CostumeTypography1>
          <CostumeTypography1>{userInfo?.createdAt ? formatJallali(userInfo?.createdAt) : "__"}</CostumeTypography1>
        </Grid>
      </Grid>
      <Typography sx={{ fontWeight: 600, pr: 3 }}> اطلاعات تماس </Typography>
      <Grid container spacing={2} sx={{ pr: 3, my: 1, border: "1px solid #C0C1CE", borderRadius: "16px" }}>
        <Grid xs={2} item sx={{ minHeight: 145 }}>
          {type === "user"
            ? contactInfo.map((i) => <CostumeTypography>{i}</CostumeTypography>)
            : publisherContactInfo.map((i) => <CostumeTypography>{i}</CostumeTypography>)}
        </Grid>
        <Grid item xs={3} sx={{ minHeight: 145 }}>
          <CostumeTypography1>{userInfo?.msisdn ? userInfo?.msisdn : "__"}</CostumeTypography1>
          <CostumeTypography1> {userInfo?.email ? userInfo?.email : "__"}</CostumeTypography1>
          {type === "publisher" && (
            <CostumeTypography1> {userInfo?.website ? userInfo?.website : "__"}</CostumeTypography1>
          )}
        </Grid>
        <Grid xs={2} item sx={{ minHeight: 145 }}>
          {contactIfo1.map((i) => (
            <CostumeTypography>{i}</CostumeTypography>
          ))}
        </Grid>
        <Grid item xs={3}>
          <CostumeTypography1>{userInfo?.city?.name ? userInfo?.city?.name : "__"}</CostumeTypography1>
          <CostumeTypography1>{userInfo?.country ? userInfo?.country : "__"}</CostumeTypography1>
        </Grid>
      </Grid>

      <Typography sx={{ fontWeight: 600, pr: 3 }}> اطلاعات مالی </Typography>
      <Grid container spacing={2} sx={{ pr: 3, my: 1, border: "1px solid #C0C1CE", borderRadius: "16px" }}>
        <Grid xs={5} item sx={{ height: 145 }}>
          {financeInfo.map((i) => (
            <CostumeTypography>{i}</CostumeTypography>
          ))}
        </Grid>
        <Grid item xs={3}>
          <CostumeTypography1>
            {userInfo?.financialInfo?.bank ? userInfo?.financialInfo?.bank : "__"}
          </CostumeTypography1>
          <CostumeTypography1>
            {userInfo?.financialInfo?.cardNumber ? userInfo?.financialInfo?.cardNumber : "__"}
          </CostumeTypography1>
          <CostumeTypography1>
            {" "}
            {userInfo?.financialInfo?.accountNumber ? userInfo?.financialInfo?.accountNumber : "__"}
          </CostumeTypography1>
          <CostumeTypography1>
            {" "}
            {userInfo?.financialInfo?.shebaNumber ? userInfo?.financialInfo?.shebaNumber : "__"}
          </CostumeTypography1>
        </Grid>
      </Grid>
    </>
  );
};
export default UserInformation;
