import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  CircularProgress,
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import useSWR from "swr";
import { createCategory } from "api/ticket";

const schema = Yup.object({
  title: Yup.string()
    .min(2, " باید از 2 کاراکتر بیشتر باشد ")
    .max(30, " بیشتر از 30 کاراکتر مجاز نیست")
    .required("نام دسته بندی اجباری است"),
  userRoleId: Yup.string().required(),
});

export default function AddTicketCategory({
  open,
  onClose,
  onMutate,
}: {
  open: boolean;
  onClose?: () => void;
  onMutate: () => void;
}) {
  const {
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    isValid,
    touched,
    errors,
    getFieldProps,

    resetForm,
  } = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      title: "",
      userRoleId: "",
    },

    onSubmit: async (data) => {
      try {
        setSubmitting(true);
        await createCategory(data);
        toast.success("با موفقیت بارگزاری شد");
        onMutate();
      } catch (error) {
        toast.error(String(error));
      } finally {
        setSubmitting(false);
        onClose && onClose();
        resetForm({ values: { title: "", userRoleId: "" } });
      }
    },
  });

  const [role, setRole] = useState("");
  const handleChange = (event: any) => {
    setFieldValue("userRoleId", event?.target.value);
    setRole(event.target.value);
  };
  const { data: userRole } = useSWR("roles");

  const publisher = userRole?.roles.find((i: any) => i.name === "publisher")._id;
  const user = userRole?.roles.find((i: any) => i.name === "user")._id;
  const content = [
    { value: user, title: "تیکت کاربر" },
    { value: publisher, title: "تیکت توسعه دهنده" },
  ];
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
    >
      <CloseRounded sx={{ cursor: "pointer", mx: 2, my: 2 }} onClick={onClose} />
      <Divider />
      <Box sx={{ p: 2, width: "400px" }}>
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <>
            <Typography sx={{ fontSize: "12px", py: 1 }}>مربوط به پنل</Typography>
            <FormControl>
              <Select sx={{ width: "370px" }} value={role} onChange={handleChange} fullWidth>
                {content.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {" "}
                    {i.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography sx={{ fontSize: "12px", py: 1 }}>دسته دبندی جدید تیکت </Typography>
            <TextField
              {...getFieldProps("title")}
              type="text"
              error={Boolean(touched.title && errors.title)}
              fullWidth
              sx={{ my: 2, background: "#F5F5F9" }}
              size="small"
            />
            {errors.title && <FormHelperText>{errors.title}</FormHelperText>}

            {isSubmitting ? (
              <CircularProgress />
            ) : (
              <Button fullWidth variant="contained" type="submit" sx={{ mb: 1 }} disabled={isSubmitting || !isValid}>
                افزودن دسته بندی جدید
              </Button>
            )}
          </>
        </form>
      </Box>
    </Dialog>
  );
}
