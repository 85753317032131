import { Typography, Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import inner from "assets/innerBanner.svg";
import left from "assets/leftBanner.svg";
import bottom from "assets/bannerBottom.svg";
import Form from "./form";

export type ICheckList = {
  detail: boolean | null;
  link: boolean | null;
  image: boolean | null;
  preview: boolean | null;
  advertisement: boolean | null;
  finall: boolean | null;
  security: boolean | null;
};
export default function AdvertisementForms({
  setValue,
}: {
  setValue: (
    a: string,
    e: string
  ) => void;
}) {
  const [bannerTypeNumber, setBannerTypeNumber] = useState(0);
  useEffect(()=>{
    setValue("accept","advertisement")
  },[])
  const bannersType = [
    { title: "بنر سمت چپ ۳۳۵*۳۶۸", id: "left" },
    { title: "بنر پایین ۱۰۰*۱۰۳۷", id: "bottom" },
    { title: "بنر داخل بازی ۵۴۰*۱۰۳۷", id: "inner" },
  ];
  function clickOnMe(index: number) {
    return Boolean(bannerTypeNumber === index);
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        {bannersType.map((bannerType: any, index: number) => {
          return (
            <Box
              key={index}
              sx={{
                borderBottom: clickOnMe(index) ? "4px solid #4A76FD" : "unset",
                width: "100%",
                cursor: "pointer",
                transition: "borderBottom 1s ease-in",
                justifyContent: "center",
                display: "flex",
                mb: 2,
              }}
              onClick={() => setBannerTypeNumber(index)}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: clickOnMe(index) ? "bold" : "unset", py: 1 }}>
                {bannerType.title}
              </Typography>
            </Box>
          );
        })}
      </Grid>
      <Grid item xs={12}>
        {bannerTypeNumber === 0 ? (
          <img src={left} alt="" />
        ) : bannerTypeNumber === 1 ? (
          <img src={bottom} alt="" />
        ) : (
          bannerTypeNumber === 2 && <img src={inner} alt="" />
        )}
      </Grid>
      <Grid xs={12}>
        <Form />
      </Grid>
    </Grid>
  );
}
