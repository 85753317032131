import { Box, Button, Grid, Typography, Card, Select, MenuItem, Dialog } from "@mui/material";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CustomDatePicker from "pages/game/components/customDatePicker";
import CustomBarChart from "components/share/barChart";
import DataGrid from "components/datagrid";
import { GridColumns } from "@mui/x-data-grid";
import { NumeralThousandGroupStyles, formatNumeral } from "cleave-zen";
import StarIcon from "@mui/icons-material/Star";
import moment from 'moment-jalaali';
import dayjs from 'dayjs';

export default function Statistic () {
  const [openStartDialog, setOpenStartDialog] = useState(false);
  const [openEndDialog, setOpenEndDialog] = useState(false);
  const [chartTime, setChartTime] = useState();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [showChart, setShowChart] = useState(false);

  const handleClickOpen = () => {
    setOpenStartDialog(true);
  };

  const handleClose = (value: string) => { 
    setStartDate(value);
  };

  const handleClickOpenEnd = () => {
    setOpenEndDialog(true);
  };

  const handleCloseEnd = (value: string) => {
    setEndDate(value);
  };

  function formatDateToJalaali(dateString: string) {
    const date = new Date(dateString);
    return moment(date).format('jYYYY/jM/jD');
}

  const rating = 3;

  const header = [
    { title: "امتیاز بازی", num: `${rating}` },
    { title: "رتبه بازی در دسته بندی", num: "8" },
    { title: "تعداد بازی انجام شده", num: "45,210" },
    { title: "تعداد بازیکنان بازی", num: "7,203" },
    { title: "مجموع درآمد بازی", num: "7,581,000 تومان" },
  ];

  const data: any[] = [
    { value: "01/06/24", income: 26000000 },
    { value: "01/06/25", income: 22000000 },
    { value: "01/06/26", income: 14000000 },
    { value: "01/06/27", income: 18000000 },
    { value: "01/06/28", income: 28000000 },
    { value: "01/06/29", income: 28000000 },
    { value: "01/06/30", income: 30000000 },
    { value: "01/06/31", income: 28000000 },
  ];

  const fakeData: any[] = [
    { row: 1, time: "01/06/24", clickCount: 13000, income: 26000000 },
    { row: 2, time: "01/06/25", clickCount: 11000, income: 22000000 },
    { row: 3, time: "01/06/26", clickCount: 7000, income: 14000000 },
    { row: 4, time: "01/06/27", clickCount: 9000, income: 18000000 },
    { row: 5, time: "01/06/28", clickCount: 14000, income: 28000000 },
    { row: 6, time: "01/06/29", clickCount: 14000, income: 28000000 },
    { row: 7, time: "01/06/30", clickCount: 15000, income: 30000000 },
    { row: 8, time: "01/06/31", clickCount: 14000, income: 28000000 },
  ];

  const rows = fakeData;
  const columns: GridColumns = [
    {
      field: "col",
      headerName: "ردیف",
      flex: 0.1,
      renderCell({ row }: any) {
        return <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{`${row.row}.`}</Typography>;
      },
    },
    {
      field: "time",
      headerName: "زمان",
      flex: 1,
      renderCell({ row }: any) {
        return <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{row.time}</Typography>;
      },
    },
    {
      field: "clickCount",
      headerName: "تعداد تبلیغ پخش/کلیک شده",
      flex: 1,
      renderCell({ row }: any) {
        return <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{row.clickCount}</Typography>;
      },
    },
    {
      field: "income",
      headerName: "درآمد",
      flex: 1,
      renderCell({ row }: any) {
        return (
          <Typography sx={{ display: "flex", gap: 1 }}>
            {" "}
            {formatNumeral(`${row.income}`, {
              numeralThousandsGroupStyle: NumeralThousandGroupStyles.THOUSAND,
            })}
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1.78rem",
          mt: 4,
        }}
      >
        {header.map((item: any, index: number) => {
          return (
            <Card
              key={index}
              sx={{
                bgcolor: "#F5F5F9",
                width: "170px",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <Typography display="inline" sx={{ alignItems: "center", fontWeight: "bold" }}>
                {item.title}
              </Typography>

              {item.title === "امتیاز بازی" ? (
                <div style={{ height: "20px" }}>
                  {[...Array(rating)].map((_, index) => (
                    <StarIcon key={index} sx={{ color: "#FC0", fontSize: "20px" }} />
                  ))}
                  {[...Array(5 - rating)].map((_, index) => (
                    <StarIcon key={index} sx={{ color: "#808080", fontSize: "20px" }} />
                  ))}
                </div>
              ) : (
                <Typography display="inline" sx={{ alignItems: "center" }}>
                  {item.num}
                </Typography>
              )}
            </Card>
          );
        })}
      </Box>
      <Typography sx={{ fontWeight: "bold", fontSize: "20px", mx: 8, my: 6 }}> گزارش مالی بازی</Typography>
      <Box sx={{ mx: 8 }}>
        <Grid container spacing={3} sx={{ mb: 6 }}>
          <Grid item xs>
            <FormControl>
              <InputLabel>انتخاب بازه</InputLabel>
              <Select
                value={chartTime}
                onChange={(event: any) => {
                  setChartTime(event.target.value);
                }}
                sx={{ width: "150px", height: "50px", borderColor: "grey.500" }}
              >
                {["یک هفته", "یک ماه", "سه ماه"].map((item: string) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <Grid
              container
              spacing={0}
              sx={{ border: 1, borderColor: "grey.500", height: "50px", borderRadius: "5px", width: "150px" }}
            >
              <Grid item xs={4}>
                <Typography sx={{ p: 2 }}>از</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  sx={{ px: 1, py: 2, cursor: "pointer" }}
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  {startDate || " انتخاب کنید"}
                </Typography>
                <Dialog onClose={() => handleClose("")} open={openStartDialog}>
                  <Box sx={{p:"10px"}}>
                    <CustomDatePicker onChangeDate={(e: any) => handleClose(formatDateToJalaali(e))} />
                    <Button
                      sx={{ bgcolor: "#4A76FD", color: "white", width: "90%", height: "40px", my:'20px', ml:"5%" }}
                      onClick={() => {
                        setOpenStartDialog(false);
                      }}
                    >
                  بستن
                  </Button>
                  </Box>
                </Dialog>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid
              container
              spacing={0}
              sx={{ border: 1, borderColor: "grey.500", height: "50px", borderRadius: "5px", width: "150px" }}
            >
              <Grid item xs={4}>
                <Typography sx={{ p: 2 }}>تا</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  sx={{ px: 1, py: 2, cursor: "pointer" }}
                  onClick={() => {
                    handleClickOpenEnd();
                  }}
                >
                  {endDate || " انتخاب کنید"}
                </Typography>
                <Dialog onClose={() => handleCloseEnd("")} open={openEndDialog}>
                  <Box sx={{p:"10px"}}>
                    <CustomDatePicker onChangeDate={(e: any) => handleCloseEnd(formatDateToJalaali(e))} /*isDisablePast={true} defaultValue={dayjs(startDate)}*//>
                    <Button
                      sx={{ bgcolor: "#4A76FD", color: "white", width: "90%", height: "40px", my:'20px', ml:"5%" }}
                      onClick={() => {
                        setOpenEndDialog(false);
                      }}
                    >
                  بستن
                  </Button>
                  </Box> 
                </Dialog>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Button
              sx={{ bgcolor: "#4A76FD", color: "white", width: "150px", height: "50px" }}
              onClick={() => {
                setShowChart(true);
              }}
            >
              مشاهده گزارش
            </Button>
          </Grid>
        </Grid>
      </Box>
      {showChart && (
        <>
          <CustomBarChart
            xAxisTitle={""}
            yAxisTitle={""}
            barColor={"#4A76FD"}
            tooltipColor={["#4A76FD"]}
            data={data}
            title={"درآمد تبلیغ"}
            yAxisBarKey={"income"}
          />
          <Box sx={{ mx: 8, my: 4 }}>
            <Typography sx={{ display: "inline" }}> مجموع درآمد در بازه زمانی:</Typography>
            <Typography sx={{ fontWeight: "bold", display: "inline", mx: 4 }}>1,400,000 تومان</Typography>
          </Box>
        </>
      )}
      <Box sx={{ minHeight: "500px", pt: 5, width: "99%", alignContent: "center" }}>
        <DataGrid
          sx={{
            ml: "5%",
            width: "90%",
            fontSize: "12px",
            cursor: "pointer",
            border: "1px solid",
            borderColor: "transparent",
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
          }}
          columns={columns}
          rows={rows?.map((row: any) => ({ ...row, id: row.row })) || []}
        />
      </Box>
    </>
  );
}

