import { useEffect, useMemo, useState } from "react";
import moment from "jalali-moment";
import { ICONS } from "assets/icons";
import { Table } from "components";
import { Link, useNavigate } from "react-router-dom";
import { GAME_STATUS } from "types/game";
import { ROUTES } from "constants/routes";
import { useTranslation } from "react-i18next";
import { Avatar, Grid, Box, Pagination, Typography } from "@mui/material";
import { StyledButton, StyledTypography } from "./GamesListItem";
import { formatJallali } from "utils/date";
import PageAnimation from "components/PageAnimation";
import PageContainer from "components/PageContainer";

const GamesList = ({ games, status, handleChange, page, fullCheckBox, value, count }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  console.log(count, "count");

  const [selectedItem, setSelectedItem] = useState<string[]>();
  const onSelectionModelChange = (ids: string[]) => {
    setSelectedItem(ids);
  };
  const gameId = games.map((i: any) => i._id) || [];

  const columns = useMemo(() => {
    let initColumns = [
      {
        field: "gameName",
        headerName: t("gameName"),
        flex: 2,
        renderCell({ row }: any) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Avatar
                src={row?.game?.coverImage?.url || row?.game?.image?.url || ICONS.GAME_IMAGE_BACKUP}
                alt={row?.game?.title}
                sx={{
                  width: "5rem",
                  height: "3rem",
                  borderRadius: "0.4rem",
                }}
              />
              <StyledTypography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{row?.game?.title}</StyledTypography>
            </Box>
          );
        },
      },

      {
        field: "version",
        headerName: t("version"),
        flex: 1,
        renderCell({ row }: any) {
          return <Typography>{`نسخه ${row?.version}`}</Typography>;
        },
      },
      {
        field: "publisher",
        headerName: t("publisher"),
        flex: 2,
        renderCell({ row }: any) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Avatar
                src={(row.publisher?.coverImage && row.publisher?.coverImage?.url) || ICONS.GAME_IMAGE_BACKUP}
                sx={{
                  width: "3rem",
                  height: "3rem",
                }}
              />
              <StyledTypography sx={{ fontSize: "1.3rem", fontWeight: 300 }}>
                {row.publisher?.fullname || "test"}
              </StyledTypography>
            </Box>
          );
        },
      },
      {
        field: "publishDate",
        headerName: t("publishDate"),
        flex: 1,
        renderCell({ row }: any) {
          return (
            <StyledTypography sx={{ fontSize: "1.3rem", fontWeight: 300 }}>
              {formatJallali(row.createdAt)}
            </StyledTypography>
          );
        },
      },
    ];

    switch (status) {
      case GAME_STATUS.DONE:
        return [
          ...initColumns,
          {
            field: "rate",
            headerName: t("rate"),
            flex: 1,
            renderCell({ row }: any) {
              return (
                <Box sx={{ display: "flex" }}>
                  <img src={ICONS.GOLDSTAR} alt="star" />
                  <Typography sx={{ px: 0.2}}>{Math.round(row.game.rate*100)/100}</Typography>
                </Box>
              );
            },
          },
          ,
        ];
      case GAME_STATUS.REJECTED:
        return [
          ...initColumns,
          {
            field: "rejectReason",
            headerName: t("rejectReason"),
            flex: 3,
          },
          {
            field: "detail",
            headerName: t("detail"),
            flex: 1,
            renderCell({ row }: any) {
              return (
                <StyledButton
                  component={Link}
                  to={ROUTES.GAME(row._id)}
                  sx={{
                    px: 2,
                    fontSize: "1.3rem",
                    fontWeight: 300,
                  }}
                >
                  {t("detail")}
                </StyledButton>
              );
            },
          },
        ];

      default:
        return initColumns;
    }
  }, [status, t]);
  useEffect(() => {
    fullCheckBox ? setSelectedItem(gameId) : setSelectedItem([]);
  }, [fullCheckBox]);

  return (
    <PageContainer noPadding={true}>
      <Grid sx={{ backgroundColor: "white", borderRadius: "1.7rem", width: "100%", mt: 1 }}>
        <Table
          columns={columns}
          rows={games}
          getRowId={(row: any) => row._id}
          onRowClick={(row: any) => {
            value === "inProgress"
              ? navigate(`${row?.row?.game?._id}/${row.id}`)
              : (value === "done" || value === "deactivatedByAdmin") &&
                navigate(`/innerGames/${row?.row?.game?._id}/${row?.row._id}`, { state: { from: `${value}` } });
          }}
          checkboxSelection
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={selectedItem}
        />
        <Pagination
          sx={{ mx: 5, pb: 10 }}
          count={Math.ceil(count / 10)}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handleChange}
          color="primary"
        />
      </Grid>
    </PageContainer>
  );
};

export default GamesList;
