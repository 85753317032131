import Axios from "axios";
import { getRefreshToken, getToken, removeToken, setToken } from "../utils/token";
import { renewAccess } from "./admin";

export const ImageUrl = "https://bazi.express/api/v1/";
export const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL || "";
export const BaseUrl = process.env.REACT_APP_API_URL || "";
export const AdminWebsocketUrl = process.env.REACT_APP_ADMIN_SOCKET_URL || "";
export const WebsocketUrl = process.env.REACT_APP_SOCKET_URL || "";
export const webDomainUrl = process.env.REACT_APP_WEB_DOMAIN_URL || "";
export const apiAgent = Axios.create({ baseURL: BaseUrl });
// export const BASE_URL = "https://bazi.express/api/v1/admin/";
// export const AdminWebsocketUrl = "https://bazi.express/admin";
// export const WebsocketUrl = "https://bazi.express";

apiAgent.interceptors.request.use(
  (config) => {
    const token = getToken();
    const isTokenFree = config.params?.tokenFree;

    if (token && config.headers && !config.headers?.Authorization && !isTokenFree) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiAgent.interceptors.response.use(
  (response) => response.data || response,
  async (error) => {
    if (error.response.status === 401) {
      // removeToken();
    } else if (error.response.status === 500) {
      const request = error.config;
      const reqUrl = request.url;
      const refreshToken = getRefreshToken();

      if (!request.callForToken && !reqUrl.includes("renewAccessToken")) {
        // This value is to avoid resending api during await and assigned to request to avoid retrying for same api
        request.callForToken = true;

        try {
          const response: any = await renewAccess({ refreshToken });
          const accessToken = response?.accessToken || "";
          setToken(accessToken);

          // Call rejected request
          request.headers.Authorization = `Bearer ${accessToken}`;

          return apiAgent(request);
        } catch (error) {
          removeToken();
          return Promise.reject(error);
        }
      }
    }

    return Promise.reject(error);
  }
);
