import { Box, Pagination, Typography } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { Table } from "components";
import PageContainer from "components/PageContainer";
import moment from "jalali-moment";
import { useState } from "react";
import useSWR from "swr";
import tapcell from "assets/tapcel-logo.svg";
import yaktanet from "assets/yektanet-logo.svg";
import googleAds from "assets/adivery-logo.svg";
import { useNavigate } from "react-router-dom";
import { companiesType } from "api/adCompanies";

const englishAlphabet = ["A", "B", "C", "D", "E"];
export default function AdsCompanies() {
  const [selectedItem, setSelectedItem] = useState<string[]>();
  function calculateTotalIncome(adsStats: any): number {
    return adsStats.reduce((total: any, stat: any) => total + stat.income, 0);
  }
  const onSelectionModelChange = (ids: string[]) => {
    setSelectedItem(ids);
  };
  const { data: companies, isLoading } = useSWR<{ companies: companiesType[]; count: number }>("/ads/companies");
  const navigate = useNavigate();

  const columns: GridColumns = [
    {
      field: "code",
      headerName: "کد یکتا",
      flex: 0.5,
      renderCell({ row }: any) {
        return <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{row.companyNumber}</Typography>;
      },
    },

    {
      field: "companyName",
      headerName: "نام شرکت",
      flex: 1,
      renderCell({ row }: any) {
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <img src={row.provider == "yektanet" ? yaktanet : row.provider === "tapcell" ? tapcell : googleAds} alt="" />
            <Typography sx={{ pt: 1 }}>{row.provider}</Typography>
          </Box>
        );
      },
    },
    {
      field: "type",
      headerName: "نوع",
      flex: 0.5,
      renderCell({ row }: any) {
        return <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{englishAlphabet[row.rank - 1]}</Typography>;
      },
    },
    {
      field: "active",
      headerName: "نوع",
      flex: 1,
      renderCell({ row }: any) {
        return (
          <Typography sx={{ fontSize: "1.3rem", fontWeight: 400, color: !row.active ? "#F84E58" : "unset" }}>
            {row.active ? "فعال" : "غیر فعال"}{" "}
          </Typography>
        );
      },
    },
    {
      field: "income",
      headerName: "درآمد",
      flex: 0.7,
      renderCell({ row }: any) {
        return (
          <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>
            درآمد اینجا نداریم
            {/* {` ${calculateTotalIncome(row.adsStats)} ریال `}{" "} */}
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "تاریخ ایجاد ",
      flex: 1,
      renderCell({ row }: any) {
        return (
          <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>
            {`${moment.utc(row.registrationDate).locale("fa").format(" YYYY/MM/DD")}`}
          </Typography>
        );
      },
    },
  ];

  return (
    <PageContainer noPadding={true}>
      <div style={{ height: "500px", width: "100%", marginTop: "1rem" }}>
        <Table
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={selectedItem}
          columns={columns}
          rows={
            companies && companies?.companies.length !== 0
              ? companies?.companies.map((i: companiesType) => ({ ...i, id: i._id }))
              : []
          }
          onRowClick={() => selectedItem && navigate(`${selectedItem}`)}
        />
        <Pagination sx={{ mx: 5, pb: 10 }} count={10} variant="outlined" shape="rounded" color="primary" />
      </div>
    </PageContainer>
  );
}
