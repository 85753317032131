import { Img } from "components";
import moment from "jalali-moment";
import { ICONS } from "assets/icons";
import { useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import coverImage from "assets/images/test.png";
import { ItemContainer } from "./GamePreviewItems";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";

const GamePreview = (props: any) => {
  const { t } = useTranslation();
  const { admin } = useAppSelector((state) => state.admin);

  const {
    categories,
    previewProps: { gamePreview, onSaveGame, onBackClick },
  } = props;
  const { preview, title, description, tags, category, ageRangeFrom, mouseControl, keysControl, createdAt } =
    gamePreview;
  const categoryData = categories?.find((item: any) => item._id === category);
  const isMouseControl = mouseControl.length > 0;
  const isKeyboardControl = keysControl.length > 0;
  console.log(
    "mouseControl, keysControl",
    mouseControl,
    isMouseControl,
    keysControl,
    isKeyboardControl,
    isMouseControl && t("mouse") + isMouseControl && isKeyboardControl && t("and") + isKeyboardControl && t("keyboard")
  );

  return (
    <Grid
      sx={{
        padding: "2rem 6rem",
      }}
    >
      <Typography
        sx={{
          fontSize: "2rem",
          fontWeight: 500,
          marginBottom: "1.5rem",
        }}
      >
        {t("preview")}
      </Typography>
      <Grid
        sx={{
          borderRadius: "1rem",
          overflow: "hidden",
        }}
      >
        {/* image */}
        {/* todo@sareh: Add carousel to show album */}
        <Box
          sx={{
            backgroundImage: `url(${coverImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
          }}
        >
          <Img
            src={ICONS.EXPAND}
            alt="expand"
            sx={{
              margin: "1rem",
              width: "3.5rem",
              height: "3.5rem",
            }}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: "white",
          }}
        >
          {/* title */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "1.8rem 1.7rem 1.4rem 2.2rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "2rem",
                fontWeight: 500,
              }}
            >
              {title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1.3rem",
              }}
            >
              {["flag", "share", "heart"].map((icon) => (
                <Box
                  key={icon}
                  sx={{
                    width: "3.5rem",
                    height: "3.5rem",
                    backgroundColor: "background.default",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Img
                    src={ICONS[icon.toUpperCase()]}
                    alt={icon}
                    sx={{
                      width: "2.5rem",
                      height: "2.5rem",
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>

          {/* details */}
          <Box
            sx={{
              width: "100%",
              height: "10.5rem",
              borderTop: "1px solid",
              borderBottom: "1px solid",
              borderTopColor: "#F5F6FA",
              borderBottomColor: "#F5F6FA",
              padding: "1.7rem 0",
              display: "flex",
              alignItems: 0,
            }}
          >
            <ItemContainer title="gameCategory" value={categoryData.title}>
              <Img
                src={categoryData.image.url}
                alt="category"
                sx={{
                  width: "3.5rem",
                  height: "3.5rem",
                }}
              />
            </ItemContainer>

            <ItemContainer title="ageRange" value={t("year")}>
              <Typography sx={{ direction: "rtl" }}>{`+ ${ageRangeFrom}`}</Typography>
            </ItemContainer>

            <ItemContainer
              title="gameControls"
              value={`${isMouseControl ? t("mouse") : ""} ${isMouseControl && isKeyboardControl ? t("and") : ""} ${
                isKeyboardControl ? t("keyboard") : ""
              }`}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  margin: "0.5rem 0",
                }}
              >
                {isMouseControl && <Img src={ICONS.MOUSE} alt="mouse" sx={{ width: "2rem", height: "3rem" }} />}
                {isKeyboardControl && (
                  <Img src={ICONS.KEYBOARD} alt="keyboard" sx={{ width: "3rem", height: "3rem" }} />
                )}
              </Box>
            </ItemContainer>

            <ItemContainer title="gamePublisher" value={admin?.me.username}>
              <Img src={admin?.me.profilePicture?.url || ""} alt={admin?.me?.username || ""} />
            </ItemContainer>
          </Box>

          {/* description */}
          <Box
            sx={{
              padding: "2.5rem 1.7rem 1rem 2.2rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "2rem",
                fontWeight: 500,
              }}
            >
              {t("gameDescription")}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.3rem",
                textAlign: "justify",
              }}
            >
              {description}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.3rem",
                  color: "#4A76FD",
                }}
              >
                {t("viewMore")}
              </Typography>
              <Img
                src={ICONS.ARROW_DOWN_BLUE}
                alt="viewMore"
                sx={{
                  width: "2rem",
                  height: "2rem",
                }}
              />
            </Box>
          </Box>

          {/* tags */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0.7rem 1.7rem 4rem 2.2rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1.2rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.3rem",
                }}
              >{`${t("tags")}:`}</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1.2rem",
                }}
              >
                {tags &&
                  tags.map((tag: string) => (
                    <Chip
                      key={tag}
                      label={tag}
                      sx={{
                        height: "2.7rem",
                        borderRadius: "0.5rem",
                        backgroundColor: "background.default",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                        fontSize: "1.1rem",
                      }}
                    />
                  ))}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.3rem",
                }}
              >
                {t("addedIn")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.3rem",
                }}
              >
                {moment.utc(createdAt).locale("fa").local().format("YY/MM/DD")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>

      {/* actions */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: "1rem",
          margin: "2.4rem 0",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            width: "17rem",
            fontWeight: 500,
          }}
          onClick={onBackClick}
        >
          {t("backAndEdit")}
        </Button>

        <Button
          variant="contained"
          sx={{
            width: "17rem",
            fontWeight: 500,
          }}
          onClick={onSaveGame}
        >
          {t("saveAndPublish")}
        </Button>
      </Box>
    </Grid>
  );
};

export default GamePreview;
