import { IImageType } from "api/file";

export enum GAME_STATUS {
  PEND = "inProgress",
  DONE = "done",
  REJECTED = "rejected",
  DRAFT = "draft",
  DEACTIVATEDBYADMIN = "deactivatedByAdmin"
  
}

export interface GameType {
  publisher: {
    _id: string;
    profilePicture: string;
    username: string;
    fullname: string;
  };
  createdAt: string;
  title: string;
  category: {
    title: string;
  };
  tags: string[];
  description: string;
  mouseControl: boolean;
  keysControl: boolean;
  ageRangeFrom: string;
  coverGif: {
    url: string;
    title: string;
  };
  coverImage: {
    url: string;
    title: string;
  };
  gameType: string;
  gameFilePath: string;
  _id: string;
}

export type categoryType = {
  createdAt: string;
  creator: string;
  deleted: boolean;
  image: IImageType;
  parent: any;
  title: string;
  updatedAt: string;
  __v: number;
  _id: string;
};

export type gameLikedtype = {
  image: any;
  _id: string;
  coverImage: any;
  title: string;
  coverGif: any;
  gameFilePath: string;
};
export type likedGametype = {
  _id: string;
  game: gameLikedtype;
  updatedAt: string;
};
export interface GameVersion {
  _id: string;
  __v: number;
  createdAt: string;
  deleted: boolean;
  game: string;
  gameFilePath: string;
  publishDate: string;
  publishStatus: "done" | "pending";
  rejectReasons: string[];
  rejectedBy: string | null;
  updatedAt: string;
  verifiedBy: string;
  version: string;
  versionChanges: string;
}
export type likedGamestype = likedGametype[];
export type gameType = {
  _id: string;
  activeVersion: GameVersion;
  publisher: {
    _id: string;
    username: string;
    coverImage: IImageType;
    profilePicture: IImageType;
    bio: string;
    fullname: string;
  };
  title: string;
  category: categoryType;
  tags: string[];
  description: string;
  gameType: string;
  fullscreenCompatible: boolean;
  iosCompatible: boolean;
  androidCompatible: boolean;
  gameFilePath: string;
  mouseControl: boolean;
  keysControl: number[];
  rate: number;
  rateCount: number;
  likeCount: number;
  commentCount: number;
  playCount: number;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  coverGif: IImageType;
  coverImage: IImageType;
  verified: boolean;
  size: number;
  ageRangeFrom: any;
  minInternetSpeedKB: number;
  totalPlayersPlayedCount: number;
};