import { Avatar, Dialog, Divider, Typography } from "@mui/material";
import { IGameType, activityLogType } from "api/game";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import rejectReasonDoc from 'assets/File_Document.svg'
import acceptDoc from 'assets/acceptDoc.png'
import { formatJallali } from "utils/date";
import { useState } from "react";
import DetailRejectModal from "./detailRejectModal";
import PageContainer from "components/PageContainer";


export default function History() {
  const[openModal,setOpenModal]=useState(false)

  const { gameId } = useParams();
  const { data: gameDetials } = useSWR<{ game: IGameType; gameActivityLogs: activityLogType[] }>(`/games/${gameId}`);
  return (
    <PageContainer noPadding={true}>
    <div style={{ paddingBottom: "2rem" }}>
      {gameDetials?.gameActivityLogs?.map((i: activityLogType, index: number) => {
        return (
          <div key={index} style={{ padding: "0 3rem" }}>
            <div style={{display:"flex",justifyContent:"space-between", padding: "0.5rem 0rem" }}>
            <div>

          
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <Avatar src={i?.executor?.profilePicture?.url} />
              <Typography
                sx={{
                  fontWeight: 600,
                  borderRight: "2px solid black",
                  px: 1,
                  mx: 1,
                  fontSize: "12px",
                  pt: 0.3,
                  height: "20px",
                  mt: 1,
                  }}
                  >
                {i.executor?.firstName || i.executor?.fullname}
              </Typography>
              <Typography
                sx={{
                  px: 1,
                  mx: 1,
                  fontSize: "12px",
                  pt: 0.3,
                  height: "20px",
                  mt: 1,
                  }}
                  >

                { i.action==="gameVerified"? ` ورژن ${i?.gameVersion?.version} بازی را تایید کرد`:` ورژن ${i?.gameVersion?.version} بازی را رد کرد`}
            
              </Typography>
            </div>
            <div style={{ paddingTop: 0 }}>
              <Typography style={{ paddingRight: "5rem", fontSize: "12px" }}>{formatJallali(i.updatedAt,{ time: true} )}</Typography>
           
            </div>
            </div>
            <div style={{paddingTop:"2rem" ,cursor:i?.action==="gameVerified" ?"unset":"pointer"}}>
           {i?.action==="gameVerified"?<img src={acceptDoc} alt="" style={{width:"32px",height:"32px"}}/>: <img onClick={()=>setOpenModal(true)} src={rejectReasonDoc} alt="" style={{width:"32px",height:"32px"}}/>}
           
            </div>
           </div>
           <Divider/>
          </div>
        );
      })}
      <Dialog open={openModal} onClose={()=>setOpenModal(false)}>
        <DetailRejectModal onClose={()=>setOpenModal(false)}/>
      </Dialog>
    </div>
</PageContainer>
  );
}
