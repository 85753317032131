import { Box, Button, CircularProgress, SxProps, Typography, styled } from "@mui/material";
import { Theme } from "@mui/system";
import { useEffect, useState } from "react";
import { useDropzone, Accept } from "react-dropzone";
import uploadIcon from "assets/uploadIcon.svg";
import upload from "assets/Folder_Upload.svg";
import { IloadingFile } from "pages/gameCategory/components/editCategory";

const CustomeBox = styled("div")(({ theme }) => ({
  textAlign: "center",
  border: "1px dashed #333337",
  cursor: "pointer",
  marginRight: "2rem",
  marginLeft: "2rem",
  marginBottom: "2rem",
  borderRadius: "8px",
}));
export default function Uploader({
  type,
  sx,
  label,
  accept,
  onDrop,
  loading,
  image,
  previewUrl,
  url,
  blobFile,
  avatar,
}: {
  type?: "image" | "gif" | "game" | "darkImage" | "darkGif" | undefined;
  sx?: SxProps<Theme>;
  label?: string;
  accept?: Accept;
  onDrop?: (files: File[], type: "image" | "gif" | "game" | "darkImage" | "darkGif") => void;
  loading?: IloadingFile | boolean;
  image?: boolean;
  previewUrl?: any;
  url?: any;
  blobFile?:any
  avatar?: boolean;
}) {
  const [files, setFiles] = useState<File[]>([]);
  useEffect(()=>{
    blobFile && setFiles([blobFile])
  },[blobFile])
  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop(acceptedFiles) {
      onDrop && type && onDrop(acceptedFiles, type);
      setFiles(acceptedFiles);
    },
  });
  const remove = (index: number) => {
    setFiles((p) => {
      const t = p.concat();
      t.splice(index, 1);
      return t;
    });
  };

  const isImage = Boolean(type === "image");
  const isGif = Boolean(type === "gif");
  const isDarkImage = Boolean(type === "darkImage");
  const isDarkgif = Boolean(type === "darkGif");
  const isGame = Boolean(type === "game");
  const title = (
    <Typography sx={{ textAlign: "center", fontSize: "12px", mb: 2 }}>
      {isImage
        ? "تصویر مربوطه(برای تم لایت)"
        : isGif
        ? "گیف مربوطه(برای تم لایت)"
        : isGame
        ? "بازی مربوطه"
        : isDarkImage
        ? "تصویر مربوطه(برای تم دارک)"
        : isDarkgif
        ? "گیف مربوطه (برای تم دارک)"
        : ""}
    </Typography>
  );
  const fileElements = files.map((file, i) => (
    <>
      {title}
      <CustomeBox
        sx={{
          width: !type ? "230px" : "150px",
          height: !type ? "200px" : "150px",
          px: !type ? 2 : "unset",

          backgroundColor: isDarkImage || isDarkgif ? "#676872" : "white",
          pt: 4,
        }}
      >
        {(isImage || isGif || isDarkImage || isDarkgif || !type) && (
          <Box>
            <Box>
              {" "}
              <img width="80px" height="80px" src={previewUrl} alt="b" />
            </Box>
            <Box>
              {" "}
              <Typography color="#4F48E4" fontWeight={700} style={{ textDecoration: "underLine", fontSize: "12px" }}>
                انتخاب فایل
              </Typography>
              
            </Box>
          </Box>
        )}

        {isGame && (
          <>
            <span>
              {file.name} - {file.size} bytes
            </span>
            <Button onClick={() => remove(i)}>❌</Button>
          </>
        )}
      </CustomeBox>
    </>
  ));

  return (
    <Box
      {...getRootProps({ className: "dropzone" })}
      component="section"
      sx={{ ...sx, bgcolor: isDragActive ? "#ccc" : "unset", cursor: "pointer" }}
    >
      <input {...getInputProps()} />
      <p>{label}</p>
      <aside>
        {(loading && typeof loading === "object" && loading[type as keyof IloadingFile]) ||
        (typeof loading === "boolean" && loading === true) ? (
          <>
            {title}
            <CustomeBox
              sx={{
                pt: 5,
                backgroundColor: isDarkImage || isDarkgif ? "#676872" : "white",
                width: !type ? "230px" : "150px",
                height: !type ? "200px" : "150px",
                px: !type ? 2 : "unset",
              }}
            >
              <CircularProgress />
            </CustomeBox>
          </>
        ) : (
          <>
            {fileElements?.length === 0 && (
              <label htmlFor={"x"}>
                {title}
                <CustomeBox
                  sx={{
                    backgroundColor: isDarkImage || isDarkgif ? "#676872" : "white",
                    width: !type ? "230px" : "150px",
                    height: !type ? "200px" : "150px",
                    px: !type ? 2 : "unset",
                  }}
                >
                  {image ? (
                    <>
                      <img src={url} alt="" style={{ marginTop: "25px" }} />
                      <Typography
                        color="#4F48E4"
                        fontWeight={700}
                        style={{ textDecoration: "underLine", fontSize: "12px" }}
                      >
                        انتخاب فایل
                      </Typography>
                    </>
                  ) : (
                    <>
                      <img alt="upload" src={uploadIcon} style={{ marginTop: "25px" }} />
                      <Typography sx={{ fontSize: "12px", color: isDarkImage || isDarkgif ? "white" : "unset" }}>
                        {" "}
                        برای بارگذاری بکشید و رها کنید یا
                      </Typography>
                      <Typography
                        my={2}
                        color="#4F48E4"
                        fontWeight={700}
                        style={{
                          textDecoration: "underLine",
                          fontSize: "12px",
                          color: isDarkImage || isDarkgif ? "#90ABFF" : "unset",
                        }}
                      >
                        انتخاب فایل
                      </Typography>
                    </>
                  )}
                </CustomeBox>
              </label>
            )}

            <Box sx={{ textAlign: "center" }}>{fileElements}</Box>
          </>
        )}
      </aside>
    </Box>
  );
}
