import useSWR from "swr";
import { createGame } from "api/game";
import { useState, ChangeEvent } from "react";
import { boolean, object, string } from "yup";
import { useTranslation } from "react-i18next";
import { useFormik, FormikValues } from "formik";

const useNewGame = () => {
  const { t } = useTranslation();

  // todo@sareh: change url using env
  const { data, error } = useSWR("https://bazi.express/api/v1/game/categories");
  const [gamePreview, setGamePreview] = useState<any>();
  const [isGamePreview, setIsGamePreview] = useState<boolean>(false);
  const [tagValue, setTagValue] = useState<string>("");

  const validationSchema = object({
    title: string().required(t("_errors.requiredValue")),
    category: string().required(t("_errors.requiredValue")),
    description: string().required(t("_errors.requiredValue")),
    // gameFieldId: string().required(t("_errors.requiredValue")),
    // coverGif: string().required(t("_errors.requiredValue")),
    // coverImage: string().required(t("_errors.requiredValue")),
  });

  const formik = useFormik({
    // todo@sareh: Add game type to init values
    initialValues: {
      title: "",
      category: "",
      description: "",
      gameFieldId: "",
      tags: [],
      ageRangeFrom: "",
      gameType: "",
    },
    validationSchema,
    onSubmit: async (formValues) => {
      await onSubmit(formValues);
    },
  });
  const { getFieldProps, handleSubmit, errors, values, setFieldValue } = formik;

  const onSubmit = async (formValues: FormikValues) => {
    setGamePreview({ ...formValues });
    setIsGamePreview(true);
  };

  const onBackClick = () => {
    setIsGamePreview(false);
  };

  const onSaveGame = async () => {
    await createGame(gamePreview);
  };

  const addNewTag = () => {
    const prevTags = values.tags;
    setFieldValue("tags", [...prevTags, tagValue]);

    setTagValue("");
  };

  const onTagChang = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTagValue(value);
  };

  const onDeleteTag = (value: string) => () => {
    const prevTags = values.tags;
    const tags = prevTags.filter((tag) => tag !== value);

    setFieldValue("tags", tags);
  };

  return {
    isGamePreview,
    categories: data?.categories,
    addGameProps: {
      errors,
      values,
      tagValue,
      addNewTag,
      onTagChang,
      onDeleteTag,
      handleSubmit,
      getFieldProps,
      setFieldValue,
    },
    previewProps: {
      onSaveGame,
      gamePreview,
      onBackClick,
    },
  };
};

export default useNewGame;
