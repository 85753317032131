import Preview from "./Preview";
import Img from "components/img/Img";
import { ICONS } from "assets/icons";
import { upload } from "utils/upload";
import { useDropzone } from "react-dropzone";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Theme, Typography, useTheme, FormHelperText } from "@mui/material";

const defaultStyles = (theme: Theme) => ({
  width: "11rem",
  height: "3.5rem",
  borderRadius: "8.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
  border: "1px solid",
  borderColor: theme.palette.text.secondary,
  color: theme.palette.text.secondary,
  fontSize: "1.3rem",
  fontWeight: 500,
  cursor: "pointer",
  margin: "0.6rem 0",
});

const imageStyles = (theme: Theme) => ({
  width: "20rem",
  height: "12.5rem",
  borderRadius: "0.7rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
  backgroundColor: "white",
  color: theme.palette.text.secondary,
  fontSize: "1.3rem",
  fontWeight: 400,
  cursor: "pointer",
  // margin: "0.6rem 0",
});

interface UPLOADED_FILE {
  name?: string;
  path?: string;
  size?: string;
  type?: string;
}

const Uploader = ({ type, onSaveFieldValue, fieldTitle, helperText, setFieldTouched,defaultPicture }: any) => {
  const [file, setFile] = useState<UPLOADED_FILE>();
  const [previewUrl, setPreviewUrl] = useState<any>();
  const { t } = useTranslation();
  const theme = useTheme();
  const onDrop = useCallback(async (acceptedFiles: any[]) => {
    // call server to upload data
    try {
      const uploadedFile = acceptedFiles[0];
      const response = await upload({ contentType: type === "gif" ? "image" : type, file: uploadedFile });
      if (isImage || type === "gif") {
        const imageUrl = URL.createObjectURL(uploadedFile);
        setPreviewUrl(imageUrl);
      }
      onSaveFieldValue(fieldTitle, response.fileId, true);
      setFile(acceptedFiles[0]);
    } catch (error) {}
  }, []);

  const isImage = type === "image";
  const styles = isImage ? imageStyles(theme) : defaultStyles(theme);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
        gap: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: isImage ? "end" : "center",
          gap: "1rem",
        }}
      >
        {file || defaultPicture ? (
          <Box>
            <Preview key={file?.name} {...{ file, previewUrl, type ,defaultPicture}} />
          </Box>
        ) : (
          <Box
            {...getRootProps()}
            sx={{
              ...styles,
            }}
          >
            <input {...getInputProps()} />
            {isImage ? (
              <>
                <Typography>{t("addImage")}</Typography>
                <Img
                  src={ICONS.CAMERA}
                  alt="image"
                  sx={{
                    width: "2rem",
                    height: "2rem",
                    cursor: "pointer",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 400,

                    color: "text.secondary",
                  }}
                >
                  500*250pxl
                </Typography>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Typography>{t("upload")}</Typography>
                <Img
                  src={ICONS.UPLOAD}
                  alt="delete"
                  sx={{
                    width: "2rem",
                    height: "2rem",
                    cursor: "pointer",
                  }}
                />
              </Box>
            )}
          </Box>
        )}

        {isImage && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <Img
              src={ICONS.EDIT_PENCIL}
              alt="edit"
              sx={{
                width: "2rem",
                height: "2rem",
                cursor: "pointer",
              }}
            />
            <Img
              src={ICONS.TRASH_EMPTY}
              alt="delete"
              sx={{
                width: "2rem",
                height: "2rem",
                cursor: "pointer",
              }}
            />
          </Box>
        )}
      </Box>
      {helperText && <FormHelperText sx={{ marginTop: isImage ? "0.6rem" : 0 }}>{helperText}</FormHelperText>}
    </Box>
  );
};

export default Uploader;
