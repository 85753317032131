import { AppBar, Box, Button, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PDFPreview from "shared/Pdf/Viewer";

export default function Preview({
  onClose,
  open,
  src,
  type,
  title,
}: {
  onClose: () => void;
  open: boolean;
  src: string;
  type: string;
  title?: string;
}) {
  return (
    <Box sx={{ minWidth: "500px", textAlign: "center" }}>
      <Box sx={{ borderBottom: "0.5px solid grey", display: "flex", justifyContent: "space-between", py: 2 }}>
        <IconButton edge="start" onClick={onClose} sx={{ mx: 2 }}>
          <CloseIcon />
        </IconButton>
        <Button variant="contained" sx={{ boxShadow: 0, px: 6, mx: 2 }}>
          <a href={src} download style={{ textDecoration: "none", color: "black", fontSize: "14px" }}>
            دانلود
          </a>
        </Button>
      </Box>

      {type === "pdf" ? (
        <PDFPreview
          pdf={src}
          width={"100%"}
          style={{ justifyContent: "center", minHeight: "800px", display: "flex", overflowY: "hidden" }}
        />
      ) : (
        <>
          <Typography sx={{ fontSize: "13px", fontWeight: 600, mt: 2 }}> {title} </Typography>
          <img
            src={src}
            width={450}
            height={300}
            alt="card"
            style={{
              borderRadius: "12px",
              marginTop: "2rem",
              objectFit: "cover",
            }}
          />
        </>
      )}
    </Box>
  );
}
