const getDirection = (language: string): "rtl" | "ltr" => {
  switch (language) {
    case "fa":
      return "rtl";

    default:
      return "ltr";
  }
};

export { getDirection };
