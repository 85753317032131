import { Button } from "@mui/material";
import React from "react";
import { ICONS } from "assets/icons";

const FileDownloader = ({ url ,type}: { url: string ,type?:string}) => {
  const isAdmin=type==="admin"
  const handleDownload = () => {
    // const url = 'https://benchgame.ir/storage/images/666ed73660f2de611d2eff99';
    const fileName = "downloaded_file.jpg"; // Adjust the file extension as needed
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => console.error("Download failed:", error));
  };

  return (
    <Button
      onClick={handleDownload}
      sx={{ color: "black" ,bgcolor:isAdmin?"#FBC349": "#FFFFFF", "&:hover": {bgcolor:isAdmin?"#FBC349": "#FFFFFF" } ,px:2,fontSize:"small",mx:1,mt:2}}
      startIcon={<img src={ICONS.DOWNLOAD} alt="" />}
    >
      پیوست فایل
    </Button>
  );
};

export default FileDownloader;
