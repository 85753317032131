import { IGameCategoryType } from "api/gameCategory";
import { IImageType } from "./image";
import { delete_, post, put, get } from "api";
import { AUTH_BASE_URL } from "./config";

const baseUrl = `${AUTH_BASE_URL}/game`;
export type IGameType = {
  verified: boolean;
  _id: string;
  publisher: {
    _id: string;
    fullname: string;
    username: string;
    coverImage: IImageType;
    profilePicture: IImageType;
    bio: string;
  };
  title: string;
  activeVersion: any;
  category: IGameCategoryType;
  gameVersionControl: string;
  versionChanges: string;
  tags: any;
  mobileImage: IImageType;
  image: IImageType;
  description: string;
  gameType: string;
  otherMarketsLinks: any[];
  ageRangeFrom: number;
  publishStatus: string;
  gameDirection: any;
  fullscreenCompatible: boolean;
  iosCompatible: boolean;
  androidCompatible: boolean;
  coverImage: IImageType;
  coverGif: IImageType;
  gameFilePath: string;
  mouseControl: any;
  keysControl: any;
  rate: number;
  rateCount: number;
  likeCount: number;
  commentCount: number;
  playCount: number;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  coverImageId: string;
  gameFileId: string;
  totalPlayTimeAmount: number;
  totalPlayersPlayedCount: number;
  coverGifId: string;
  version: string;
  executer: any;
  __v: number;
  versions: any;
};
export type activityLogType = {
  action: string;
  createdAt: string;
  executor: executorType;
  game: IGameType;
  gameVersion: gameVersionType;
  rejectReasons: any[];
  updatedAt: string;
}
export type gameVersionType = {
  createdAt: string;
  deleted: false;
  game: IGameType;
  gameFileId: string;
  gameFilePath: string;
  publishDate: string;
  publishStatus: string;
  publisher: publisherType;
  rejectReasons: any[];
  rejectedBy: null;
  updatedAt: string;
  verifiedBy: string;
  version: string;
  versionChanges: string;
  __v: number;
  _id: string;
};
export type executorType = {
  bio: string;
  coverImage: IImageType;
  firstName: string;
  fullname: string;
  lastName: string;
  profilePicture: IImageType;
  roles: any[];
};
export type publisherType = {
  coverImage: IImageType;
  fullname: string;
  profilePicture: IImageType;
  username: string;
  _id: string;
  rejectReasons: any[];
  rejectedBy: any;
  updatedAt: string;
  verifiedBy: string;
  version: string;
  versionChanges: string;
  __v: 0;
};
export type keysControlType = { alt: boolean; ctrl: boolean; description: string; key: string; shift: false };
export const updateGame = (data: { verified: boolean }, id: string) => {
  return put(`/game/${id}`, data);
};
export const changeGamePublishStatus = (active:boolean, gameId: string,versionId:string) => {
  return put(`/game/${gameId}/version/${versionId}`,{active:active});
}
export const deleteGame = (id: string) => {
  return delete_(`/game/${id}`);
};
export const createGame = (data: any) => post(baseUrl, data);
export const getGame = (id?: string) => get(`${baseUrl}/details/${id}`);
export const rejectGame = (
  gameId: string,
  versionId: string,
  data?: {
    rejectReasons: [
      {
        title: string;
        description: string;
      }
    ];
  }
) => put(`/game/${gameId}/reject/${versionId}`, data);

export const verifyGame = (gameId: string, versionId: string, data?: { publishDate: string }) =>
  put(`/game/${gameId}/verify/${versionId}`, data);
