import { BackdropProps } from "@mui/material";
import { post, put } from "api";
import { boolean } from "yup";

export type adsType = {
  id?: string;
  _id: string;
  uniqueIdentifier: string;
  provider: "googleAds" | "yektanet" | "tapcell";
  type: "banner" | "video";
  bannerAds: {
    code: string;
    link: string;
    width: number;
    height: number;
    createdAt: string;
    updatedAt: string;
  };
  videoAds: {
    code: string;
    link: string;
    createdAt: string;
    updatedAt: string;
  };
  location: string;
  weight: number;
  active: boolean;
  adsStats: [
    {
      view: number;
      click: number;
      startTime: string;
      endTime: string | null;
      income: number;
      _id: string;
    }
  ];
  totalAdsStats: {
    startTime: string;
    endTime: string | null;
    _id: string;
  };
  createdAt: string;
  updatedAt: string;
  __v: number;
};

export type bannerType = {
  uniqueIdentifier: string;
  provider: "yektanet" | "googleAds" | "tapcell";
  code: string; // code generated by ad provider
  location: "site_left" | " site_bottom" | "game_inner";
  active: boolean;
  isResponsive: boolean;
  adsLink: string;
  width: number;
  height: number;
  weight?: number;
  type?: "banner" | "video" | "native";
};

export type videoType = {
  uniqueIdentifier: string;
  provider: "yektanet" | "googleAds" | "tapcell";
  code: string;
  active: boolean;
  isResponsive?: boolean;
  adsLink: string;
  location: string;
  width?: number;
  height?: number;
};

export const changeActive = (
  data: {
    active?: boolean;
    weight?: number;
  },

  adsId: string
) => {
  return put(`/ads/${adsId}/activeStatus`, data);
};

export const addBannerAd = (data: bannerType) => {
  return post("/ads/banner", data);
};

export const addVideoAd = (data: videoType) => {
  return post("/ads/video", data);
};
