import { PaletteMode } from "@mui/material";

export const THEME: { [key: string]: PaletteMode } = {
  DARK: "dark",
  LIGHT: "light",
};

export const LAYOUT = {
  DRAWER_WIDTH: "27rem",
  HEADER_HEIGHT: "6rem",
};
