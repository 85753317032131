import { post, delete_ } from "api";
import axios from "axios";
import { AUTH_BASE_URL } from "./config";

export type IImageType = {
  contentType: string;
  createdAt: string;
  owner: string;
  updatedAt: string;
  uploadStatus: string;
  url: string;
  __v: number;
  _id: string;
  fileSizeBytes: number;
};
export type IAllFilesType = {
  contentType: string;
  createdAt: string;
  owner: string;
  updatedAt: string;
  uploadStatus: string;
  url: string;
  __v: number;
  _id: string;
};

export const getUploadLink = (contentType: "image" | "game") => {
  return post<{
    uploadLink: string;
    fileId: "string";
  }>(`${AUTH_BASE_URL}/file/upload/link/${contentType}`);
};

export const getUploadLink2 = (contentType: "image" | "game") => {
  return post<{
    uploadLink: string;
    fileId: string;
  }>(`https://bazi.express/api/v1/file/upload/link/${contentType}`);
};

export const uploadFile = (url: string, file: string | File, contentType: "image" | "game") => {
  console.log("url", url);
  if (contentType === "game") {
    return axios.put(url, file, {
      headers: { "Content-Type": "application/zip", Accept: "*/*" },
    });
  } else {
    return axios.put(url, file, {
      headers: { "Content-Type": "application/image", Accept: "*/*" },
    });
  }
};

export const uploadDone = (fileId: string) => {
  return post<{
    status: string;
  }>(`${AUTH_BASE_URL}/file/upload/done/${fileId}`);
};

export const upload = async ({ contentType, file }: { contentType: "image" | "game"; file: string | File }) => {
  try {
    const link = await getUploadLink(contentType);
    console.log("link", link);
    if (!link?.fileId || !link?.uploadLink) {
      throw new Error("Can't get upload link");
    }

    await uploadFile(link.uploadLink, file, contentType);
    await uploadDone(link.fileId);

    return { msg: "Done", ...link };
  } catch (error) {
    throw error;
  }
};
export const deleteFile = async (fileId: string) => {
  return delete_(`${AUTH_BASE_URL}/file/${fileId}`);
};

export const uploadEx = async (contentType: "image" | "game", file: string | File): Promise<any> => {
  try {
    const link = await getUploadLink(contentType);
    console.log(link);
    if (!link?.fileId || !link?.uploadLink) {
      throw new Error("Can't get upload link");
    }

    await uploadFile(link.uploadLink, file, contentType);
    await uploadDone(link.fileId);

    return { msg: "Done", ...link };
  } catch (error) {
    throw error;
  }
};
