import { DatePickerInput } from "./DatePickerInput";
import BaseDatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

const DatePicker = ({ helperText, error, disable,value, ...rest }: any) => {
  
  return (
    <BaseDatePicker
      disabled={disable}
      calendar={persian}
      locale={persian_fa}
      value={value}
      render={<DatePickerInput {...{ helperText, error,value }} />}
      {...rest}
    />
  );
};

export default DatePicker;
