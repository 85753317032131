import { Box, Grid, Typography, Avatar, styled } from "@mui/material";
import { ReportType } from "api/chat";
import show from "assets/Show.svg";
import block from "assets/block.png";
import { formatJallali } from "utils/date";

const TilteTypography = styled(Typography)({
  fontSize: "12px",
  color: "#676872",
  marginTop: "0.1rem",
  padding: "0px 5px",
});
export default function Report({ report, onSetAnchorE }: { report: ReportType; onSetAnchorE: (e: any) => void }) {
  return (
    <Grid container sx={{ py: 3, borderTop: "1.5px solid #C0C1CE" }}>
      <Grid item xs={4}>
        <Box sx={{ display: "flex", pb: 3, justifyContent: "space-between", pr: 3 }}>
          <Typography>{formatJallali(report?.createdAt, { time: true })}</Typography>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "12px", px: 0.5 }}>گزارش کاربر از</Typography>
            <TilteTypography sx={{ color: "#4A76FD" }}>
              {report?.reportedBy?.username || report?.reportedBy?.fullname || "ananymous"}
            </TilteTypography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontSize: "13px" }}>علت ذکر شده:</Typography>
          <TilteTypography>{`${report?.title} | ${report?.description}`}</TilteTypography>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Box>
          <Grid
            container
            sx={{
              pr: 1,
              py: 0.5,
              bgcolor: "#F5F6FA",
              cursor: "pointer",
            }}
          >
            <Grid item xs={1}>
              <Avatar
                src={report?.chatMessage?.sender?.coverImage?.url || report?.chatMessage?.sender?.profilePicture?.url}
              />
            </Grid>
            <Grid item xs={0.5}>
              <Typography sx={{ fontSize: "11px", pt: 1.3 }}>
                {new Intl.DateTimeFormat("fa-IR", { timeStyle: "short" }).format(
                  new Date(report.chatMessage.updatedAt)
                )}
              </Typography>
            </Grid>
            <Grid item xs={1} sx={{ fontSize: "13px", pt: 0.9, opacity: "70%", px: 0.3 }}>
              <TilteTypography>
                {report.chatMessage.sender.username || report.chatMessage.sender.fullname}
              </TilteTypography>
            </Grid>
            <Grid item xs={8.5} sx={{ textAlign: "start", pt: 0.7, px: 1 }}>
              <Typography sx={{ fontSize: "13px" }}>{report.chatText}</Typography>
            </Grid>
            <Grid
              item
              xs={0.5}
              sx={{
                pt: 0.5,
                cursor: "pointer",
                
              }}
            >
              {/* <img src={show} alt="" width={20} height={20} /> */}
              <img src={block} alt="" width={20} height={20} onClick={(e: any) => onSetAnchorE(e.target)} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
