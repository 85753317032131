import React, { Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useAuthStatus } from "../features/admin/hooks";
import Games from "../pages/games";
import { AUTH_STATUS } from "../types/status";
import { ROUTES } from "../constants/routes";
import { Layout } from "../components";
import UserDetails from "../pages/users/UserDetails";
import UserGames from "../pages/users/UserGames";
import UserTickets from "../pages/users/userTickets";
import Ticket from "../pages/ticket";
import NewGame from "../pages/newGame";
import Game from "../pages/game";
import Leagues from "../pages/leagues";
import Dashboard from "../pages/dashboard";
import GameCategory from "../pages/gameCategory";
import { Box } from "@mui/material";
import Tags from "../pages/tags/Tags";
import Support from "../pages/support";
import TicketsCategories from "pages/ticketsCategories/TicketCategories";
import LeagueManagement from "pages/leagues/leagueManagement";
import BannerList from "pages/leagues/leagueManagement/bannerList";
import NewLeagueSlider from "pages/leagues/leagueManagement/newLeagueSlider";
import InnerLeague from "pages/leagues/[id].tsx";
import InnerLeagueDetails from "components/league/innerLeagueDetails";
import InnerLeagueUsersReport from "components/league/innerLeagueUsersReport";
import InnerLeagueBlockedUsers from "components/league/innerLeagueBlockedUsers";
import InnerLeagueScoreBoard from "components/league/innerLeagueScorBoard";
import InnerLeagueComminucation from "components/league/innerLeagueComminucation";
import NewLeague from "pages/newLeague";
import PublishedGame from "pages/publishedGame";
import Details from "pages/publishedGame/details";
import Statistic from "pages/publishedGame/statistic";
import Versions from "pages/publishedGame/versions";
import Advertisement from "pages/publishedGame/advertisement";
import History from "pages/publishedGame/history";
import Preview from "pages/publishedGame/preview";
import ManageAds from "pages/manageAds";
import AdsStatistics from "pages/manageAds/AdsStatistics";

import AdsList from "pages/manageAds/adsList";
import AdListsDetails from "pages/manageAds/adsList/[id]";
import Statistics from "pages/manageAds/adsList/[id]/statistics";
import Detail from "pages/manageAds/adsList/[id]/detail";
import AdsCompanies from "pages/manageAds/adCompany";
import AdCompaniesDetails from "pages/manageAds/adCompany/[id]";
import CompanyInfo from "pages/manageAds/adCompany/[id]/detail";
import CompanyPeople from "pages/manageAds/adCompany/[id]/companyPeople";
import CompanyStatistics from "pages/manageAds/adCompany/[id]/statistics";

const Panel = React.lazy(() => import("layout/Panel"));
const CleanLayout: any = React.lazy(() => import("layout/Clean"));
const BlogCategoryDetail = React.lazy(() => import("pages/blogCategory/categoryDetail"));
const BlogCategory = React.lazy(() => import("pages/blogCategory"));
const Users = React.lazy(() => import("pages/users"));
const Blogs = React.lazy(() => import("pages/blogs"));
const BlogDetails = React.lazy(() => import("pages/blogs/blogDetails"));
const DefaultProfiles = React.lazy(() => import("pages/defaultProfiles"));
const NotFound = React.lazy(() => import("pages/404"));
const Login = React.lazy(() => import("pages/login"));
const Loading = React.lazy(() => import("pages/Loading"));

export default function Router() {
  const authStatus = useAuthStatus();

  if (authStatus === AUTH_STATUS.PENDING || authStatus === "idle") {
    return <Loading />;
  }
  if (authStatus === AUTH_STATUS.UNAUTHORIZED) {
    return (
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<CleanLayout />}>
            <Route index element={<Navigate replace to="/login" />} />
            <Route
              path="login"
              element={
                <Box>
                  <Login />
                </Box>
              }
            />
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Route>
        </Routes>
      </Suspense>
    );
  }

  return (
    <AnimatePresence>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={ROUTES.HOME} element={<Layout />}>
            <Route path={ROUTES.ADVERTISEMENT} element={<ManageAds />}>
              <Route index element={<Navigate to={ROUTES.ADS_STATISTICS} />} />
              <Route path={ROUTES.ADS_STATISTICS} element={<AdsStatistics />} />
              <Route path={ROUTES.ADS_COMPANY} element={<AdsCompanies />} />
              <Route path={ROUTES.ADS_LIST} element={<AdsList />} />
            </Route>
            <Route path={ROUTES.ADS_LIST_DETAILS} element={<AdListsDetails />}>
              <Route index element={<Navigate to={ROUTES.ADS_LIST_DETAILS_DETAILS} />} />
              <Route path={ROUTES.ADS_LIST_DETAILS_DETAILS} element={<Detail />} />
              <Route path={ROUTES.ADS_LIST_DETAILS_STATISTICS} element={<Statistics />} />
            </Route>
            <Route path={ROUTES.ADS_COMPANIES_DETAILS} element={<AdCompaniesDetails />}>
              <Route index element={<Navigate to={ROUTES.ADS_COMPANIES_DETAILS_DETAILS} />} />
              <Route path={ROUTES.ADS_COMPANIES_DETAILS_DETAILS} element={<CompanyInfo />} />
              <Route path={ROUTES.ADS_COMPANIES_DETAILS_RELATED_PEOPLE} element={<CompanyPeople />} />
              <Route path={ROUTES.ADS_COMPANIES_DETAILS_STATISTICS} element={<CompanyStatistics />} />
            </Route>
            <Route path={ROUTES.USERS} element={<Users type={"user"} />} />
            <Route path={ROUTES.USER} element={<UserDetails type={"user"} />} />
            <Route path={ROUTES.PUBLISHER} element={<UserDetails type={"publisher"} />} />
            <Route path={ROUTES.DEVELOPERS} element={<Users type={"publisher"} />} />
            <Route path={ROUTES.HOME} element={<Navigate to={ROUTES.DASHBOARD} />} />
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route path={ROUTES.USER_GAMES_BASE} element={<UserGames />} />
            <Route path={ROUTES.USER_TICKETS_BASE} element={<UserTickets />} />
            <Route path={ROUTES.USER_TICKET_BASE} element={<Ticket />} />
            <Route path={ROUTES.GAMES} element={<Games />} />
            <Route path={ROUTES.GAME_BASE} element={<Game />} />
            <Route path={ROUTES.PUBLISHED_GAME} element={<PublishedGame />}>
              <Route index element={<Navigate to={ROUTES.PUBLISHED_GAME_DETAILS} />} />
              <Route path={ROUTES.PUBLISHED_GAME_DETAILS} element={<Details />} />
              <Route path={ROUTES.PUBLISHED_GAME_STATISTIC} element={<Statistic />} />
              <Route path={ROUTES.PUBLISHED_GAME_VERSIONS} element={<Versions />} />
              <Route path={ROUTES.PUBLISHED_GAME_ADVERTISEMENT} element={<Advertisement />} />
              <Route path={ROUTES.PUBLISHED_GAME_HISTORY} element={<History />} />
              <Route path={ROUTES.PUBLISHED_GAME_PREVIEW} element={<Preview />} />
            </Route>
            <Route path={ROUTES.NEW_GAME} element={<NewGame />} />
            <Route path={ROUTES.LEAGUES} element={<Leagues />} />
            <Route path={ROUTES.INNERLEAGUE} element={<InnerLeague />}>
              <Route index element={<Navigate to={ROUTES.DETAILS} />} />
              <Route path={ROUTES.DETAILS} element={<InnerLeagueDetails />} />
              <Route path={ROUTES.COMMINUCATION} element={<InnerLeagueComminucation />} />
              <Route path={ROUTES.USERSREPORTS} element={<InnerLeagueUsersReport />} />
              <Route path={ROUTES.BLOCKEDUSERS} element={<InnerLeagueBlockedUsers />} />
              <Route path={ROUTES.SCORBOARD} element={<InnerLeagueScoreBoard />} />
            </Route>
            <Route path={ROUTES.CATEGORIES} element={<GameCategory />} />
            <Route path={ROUTES.SUPPORT} element={<Support />} />
            <Route path={ROUTES.AVATARS} element={<DefaultProfiles />} />
            <Route path={ROUTES.TICKETSCATEGORIES} element={<TicketsCategories />} />
            <Route path={ROUTES.TAGS} element={<Tags />} />
            <Route path={ROUTES.NEW_LEAGUE} element={<NewLeague />} />
            <Route path={ROUTES.LEAGUESMANAGMENT} element={<LeagueManagement />}>
              <Route index element={<Navigate to={ROUTES.LEAGUESBANNER} />} />
              <Route path={ROUTES.LEAGUESBANNER} element={<BannerList type="banner" />} />
              <Route path={ROUTES.LEAGUESSLIDER} element={<BannerList type="slider" />} />
            </Route>
            <Route path={ROUTES.NEWLEAGUESLIDER} element={<NewLeagueSlider type="slider" />} />
            <Route path={ROUTES.NEWLEAGUEBANNER} element={<NewLeagueSlider type="banner" />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
}
