import { Box, Typography, IconButton } from "@mui/material";
import { ICONS } from "assets/icons";
import { Img, TextField } from "components";
import { useTranslation } from "react-i18next";

export const ItemContainer = (props: any) => {
  const { t } = useTranslation();
  const { title, children, subtitle } = props;

  return (
    <Box
      sx={{
        display: "flex",
        gap: "2.87rem",
        alignItems: "start",
        marginTop: "2.6rem",
        ":first-of-type": {
          marginTop: "0",
        },
      }}
    >
      <Typography
        sx={{
          minWidth: "16rem",
          width: "16rem",
          fontSize: "1.5rem",
          fontWeight: 500,
          color: "#676872",
          textAlign: "start",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          {t(title)}
          {subtitle && (
            <Typography sx={{ fontWeight: 300, fontSize: "1.2rem", color: "#676872" }}>{subtitle}</Typography>
          )}
        </Box>
        <br />
        <br />
        {(props.type === "gifUploader" || props.type === "imageUploader") && (
          <div style={{ width: "50px", paddingRight: "2rem", fontSize: "12px", color: "#676872" }}>233*214 </div>
        )}
      </Typography>

      <Box sx={{ width: "50rem" }}>{children}</Box>
    </Box>
  );
};

export const SubItemContainer = (props: any) => {
  const { t } = useTranslation();
  const { title, children, titleWidth, hasMargin } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: hasMargin && "1rem",
      }}
    >
      <Typography
        sx={{
          minWidth: titleWidth,
          width: titleWidth,
          fontSize: "1.5rem",
          fontWeight: 400,
        }}
      >
        {t(title)}
      </Typography>
      <Box sx={{ flex: 1 }}>{children}</Box>
    </Box>
  );
};

export const LimitedTextField = ({ maxLength, value, ...rest }: any) => {
  return (
    <TextField
      inputProps={{ maxLength }}
      fullWidth
      value={value}
      endAdornment={
        <Typography
          sx={{
            fontSize: "1.2rem",
            color: "gray.light",
          }}
        >{`${value?.length || 0}/${maxLength}`}</Typography>
      }
      {...rest}
    />
  );
};

export const LeaguePrizes = (props: any) => {
  return <Box></Box>;
};

export const NumericTextField = (props: any) => (
  <TextField
    sx={{
      "& .MuiOutlinedInput-root": {
        paddingRight: 0,
        overflow: "hidden",
      },
    }}
    endAdornment={
      <Box
        sx={{
          borderRadius: 0,
          height: "100%",
          aspectRatio: "1",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IconButton sx={{ padding: 0, width: "100%", height: "50%", borderRadius: 0 }}>
          <Img
            src={ICONS.UP}
            alt="up"
            sx={{
              width: "2rem",
              height: "2rem",
            }}
          />
        </IconButton>
        <IconButton sx={{ padding: 0, width: "100%", height: "50%", borderRadius: 0 }}>
          <Img
            src={ICONS.DOWN}
            alt="down"
            sx={{
              width: "2rem",
              height: "2rem",
            }}
          />
        </IconButton>
      </Box>
    }
    {...props}
  />
);
