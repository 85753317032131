import { useMemo, useState } from "react";
import moment from "jalali-moment";
import { ICONS } from "assets/icons";
import { Checkbox, Img, Table } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Avatar, Grid, Box, Typography } from "@mui/material";
import { ITicketType } from "api/ticket";
import PageContainer from "components/PageContainer";

interface UsersListProps {
  type?: "user" | "publisher";
  tickets: ITicketType[];
  total?: number;
  onSelectionModelChange?: (ids: string[]) => void;
  selectedItems?: string[];
  role?: string;
  onPageChange?: (page: number) => void;
  setSelectedItems?: () => void;
}

const TicketList = ({
  tickets,
  type,
  onPageChange,
  total,
  setSelectedItems,
  selectedItems,
  onSelectionModelChange,
}: UsersListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    const commonColumns = [
      {
        field: "ticketId",
        headerName: t("ticketId"),
        flex: 2,
        renderCell({ row }: any) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Typography sx={{ fontSize: "1.3rem", fontWeight: 300 }}>{row.ticketId}</Typography>
            </Box>
          );
        },
      },
      {
        field: "title",
        headerName: t("title"),
        flex: 2,
        renderCell({ row }: any) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Typography sx={{ fontSize: "1.3rem", fontWeight: 300 }}>{row.title}</Typography>
            </Box>
          );
        },
      },
      {
        field: "category",
        headerName: t("category"),
        flex: 3,
        renderCell({ row }: any) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <img src={ICONS.CATEGORY} alt="category" />
              <Typography sx={{ fontSize: "1.3rem", fontWeight: 300 }}>
                {row.category ? row.category : "دسته بندی نامشخص"}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "userInfo",
        headerName: t("userInfo"),
        flex: 2,
        renderCell({ row }: any) {
          return (
            <>
              <Avatar sx={{ width: 30, height: 30, mx: 1 }} src={row?.user?.profilePicture?.url} sizes="small" />
              {row.userRole[0] === "publisher" ||
                (row.userRole[0] === "admin" && <img src={ICONS.PUBLISHERROLE} alt="tag" />)}
              <Typography> {row?.user?.username}</Typography>
            </>
          );
        },
      },
      {
        field: "updatedAt",
        headerName: t("updatedAt"),
        flex: 2,
        renderCell({ row }: any) {
          return (
            <Typography sx={{ fontSize: "1.3rem", fontWeight: 300 }}>{`${moment
              .utc(row.updatedAt)
              .locale("fa")
              .local()
              .format("YYYY/MM/DD")}`}</Typography>
          );
        },
      },
    ];

    return commonColumns;
  }, [t, type]);

  const onRowClick = (row: any) => {
    row.row?.user && row.row && navigate(`/users/user/tickets/${row.row?._id}/${row.row?.user?._id}`);
  };

  return (
    <PageContainer noPadding={true}>
      <Grid
        sx={{
          backgroundColor: "white",
          borderRadius: "1.7rem",
          width: "100%",
        }}
      >
        <Table
          columns={columns}
          rows={tickets}
          onRowClick={onRowClick}
          getRowId={(row: any) => row._id}
          checkboxSelection
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={selectedItems}
        />
      </Grid>
    </PageContainer>
  );
};

export default TicketList;
