import { Box, Button, IconButton, LinearProgress, SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { useState } from "react";
import { useDropzone, Accept } from "react-dropzone";
import { ICONS } from "assets/icons";
import UploadListItem from "./UploadListItem";
import { deleteFile } from "api/file";
import { toast } from "react-toastify";
import { CloseRounded } from "@mui/icons-material";
export default function UploaderImage({
  sx,
  label,
  accept,
  onDrop,
  loading,
  fileId,
}: {
  sx?: SxProps<Theme>;
  label?: string;
  accept?: Accept;
  onDrop?: (files: File[]) => void;
  loading?: boolean;
  fileId?: any;
}) {
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(-1);
  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    accept,

    onDrop(acceptedFiles) {
      onDrop && onDrop(acceptedFiles);
      setFiles([...files, ...acceptedFiles]);
    },
  });

  const remove = async (index: number) => {
    await deleteFile(fileId);
    toast.success("فایل مورد نظر حذف شد ");
    setFiles((p) => {
      const t = p.concat();
      t.splice(index, 1);
      return t;
    });
  };

  const fileElements = files.map((file, i: number) => {
    return (
      <div key={i}>
        {/* {loading ? (
          <Box display="flex">
            <IconButton color="error" onClick={() => () => remove(i)}>
              <CloseRounded />
            </IconButton>
            <LinearProgress color="primary" sx={{ height: "10px", width: "150px", borderRadius: "3px", mt: 1.75 }} />
          </Box>
        ) : ( */}
          <UploadListItem key={i} fileName={file.name} loading={loading} onRemove={() => remove(i)} />
        {/* // )} */}
      </div>
    );
  });

  return (
    <Box
      {...getRootProps({ className: "dropzone" })}
      component="section"
      sx={{ ...sx,
       
       bgcolor: isDragActive ? "#ccc" : "unset", cursor: "pointer" 
        }}
    >
      <div>
        <input {...getInputProps()} />
        <p>{label}</p>
      </div>
      <aside>
        <>
          <Box sx={{display:"flex",justifyContent:"flex-end"}}>
            <Button
              startIcon={<img alt="upload" src={ICONS.ATTACHMENT} />}
              variant="outlined"
              sx={{
                border: "1px solid #C5C8D2",
                height: "40px",
                mb: "10px",
                color: "#C5C8D2",
                "&:hover": {},
              }}
              onClick={() => {}}
            >
              پیوست{" "}
            </Button>
          </Box>
          {loading &&
            files.map((file, i: number) => {
              return (
                <div key={i}>
                  <UploadListItem fileName={file.name} loading={loading} onRemove={() => remove(i)} />
                </div>
              );
            })}

          {files?.length === 0 ? <></> : !loading && <>{fileElements}</>}
        </>
      </aside>
    </Box>
  );
}
