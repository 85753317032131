
import { ClickAwayListener, Paper, Popper, PopperPlacementType } from "@mui/material";
export default function SearchResult({
  open,
  height,
  width,
  anchorEl,
  placement,
  onClose,
}: {
  open?: boolean;
  anchorEl?: HTMLElement;
  width?: number | string;
  height?: number | string;
  placement?: PopperPlacementType;
  onClose: () => void;
}) {
  let content;
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        disablePortal
        open={open !== undefined ? open : Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={{ zIndex: 9999 }}
        placement={placement}
      >
        <Paper elevation={1} sx={{ width: width || 360, maxHeight: height || 300, overflow: "auto", marginX: "8px" }}>
          {content}
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
}
