import { Box, Typography } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import DataGrid from "components/datagrid";
import { useOutletContext } from "react-router-dom";
import { adsType } from "api/ads";
import { useDashboardScrollBar } from "components/share/useStyle";
type AdListDetailsContext = [adsType[]];

export default function StateTable() {
  const classes = useDashboardScrollBar();
  const [filterAdsById] = useOutletContext<AdListDetailsContext>();

  function convertDate(date: string){
    const persianDate = new Date(date).toLocaleDateString('fa-IR');
    return(persianDate)
  }

  const rows = filterAdsById[0].adsStats;
  const columns: GridColumns = [
    {
      field: "startTime",
      headerName: "تاریخ شروع",
      flex: 0.5,
      renderCell({ row }: any) {
        return <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{convertDate(row.startTime)}</Typography>;
      },
    },
    {
      field: "endTime",
      headerName: "تاریخ پایان",
      flex: 0.5,
      renderCell({ row }: any) {
        return <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{row.endTime ? convertDate(row.endTime) : ''}</Typography>;
      },
    },
    {
      field: "display",
      headerName: "نمایش",
      flex: 1,
      renderCell({ row }: any) {
        return <Box sx={{ display: "flex", gap: 1 }}>{row.view}</Box>;
      },
    },
    {
      field: "click",
      headerName: "کلیک",
      flex: 1,
      renderCell({ row }: any) {
        return (
          <Typography sx={{ fontSize: "1.3rem", fontWeight: 400, color: !row.active ? "#F84E58" : "unset" }}>
            {row.click}
          </Typography>
        );
      },
    },
    {
      field: "income",
      headerName: "درآمد",
      flex: 0.7,
      renderCell({ row }: any) {
        return <Typography sx={{ fontSize: "1.3rem", fontWeight: 400 }}>{` ${row.income} ریال `}</Typography>;
      },
    },
  ];

  return (
    <Box sx={{ minHeight: "500px", pt: 5, width:'99%', alignContent:'center'}}>
      <DataGrid
        /*className={classes.root}
        style={{
          overflowY: "scroll",
          marginTop: "1.6rem",
          height: "500px",
          overflowX: "hidden",
        }}*/
        sx={{
          fontSize: "12px",
          cursor: "pointer",
          border: "1px solid",
          borderColor: "transparent",
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
        columns={columns}
        rows={rows?.map((row:any)=>({ ...row, id: row._id }))||[]}
      />
    </Box>
  );
}
