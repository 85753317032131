import { get, post } from "api";
import { AUTH_BASE_URL } from "./config";
import { IUserType } from "./user";

export type adminType = {
  me: IUserType;
};

export const getMe = () => {
  return get<any>(`${AUTH_BASE_URL}/user/me`);
};

export const login = (data: { email: string; password: string }) => {
  return post<any>("auth/local/login", data, { tokenFree: true });
};

export const renewAccess = (data: { refreshToken: string | null }) => {
  return post<any>(`${AUTH_BASE_URL}/auth/renewAccessToken`, data, { tokenFree: true });
};

// export function renewAccessToken() {
//   return axios.post<{ data: { accessToken: string } }>(`${BaseUrl}auth/renewAccessToken`, {
//     refreshToken: getRefreshToken(),
//   })
// }
