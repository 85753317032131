import styled from "@emotion/styled";
import { Typography, Box, Chip } from "@mui/material";
import { useEffect, useState } from "react";
import purpleCircle from "assets/blueCircle.svg";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import useSWR from "swr";
import { ILeagueType } from "types/league";
import { formatJallali } from "utils/date";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { numberToWords } from "@persian-tools/persian-tools";
const StyledTypography = styled(Typography)({
  opacity: "0.8",
  fontSize: "12px",
  paddingRight: "10px",
});
interface touchType {
  0: boolean;
  1: boolean;
  2: boolean;
}
export default function InnerLeagueDetails() {
  const context: { tabValue: boolean } = useOutletContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const [touch, setTouch] = useState<touchType>({ 0: false, 1: false, 2: false });
  const [item, setItem] = useState<any[]>([]);
  const { data: allLeagues } = useSWR<{ leagues: ILeagueType[] }>("/leagues");
  const filterdLeagueById = allLeagues?.leagues.filter((i: ILeagueType) => i._id === id) || [];

  useEffect(() => {
    setItem(
      filterdLeagueById[0]?.prizes?.map((i: any, index: number) => ({
        title:
          i.fromRank === i.toRank
            ? `نفر ${numberToWords((index + 1).toString(), { ordinal: true })}`
            : `از نفر ${numberToWords(i.fromRank.toString(), {
                ordinal: true,
              })} تا نفر ${numberToWords(i.toRank.toString(), { ordinal: true })}`,
        prize: i.prize,
        prizeType: i.prizeType,
      }))
    );
  }, [filterdLeagueById[0]]);

  const content = [
    {
      title: "شروع لیگ: ",
      content: <Typography>{formatJallali(filterdLeagueById[0]?.startDate)}</Typography>,
      editIcon: <BorderColorIcon />,
    },
    { title: "پایان لیگ: ", content: <Typography>{formatJallali(filterdLeagueById[0]?.endDate)}</Typography> },
    {
      title: " برچسب ها:",
      content: (
        <Box sx={{ display: "flex" }}>
          {filterdLeagueById[0]?.tags && filterdLeagueById[0]?.tags?.length !== 0
            ? filterdLeagueById[0].tags?.map((i: any, index: number) => {
                return (
                  <Box key={index}>
                    <Chip
                      sx={{ borderRadius: "9px", bgcolor: "#676872", color: "white", fontSize: "11px", px: 1, mr: 0.5 }}
                      label={i}
                    />
                  </Box>
                );
              })
            : ["تگ 1", "تگ 2", "تگ 3", "تگ 4"].map((i: any, index: number) => {
                return (
                  <Box key={index}>
                    <Chip
                      sx={{
                        borderRadius: "9px",
                        bgcolor: "#676872",
                        color: "white",
                        fontSize: "11px",
                        px: 1,
                        mr: 0.5,
                      }}
                      label={i}
                    />
                  </Box>
                );
              })}
        </Box>
      ),
    },
    { title: "توضیحات: ", content: <Typography>{filterdLeagueById[0]?.description}</Typography> },
    {
      title: "جوایز لیگ: ",
      content: (
        <>
          {item?.map((i: any, idx: number) => {
            return (
              <Box sx={{ display: "flex", my: 1 }} key={idx}>
                <Box
                  sx={{ mt: 0.6 }}
                  onMouseLeave={() => setTouch({ ...touch, [idx]: false })}
                  onMouseEnter={() => setTouch({ ...touch, [idx]: true })}
                >
                  <img alt="" src={purpleCircle} width={touch[idx as keyof touchType] ? 10 : 8} height={10} />
                </Box>

                <Typography sx={{ mx: 1, color: "#4A76FD", fontSize: "12px" }}>{i.title}</Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  {filterdLeagueById[0]?.prizes[idx]?.prizeType === "merchandise"
                    ? "به ارزش" + filterdLeagueById[0]?.prizes[idx].prize + " تومان"
                    : filterdLeagueById[0]?.prizes[idx].prize + " تومان"}
                </Typography>
              </Box>
            );
          })}
        </>
      ),
    },
    {
      title: " تصویر بنر لیگ: ",
      content: (
        <img
          src={filterdLeagueById[0]?.bannerImage?.url || ""}
          style={{ borderRadius: "2px" }}
          height={50}
          width={50}
          alt=""
        />
      ),
    },
    {
      title: " تصویر متحرک لیگ: ",
      content: (
        <img src={filterdLeagueById[0]?.gif?.url || ""} style={{ borderRadius: "2px" }} height={50} width={50} alt="" />
      ),
    },
  ];

  return (
    <Box sx={{ mt: 5, ml: 2, display: "flex", flexDirection: "column", gap: 3 }}>
      {content?.map((i: any, index: number) => {
        return (
          <Box key={index} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex" }}>
              <StyledTypography>{i.title}</StyledTypography>

              <Box sx={{ ml: index === 6 ? 1.5 : index === 5 ? 3.75 : 6 }}>{i.content}</Box>
            </Box>
            {!context?.tabValue && (
              <Box sx={{ mx: 5, cursor: "pointer" }} onClick={() => navigate("/leagues/new-league", { state: { id } })}>
                {i.editIcon}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
