import { createContext, ReactNode, useContext } from "react";
import ChatAdapter from "./Chat/AdminAdapter";

const socketContext = createContext<{
  adminChat: ChatAdapter | null;
}>({
  adminChat:  new ChatAdapter(),
});

export default function SocketProvider({ children }: { children: ReactNode }) {
    const adminChat = ChatAdapter && new ChatAdapter();

  return <socketContext.Provider value={{ adminChat }}>{children}</socketContext.Provider>;
}

export const useAdminSocket = () => {
  const adminChat = useContext(socketContext);
  return adminChat;
};
