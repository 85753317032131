import { Img } from "components";
import { ICONS } from "assets/icons";
import { useTranslation } from "react-i18next";
import Checkbox from "components/checkbox/Chechbox";
import { Typography, styled, Box, FormControlLabel, IconButton, IconButtonProps } from "@mui/material";

export const ItemContainerBox = styled(Box)({
  display: "flex",
  gap: "2.87rem",
  alignItems: "center",
  marginTop: "1.71rem",

  ":first-of-type": {
    marginTop: "0",
  },
});

export const ItemTitleTypography = styled(Typography)({
  minWidth: "16rem",
  width: "16rem",
  fontSize: "1.5rem",
  fontWeight: 500,
});

export const ItemContainer = (props: any) => {
  const { t } = useTranslation();
  const { title, children } = props;

  return (
    <ItemContainerBox>
      <ItemTitleTypography>{t(title)}</ItemTitleTypography>
      <Box sx={{ width: "40rem" }}>{children}</Box>
    </ItemContainerBox>
  );
};

export const HintTypography = styled(Typography)({
  fontSize: "1.2rem",
  fontWeight: 400,
  textAlign: "justify",
  color: "text.secondary",
  marginTop: "0.5rem",
});

export const CheckBoxItem = (props: any) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      }}
      control={<Checkbox {...props} />}
      label={
        <Typography
          sx={{
            fontSize: "1.3rem",
            fontWeight: 500,
          }}
        >
          {t(props.title)}
        </Typography>
      }
    />
  );
};

export const AddTagButton = (props: IconButtonProps) => {
  const { t } = useTranslation();

  return (
    <IconButton
      sx={(theme) => ({
        backgroundColor: "primary.main",
        borderRadius: 0,
        height: "100%",
        aspectRatio: "1",

        ":hover": {
          backgroundColor: `${theme.palette.primary.main}a6`,
        },

        "&.Mui-disabled": {
          backgroundColor: `${theme.palette.primary.main}7d`,
        },
      })}
      title={t("addTag")}
      {...props}
    >
      <Img
        src={ICONS.CHECK}
        alt="hide"
        sx={{
          width: "3rem",
          height: "3rem",
          cursor: "pointer",
        }}
      />
    </IconButton>
  );
};

export const DeleteButton = (props: IconButtonProps) => {
  return (
    <IconButton
      sx={(theme) => ({
        padding: 0,

        ":hover": {
          backgroundColor: `${theme.palette.primary.main}75`,
        },
      })}
      {...props}
    >
      <Img
        src={ICONS.CLOSE}
        alt="hide"
        sx={{
          width: "2rem",
          height: "2rem",
          cursor: "pointer",
        }}
      />
    </IconButton>
  );
};
