import { post, put } from "api";
import { IImageType } from "./file";

export type UserInformationType = {
  bio: string;
  coverImage: IImageType;
  firstName: string;
  fullname: string;
  lastName: string;
  profilePicture: IImageType;
  roles: any[];
  username: string;
  _id: string;
};

export type ChatMessageType = {
  conversationId: string;
  createdAt: string;
  message: string;
  sender: UserInformationType;
  updatedAt: string;
  __v: number;
  _id: string;
};
export type ReportType = {
  chatMessage: ChatMessageType;
  chatText: string;
  conversationId: string;
  createdAt: string;
  description: string;
  reportedBy: UserInformationType;
  title: string;
  updatedAt: string;
  __v: number;
  _id: string;
};
// export type chatIdType = {
//   conversationId: string;
//   createdAt: string;
//   message: string;
//   sender: UserInformationType;
//   updatedAt: string;
//   __v: number;
//   _id: string;
// };
export type blockedUserType = {
  blockUntil: string;
  chatId: ChatMessageType;
  conversationId: string;
  createdAt: string;
  targetFullName: string;
  targetUser: UserInformationType;
  targetUsername: string;
  updatedAt: string;
  user: UserInformationType;
  __v: number;
  _id: string;
};

export function blockeUser(userId: string, data: { chatId: string; blockUntil: string }) {
  return post(`/chat/user/${userId}/block`, data);
}
export function unblockUser(userId:string){
  return put(`/chat/user/${userId}/unblock`)
  
}
