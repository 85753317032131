import { Grid, Box, Typography } from "@mui/material";
import { IGameType } from "api/game";
import { formatJallali } from "utils/date";

export default function GamePublicInfo({ gameInfo }: { gameInfo?: IGameType }) {
  const infoList = [
    { title: "وضعیت", content: gameInfo?.active ? "تایید شده/فعال" : "تایید شده/غیر فعال" },
    { title: "ورژن بازی", content: gameInfo?.activeVersion?.version },
    { title: "ثبت بازی ", content:formatJallali( gameInfo?.createdAt)  },
    { title: "انتشار بازی", content:formatJallali( gameInfo?.updatedAt) },
    { title: "اخرین به روز رسانی", content: formatJallali(gameInfo?.updatedAt) },
  ];

  return (
    <Grid container>
      <Grid item xs={6} >
        <img src={gameInfo?.coverImage?.url} alt="" style={{ borderRadius: "5px", width: "230px", height: "153px" }} />
      </Grid>
      <Grid item xs={6}>
        {infoList.map((info: any, index: number) => {
          return (
            <Box key={index} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{fontSize:"14px",py:0.5}}>{info.title}</Typography>
              <Typography sx={{fontSize:"14px",py:0.5}}>{info.content}</Typography>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
}
