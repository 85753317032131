import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import Uploader from "components/uploader/Uploader";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { IGameType } from "api/game";
import { useEffect, useState } from "react";
import PageContainer from "components/PageContainer";
import EditForm from "./editForm";
import { Img } from "components";

const title = [
  { name: "title", type: "text", title: "عنوان بازی", disabled: true },
  { name: "category", type: "text", title: "دسته بندی ", disabled: true },
  { name: "tags", type: "text", title: " برچسب ها" },
  { name: "description", type: "text", title: "توضیحات بازی" },
  { name: "versionChanges", type: "text", title: " تغییرات آخرین نسخه " },
  { name: "keysControl", type: "text", title: " کنترل های بازی" },
  { name: "ageRangeFrom", type: "number", title: " محدوده سنی" },
  { name: "gameType", type: "text", title: "تکنولوژی بازی" },
  //{ name: "otherLink", type: "text", title: "لینک های دیگر بازی" },
];

export default function Details() {
  const { gameId } = useParams();
  const { data } = useSWR<{ game: IGameType }>(`/games/${gameId}`);
  const [coverGif, setCoverGif] = useState<Blob>();
  const [coverImage, setCoverImage] = useState<Blob>();
  const [mobileImage, setMobileImage] = useState<Blob>();
  const [imageFile, setImageFile] = useState<Blob>();

  const getImageByName = (fieldName: string) => {
    switch (fieldName) {
      case "coverImage":
        return coverImage;
      case "mobileImage":
        return mobileImage;
      case "imageFile":
        return imageFile;
      default:
        return null;
    }
  };

  const setImageToNullByName = (fieldName: string) => {
    switch (fieldName) {
      case "coverImageId":
        return setCoverImage(undefined);
      case "mobileImageId":
        return setMobileImage(undefined);
      case "imageFileId":
        return setImageFile(undefined);
      default:
        return null;
    }
  };

  const getImageIdByName = (fieldName: string) => {
    switch (fieldName) {
      case "coverImage":
        return data?.game?.coverImage?._id;
      case "mobileImage":
        return data?.game?.mobileImage?._id;
      case "imageFile":
        return data?.game?.image?._id;
      default:
        return null;
    }
  };

  useEffect(() => {
    data?.game?.coverGif && urlToFile(data?.game?.coverGif.url).then((file) => setCoverGif(file));
    data?.game?.coverImage && urlToFile(data?.game.coverImage.url).then((file) => setCoverImage(file));
    data?.game?.mobileImage && urlToFile(data?.game?.mobileImage.url).then((file) => setMobileImage(file));
    data?.game?.image && urlToFile(data.game.image.url).then((file) => setImageFile(file));
  }, [data]);

  const imageUploaderList = [
    { direction: "Square", name: "imageId", fileField: "imageFile", defaultPicture: data?.game?.image?.url },
    {
      direction: "vertical",
      name: "mobileImageId",
      fileField: "mobileImage",
      defaultPicture: data?.game?.mobileImage?.url,
    },
    {
      direction: "horizontal",
      name: "coverImageId",
      fileField: "coverImage",
      defaultPicture: data?.game?.coverImage?.url,
    },
  ];

  const getFileFildName = (fieldTitle: string) => {
    return fieldTitle === "gameFileId"
      ? "gameFile"
      : fieldTitle === "coverGifId"
      ? "coverGif"
      : imageUploaderList.filter((item) => item.name === fieldTitle).at(0)?.fileField || "";
  };

  const urlToFile = async (url: string) => {
    return await fetch(url).then((r) => r.blob());
  };

  return (
    <PageContainer noPadding={true}>
      {!data && (
        <div>
          <CircularProgress />
        </div>
      )}
      <Box sx={{ borderBottom: "1px solid grey", borderBottomStyle: "dotted" }}>
        <Typography sx={{fontWeight:"bold",mt:2}}>توسعه دهنده</Typography>
        <Box sx={{ display: "flex", py: 2 }}>
          <Avatar
            src={data?.game?.publisher?.coverImage?.url || data?.game?.publisher?.profilePicture?.url}
            alt=""
            sx={{ border: "1px solid grey" }}
          />
          <Box sx={{ px: 3 }}>
            <Typography>{data?.game?.publisher?.username}</Typography>
            <Typography>{data?.game?.publisher?.fullname}</Typography>
          </Box>
        </Box>
      </Box>
      {data &&
        title.map((el, index) => (
          <EditForm
            data={data}
            index={index}
            key={index}
            keyName={el.name}
            field={el.name as keyof IGameType}
            title={el.title}
            type={el.type as any}
            disabled={el.disabled}
          />
        ))}
      <Typography sx={{ my: 6, mb: 1, fontWeight: 600 }}> تصاویر پیش نمایش بازی</Typography>%
      <Box sx={{ display: "flex", justifyContent: "space-between", width: "70%" }}>
        {data &&
          imageUploaderList.map((i: any, idx: number) => (
            <Img
              src={i.defaultPicture}
              alt="knk"
              sx={{
                width: i.direction === "vertical" ? "150px" : i.direction === "horizontal" ? "270px" : "170px",
                height: i.direction === "vertical" ? "250px" : i.direction === "horizontal" ? "170px" : "170px",
                objectfit: "cover",
                borderRadius: "8px",
              }}
            />
          ))}
      </Box>
      <Typography sx={{ my: 6, mb: 1, fontWeight: 600 }}> آیکون متحرک پیش نمایش بازی (gif)</Typography>
      {data && (
        <Img
          src={data?.game?.coverGif?.url}
          alt="knk"
          sx={{
            width: "270px",
            height: "280px",
            objectfit: "cover",
            borderRadius: "8px",
          }}
        />
      )}
    </PageContainer>
  );
}
