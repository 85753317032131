import { Box, Dialog, Grid, IconButton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ICONS } from "assets/icons";
import { Img } from "components";
import DataGrid from "components/datagrid";
import Table from "components/table/Table";
import moment from "jalali-moment";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import useSWR from "swr";

export default function Version({ versions }: { versions: any[] }) {
  const [description, setDescription] = useState(false);
  const [rowObject, setRowObject] = useState<any>();
  let rows = versions?.map((i: any) => ({ ...i, id: i._id })) || [];
  const columns: GridColDef[] = useMemo(() => {
    const commonColumns: GridColDef[] = [
      {
        field: "version",
        headerName: "نسخه",
        flex: 1,
        renderCell({ row }: any) {
          return <div style={{ fontSize: "1.1rem", fontWeight: 600 }}>{row?.version}</div>;
        },
      },
      {
        field: "versionSDK",
        headerName: "ورژن sDK",
        flex: 1,
        renderCell({ row }: any) {
          return <div style={{ fontSize: "1rem", textAlign: "center", fontWeight: 600 }}>--- </div>;
        },
      },
      {
        field: "createdAt",
        headerName: " تاریخ آپلود",
        flex: 1.5,
        renderCell({ row }: any) {
          return (
            <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>{`${moment
              .utc(row?.createdAt)
              .locale("fa")
              .local()
              .format("YYYY/MM/DD")}`}</Typography>
          );
        },
      },
      {
        field: "updatedAt",
        headerName: " تایید و انتشار  ",
        flex: 1.5,
        renderCell({ row }: any) {
          return (
            <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>{`${moment
              .utc(row?.publishDate)
              .locale("fa")
              .local()
              .format("YYYY/MM/DD")}`}</Typography>
          );
        },
      },
      {
        field: "publishStatus",
        headerName: "وضعیت انتشار",
        flex: 1,
        renderCell({ row }: any) {
          return (
            <Typography sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
              {row.publishStatus === "done"
                ? "منتشر شده"
                : row.publishStatus === "inProgress"
                ? "درحال بررسی"
                : "رد شده"}
            </Typography>
          );
        },
      },

      {
        field: "data",
        headerName: "",
        flex: 1,
        renderCell({ row }: any) {
          return (
            <div style={{ display: "flex" }}>
              <a href={row?.gameFilePath} download style={{cursor:"pointer"}}>
               
              <img src={ICONS.DOWNLOAD} alt="2" />
                </a>

              <img
                style={{ cursor: "pointer" }}
                alt="1"
                src={ICONS.CHAT}
                onClick={() => {
                  setDescription(true);
                  setRowObject(row);
                }}
              />
            </div>
          );
        },
      },
    ];

    return commonColumns;
  }, []);
  return (
    <>
      <DataGrid
        sx={{
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: 600,
            overflow: "visible !important",
            width:"100%"
          },
          
        }}
        columns={columns}
        rows={rows}
      />
      <Dialog open={description} onClose={() => setDescription(false)}>
        <Box sx={{ mx: 2, mb: 2, width: "400px" }}>
          <Box display={"flex"}>
            <IconButton onClick={() => setDescription(false)}>
              <ClearIcon />
            </IconButton>
            <h3 style={{ paddingRight: "140px" }}>تغییرات بازی در ورژن</h3>
          </Box>
          <div>
            {(rowObject && rowObject?.versionChanges) ||
              "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Non nemo perspiciatis molestias! Reprehenderit, deleniti. Neque, expedita suscipit? Unde ex ducimus voluptates eaque iure ab explicabo, officiaveritatis minima?"}
          </div>
        </Box>
      </Dialog>
    </>
  );
}
