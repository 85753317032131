import React from "react";
import AddIcon from "@mui/icons-material/Add";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Button, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ButtonGroup() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Button
        startIcon={<AddIcon />}
        endIcon={<NavigateBeforeIcon />}
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        sx={{ px: 2,my:2 }}
        onClick={handleClick}
      >
        افزودن به لیست
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {[
          { title: "لیگ به عنوان اسلایدر", link: "/manageLeagues/slider/new-slider" },
          { title: "لیگ به عنوان بنر", link: "/manageLeagues/banner/new-banner" },
        ].map((item: any) => (
          <MenuItem onClick={() => navigate(item.link)}>
            <ListItemIcon>
              <NavigateBeforeIcon fontSize="small" />
            </ListItemIcon>
            <Typography sx={{ fontSize: "12px", pr: 2, fontWeight: 500 }}>{item.title}</Typography>

            <AddIcon fontSize="small" />
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
