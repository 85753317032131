import { Box, Pagination, Typography } from "@mui/material";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { ITicketType } from "api/ticket";
import { IUserType } from "api/user";
import { Table } from "components";
import PageContainer from "components/PageContainer";
import DataGrid from "components/datagrid";
import moment from "jalali-moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

export default function UserSupport({
  userInfo,
  type,
}: {
  userInfo: IUserType | undefined;
  type: "user" | "publisher";
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: usertickets } = useSWR<any>(`/tickets?userId=${userInfo?._id}`);
  const [page, setPage] = useState<number>(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    //onPageChange(value);
  };
  useEffect(() => {
    if (usertickets?.tickets) {
      let rows: GridRowsProp = usertickets?.tickets.map((ticket: ITicketType) => ({
        number: ticket?.ticketId,
        title: ticket?.title,
        status: ticket?.status,
        createdAt: ticket?.createdAt,
        updatedAt: ticket?.updatedAt,
        id: ticket?._id,
      }));
    }
  }, [usertickets]);

  const columns: GridColDef[] = [
    {
      field: "number",
      headerName: t("number"),

      flex: 0.5,
      renderCell({ row }: any) {
        return <Typography sx={{ fontSize: "12px" }}>{row?.number}</Typography>;
      },
    },
    {
      field: "title",
      headerName: t("title"),
      flex: 1,

      renderCell({ row }: any) {
        return <Typography sx={{ fontSize: "12px" }}>{row?.title}</Typography>;
      },
    },
    {
      field: "status",
      headerName: t("status"),
      flex: 0.5,

      renderCell({ row }: { row: ITicketType }) {
        return (
          <>
            {row?.status === "new" ? (
              <Typography sx={{ bgcolor: "#FFC1C1", color: "#F84E58", px: 3, py: 0.5, borderRadius: "31px" }}>
                جدید
              </Typography>
            ) : row?.status === "replied" ? (
              <Typography sx={{ bgcolor: "#E9FFEA", color: "#04BD0C", px: 3, py: 0.5, borderRadius: "31px" }}>
                پاسخ داده شده
              </Typography>
            ) : row?.status === "closed" ? (
              <Typography sx={{ bgcolor: "#292A5433", color: "#292A54", px: 3, py: 0.5, borderRadius: "31px" }}>
                بسته شده
              </Typography>
            ) : (
              <Typography sx={{ bgcolor: "#FFF0CF", color: "#E89F00", px: 3, py: 0.5, borderRadius: "31px" }}>
                درحال بررسی
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "createdAt",
      headerName: t("createdAt"),
      flex: 0.5,

      renderCell({ row }: { row: ITicketType }) {
        return (
          <Typography sx={{ fontSize: "1.3rem", fontWeight: 300 }}>{`${moment
            .utc(row.createdAt)
            .locale("fa")
            .local()
            .format(" YYYY/MM/DD")}`}</Typography>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: t("lastReplay"),
      flex: 0.5,

      renderCell({ row }: { row: ITicketType }) {
        return (
          <Typography sx={{ fontSize: "1.3rem", fontWeight: 300 }}>{`${moment
            .utc(row.updatedAt)
            .locale("fa")
            .local()
            .format(" YYYY/MM/DD")}`}</Typography>
        );
      },
    },
  ];

  return (
    <PageContainer noPadding={true}>
      <DataGrid
        columns={columns}
        rows={
          usertickets?.tickets?.map((ticket: ITicketType) => ({
            number: ticket?.ticketId,
            title: ticket?.title,
            status: ticket?.status,
            createdAt: ticket?.createdAt,
            lastReplay: ticket?.updatedAt,
            id: ticket?._id,
          })) || []
        }
        // getRowId={(row: any) => row.id}
        onRowClick={(row) => navigate(`/users/user/tickets/${row?.id}/${userInfo?._id}`)}
        // onRowClick={onRowClick}
        // checkboxSelection
        // onSelectionModelChange={onSelectionModelChange}
        // selectionModel={selectedItems}
        sx={{
        
          border: "1px solid gray.light",
          //   borderColor: "transparent",
borderRadius:"12px",
          "& .MuiDataGrid-HeaderContent": {
            fontSize: "2rem",
            fontWeight: 400,
            textAlign: "center",
            pl: 4,
            bgcolor: "red",
          },
          "& .MuiDataGrid-virtualScroller": { marginTop: "14px" },
          "& .MuiDataGrid-cellContent": {
            borderBottomColor: "text.primary",
            fontSize: "1.3rem",
            fontWeight: 300,
          },

        }}
      />
      <Box sx={{ py: 2 }}>
        <Pagination count={10} variant="outlined" shape="rounded" page={page} onChange={handleChange} color="primary" />
      </Box>
    </PageContainer>
  );
}
