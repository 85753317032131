export function combineDateTime(timeString: string, dateString: string): Date {
  const [hours, minutes] = timeString.split(":");
  const [year, month, day] = dateString.split("-");

  console.log(year, month, day, hours, minutes);
  const dateTime = new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10),
    parseInt(hours, 10),
    parseInt(minutes, 10)
  );

  return dateTime;
}

//    Example usage
// const timeString = '14:30'; // hh:mm format
// const dateString = '2023-04-23'; // yyyy-mm-dd format

// const combinedDateTime = combineDateTime(timeString, dateString);
// console.log(combinedDateTime);  Output: Sun Apr 23 2023 14:30:00 GMT+0000 (Coordinated Universal Time)
