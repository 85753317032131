import { Typography, Box, TextField, InputAdornment, styled } from "@mui/material";
import linkIcon from "assets/link.png";
import { Img } from "components";
import { useFormik } from "formik";
const MyTYpography = styled(Typography)({
  marginBottom: "1rem",
  marginTop: "1rem",
  fontSize: "14px",
  color: "#676872",
});
const StyledTextField = styled(TextField)({
  height: "40px",
});
const textAreaStyle = {
  width: "100%",
  borderRadius: "8px",
  minHeight: "100px",
  border: "1px solid #676872",
};

export default function Form() {
  const formik = useFormik({
    initialValues: {
      shenase: "",
      link: "",
      code: "",
    },
    onSubmit: () => {
      console.log("still not to have api");
    },
  });
  return (
    <Box sx={{ lineHeight: "5rem" }}>
      <MyTYpography>شناسه یکتا</MyTYpography>
      <StyledTextField fullWidth />

      <MyTYpography>لینک</MyTYpography>

      <StyledTextField
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Img
                onClick={() => console.log("fgvhfg")}
                src={linkIcon}
                alt="link"
                sx={{
                  cursor: "pointer",
                  backgroundColor: "white",
                  borderRadius: 2,
                  width: "4rem",
                  height: "4rem",
                  aspectRatio: "1",

                  "&.Mui-disabled": {
                    backgroundColor: "blue",
                  },
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      <MyTYpography>کد تبلیغ</MyTYpography>
      <textarea style={{ ...textAreaStyle }} />
    </Box>
  );
}
