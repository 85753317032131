import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEN from "./translation/en.json";
import translationsFa from "./translation/fa.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  fa: {
    translation: translationsFa,
  },
  en: {
    translation: translationsEN,
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    lng: "fa", // set default lng to fa
    fallbackLng: "fa", // default language
    supportedLngs: ["fa", "en"],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
