import { Avatar, Box, Dialog, Divider, Grid, Menu, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import blockUser from "assets/block-user.svg";
import reportUser from "assets/report-user.svg";
import { useState } from "react";
import ReportUser from "./reportUser";
import { useSocket } from "logic/SocketProvider";
import { formatJallali } from "utils/date";
import baziExCahtLogo from "assets/baziexChatLogo.svg";

import { useAdmin } from "features/admin/hooks";

export default function ChatDetails({
  chatMessages,
  index,
  onShowChatTime,
}: {
  chatMessages: any;
  index: number;
  onShowChatTime: boolean;
}) {
  const [displayMore, setDisplayMore] = useState<number>(-1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openReportModal, setOpenReportModal] = useState(false);
  const open = Boolean(anchorEl);
  const { chat } = useSocket();
  const me = useAdmin();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    chat?.blockUser(chatMessages?.sender?._id);

    setAnchorEl(null);
  };
  const handleMenu = (index: number) => {
    setDisplayMore(index);
  };
  const handleCloseMenu = (index: number) => {
    setDisplayMore(index);
  };

  return (
    <div>
      <Grid
        container
        sx={{
          pr: 1,
          py: 0.5,
          cursor: "pointer",
        }}
      >
        <Grid item xs={1} sx={{ pl: 2 }}>
          <Avatar
            src={me?._id === chatMessages?.sender?._id ? baziExCahtLogo : chatMessages?.sender?.profilePicture?.url}
          />
        </Grid>
        <Grid item xs={0.5}>
          {onShowChatTime && (
            <Typography sx={{ fontSize: "11px", pt: 1.3 }}>{formatJallali(chatMessages?.createdAt)}</Typography>
          )}
        </Grid>
        <Grid item xs={1.5} sx={{ fontSize: "13px", pt: 0.9, opacity: "70%", px: 0.3 }}>
          {me?._id === chatMessages?.sender?._id
            ? "شما"
            : chatMessages?.sender?.username ||
              chatMessages?.sender?.fullname ||
              chatMessages?.sender?.firstName ||
              chatMessages?.sender?.lastName ||
              "ananymous"}
        </Grid>
        <Grid item xs={8} sx={{ textAlign: "start", pt: 0.7, pr: 1 }}>
          <Typography sx={{ fontSize: "13px" }}>{chatMessages?.message}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{ pt: 0.5, cursor: "pointer", textAlign: "end" }}
          onClick={(event: any) => handleClick(event)}
        >
          {/* {displayMore === index && <MoreVertIcon />} */}
        </Grid>
      </Grid>

      {/* <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose} sx={{ display: "flex", justifyContent: "center" }}>
          <Typography> {chatMessages?.sender?.username}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <img src={blockUser} height={35} width={120} alt="" />
        </MenuItem>
        <MenuItem onClick={() => setOpenReportModal(true)}>
          <img src={reportUser} height={30} width={90} alt="" />
        </MenuItem>
      </Menu>
      <Dialog open={openReportModal} onClose={() => setOpenReportModal(false)}>
        <ReportUser onclose={() => setOpenReportModal(false)} chatId={chatMessages?._id} />
      </Dialog> */}
    </div>
  );
}
