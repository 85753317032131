import { Box, Button, Grid } from "@mui/material";
import { ICONS } from "assets/icons";
import { Checkbox, Img } from "components";
import PageContainer from "components/PageContainer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import NewAdSlider from "./newAdSlider";
import NewCompanySlider from "./newCompanySlider";
import { useLocation } from "react-router-dom";

export default function ManageAds() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fullCheckbox, setFullCheckbox] = useState(false);
  const [isClicked, setIsClicked] = useState(0);
  const [openAddAdList, setOpenAddAdList] = useState(false);
  const [openAddCompany, setOpenAddCompany] = useState(false);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname.slice(location.pathname.lastIndexOf("/")).substring(1));
  useEffect(() => {
    setActiveTab(location.pathname.slice(location.pathname.lastIndexOf("/")).substring(1));
  }, [location]);
  const handleClose = () => {
    if (activeTab === "adsList") {
      setOpenAddAdList(false);
    } else if (activeTab === "adsCompanies") {
      setOpenAddCompany(false);
    }
  };

  function handleClick(link: string, idx: number) {
    setIsClicked(idx);
    navigate(link);
    setActiveTab(link);
  }
  const list = [
    { title: "آمار و گزارشات", link: "adsStatistics" },
    { title: "شرکت های تبلیغاتی", link: "adsCompanies" },
    { title: "لیست تبلیغات", link: "adsList" },
  ];
  function handleOpenModal() {
    if (activeTab === "adsList") {
      setOpenAddAdList(true);
    } else if (activeTab === "adsCompanies") {
      setOpenAddCompany(true);
    }
  }
  return (
    <PageContainer>
      <Grid
        container
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "1.7rem",
          textAlign: "center",
          mb: 2,
        }}
      >
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "start" }}>
          <Box sx={{ p: 2 }}>
            {activeTab !== "adsStatistics" && (
              <Button
                onClick={handleOpenModal}
                variant="contained"
                startIcon={<Img src={ICONS.PLUS} alt="add" sx={{ width: "2rem", height: "2rem" }} />}
                sx={{ height: "44px", px: 2 }}
              >
                {activeTab === "adsList" ? t("addNewAd") : activeTab === "adsCompanies" && "افزودن شرکت جدید"}
              </Button>
            )}
            {activeTab === "adsCompanies" && <NewCompanySlider open={openAddCompany} onClose={handleClose} />}
            {activeTab === "adsList" && <NewAdSlider open={openAddAdList} onClose={handleClose} />}
            <Box sx={{ pb: 1, display: "flex", mt: 5 }}>
              <Checkbox checked={fullCheckbox} onClick={() => setFullCheckbox(!fullCheckbox)} />
              <Box>
                <Button>
                  <Img
                    src={ICONS.ARROW_DOWN}
                    alt="add"
                    sx={{
                      width: "2rem",
                      height: "2rem",
                    }}
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: "1px solid #C0C1CE", display: "flex", gap: 3 }}>
            {list.map((i: any, idx: number) => {
              return (
                <Box
                  onClick={() => handleClick(i.link, idx)}
                  key={idx}
                  sx={{
                    borderRadius: "2px",
                    borderBottom: activeTab === i.link ? "3px solid blue" : "1px solid transparent",
                    cursor: "pointer",
                    fontSize: "14px",
                    py: 2,
                    transition: "border 0.2s  allow-discrete",
                    pl: 1,
                    fontWeight: activeTab === i.link ? 600 : "unset",
                    color: activeTab === i.link ? "#4A76FD" : "unset",
                  }}
                >
                  {i.title}
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Outlet />
      </Grid>
    </PageContainer>
  );
}
