type queryType = string | number | undefined | null | string[] | number[];

export default function generateQuery(queries: { [key: string]: queryType }) {
  const temp = JSON.parse(JSON.stringify(queries));
  Object.keys(temp).forEach((k: keyof typeof temp) => {
    if (!temp[k]) {
      delete temp[k];
    }
  });
  const searchQuery = new URLSearchParams(temp);

  const entries: any = {};
  searchQuery.forEach((value, key) => {
    entries[key] = value;
  });

  return { query: searchQuery, entries };
}
