import { Grid } from "@mui/material";
import { ListCheckbox } from "./Chechbox";
import { DataGrid } from "@mui/x-data-grid";

const Table = (props: any) => {
  const { rows, columns, ...rest } = props;

  return (
    <Grid
      sx={{
        height: "85rem",

        // height: `${(rows.length + 1) * 41}px`,
        //  minHeight: `${11 * 40}px`
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter
        disableColumnMenu
        headerHeight={40}
        rowHeight={40}
        checkboxSelection={true}
        components={{
          BaseCheckbox: (props) => <ListCheckbox {...props} />,
          // ColumnSortedAscendingIcon
          // ColumnSortedDescendingIcon
        }}
        // onSortModelChange
        sx={{
          fontSize: "12px",
          cursor: "pointer",
          border: "1px solid",
          borderColor: "transparent",
          "& .MuiDataGrid-columnHeaders": {
            display: props?.onHeaderHide ? "unset" : "none",
          },
          "& .MuiDataGrid-virtualScroller": { marginTop: props?.onHeaderHide ? "2rem" : "0!important" },
          "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
            borderBottomColor: "gray.light",
          },
          "& .MuiDataGrid-cellContent": {
            borderBottomColor: "text.primary",
            fontSize: props?.onHeaderHide ? "0.4rem" : "1.3rem",
            fontWeight: 300,
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },

          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
            display: "none",
          },

          "& .MuiDataGrid-row": {
            "&.Mui-selected": {
              backgroundColor: "background.selectedTableRow",
            },
          },

          ...props.sx,
        }}
        {...rest}
      />
    </Grid>
  );
};

export default Table;
