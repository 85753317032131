import { IImageType } from "api/file";
import { gameType } from "./game";

export enum LEAGUE_STATUS {
  ACTIVE = "active",
  LASTLEAGUES = "lastleagues",
  UPCOMING = "upcoming",
}

export interface LeagueType {
  title: string;
  gameId: null | string;
  bannerImageId: null | string;
  startDate: Date | string;
  endDate: Date | string;
  description: string;
  prizes: PrizeType[];
  tags?: string[];
  showInSlider?: boolean;
  entranceCoinRequired: number;
  totalPrize: string;
  gifId: null | string;
  colorCode: string;
}

export interface PrizeType {
  fromRank: number;
  toRank: number;
  prize: string;
  isRange: boolean;
}


export type ILeagueType = {
  _id: string;
  coverImage: IImageType;
  bannerImage: IImageType;
  owner: {
    _id: string;
    firstName: string;
    lastName: string;
    fullname: string;
    username: string;
    coverImage: any;
    profilePicture: {
      _id: string;
      owner: string;
      contentType: string;
      uploadStatus: string;
      createdAt: string;
      updatedAt: string;
      __v: number;
      url: string;
    };
    roles: any[];

    bio: string;
  };
  title: string;
  game: gameType;
  startDate: string;
  endDate: string;
  active: boolean;
  description: string;
  entranceCoinRequired?: number;
  prizes: {
    fromRank: number;
    toRank: number;
    prize: number;
    _id: string;
    prizeType: "merchandise" | "cash";
  }[];
  gif: IImageType;
  reportCount: 0;
  totalPlayersPlayedCount: 0;
  playCount: 0;
  totalPlayTimeAmount: 0;
  prizeStatus: "notProcessed" | "";
  specialLeague: false;
  tags: Array<[string]>;
  totalPrize: number;
  special: boolean;
  rate: any;
  rateCount: number;
  likeCount: number;
  commentCount: number;
  createdAt: string;
  updatedAt: string;
  colorCode: string;
  __v: number;
};
