import "./i18n";
import App from "./App";
import React from "react";
import store from "store";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

if (localStorage.getItem('i18nextLng') === null) {
  localStorage.setItem('i18nextLng', 'fa')
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
