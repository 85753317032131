import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { IGameType } from "api/game";
import ClearIcon from "@mui/icons-material/Clear";

export default function PlayGame({
  game,
  onClose,
  gameVersion,
}: {
  game: IGameType;
  onClose: () => void;
  gameVersion: any;
}) {
  
  return (
    <Box>
      <AppBar position="static" sx={{ bgcolor: "#F5F5F9" }}>
        <Toolbar sx={{ bgcolor: "#F5F5F9" }}>
          <IconButton size="large" edge="start" aria-label="menu" sx={{ mr: 2 }} onClick={onClose}>
            <ClearIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <iframe
        title="game"
        allowTransparency={true}
        src={gameVersion?.gameFilePath}
        // ref={iframeRef}
        allowFullScreen={true}
        width="100%"
        height="100%"
        style={{
          display: "block",
          width: "100%",
          height: "600px",
          border: "none",
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: "white",
        }}
      ></iframe>
    </Box>
  );
}
